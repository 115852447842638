import { useErrorHandler } from 'lib/ErrorHandling';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import { useLocalization } from 'lib/Localization';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Backend } from 'lib/Http/Backend';
import { User } from 'types/models';
import { useAccount } from 'lib/Account';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

const { formLayout } = SingleColumnFormLayout;

type Props = {
    setIsEditUserProfileOpen: (isEditUserProfileOpen: boolean) => void;
};

export const UserProfileForm = ({ setIsEditUserProfileOpen }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const { accountUser, setAccountUser } = useAccount();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);

    /**
     * Fetch data form backend.
     *
     * @return {Promise<void>}
     */
    const loadUserProfileData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get(`/account/profile`);
            form.setFieldsValue(response.data);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [form, handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadUserProfileData();
    }, [loadUserProfileData]);

    /**
     * Save user's profile data.
     *
     * @param values
     *
     * @returns {Promise<void>}
     */
    const saveUserProfileHandler = async (values: User) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.put(`/account/profile`, values);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Profile successfully saved'),
                });
                setAccountUser({ ...accountUser, ...response.data });
                setIsEditUserProfileOpen(false);
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const profileLayout = { ...formLayout, labelCol: { span: 7 }, wrapperCol: { span: 20 } };

    return isDataLoading ? (
        <LoaderSkeleton size={2} />
    ) : (
        <Form form={form} onFinish={saveUserProfileHandler} {...profileLayout} layout={'vertical'}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="first_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a first name')
                    },
                    {
                        max: 150,
                        message: t('The first name may not be greater than 150 characters')
                    }
                ]}>
                <FloatingLabel label={t('First name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="last_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a last name')
                    },
                    {
                        max: 150,
                        message: t('The last name may not be greater than 150 characters')
                    }
                ]}>
                <FloatingLabel label={t('Last name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: t('Please enter a valid email'),
                    },
                    {
                        required: true,
                        message: t('Please enter an email address'),
                    },
                ]}
            >
                <FloatingLabel label={t('Email')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="phone_number">
                <FloatingLabel label={t('Phone')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};
