import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { AddressTable } from './AddressTable';
import { AddressForm } from './AddressForm';
import { Button, Drawer, FormInstance, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Address } from 'types/models/Address';
import { Department } from 'types/models/Department';
import { useNavigate } from 'react-router-dom-v5-compat';

export const Addresses = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const navigate = useNavigate();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [address, setAddress] = useState<Address>();
    const [departments, setDepartments] = useState<Department[]>([]);
    const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);

    const addressesURL = '/data-management/addresses';

    /**
     * Fetch data form backend.
     *
     * @return void
     */
    const loadAddressesData = useCallback(async () => {
        try {
            setIsDataLoading(true);

            const response = await Backend.get(`${addressesURL}?collect=departments&relations=country,departments`);

            setAddresses(response.data.addresses);
            setDepartments(response.data.collections.departments);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadAddressesData();
    }, [loadAddressesData]);

    /**
     * Open the address form to create/update a address.
     *
     * @param address {object}
     * @return void
     */
    const openAddressHandler = (address?: Address) => {
        setAddress(address);
        setIsAddressFormOpen(true);
    };

    /**
     * Save a address.
     *
     * @param values
     * @param form
     * @return void
     */
    const saveAddressHandler = async (values: Address, form: FormInstance) => {
        // Update, else create new.
        try {
            setIsDataSaving(true);
            if (values.id) {
                const response = await Backend.put(`${addressesURL}/${values.id}`, values);
                if (response.status === Backend.responseStatus.HTTP_OK) {
                    notification.success({
                        message: t('Data successfully saved'),
                    });
                    setIsAddressFormOpen(false);
                }
            } else {
                const response = await Backend.post(addressesURL, values);
                if (response.status === Backend.responseStatus.HTTP_CREATED) {
                    notification.success({
                        message: t('Data successfully created'),
                    });
                    setIsAddressFormOpen(false);
                }
            }
            loadAddressesData();
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const headerButtons = [
        <Button key="addAddress" type="primary" icon={<PlusOutlined />} onClick={() => openAddressHandler()}>
            {t('Add Location')}
        </Button>,
    ];

    const departmentFilterOptions = departments?.map((department) => ({
        value: department.id,
        text: department.name,
    }));

    return (
        <>
            <PageHeader
                title={t('Locations')}
                backIcon={false}
                ghost={false}
                onBack={() => navigate(-1)}
                extra={headerButtons}
            />

            <AddressTable
                addressesURL={addressesURL}
                addresses={addresses}
                openAddressHandler={openAddressHandler}
                departmentFilterOptions={departmentFilterOptions}
                loading={isDataLoading}
                loadAddressesData={loadAddressesData}
            />
            <Drawer
                title={address?.id ? t('Edit Location') : t('Add Location')}
                width={500}
                onClose={() => setIsAddressFormOpen(false)}
                open={isAddressFormOpen}
            >
                <AddressForm
                    address={address}
                    departments={departments}
                    saveAddressHandler={saveAddressHandler}
                    isDataSaving={isDataSaving}
                />
            </Drawer>
        </>
    );
};
