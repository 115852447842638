import { AppSection, MemberType, OrganizationType } from 'types';
import { SideMenu } from './SideMenu';
import { Admin } from './Admin';

/**
 * Admin sub application.
 */
export const AdminSection: AppSection = {
    bottomMenu: false,
    identifier: OrganizationType.ADMIN,
    userType: MemberType.ADMIN,
    sideMenu: SideMenu,
    content: Admin,
};
