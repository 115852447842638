import 'shared/AntDesignUtils/TableDatePickerFilterDropdown/TableDateRangeFilterStyle.scss';
import { Button, DatePicker, Space } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { getDateRangePresets } from 'lib/Helpers/FilterHelper';

type Props = {
    setSelectedKeys: (selectedKeys: any[]) => void;
    selectedKeys: any[];
    confirm: () => void;
    clearFilters: () => void;
    dataIndex: string;
    visible: boolean;
};

const { RangePicker } = DatePicker;

export const TableDateRangePickerFilterDropDown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex,
    visible,
}: Props) => {
    const { t } = useLocalization();

    const [selectedRange, setSelectedRange] = useState(null);
    const [open, setOpen] = useState(visible);

    useEffect(() => setOpen(visible), [visible]);

    const clearDates = () => {
        setSelectedKeys([['', '']]);
        setSelectedRange(null);
        clearFilters();
        confirm();
        setOpen(false);
    };

    const rangeChanged = (rangeDates: any) => {
        if (rangeDates === null) {
            clearDates();
        } else {
            if (rangeDates[0] !== null && rangeDates[1] !== null) {
                setSelectedKeys([rangeDates.map((date: any) => date.format('DD-MM-YYYY'))]);
                confirm();
                setOpen(false);
            }

            setSelectedRange(rangeDates);
        }
    };

    return (
        <div className="table-date-range-picker-filter-dropdown" style={{ padding: 8 }}>
            <Space>
                <RangePicker
                    onChange={(e: any) => {
                        rangeChanged(e);
                    }}
                    style={{ width: '370px' }}
                    picker="date"
                    allowEmpty={[true, true]}
                    format={'DD.MM.YYYY'}
                    allowClear={false}
                    presets={getDateRangePresets(t)}
                    value={selectedRange}
                    open={open}
                    onOpenChange={setOpen}
                />
                <Button
                    className="clear-dates-btn"
                    type="link"
                    title={t('Click to clear the dates filter')}
                    onClick={() => clearDates()}
                >
                    {t('Clear dates')}
                </Button>
                <Button key="1" type="primary" title={t('Click to close the filter')} onClick={() => confirm()}>
                    {t('Close')}
                </Button>
            </Space>
        </div>
    );
};
