export enum RequestState {
    DRAFT = 'draft',
    POSTED = 'posted',
    PENDING = 'pending',
    FULFILLED = 'fulfilled',
    CANCELED = 'cancelled',
    REJECTED = 'rejected',
    CONCLUDED = 'concluded',
    EXPIRED = 'expired',
}

export enum RequestTransition {
    POST = 'post',
    CANCEL = 'cancel',
    REJECT = 'reject',
    ACCEPT = 'accept',
    FULFILL = 'fulfill',
}
