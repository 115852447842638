export enum JobState {
    OPEN = 'open',
    FILLED = 'filled',
    CANCELED = 'cancelled',
}

export enum JobTransition {
    FILL = 'fill',
    CANCEL = 'cancel',
}
