export enum ProviderRequestState {
    OPEN = 'open',
    ACCEPTED = 'accepted',
    CONCLUDED = 'concluded',
    REJECTED = 'rejected',
}

export enum ProviderRequestTransition {
    ACCEPT = 'accept',
    REJECT = 'reject',
    CONCLUDE = 'conclude',
}
