import { t } from 'lib/Localization';
import { getMustBeValidErrorMessage, parseDateTime, throwTypeError, valueIsPresent } from 'lib/Validation/helpers';
import { timePattern } from 'lib/Validation/constants';

export const after = (targetValue, errorMessage) => ({
    async validator(_, value) {
        if (!valueIsPresent(value) || !valueIsPresent(targetValue)) {
            return true;
        }

        const valueDate = parseDateTime(value);
        const targetDate = parseDateTime(targetValue);

        if (!valueDate.isAfter(targetDate)) {
            throw new Error(errorMessage || t('Value must be after %{value}', { value: targetValue }));
        }

        return true;
    },
});

export const afterField =
    (field, errorMessage) =>
    ({ getFieldValue }) =>
        after(getFieldValue(field), errorMessage);

export const afterOrEqual = (targetValue, errorMessage) => ({
    async validator(_, value) {
        if (!valueIsPresent(value) || !valueIsPresent(targetValue)) {
            return true;
        }

        const valueDate = parseDateTime(value);
        const targetDate = parseDateTime(targetValue);

        if (!valueDate.isSameOrAfter(targetDate)) {
            throw new Error(errorMessage || t('Value must be same as or after %{value}', { value: targetValue }));
        }

        return true;
    },
});

export const afterOrEqualToField =
    (field, errorMessage) =>
    ({ getFieldValue }) =>
        afterOrEqual(getFieldValue(field), errorMessage);

export const alpha = (errorMessage) => ({
    pattern: /^[a-z]+$/i,
    message: errorMessage || t('Value can only contain alphabets'),
});

export const alphaNum = (errorMessage) => ({
    pattern: /^[a-z0-9]+$/i,
    message: errorMessage || t('Value can only contain alphabets and numbers'),
});

export const before = (targetValue, errorMessage) => ({
    async validator(_, value) {
        if (!valueIsPresent(value) || !valueIsPresent(targetValue)) {
            return true;
        }

        const valueDate = parseDateTime(value);
        const targetDate = parseDateTime(targetValue);

        if (!valueDate.isBefore(targetDate)) {
            throw new Error(errorMessage || t('Value must be before %{value}', { value: targetValue }));
        }

        return true;
    },
});

export const beforeField =
    (field, errorMessage) =>
    ({ getFieldValue }) =>
        before(getFieldValue(field), errorMessage);

export const beforeOrEqual = (targetValue, errorMessage) => ({
    async validator(_, value) {
        if (!valueIsPresent(value) || !valueIsPresent(targetValue)) {
            return true;
        }

        const valueDate = parseDateTime(value);
        const targetDate = parseDateTime(targetValue);

        if (!valueDate.isSameOrBefore(targetDate)) {
            throw new Error(errorMessage || t('Value must be same as or before %{value}', { value: targetValue }));
        }

        return true;
    },
});

export const beforeOrEqualToField =
    (field, errorMessage) =>
    ({ getFieldValue }) =>
        beforeOrEqual(getFieldValue(field), errorMessage);

export const betweenDates = (startDate, endDate, errorMessage) => ({
    async validator(_, value) {
        if (!valueIsPresent(value) || !valueIsPresent(startDate) || !valueIsPresent(endDate)) {
            return true;
        }

        const valueDate = parseDateTime(value);

        if (valueDate.isBefore(parseDateTime(startDate)) || valueDate.isAfter(parseDateTime(endDate))) {
            throw new Error(
                errorMessage || t('Value must be between %{startDate} and %{endDate}', { startDate, endDate })
            );
        }

        return true;
    },
});

export const betweenDateFields =
    (startField, endField, errorMessage) =>
    ({ getFieldValue }) =>
        betweenDates(getFieldValue(startField), getFieldValue(endField), errorMessage);

export const color = (errorMessage) => ({
    pattern: /^#([a-f0-9]{3}|[a-f0-9]{6})$/i,
    message: errorMessage || getMustBeValidErrorMessage('color'),
});

export const currency = (errorMessage) => ({
    pattern: /^[-+]?\d{1,10}(?:\.\d{0,2})?$/,
    message: errorMessage || getMustBeValidErrorMessage('currency'),
});

export const date = (errorMessage) => ({
    type: 'date',
    message: errorMessage || getMustBeValidErrorMessage('date'),
});

export const dateTime = (errorMessage) => ({
    type: 'date',
    pattern:
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])\s(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9])(:(0[0-9]|[1-5][0-9]))?([ap]m)?$/i,
    message: errorMessage || getMustBeValidErrorMessage('date and time'),
});

export const decimal = (errorMessage) => ({
    pattern: /^\d+\.\d+$/i,
    message: errorMessage || getMustBeValidErrorMessage('decimal number'),
});

export const email = (errorMessage) => ({
    type: 'email',
    message: errorMessage || getMustBeValidErrorMessage('email'),
});

export const file = (errorMessage) => ({
    async validator(_, value) {
        if (valueIsPresent(value) && !(value instanceof File)) {
            return new Error(errorMessage || getMustBeValidErrorMessage('file'));
        }

        return true;
    },
});

export const max = (maxValue, errorMessage) => ({
    async validator(_, value) {
        if (valueIsPresent(value) && Number(value) > Number(maxValue)) {
            throw new Error(errorMessage || t('Value must not be more than %{maxValue}', { maxValue }));
        }

        return true;
    },
});

export const maxLength = max;

export const min = (value, errorMessage) => ({
    min: value,
    message: errorMessage || t('Value must not be less than %{value}', { value }),
});

export const minLength = min;

export const number = (errorMessage) => ({
    async validator(_, value) {
        if (valueIsPresent(value) && `${parseFloat(value)}` !== `${value}`) {
            throwTypeError('number', errorMessage);
        }

        return true;
    },
});

export const numeric = number;

export const phone = (errorMessage) => ({
    pattern: /^(?:\+|00|011)[\d. ()-]{7,}$/,
    message: errorMessage || getMustBeValidErrorMessage('phone number'),
    transform: (value) => (value || '').replace(/\s/g, ''),
});

export const regex = (pattern, errorMessage) => ({
    pattern,
    message: errorMessage || t('Value is invalid'),
});

export const required = (errorMessage) => ({
    required: true,
    message: errorMessage || t('Value is required'),
});

export const same = (targetField, { date, errorMessage, otherName }) => ({
    async validator({ field }, value, callback, source) {
        const targetFieldValue = source[targetField];

        if (
            valueIsPresent(value) &&
            valueIsPresent(targetFieldValue) &&
            targetFieldValue !== value &&
            date &&
            !parseDateTime(value).isSame(parseDateTime(targetFieldValue))
        ) {
            return new Error(
                errorMessage || t('Value must be same as %{otherName}', { otherName: otherName || targetField })
            );
        }

        return true;
    },
});

export const time = (errorMessage) => ({
    pattern: timePattern,
    message: errorMessage || getMustBeValidErrorMessage('time'),
});

export const url = (errorMessage) => ({
    type: 'url',
    message: errorMessage || getMustBeValidErrorMessage('url'),
});
