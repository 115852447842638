import './SetupGuidePopup.scss';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { Button, Progress } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useEffect, useRef, useState } from 'react';
import { ShrinkOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ChatInfoIcon } from 'shared/icons/ChatInfoIcon';
import { Backend } from 'lib/Http/Backend';
import { User } from 'types/models';
import { getSteps } from './Steps';
import { useAccount } from 'lib/Account';
import { userIsAdmin } from 'lib/Helpers/UserHelper';

type Props = {
    open: boolean;
    onClose: () => void;
    profile: User;
};

export const SetupGuidePopup = ({ open, onClose, profile }: Props) => {
    const { t } = useLocalization();
    const [currentStep, setCurrentStep] = useState(0);
    const [isExpanded, setIsExpanded] = useState(true);
    const [tutorialCompleted, setTutorialCompleted] = useState(false);
    const { accountUser: user } = useAccount();
    const steps = getSteps(user, t);

    const navigate = useNavigate();
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (profile) {
            setTutorialCompleted(profile.tutorial_completed);
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profile]);

    if (tutorialCompleted || (user && userIsAdmin(user))) return null;

    const completeTutorial = async () => {
        if (!profile) {
            return;
        }

        try {
            await updateUserTutorialStatus(true, profile);
            setTutorialCompleted(true);
            onClose();
        } catch {
            return;
        }
    };

    const handleNext = () => {
        if (currentStep >= steps.length - 1) {
            return;
        }

        setCurrentStep(currentStep + 1);

        const nextStep = steps[currentStep + 1];

        if (nextStep && nextStep.route) {
            navigate(nextStep.route);
        }
    };

    const updateUserTutorialStatus = async (status: boolean, userData: User) => {
        try {
            const payload = {
                ...userData,
                tutorial_completed: status,
            };

            const response = await Backend.put(`/account/profile`, payload);

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error updating tutorial status:', error);
        }
    };

    const handlePrevious = () => {
        if (currentStep <= 0) {
            return;
        }

        setCurrentStep(currentStep - 1);

        const previousStep = steps[currentStep - 1];

        if (previousStep && previousStep.route) {
            navigate(previousStep.route);
        }
    };

    const getFooterButtons = () => {
        const buttons = [];

        if (currentStep === 0) {
            buttons.push(
                <span key="ask-later" className="ask-later" onClick={onClose}>
                    {t('Ask me later')}
                </span>,
                <Button key="start" className="start-button" type="primary" onClick={handleNext}>
                    {t('Get Started')}
                </Button>
            );
        } else if (currentStep === steps.length - 1) {
            buttons.push(
                <Button key="finish" className="finish-button" type="primary" onClick={completeTutorial}>
                    {t('Finish')}
                </Button>
            );
        } else {
            buttons.push(
                <div key="progress" className="setup-guide-progress">
                    <Progress percent={((currentStep + 1) / steps.length) * 100} showInfo={false} />
                </div>,
                <Button key="previous" className="back-button" onClick={handlePrevious}>
                    {t('Back')}
                </Button>,
                <Button key="next" className="next-button" type="primary" onClick={handleNext}>
                    {t('Next Step')}
                </Button>
            );
        }

        return buttons;
    };

    return isExpanded ? (
        <CustomModal
            className="setup-guide-popup"
            footer={null}
            open={open}
            onCancel={onClose}
            mask={false}
            maskClosable={false}
        >
            <div ref={modalRef}>
                <div className={`setup-guide-header ${!steps[currentStep].image ? 'no-image' : ''}`}>
                    <div className="setup-guide-buttons-container">
                        <Button
                            key="collapse"
                            type="text"
                            onClick={() => setIsExpanded(false)}
                            className="setup-guide-collapse-button"
                        >
                            <ShrinkOutlined />
                        </Button>
                        <Button key="close" type="text" onClick={onClose} className="setup-guide-close-button">
                            <CloseOutlined />
                        </Button>
                    </div>

                    {steps[currentStep].image && (
                        <div className="setup-guide-image-container">
                            <img src={steps[currentStep].image} className="setup-guide-image" />
                        </div>
                    )}
                </div>

                <div className="setup-guide-content">
                    <p>
                        <b>{steps[currentStep].text_1}</b>
                    </p>
                    <h1>{steps[currentStep].text_2}</h1>
                    <p>{steps[currentStep].text_3}</p>
                    <div className="setup-guide-footer">{getFooterButtons()}</div>
                </div>
            </div>
        </CustomModal>
    ) : (
        <div className="setup-guide-collapsed" onClick={() => setIsExpanded(true)}>
            <ChatInfoIcon />
            <p>{t('Setup guide')}</p>
        </div>
    );
};
