import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useState, useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import { notification, Space, Table } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Profession, Worker } from 'types/models';
import { ColumnsType } from 'antd/lib/table';
import { ProfessionMultiSelect } from 'pages/Provider/Workers/ProfessionMultiSelect';
import { TableFilterDropdown } from 'shared/AntDesignUtils/TableFilterDropdown/TableFilterDropdown';
import { SortOrder } from 'antd/lib/table/interface';

interface SorterState {
    field?: string;
    order?: SortOrder;
}

type Props = {
    workersUrl: string;
    workers: Worker[];
    openHandler: (worker?: Worker) => void;
    loadWorkersData: () => Promise<void>;
    isDataLoading: boolean;
    allProfessions: Profession[];
    handleProfessionChange: (worker: Worker, selectedProfessions: Profession[]) => Promise<void>;
    pagination: {
        current: number;
        pageSize: number;
        total: number;
    };
    onPaginationChange: (newPage: number) => void;
};

export const WorkersTable = ({
    workersUrl,
    workers,
    openHandler,
    allProfessions,
    loadWorkersData,
    isDataLoading,
    handleProfessionChange,
    pagination,
    onPaginationChange,
}: Props) => {
    const { t } = useLocalization();
    const [key, setKey] = useState(0);
    const handleError = useErrorHandler();
    const [isDataDeleting, setIsDataDeleting] = useState(false);
    const sortedWorkers: Worker[] = workers.sort((workerA, workerB) =>
        workerA.id < workerB.id ? 1 : workerB.id < workerA.id ? -1 : 0
    );
    const [sorter, setSorter] = useState<SorterState>({});

    const renderEmailCell = (email: string) => (
        <a href={`mailto:${email}`} target="blank">
            {email}
        </a>
    );

    const renderProfessionCell = (worker: Worker, professions: Profession[]) => (
        <ProfessionMultiSelect
            allProfessions={allProfessions}
            handleProfessionChange={handleProfessionChange}
            defaultSelections={professions}
            worker={worker}
        />
    );

    useEffect(() => {
        // even though workers get updated and the professions field of each worker object as well,
        // the professions table column doesn't re-render, so we need to force it to re-render
        setKey(key + 1);
    }, [workers]);

    const renderActionCell = (record: Worker) => (
        <Space>
            <EditOutlined onClick={() => openHandler(record)} />
            <DeletePopconfirm
                title={t('Are you sure you want to delete this worker?')}
                id={record.id}
                isDataDeleting={isDataDeleting}
                deleteHandler={() => deleteWorkerHandler(record)}
            />
        </Space>
    );

    const deleteWorkerHandler = async (record: Worker) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`${workersUrl}/${record.id}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Worker successfully removed'),
                });
                loadWorkersData();
            }
        } catch (error) {
            notification.error({
                message: t('Already assigned workers cannot be deleted.'),
            });
        } finally {
            setIsDataDeleting(false);
        }
    };

    const columns: ColumnsType<Worker> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            sorter: (a, b) => sortColumn(a, b, 'first_name'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: sorter.field === 'name' ? sorter.order : undefined,
            filterDropdown: (props: any) => <TableFilterDropdown {...props} dataIndex="name" />,
            filterIcon: (filtered: boolean) => <SearchOutlined className={filtered ? 'table-filter-icon' : ''} />,
            onFilter: (value: any, worker: Worker): boolean => {
                const stringVal = value.toString().toLowerCase();
                const firstName = worker.first_name.toLowerCase();
                const lastName = worker.last_name.toLowerCase();
                const fullName = `${firstName} ${lastName}`;

                return fullName.includes(stringVal);
            },
            render: (text, record) => `${record.first_name} ${record.last_name}`,
        },
        {
            title: t('Profession'),
            dataIndex: 'professions',
            sortOrder: sorter.field === 'professions' ? sorter.order : undefined,
            key: 'professions',
            sorter: (a, b) => sortColumn(a, b, 'professions'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('professions'),
            render: (professions: Profession[], worker: Worker) => {
                return renderProfessionCell(worker, professions);
            },
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sortColumn(a, b, 'email'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: sorter.field === 'email' ? sorter.order : undefined,
            ...getColumnSearchProps('email'),
            render: (email) => renderEmailCell(email),
        },
        {
            title: t('Phone number'),
            dataIndex: 'phone_number',
            sortOrder: sorter.field === 'phone_number' ? sorter.order : undefined,
            key: 'phone_number',
            sorter: (a, b) => sortColumn(a, b, 'phone_number'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('phone_number'),
        },
        {
            title: t('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value, record) => renderActionCell(record),
        },
    ];

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: {
            field?: string;
            order?: SortOrder;
        }
    ) => {
        const sortedOrder = sorter.order;
        const sortedField = sorter.field || (sorter.field === undefined ? 'name' : undefined);

        setSorter({
            field: sortedField,
            order: sortedOrder,
        });
    };

    return (
        <Table
            key={key}
            rowKey="id"
            columns={columns}
            dataSource={sortedWorkers}
            pagination={{
                ...pagination,
                onChange: onPaginationChange,
            }}
            loading={isDataLoading}
            onChange={handleTableChange as any}
        />
    );
};
