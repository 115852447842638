import { useErrorHandler } from 'lib/ErrorHandling';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Input, Result, Typography } from 'antd';
import { Authentication, useAccount } from 'lib/Account';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';
import { Invitation, User } from 'types/models';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { InvitationAccountType } from 'types/models/Invitation';
import { PasswordFormItem } from 'shared/AntDesignUtils/Password/PasswordFormItem';
import { AuthLayout } from 'pages/Authentication/AuthLayout';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

interface MatchParams {
    token: string;
}

type Props = {
    invitationAccountType: InvitationAccountType;
}

export const Register = (props: Props) => {
    const { t, locale } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const { setAccountUser } = useAccount();
    const location = useLocation();
    const navigate = useNavigate();
    const [tokenErrorMessage, setTokenErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [invitation, setInvitation] = useState<Invitation | null>(null);
    const [fetching, setFetching] = useState(true);

    const { referer } = location.state || { referer: { pathname: '/' } };
    const params = useParams<MatchParams>();
    const token = params.token;

    const fetchInvitation = useCallback(async () => {
        try {
            const { data: invitation } = await Backend.get(`account/register/invited/${ token }`);

            if (!invitation) {
                setTokenErrorMessage(t('No invitation found!'));

                return;
            }

            setInvitation(invitation);

            form.setFieldsValue({
                email: invitation.email,
                first_name: invitation.first_name,
                last_name: invitation.last_name,
            });
        } catch (error: any) {
            if (error.response) {
                setTokenErrorMessage(error.response.data.error);
            }
        }
    }, [form, t, token]);

    // After initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchInvitation();
            } catch (error) {
                handleError(error);
            } finally {
                setFetching(false);
            }
        };
        fetchData();
    }, [fetchInvitation, form, handleError]);

    // While still fetching data
    if (fetching || !locale.isLoaded) {
        return <AuthLayout />;
    }

    // If token is invalid display Card with error message.
    if (tokenErrorMessage) {
        return (
            <AuthLayout>
                <Result status="warning" title={ t(tokenErrorMessage) } />
            </AuthLayout>
        );
    }

    const onSubmit = async (userData: User) => {
        try {
            setLoading(true);
            const loggedInUser = await Authentication.register(userData, token);

            setAccountUser(loggedInUser);
            navigate(referer, { replace: true });
        } catch (error) {
            handleError(error, form);
        } finally {
            setLoading(false);
        }
    };

    const passwordFields = (
        <>
            <PasswordFormItem
                label={t('Password')}
                name="password"
                rules={ [
                    {
                        required: true,
                        message: t('Please enter a new password!')
                    },
                ] }
                showConditions
            />

            <PasswordFormItem
                label={ t('Confirm password') }
                name="password_confirmation"
                dependencies={ ['password'] }
                rules={ [
                    {
                        required: true,
                        message: t('Please confirm your password!')
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('The two passwords that you entered do not match!'));
                        }
                    })
                ] }
            />
        </>
    );

    const organizationForm = (
        <>
            <Form.Item
                name="email"
                rules={ [
                    {
                        type: 'email',
                        message: t('Please enter a valid email'),
                    },
                    {
                        required: true,
                        message: t('Please enter an email address'),
                    },
                ]}
            >
                <FloatingLabel label={t('Email')} required>
                    <Input disabled />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="first_name" hidden>
                <FloatingLabel label={ t('First name') }>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="last_name" hidden>
                <FloatingLabel label={ t('Last name') }>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            { passwordFields }
        </>
    );

    return (
        <AuthLayout cardClass="register-box">
            <Typography.Title className="title">{ t('Register') }</Typography.Title>

            <Form form={ form } name="register" size="middle" labelCol={ { span: 8 } } onFinish={ onSubmit }>
                    <div className="registration-step-account">
                        <div className="registration-account-credentials">
                            { invitation?.account_type === InvitationAccountType.MEMBER ? passwordFields : organizationForm }
                        </div>
                    </div>
                    <div className="registration-controls">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block={ true } size="large" loading={ loading }>
                                { t('Register') }
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to="/login">
                                <Button type="link" block={ true } size="large" loading={ false }>
                                    { t('Log in') }
                                </Button>
                            </Link>
                        </Form.Item>
                    </div>
                </Form>
        </AuthLayout>
    );
};
