import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { Drawer, FormInstance, notification } from 'antd';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Address, Department } from 'types/models';
import { AddressForm } from 'pages/Client/Configuration/Addresses/AddressForm';
import { DepartmentForm } from 'pages/Client/Configuration/Departments/DepartmentForm';

type Props = {
    departments: Department[];
    onSaveDepartmentHandler: (department?: Department) => void;
    onSaveAddressHandler: (address?: Address) => void;
    isDepartmentFormOpen: boolean;
    isAddressFormOpen: boolean;
    onFormClose: () => void;
};

export const RequestCreationLocationFormsDrawer = ({
    departments,
    onSaveAddressHandler,
    onSaveDepartmentHandler,
    isDepartmentFormOpen,
    isAddressFormOpen,
    onFormClose,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [shifts, setShifts] = useState([]);

    const departmentsURL = '/data-management/departments';
    const addressesURL = '/data-management/addresses';
    const shiftsURL = '/data-management/work-shifts';

    const loadShifts = useCallback(async () => {
        try {
            const response = await Backend.get(shiftsURL);
            setShifts(response.data.workShifts);
        } catch (e) {
            handleError(e);
        }
    }, [handleError]);

    useEffect(() => {
        loadShifts();
    }, [loadShifts]);

    const saveAddressHandler = async (values: Address, form: FormInstance) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.post(addressesURL, values);
            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                notification.success({
                    message: t('Data successfully created'),
                });
                onSaveAddressHandler({ ...values, id: response.data.id });
            } else {
                onSaveAddressHandler();
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const saveDepartmentHandler = async (values: Department, form: FormInstance) => {
        try {
            setIsDataSaving(true);

            const response = await Backend.post(departmentsURL, values);

            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                notification.success({
                    message: t('Data successfully created'),
                });
                onSaveDepartmentHandler({ ...response.data });
            } else {
                onSaveDepartmentHandler();
            }

            setIsDataSaving(false);

            return true;
        } catch (error) {
            handleError(error, form);
            setIsDataSaving(false);

            return false;
        }
    };

    return (
        <>
            <Drawer
                title={isAddressFormOpen ? t('Add Location') : t('Add Department')}
                width={500}
                onClose={() => onFormClose()}
                open={isDepartmentFormOpen || isAddressFormOpen}
            >
                {isAddressFormOpen && (
                    <AddressForm
                        departments={departments}
                        saveAddressHandler={saveAddressHandler}
                        isDataSaving={isDataSaving}
                    />
                )}
                {isDepartmentFormOpen && (
                    <DepartmentForm
                        isDataSaving={isDataSaving}
                        saveDepartmentHandler={saveDepartmentHandler}
                        shifts={shifts}
                    />
                )}
            </Drawer>
        </>
    );
};
