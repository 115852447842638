import Polyglot from 'node-polyglot';
import { find } from 'lodash';
import { locales } from './Locales';
import { addTranslation } from './Translation';
import { Locale } from 'types';

const polyglot: Polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: onMissingKey,
});

/**
 * On missing key callback.
 *
 * It is called whenever a phrase is missing from the translations.
 *
 * @param {string} key
 * @param {object} options
 * @param {string} locale
 *
 * @returns {*}
 */
function onMissingKey(key: string, options: {}, locale: string) {
    if (!key) return '';

    if (!key.trim()) {
        return key;
    }

    if (process.env.REACT_APP_ENVIRONMENT !== 'production' && localeIsLoaded(locale)) {
        addTranslation(key);
    }

    polyglot.extend({ [key]: key });

    return polyglot.t(key, options);
}

/**
 * Check if the given locale was already loaded.
 *
 * @param {string} localeShortCode
 *
 * @returns {boolean}
 */
function localeIsLoaded(localeShortCode: string) {
    return (find(locales, (locale) => locale.libraries.polyglot.shortCode === localeShortCode) as Locale).isLoaded;
}

export { polyglot };
