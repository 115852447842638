import { AutoComplete, Input, Tooltip } from 'antd';
import { forwardRef, Ref } from 'react';
import { RefSelectProps } from 'antd/lib/select';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTimeOptionsForTimePicker } from 'lib/Helpers/DateTimeHelper';
import { ClockCircleOutlined } from '@ant-design/icons';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    dropdownOptions?: DefaultOptionType[];
    endTime?: string;
    inputSuffix?: JSX.Element;
    minutesStep?: number;
    onIconClick: () => void;
    placeholder?: string;
    showDuration?: boolean;
    showIcon: boolean;
    showNextDay?: boolean;
    startTime?: string;
    tooltip?: boolean;
};

export const TimePicker = forwardRef(
    (
        {
            dropdownOptions,
            endTime,
            inputSuffix,
            minutesStep = 15,
            onIconClick = () => {},
            placeholder,
            showDuration = true,
            showIcon = true,
            showNextDay = false,
            startTime,
            tooltip = false,
            ...props
        }: Props,
        ref: Ref<RefSelectProps> | undefined
    ) => {
        const options: DefaultOptionType[] = Array.isArray(dropdownOptions)
            ? dropdownOptions
            : getTimeOptionsForTimePicker(minutesStep, startTime, endTime, showDuration, showNextDay);

        const field =
            <AutoComplete {...props} options={options} placeholder={placeholder} ref={ref} dropdownMatchSelectWidth={false}>
                <Input
                    bordered={props.bordered}
                    prefix={showIcon ? <ClockCircleOutlined onClick={() => onIconClick()} /> : undefined}
                    suffix={inputSuffix}
                />
            </AutoComplete>

        return tooltip ?
            (
                <Tooltip placement="topLeft" title={placeholder}>
                    {field}
                </Tooltip>
            ) :
            field;
    }
);
