import { useLocalization } from 'lib/Localization';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Menu, Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faChevronDown, faClock, faFlag, faMugSaucer } from '@fortawesome/free-solid-svg-icons';
import { WorkShift } from 'types/models';
import { GenericObject } from 'shared/Contracts';

type ShiftsDropdownProps = GenericObject & {
    shifts: WorkShift[];
    handleShiftSelected: (shift: WorkShift) => void;
};

export const ShiftsDropdown = ({ shifts, handleShiftSelected, ...otherProps }: ShiftsDropdownProps) => {
    const { t, locale } = useLocalization();

    const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
        const shift = shifts.find((shift) => shift.id === Number(key));

        shift && handleShiftSelected(shift);
    };

    const formatShiftTimes = (start: string, end: string): string => {
        const startTime = start.split(':').slice(0, 2).join(':');
        const endTime = end.split(':').slice(0, 2).join(':');

        return `${startTime} - ${endTime}`;
    };

    const formatBreakDuration = (breakDuration: number): string => (breakDuration ? breakDuration + 'm' : '-');

    const formatBreakStart = (timeString: string): string => {
        if (!timeString) {
            return '';
        }

        const [hours, minutes] = timeString.split(':');
        const date = new Date();

        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));

        const formattedTime = date.toLocaleTimeString(locale.shortCode, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        return formattedTime.replace(/^24/, '00');
    };

    const titleItem = [
        {
            key: 'first-item',
            disabled: true,
            label: (
                <Menu.Item className="shifts-header-title" key="shift-header">
                    <Space direction="vertical">
                        <Typography.Text type="secondary">{t('Predefined shifts')}</Typography.Text>
                    </Space>
                </Menu.Item>
            ),
        },
    ];

    const shiftDropdownItems = shifts.map((shift: WorkShift) => ({
        title: t('Predefined shifts'),
        key: shift.id,
        label: (
            <div className="shift-menu-item-content">
                <span>{shift.name}:</span>
                <span>
                    <FontAwesomeIcon icon={faClock} />
                    {formatShiftTimes(shift.start_time, shift.end_time)}
                </span>
                <span>
                    <FontAwesomeIcon icon={faMugSaucer} />
                    {formatBreakDuration(shift.break_duration)}
                </span>
                <span>
                    <FontAwesomeIcon icon={faFlag} />
                    {formatBreakStart(shift.break_start_time)}
                </span>
            </div>
        ),
    }));

    const items: MenuProps['items'] = [...titleItem, ...shiftDropdownItems];

    return (
        <Dropdown menu={{ items, onClick: handleMenuClick }} overlayClassName="shifts-dropdown" {...otherProps}>
            <Button type="primary" className="shift-menu-item-button">
                <Space>
                    <FontAwesomeIcon icon={faCalendarWeek} />
                    <FontAwesomeIcon icon={faChevronDown} />
                </Space>
            </Button>
        </Dropdown>
    );
};
