import { useEffect, useRef, useState } from 'react';
import { tapState } from 'lib/Helpers/Debugger';
import { GenericObject } from 'shared/Contracts';

/**
 *
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in localStorage
 * @param {{serialize: Function, deserialize: Function, debug: Boolean|String}} options The serialize and deserialize functions to use (defaults to JSON.stringify and JSON.parse respectively)
 */

export const useLocalStorageState = (
    key: string,
    defaultValue: any = {},
    {
        serialize = JSON.stringify,
        deserialize = JSON.parse,
        debug = false,
    }: GenericObject = {}
) => {
    const getDefaultState = () => {
        const valueInLocalStorage = window.localStorage.getItem(key);

        if (valueInLocalStorage) {
            if (deserialize === JSON.parse) {
                try {
                    const dateParser = (key: string, value: string | number | Date) => {
                        const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
                        const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

                        if (typeof value === 'string') {
                            if (reISO.exec(value)) {
                                return new Date(value);
                            }

                            const a = reMsAjax.exec(value);

                            if (a) {
                                const b = a[1].split(/[-+,.]/);

                                return new Date(b[0] ? +b[0] : 0 - +b[1]);
                            }
                        }

                        return value;
                    };

                    return JSON.parse(valueInLocalStorage, dateParser);
                } catch {
                    return valueInLocalStorage;
                }
            } else {
                return deserialize(valueInLocalStorage);
            }
        }

        return typeof defaultValue === 'function' ? defaultValue() : structuredClone(defaultValue);
    };

    const [state, setState] = useState(getDefaultState());

    const tappedSetState = tapState(setState, (newState: any, oldState: any) => {
        if (typeof debug === 'string') {
            if (JSON.stringify(oldState[debug]) === JSON.stringify(newState[debug])) {
                return;
            }

            newState = newState[debug];
            oldState = oldState[debug];
        }

        console.trace('LocalStorageHelper', key, { newState, oldState });
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;

        if (prevKey !== key) {
            window.localStorage.removeItem(prevKey);
        }

        prevKeyRef.current = key;
        window.localStorage.setItem(key, serialize(state));
    }, [key, state, serialize]);

    const deleteState = () => {
        window.localStorage.removeItem(key);
        setState(getDefaultState());
    };

    return [state, debug ? tappedSetState : setState, deleteState];
};
