import React from 'react';

type Props = {
    [index: string]: any;
    color?: string;
};

export const AddCommentIcon = ({ color = '#1677FF', ...otherProps }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24" fill="none" {...otherProps}>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.2928 7.79289C11.4803 7.60536 11.7346 7.5 11.9999 7.5C12.2651 7.5 12.5194 7.60536 12.707 7.79289C12.8945 7.98043 12.9999 8.23478 12.9999 8.5V10.75H15.2499C15.5151 10.75 15.7694 10.8554 15.957 11.0429C16.1445 11.2304 16.2499 11.4848 16.2499 11.75C16.2499 12.0152 16.1445 12.2696 15.957 12.4571C15.7694 12.6446 15.5151 12.75 15.2499 12.75H12.9999V15C12.9999 15.2652 12.8945 15.5196 12.707 15.7071C12.5194 15.8946 12.2651 16 11.9999 16C11.7346 16 11.4803 15.8946 11.2928 15.7071C11.1052 15.5196 10.9999 15.2652 10.9999 15V12.75H8.74988C8.48466 12.75 8.23031 12.6446 8.04277 12.4571C7.85524 12.2696 7.74988 12.0152 7.74988 11.75C7.74988 11.4848 7.85524 11.2304 8.04277 11.0429C8.23031 10.8554 8.48466 10.75 8.74988 10.75H10.9999V8.5C10.9999 8.23478 11.1052 7.98043 11.2928 7.79289ZM8.17303 2.7612C9.38629 2.25866 10.6866 2 11.9999 2C14.652 2 17.1956 3.05357 19.0709 4.92893C20.9463 6.8043 21.9999 9.34784 21.9999 12C21.9999 14.6522 20.9463 17.1957 19.0709 19.0711C17.1956 20.9464 14.652 22 11.9999 22H2.99987C2.80187 22.0083 2.60586 21.9577 2.43671 21.8544C2.26757 21.7511 2.13292 21.5999 2.04987 21.42C1.97329 21.2379 1.95236 21.0372 1.98974 20.8432C2.02711 20.6492 2.12111 20.4706 2.25987 20.33L4.25987 18.33C2.79066 16.5485 1.99112 14.3091 1.99987 12C1.99987 9.34784 3.05343 6.8043 4.9288 4.92893C5.85738 4.00035 6.95978 3.26375 8.17303 2.7612ZM5.40987 20H11.9999C13.8506 19.9985 15.6436 19.3554 17.0734 18.1802C18.5032 17.005 19.4813 15.3705 19.841 13.555C20.2007 11.7395 19.9199 9.85551 19.0463 8.22389C18.1727 6.59227 16.7604 5.31402 15.0501 4.60691C13.3397 3.8998 11.4371 3.80759 9.66638 4.34597C7.89566 4.88436 6.36638 6.02004 5.33909 7.55952C4.31181 9.09901 3.85007 10.947 4.03256 12.7888C4.21504 14.6305 5.03045 16.352 6.33987 17.66C6.52828 17.8446 6.63611 18.0962 6.63987 18.36C6.63932 18.4923 6.61252 18.6232 6.56102 18.7451C6.50952 18.8669 6.43435 18.9774 6.33987 19.07L5.40987 20Z"
              fill={color} />
    </svg>
);
