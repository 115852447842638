import _ from 'lodash';
import { sortColumn } from 'lib/Helpers/TableHelper';
import { Profession, ProfessionTranslation } from 'types/models';

/**
 * Format professions.
 *
 * @param professions
 * @param currentLocale
 * @returns {Array}
 */
export const localizeProfessions = (
    professions: Profession[],
    currentLocale: {
        shortCode: string;
    }
) => {
    const localizedProfessions = professions.map((profession) => {
        return localizeProfession(profession, currentLocale);
    });
    localizedProfessions.sort((a, b) => sortColumn(a, b, 'name'));

    return localizedProfessions as Profession[];
};

/**
 * Format profession
 *
 * @param profession
 * @param currentLocale
 * @returns {*&{name: *, description: *, key}}
 */
export const localizeProfession = (
    profession: Profession,
    currentLocale: {
        shortCode: string;
    }
) => {
    const translation: ProfessionTranslation = _.find(profession['translations'], {
        language: { code: currentLocale.shortCode },
    }) as ProfessionTranslation;
    return {
        ...profession,
        key: profession.id,
        name: translation ? translation['name'] : profession.name,
        description: translation ? translation['description'] : profession.description,
    };
};
