import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const useTabLocation = (defaultTab: string): [string, (tab: string) => void] => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(location.hash.substring(1) || defaultTab);

    const setTheActiveTab = (tab: string) => {
        navigate(`#${tab}`, { replace: true });
        setActiveTab(tab);
    }

    return [activeTab, setTheActiveTab];
};
