import React from 'react';
import { GenericObject } from 'shared/Contracts';

type IconProps = GenericObject & {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
};

function FileIcon({ className = '', color = 'white', width, height, ...otherProps }: IconProps) {
    return (
        <svg
            className={`svg-inline--fa fa-suitcase ant-menu-item-icon ${className}`}
            width={width ?? 17}
            height={height ?? 17}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99063 1.14688L13.3531 4.51094C13.4469 4.60469 13.5 4.73125 13.5 4.86406V9.3C13.5 9.41046 13.4105 9.5 13.3 9.5H12.575C12.4645 9.5 12.375 9.41046 12.375 9.3V6.15625H9C8.82595 6.15625 8.65903 6.08711 8.53596 5.96404C8.41289 5.84097 8.34375 5.67405 8.34375 5.5V2.125H3.625V13.875H7.8C7.91046 13.875 8 13.9645 8 14.075V14.8C8 14.9105 7.91046 15 7.8 15H3C2.72344 15 2.5 14.7766 2.5 14.5V1.5C2.5 1.22344 2.72344 1 3 1H9.63594C9.76875 1 9.89688 1.05313 9.99063 1.14688ZM9.40625 5.09375H12.3469L9.40625 2.15313V5.09375Z"
                fill={color}
            />
            <path
                d="M12.159 12.8902L12.159 14.0168C12.159 14.0397 12.1655 14.0621 12.1776 14.0814C12.1898 14.1008 12.2072 14.1164 12.2277 14.1264C12.2483 14.1364 12.2713 14.1404 12.294 14.138C12.3168 14.1356 12.3384 14.1269 12.3564 14.1128L14.5189 12.4035C14.5805 12.355 14.5815 12.2611 14.5189 12.2114L12.3564 10.5022C12.2766 10.4396 12.159 10.4968 12.159 10.5982L12.159 11.7291H8.62966C8.56275 11.7291 8.50772 11.7841 8.50772 11.851L8.50772 12.7661C8.50772 12.833 8.56275 12.888 8.62966 12.888L12.159 12.8902Z"
                fill={color}
            />
        </svg>
    );
}

export { FileIcon };
