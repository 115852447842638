import { useCallback, useEffect, useState } from 'react';
import { Button, Descriptions, Drawer, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { ProfessionTranslationForm } from './ProfessionTranslationForm';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { ProfessionForm } from './ProfessionForm';
import { useParams } from 'react-router-dom';
import { ProfessionTranslationsTable } from './ProfessionTranslationsTable';
import { useErrorHandler } from 'lib/ErrorHandling';
import moment from 'moment';
import { Category, Country, Language, Profession as ProfessionModel, ProfessionTranslation } from 'types/models';
import { useCollections } from 'lib/Contexts/Collections';

/**
 * Stateful component responsible for profession data management.
 *
 */
export const Profession = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [profession, setProfession] = useState<ProfessionModel>({} as ProfessionModel);
    const [categories, setCategories] = useState([]);
    const [professionTranslations, setProfessionTranslations] = useState([]);
    const [professionTranslation, setProfessionTranslation] = useState<ProfessionTranslation>();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [collections] = useCollections();

    const { id } = useParams<{ id: string }>();

    /**
     * Fetch data form backend.
     *
     * @return {Promise<void>}
     */
    const loadProfessionData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get(`/data-management/professions/${id}?relations=category`);
            setProfession(response.data.profession);
            setProfessionTranslations(response.data.profession.translations);
            setCategories(response.data.collections.categories);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [id, handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadProfessionData();
    }, [loadProfessionData]);

    const descriptions = (
        <Descriptions size="small" column={1}>
            <Descriptions.Item label={t('Created on')}>
                {moment(profession?.created_at).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Updated on')}>
                {moment(profession?.updated_at).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
        </Descriptions>
    );

    const countryOptions = collections.countries.map((country: Country) => ({
        value: country.id,
        label: t(country.name),
    }));

    const categoryOptions = categories.map((category: Category) => ({
        value: category.id,
        label: t(category.name),
    }));

    const languageOptions = collections.languages
        // excludes existing translation languages
        .filter((language: Language) =>
            professionTranslations.every(
                (professionTranslation: ProfessionTranslation) => professionTranslation.language?.id !== language.id
            )
        )
        .map((language: Language) => ({
            value: language.id,
            label: t(language.name),
        }));

    /**
     * Opens the professionTranslation form
     *
     * @param professionTranslation
     */
    const openProfessionTranslationFormHandler = (professionTranslation?: ProfessionTranslation) => {
        const professionTrans = { ...professionTranslation } as ProfessionTranslation;
        setProfessionTranslation({
            ...professionTrans,
            profession_id: profession?.id,
        });
        setIsFormOpen(true);
    };

    const headerButtons = [
        languageOptions.length > 0 ? (
            <Button
                key="1"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => openProfessionTranslationFormHandler()}
            >
                {t('Add Profession Translation')}
            </Button>
        ) : null,
    ];

    /**
     * Format profession translations.
     *
     * @param professionTranslations
     * @return {Array}
     */
    const formatProfessionTranslations = (professionTranslations: ProfessionTranslation[]) =>
        professionTranslations.map((professionTranslation) => ({
            key: professionTranslation.id,
            ...professionTranslation,
        }));

    if (isDataLoading) {
        return <LoaderSkeleton size={2} />;
    }

    const tabItems = [
        {
            label: t('Profession'),
            key: '1',
            children: (
                <ProfessionForm
                    profession={profession}
                    countryOptions={countryOptions}
                    categoryOptions={categoryOptions}
                    loadProfessionData={loadProfessionData}
                    setIsFormOpen={setIsFormOpen}
                />
            ),
        },
        {
            label: t('Translations'),
            key: '2',
            children: (
                <ProfessionTranslationsTable
                    professionTranslations={formatProfessionTranslations(professionTranslations)}
                    loadProfessionData={loadProfessionData}
                    openProfessionTranslationFormHandler={openProfessionTranslationFormHandler}
                />
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title={profession?.name}
                backIcon={false}
                ghost={false}
                extra={headerButtons}
                footer={<Tabs items={tabItems} />}
            >
                {descriptions}
            </PageHeader>
            <Drawer
                title={professionTranslation?.id ? t('Edit translation') : t('Add translation')}
                width={500}
                onClose={() => setIsFormOpen(false)}
                open={isFormOpen}
            >
                <ProfessionTranslationForm
                    professionTranslation={professionTranslation}
                    languageOptions={languageOptions}
                    loadProfessionData={loadProfessionData}
                    setIsFormOpen={setIsFormOpen}
                />
            </Drawer>
        </>
    );
};
