import { User } from '.';

export enum InvitationAccountType {
    MEMBER = 1,
    ORGANIZATION = 2,
}

export enum InvitationActions {
    CANCELED = 'cancel',
    DELETED = 'delete',
    ACCEPTED = 'accept',
    DECLINED = 'decline',
}

export enum InvitationStatus {
    CANCELED = 'canceled',
    DELETED = 'deleted',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    PENDING = 'not used',
    REGISTERED = 'registered',
    ACCESSED = 'accessed',
    EXPIRED = 'expired',
    ANONYMYZED = 'anonymized',
}

export interface Invitation {
    id: number;
    email: string;
    first_name?: string;
    last_name?: string;
    company_name?: string;
    account_type: InvitationAccountType;
    invitation_token: string;
    expiration_date: Date;
    inviter_user_id: number;
    invitation_type: string;
    status: string;
    last_accessed?: Date;
    created_at?: Date;
    updated_at?: Date;
    user?: User;
    data?: {
        city: string;
        company_name: string;
        company_categories?: string[];
        country: number;
        first_name: string;
        last_name: string;
        postal_code: string;
        street: string;
    };
    message: string | null;
}
