import { Button } from 'antd';
import { useLocalization } from 'lib/Localization';
import { OrganizationType } from 'types';

type Props = {
    openInvitationForm: () => void;
    organizationType: OrganizationType;
};

export const InvitationButton = ({ openInvitationForm, organizationType }: Props) => {
    const { t } = useLocalization();

    return (
        <Button type="primary" onClick={() => openInvitationForm()}>
            {t(`Invite ${organizationType}`)}
        </Button>
    );
};
