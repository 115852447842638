import './Notifications.scss';
import { useLocalization } from 'lib/Localization';
import { SettingFilled } from '@ant-design/icons';
import { Button, Switch } from 'antd';
import { Notification } from 'types/models';
import { useEffect, useRef, useState } from 'react';
import { NotificationItem } from './NotificationItem';
import EmptyNotifications from 'assets/img/notifications/empty.png';
import { Link } from 'react-router-dom-v5-compat';
import { LoaderSpin } from '../AntDesignUtils/Loaders';
import { useAccount } from 'lib/Account';
import { Backend } from 'lib/Http/Backend';
import { InfiniteScroll } from 'shared/InfiniteScroll';
import { GenericObject } from 'shared/Contracts';
import { useErrorHandler } from 'lib/ErrorHandling';
import { userIsProvider } from 'lib/Helpers/UserHelper';

const url = '/account/notifications';
type Props = {
    closePopover: () => void;
};
export const NotificationsList = ({ closePopover }: Props) => {
    const { accountUser, setAccountUser } = useAccount();
    const handleError = useErrorHandler();
    const { t } = useLocalization();

    const userType = useRef(userIsProvider(accountUser) ? 'provider' : 'client');
    const [showingUnread, setShowingUnread] = useState(false);
    const [markingAll, setMarkingAll] = useState(false);
    const [notificationsUrl, setNotificationsUrl] = useState(url);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [meta, setMeta] = useState<GenericObject>({});
    const [isLoading, setIsLoading] = useState(false);
    const [todayNotifications, setTodayNotifications] = useState<Notification[]>([]);
    const [yesterdayNotifications, setYesterdayNotifications] = useState<Notification[]>([]);
    const [olderNotifications, setOlderNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        setNotifications([]);

        if (showingUnread) {
            setNotificationsUrl(`${url}?unread=1`);
        } else {
            setNotificationsUrl(url);
        }
    }, [showingUnread]);

    useEffect(() => {
        setTodayNotifications(notifications.filter(({ is_today }) => is_today));
        setYesterdayNotifications(notifications.filter(({ is_yesterday }) => is_yesterday));
        setOlderNotifications(notifications.filter(({ is_today, is_yesterday }) => !is_today && !is_yesterday));
    }, [notifications]);

    const markAsRead = async ({ id, read_at }: Notification) => {
        closePopover();

        if (read_at) {
            return;
        }

        await Backend.get(`${url}/mark-read/${id}`);

        setAccountUser({
            ...accountUser,
            unreadNotificationsCount: accountUser.unreadNotificationsCount - 1,
        });

        setNotifications((prevState) => {
            const localState = [...prevState];
            const index = localState.findIndex((notification) => notification.id === id);

            if (index > -1) {
                if (showingUnread) {
                    localState.splice(index, 1);
                } else {
                    localState.splice(index, 1, {
                        ...localState[index],
                        read_at: Date.now().toString(),
                    });
                }
            }

            return localState;
        });
    };

    const markAllRead = async () => {
        setMarkingAll(true);

        await Backend.get(`${url}/mark-all-read`);

        setAccountUser({
            ...accountUser,
            unreadNotificationsCount: 0,
        });

        setNotifications((prevState) => {
            if (showingUnread) {
                return [];
            }

            return [...prevState].map((notification) => {
                if (!notification.read_at) {
                    notification.read_at = Date.now().toString();
                }

                return notification;
            });
        });

        setMarkingAll(false);
    };

    const markAllButton = !!accountUser.unreadNotificationsCount && (
        <Button
            disabled={markingAll}
            icon={markingAll ? <LoaderSpin style={{ marginRight: '5px' }} inline /> : null}
            onClick={() => markAllRead()}
            type="link"
        >
            {t('Mark all as read')}
        </Button>
    );

    const fetchData = async (page: number) => {
        try {
            setIsLoading(true);

            const separator = notificationsUrl.includes('?') ? '&' : '?';
            const response = await Backend.get(`${notificationsUrl}${separator}page=${page}`);

            const { notifications, meta } = response.data;

            setAccountUser({
                ...accountUser,
                unreadNotificationsCount: meta.unread_count,
            });

            setNotifications((prevState) => [...prevState, ...notifications]);
            setMeta(meta);
            setIsLoading(false);

            return !!notifications.length;
        } catch (error) {
            setIsLoading(false);
            handleError(error);

            return false;
        }
    };

    const renderHeader = () => (
        <header>
            <span className="notifications-title">
                <span>{t('Notifications')}</span>

                {isLoading && <LoaderSpin inline />}
            </span>
            <div className="pull-right">
                {t('Show only unread')}
                <Switch
                    className="notifications-unread-switch"
                    onChange={(state: boolean) => setShowingUnread(state)}
                    size="small"
                />
                <Link to={`/${userType.current}/settings#notifications`}>
                    <SettingFilled className="notifications-settings" />
                </Link>
            </div>
        </header>
    );

    const renderToday = () => {
        if (!todayNotifications.length) {
            return;
        }

        return (
            <>
                <div className="notifications-day">
                    {t('Today')}

                    {markAllButton}
                </div>

                {todayNotifications.map((notification: Notification) => (
                    <Link key={notification.id} to={notification.link} onClick={() => markAsRead(notification)}>
                        <NotificationItem images={meta.images} notification={notification} />
                    </Link>
                ))}
            </>
        );
    };

    const renderYesterday = () => {
        if (!yesterdayNotifications.length) {
            return;
        }

        return (
            <>
                <div className="notifications-day">
                    {t('Yesterday')}

                    {!todayNotifications.length && markAllButton}
                </div>

                {yesterdayNotifications.map((notification: Notification) => (
                    <Link key={notification.id} to={notification.link} onClick={() => markAsRead(notification)}>
                        <NotificationItem images={meta.images} notification={notification} />
                    </Link>
                ))}
            </>
        );
    };

    const renderOlder = () => {
        if (!olderNotifications.length) {
            return;
        }

        return (
            <>
                {(!!todayNotifications.length ||
                    !!yesterdayNotifications.length ||
                    !!accountUser.unreadNotificationsCount) && (
                    <div className="notifications-day">
                        {t('Older')}

                        {!todayNotifications.length && !yesterdayNotifications.length && markAllButton}
                    </div>
                )}

                {olderNotifications.map((notification: Notification) => (
                    <Link key={notification.id} to={notification.link} onClick={() => markAsRead(notification)}>
                        <NotificationItem images={meta.images} notification={notification} />
                    </Link>
                ))}
            </>
        );
    };

    const renderBody = () => (
        <InfiniteScroll id="notifications" className="notifications-body" fetchData={fetchData} url={notificationsUrl}>
            <>
                {!notifications.length && !isLoading && (
                    <div className="notifications-empty">
                        <img src={EmptyNotifications} />

                        <div>{t(showingUnread ? 'You have no unread notifications' : 'You have no notifications')}</div>
                    </div>
                )}

                {renderToday()}
                {renderYesterday()}
                {renderOlder()}

                {!!notifications.length && !isLoading && (
                    <div className="notification-end">
                        {t('These are all your notifications from the last 30 days.')}
                    </div>
                )}
            </>
        </InfiniteScroll>
    );

    return (
        <section id="notifications-container">
            {renderHeader()}
            {renderBody()}
        </section>
    );
};
