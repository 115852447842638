import { MemberType, OrganizationType } from 'types';
import { User } from 'types/models';

/**
 * Why if I invite a contact then I am a client and not a provider?
 *
 * It's just a convention that we use.
 * If the organization is a provider, their members are called agents.
 * If the organization is a client, their members are called contacts.
 * This comes from the beginning of the project this is how the requirements were defined.
 * https://smartbridge.atlassian.net/wiki/spaces/development/pages/1899069441/Technical+Specs#Profiles
 */

const organizationTypesMapping = {
    [MemberType.CONTACT]: OrganizationType.CLIENT,
    [MemberType.AGENT]: OrganizationType.PROVIDER,
    [MemberType.ADMIN]: OrganizationType.ADMIN,
};

export const memberTypesMapping = {
    [OrganizationType.CLIENT]: MemberType.CONTACT,
    [OrganizationType.PROVIDER]: MemberType.AGENT,
    [OrganizationType.ADMIN]: MemberType.ADMIN,
};

export const getOrganizationTypeFromMemberType = (memberType: MemberType) => {
    return organizationTypesMapping[memberType];
};

export const getOppositeOrganizationType = (organizationType: OrganizationType) => {
    if (organizationType === OrganizationType.CLIENT) {
        return OrganizationType.PROVIDER;
    }
    if (organizationType === OrganizationType.PROVIDER) {
        return OrganizationType.CLIENT;
    }
};

export const getUserOrganizationType = (user: User) => {
    if (user.admin_profile) {
        return organizationTypesMapping[MemberType.ADMIN];
    } else if (user.agent_profile) {
        return organizationTypesMapping[MemberType.AGENT];
    }

    return organizationTypesMapping[MemberType.CONTACT];
};
