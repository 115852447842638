import 'shared/Configuration/Professions/Professions.scss';

import { Button, Checkbox, Col, Modal, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { ReactNode, useEffect, useState } from 'react';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Category, Client, Organization, Profession, Provider } from 'types/models';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { Authentication, useAccount } from 'lib/Account';
import { getOppositeMemberType, getOrganizationTypeFromMemberType } from 'lib/Organization';
import { MemberType, OrganizationType } from 'types';
import { uniqBy } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import { CheckboxSelection } from 'shared/AntDesignUtils/CheckboxSelection/CheckboxSelection';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';

export const Professions = () => {
    const { t } = useLocalization();
    const { locale } = useLocalization();
    const handleError = useErrorHandler();
    const { accountUser } = useAccount();

    const isProvider = userIsProvider(accountUser);
    const isClient = userIsClient(accountUser);

    const professionRelations = ['country', 'category'];

    if (isProvider) {
        professionRelations.push('clients')
    } else if (isClient) {
        professionRelations.push('providers')
    } else {
        professionRelations.push('clients', 'providers');
    }

    const url = `/data-management/professions?relations=${professionRelations.join(',')}&all=0`;

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [professions, setProfessions] = useState<Profession[]>([]);
    const [professionCollections, setProfessionCollections] = useState<Profession[]>([]);
    const [categoriesCollections, setCategoriesCollections] = useState<Category[]>([]);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [isDontAskChecked, setDontAskChecked] = useState(false)

    const { accountUser: user } = useAccount();
    const memberType = Authentication.getCurrentUserMemberType(user);
    const oppositeMemberType = getOppositeMemberType(memberType) as MemberType;
    const oppositeOrganizationType = getOrganizationTypeFromMemberType(oppositeMemberType);
    const oppositeOrganizationTypeTrans =
        oppositeOrganizationType === OrganizationType.PROVIDER ? t('Providers') : t('Clients');
    let allConnectedOrganizations: Organization[] | undefined;
    if (oppositeOrganizationType === OrganizationType.PROVIDER) {
        allConnectedOrganizations = professions.flatMap((profession) => profession.providers as Provider[]);
    } else if (oppositeOrganizationType === OrganizationType.CLIENT) {
        allConnectedOrganizations = professions.flatMap((profession) => profession.clients as Client[]);
    }
    // remove duplicates
    allConnectedOrganizations = uniqBy(allConnectedOrganizations, 'id');

    const localizedProfessionCollections = localizeProfessions(professionCollections, locale);
    const activeProfessionIds = professions.map((profession) => profession.id);

    useEffect(() => {
        const loadProfessions = async () => {
            try {
                setIsDataLoading(true);
                const response = await Backend.get(url);
                setProfessions(response.data.professions);
                setProfessionCollections(response.data.collections.professions);
                setCategoriesCollections(response.data.collections.categories);
            } catch (error) {
                handleError(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        loadProfessions();
    }, [handleError]);

    const saveProfessionsHandler = async (activeProfessionIds: number[]) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.put(url, activeProfessionIds);
            setProfessions(response.data.professions);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataSaving(false);
        }
    };

    const nameColumnRenderer = (text: string, profession: Profession): ReactNode => (
        <Tooltip title={profession.description}>
            <span>{profession.name}</span>
        </Tooltip>
    );

    const getProfessionConnectedOrganizations = (profession: Profession): Organization[] | undefined => {
        let connectedOrganizations: Organization[] | undefined;

        if (oppositeOrganizationType === OrganizationType.PROVIDER) {
            connectedOrganizations = profession.providers;
        } else if (oppositeOrganizationType === OrganizationType.CLIENT) {
            connectedOrganizations = profession.clients;
        }

        return connectedOrganizations;
    };

    const seeAllProviders = (profession: Profession) => {
        const connectedOrganizations = getProfessionConnectedOrganizations(profession);
        const firstColWidth = 6;
        const secondColWidth = 18;
        const rowStyle = { paddingBottom: '10px' };

        Modal.info({
            icon: <></>,
            maskClosable: true,
            closable: true,
            centered: true,
            width: '45vw',
            title: t('Profession details'),
            content: (
                <>
                    <Row style={rowStyle}>
                        <Col span={firstColWidth}>{t('Profession')}:</Col>
                        <Col span={secondColWidth}>
                            <Tooltip title={profession.description}>{profession.name}</Tooltip>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col span={firstColWidth}>{t('Category')}:</Col>
                        <Col span={secondColWidth}>{profession.category?.name ? t(profession.category?.name) : ''}</Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col span={firstColWidth}>{t(isClient ? 'Providers' : (isProvider ? 'Clients' : 'Organizations'))}:</Col>
                        <Col span={secondColWidth}>
                            {connectedOrganizations?.map((connectedOrganization: Organization) => (
                                <Tag key={connectedOrganization.id} className="primary-color-tag">
                                    {connectedOrganization.company_name}
                                </Tag>
                            ))}
                        </Col>
                    </Row>
                </>
            ),
        });
    };

    const connectedOrganizationsRender = (text: string, profession: Profession): ReactNode => {
        let connectedOrganizations = getProfessionConnectedOrganizations(profession);

        return (
            <Row align="middle">
                <Col span={21} className="ant-table-cell-ellipsis">
                    {connectedOrganizations?.map((connectedOrganization: Organization) => (
                        <Tag key={connectedOrganization.id} className="primary-color-tag">
                            {connectedOrganization.company_name}
                        </Tag>
                    ))}
                </Col>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <Button
                        type="link"
                        onClick={() => seeAllProviders(profession)}
                        title={t('see all')}
                        style={{ padding: 0, whiteSpace: 'normal' }}
                    >
                        {t('see all')}
                    </Button>
                </Col>
            </Row>
        );
    };

    const connectedOrganizationsFilter = (value: string | number | boolean, profession: Profession): boolean => {
        let connectedOrganizations: Organization[] | undefined;
        if (oppositeOrganizationType === OrganizationType.PROVIDER) {
            connectedOrganizations = profession.providers;
        } else if (oppositeOrganizationType === OrganizationType.CLIENT) {
            connectedOrganizations = profession.clients;
        }

        return connectedOrganizations?.some((organization) => organization.id === value) || false;
    };

    const toggleActiveProfession = (activeProfessionIds: number[], professionId: number) => {
        const toggledActiveProfessionIds = activeProfessionIds.includes(professionId)
            ? activeProfessionIds.filter((activeProfessionId) => activeProfessionId !== professionId)
            : [...activeProfessionIds, professionId];
        saveProfessionsHandler(toggledActiveProfessionIds);
        return false;
    };

    const toggleActiveAllProfession = (activate: boolean) => {
        const toggledActiveProfessionIds = activate ? professionCollections.map((profession) => profession.id) : [];
        saveProfessionsHandler(toggledActiveProfessionIds);
    };

    const actionConfirm = (title: string, content: string, options: {}, showDontAskAgain?: boolean) =>
        Modal.confirm({
            icon: null,
            title: t(title),
            maskClosable: true,
            content: (
                <div>
                    <p>
                        <Typography.Text type="secondary">{t(content)}</Typography.Text>
                    </p>
                    {showDontAskAgain && (
                        <Checkbox
                            onChange={(e) => setDontAskChecked(e.target.checked)}
                        >
                            {t("Don't ask again")}
                        </Checkbox>
                    )}
                </div>
            ),
            okText: t('Deselect'),
            cancelText: t('Cancel'),
            centered: true,
            ...options,
        });

    const renderActiveCell = (profession: Profession) => (
        <div
            className="active-col-render"
            title={activeProfessionIds.includes(profession.id) ? t('Deactivate profession') : t('Activate profession')}
        >
            <Checkbox
                onChange={() => {
                    if (activeProfessionIds.includes(profession.id) && !isDontAskChecked) {
                        actionConfirm('Are you sure you want to deselect this profession?',
                            (isClient ? 'You won’t be able to create requests for this profession anymore.' : 'Clients will not be able to send you requests for this profession anymore.'),
                            {
                                onOk: () => toggleActiveProfession(activeProfessionIds, profession.id)
                            },
                            true)
                    } else {
                        toggleActiveProfession(activeProfessionIds, profession.id);
                    }
                }}
                checked={activeProfessionIds.includes(profession.id)}
            />
        </div>
    );

    const columns: ColumnsType<Profession> = [
        {
            filterIcon: () => (
                <CheckboxSelection
                    title={professions.length === 0 ? t('activate all professions') : t('deactivate all professions')}
                    fullSelected={professions.length === professionCollections.length}
                    partialSelected={professions.length > 0 && professions.length < professionCollections.length}
                    noneSelected={professions.length === 0}
                    onChange={(checked) => {
                        if (!checked) {
                            actionConfirm('Are you sure you want to deselect all professions?',
                                (isClient ? 'You won’t be able to create new requests if no professions are selected.' : 'Clients will not be able to send you any requests if no professions are selected.'),
                                {
                                    onOk: () => toggleActiveAllProfession(checked)
                                },
                                false)
                        } else {
                            toggleActiveAllProfession(checked);
                        }
                    }}
                />
            ),
            filterDropdown: ({ confirm }) => '',
            onFilter: (value, record) => true,
            dataIndex: 'active',
            key: 'active',
            render: (value, record) => renderActiveCell(record),
            width: 80,
            className: 'toggle-active-professions',
        },
        {
            dataIndex: 'name',
            title: t('Name'),
            key: 'name',
            render: nameColumnRenderer,
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            width: '25%',
        },
        {
            title: t('Category'),
            dataIndex: 'category_id',
            key: 'category_id',
            filters: categoriesCollections.map((category: Category) => ({
                value: category.id,
                text: t(category.name),
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.category_id === value,
            sorter: (a, b) => sortColumn(a.category, b.category, 'name'),
            sortDirections: ['ascend', 'descend'],
            render: (name, record) => (record.category?.name ? t(record.category?.name) : ''),
        },
        {
            title: oppositeOrganizationTypeTrans,
            dataIndex: 'connectedOrganizationsProfessions',
            key: 'connectedOrganizationsProfessions',
            filters: allConnectedOrganizations.map((organization: Organization) => ({
                value: organization.id,
                text: organization.company_name,
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => connectedOrganizationsFilter(value, record),
            render: (name, record) => connectedOrganizationsRender(name, record),
            ellipsis: true,
            width: '50%',
        },
    ];

    return (
        <PageHeader className="professions" title={t('Professions')} backIcon={false} ghost={false}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Table<Profession>
                    rowKey="id"
                    size="middle"
                    pagination={getDefaultPaginationProp(localizedProfessionCollections.length)}
                    columns={columns}
                    dataSource={localizedProfessionCollections}
                    loading={isDataLoading || isDataSaving}
                />
            </Space>
        </PageHeader>
    );
};