import { createContext, useContext, useEffect, useState } from 'react';
import { Collections } from 'types/Collections';
import { Backend } from 'lib/Http/Backend';
import axios from 'axios';
import { useErrorHandler } from 'lib/ErrorHandling';

const emptyCollections: Collections = {
    countries: [],
    languages: [],
    roles: [],
    permissions: [],
};

const CollectionsContext = createContext<[Collections, (collections: Collections) => void]>([
    emptyCollections,
    () => {},
]);

export const CollectionsProvider = (props: any) => {
    const handleError = useErrorHandler();
    const [collections, setCollections] = useState<Collections>(emptyCollections);
    const value = [collections, setCollections];

    useEffect(() => {
        const getCollections = async () => {
            try {
                const [{ data: countries }, { data: languages }, { data: roles }, { data: permissions }] =
                    await axios.all([
                        Backend.get('/data-management/countries'),
                        Backend.get('/data-management/languages'),
                        Backend.get('/data-management/roles'),
                        Backend.get('/data-management/permissions'),
                    ]);
                const fetchedCollections: Collections = {
                    countries,
                    languages,
                    roles,
                    permissions,
                };

                return fetchedCollections;
            } catch (error) {
                handleError(error);
            }
        };
        getCollections().then((initialCollections) => setCollections(initialCollections as Collections));
    }, [handleError]);

    return <CollectionsContext.Provider value={value} {...props} />;
};

export const useCollections = () => {
    const context = useContext(CollectionsContext);
    if (!context) {
        throw new Error('useCollections must be used within a CollectionsProvider');
    }

    return context;
};
