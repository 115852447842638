import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    title: string;
    id: number;
    isDataDeleting: boolean;
    deleteHandler: (id: number) => Promise<void>;
};

export const DeletePopconfirm = ({ title, id, isDataDeleting, deleteHandler, ...rest }: Props) => {
    const { t } = useLocalization();
    const [popConfirmOpen, setPopConfirmOpen] = useState<{ [index: number]: boolean }>({});

    return (
        <div>
            <Popconfirm
                title={title}
                open={popConfirmOpen[id]}
                okButtonProps={{ loading: isDataDeleting }}
                onConfirm={async () => {
                    await deleteHandler(id);
                    setPopConfirmOpen({ [id]: false });
                }}
                onCancel={() => setPopConfirmOpen({ [id]: false })}
                okText={t('Yes')}
                cancelText={t('No')}
                {...rest}
            >
                <DeleteOutlined onClick={() => setPopConfirmOpen({ [id]: true })} />
            </Popconfirm>
        </div>
    );
};
