import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useEffect, useState } from 'react';
import { Job, Request } from 'types/models';
import dayjs from 'dayjs';
import { useLocalization } from 'lib/Localization';
import { useAccount } from 'lib/Account';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { FormRequestTime } from 'types/staffing/FormRequest';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { JobState } from 'types/staffing/JobStateMachine';

export const useRequestActions = (request?: Request, setRequest?: (request: Request) => void) => {
    const handleError = useErrorHandler();
    const { t } = useLocalization();
    const { accountUser: user } = useAccount();

    const isClient = userIsClient(user);

    const [movablePastJobs, setMovablePastJobs] = useState<Job[]>([]);

    useEffect(() => {
        if (!request) {
            setMovablePastJobs([]);

            return;
        }

        setMovablePastJobs(getMovablePastJobs());
    }, [request]);

    const getDateTimes = ({ dates, differentTimes }: OrderFormRequest, index = 0) => {
        if (!(dates || []).length) {
            return [];
        }

        let key = 0;

        if (differentTimes) {
            key = dates?.[index] ? index : 0;
        }

        return dates?.[key].times;
    };

    const getMovablePastJobs = (fromRequest?: Request) => ((fromRequest || request)?.jobs || [])
        .filter((job) => dayjs(`${job.date} ${job.end_time}`).isBefore(new Date()))
        .filter((job) => (isClient && !job.is_client_archived) || (!isClient && !job.is_provider_archived));

    const getProviderWorkerQuota = (request: OrderFormRequest, providerId: number) => {
        const activeJobs = (request.jobs || []).filter(({ state }) => state !== JobState.CANCELED);
        const quotaIsNotEmpty = activeJobs.some((job) => !!job.providers?.length);

        if (activeJobs.length) {
            if (quotaIsNotEmpty) {
                return activeJobs.reduce((prev, { providers, state }) => {
                    if (state !== JobState.CANCELED && (!providers?.length || providers?.includes(providerId))) {
                        return prev + 1;
                    }

                    return prev;
                }, 0);
            } else {
                return activeJobs.length;
            }
        }

        return getTotalWorkers(request) * (request.selectedDates?.length || 1);
    };

    const getTotalDateTimesWorkers = (dateTimes: FormRequestTime[]) => dateTimes.reduce(
        (total, { workersNumber }: any) => total + parseInt(workersNumber),
        0
    );

    const getTotalWorkers = (request: OrderFormRequest, index?: number) => {
        const dateTimes = getDateTimes(request, index) || [];

        return getTotalDateTimesWorkers(dateTimes);
    };

    const moveJobsToHistory = async (jobIds: number[] = []) => {
        const ids = jobIds.length ? jobIds : movablePastJobs.map(({ id }) => id);

        if (!ids.length) {
            return;
        }

        try {
            const { data } = await Backend.put(`/staffing/jobs/archive`, { ids });

            if (request && setRequest) {
                setRequest({
                    ...request,
                    jobs: request.jobs.map((job) => {
                        if (ids.includes(job.id)) {
                            job = {
                                ...job,
                                ...data,
                            };
                        }

                        return job;
                    }),
                });
            }

            return { jobIds: ids, data };
        } catch (e) {
            handleError(e);
        }
    };

    return {
        movablePastJobs,
        getDateTimes,
        getMovablePastJobs,
        getProviderWorkerQuota,
        getTotalDateTimesWorkers,
        moveJobsToHistory,
    };
};
