import 'shared/Requests/Request/RequestLocation.scss';
import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Checkbox, Col, Divider, Form, FormInstance, Row, Select as AntSelect, Tooltip } from 'antd';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { Address, Department } from 'types/models';
import { PlusOutlined } from '@ant-design/icons';
import { useRecentItems } from 'lib/hooks';
import { RecentItem } from 'types/RecentItem';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';

const { Option } = AntSelect;

type Props = {
    departments: Department[];
    addresses: Address[];
    recentDepartments: number[];
    recentAddresses: number[];
    loadingLocations: boolean;
    selectedAddressId?: number;
    isOrder: boolean;
    defaultAddress?: number;
    form: FormInstance;
    onCreateAddressHandler: () => void;
    onCreateDepartmentHandler: () => void;
    setDepartmentShiftIds: (ids: number[]) => void;
    setDefaultAddress: (id: number) => void;
};

export const RequestCreationLocation = ({
    departments,
    addresses,
    recentDepartments,
    recentAddresses,
    loadingLocations,
    selectedAddressId,
    isOrder,
    defaultAddress,
    form,
    onCreateAddressHandler,
    onCreateDepartmentHandler,
    setDepartmentShiftIds,
    setDefaultAddress,
}: Props) => {
    const { t } = useLocalization();
    const [filteredDepartments, setFilteredDepartments] = useState<Department[]>([]);
    const { getSelectOptionsFor } = useRecentItems();
    const [rememberLocation, setRememberLocation, deleteRememberLocation] = useLocalStorageState('rememberLocation',[]);

    /**
     * If no department is selected, addresses list shows the addresses not associated with a department
     */
    const filterDepartmentsByAddressId = useCallback(
        (addressId?: number | undefined) => {
            if (!addressId) {
                setFilteredDepartments(departments);

                return;
            }

            const address = addresses.find(({ id }) => id === addressId);

            let filteredDepartmentsTemp = departments.filter(({ id }) => address?.department_ids?.includes(id));

            setFilteredDepartments(filteredDepartmentsTemp.length > 0 ? filteredDepartmentsTemp : departments);
        },
        [addresses, departments]
    );

    const locationsChangeHandler = (addressId: number) => {
        filterDepartmentsByAddressId(addressId);

        // remove department_id if it doesn't exist in the chosen address
        if (
            filteredDepartments.length &&
            !filteredDepartments.some(({ id }) => form.getFieldValue('department_id') === id)
        ) {
            form.setFieldsValue({ department_id: undefined });
        }

        if (rememberLocation) {
            setDefaultAddress(addressId);
        }
    };

    const rememberLocationChangeHandler = (e: any) => {
        form.setFieldsValue({ remember_location: !rememberLocation })
        setRememberLocation(!rememberLocation);
    };

    const departmentsChangeHandler = (departmentId: number) => {
        const department = departments.find(({ id }) => id === departmentId);

        if (department?.work_shift_ids?.length === 0) {
            let workShiftIds: number[] = [];

            departments.forEach((department) => {
                if (department.work_shift_ids) workShiftIds.push(...department.work_shift_ids);
            });

            setDepartmentShiftIds(workShiftIds);
        } else {
            setDepartmentShiftIds(department?.work_shift_ids || []);
        }
    };

    const getDepartmentOptions = () => getSelectOptionsFor('departments', recentDepartments, filteredDepartments);

    const getLocationOptions = () =>
        getSelectOptionsFor('locations', recentAddresses, addresses, ({ id, name, address, city }: RecentItem) => ({
            label: `${name}, ${address}, ${city}`,
            value: id,
        }));

    useEffect(() => {
        if (typeof form.getFieldValue('remember_location') === 'undefined') {
            form.setFieldsValue({ remember_location: true });
        }

        if (isOrder && rememberLocation === true && defaultAddress) {
            form.setFieldsValue({ address_id: defaultAddress });
            form.setFieldsValue({ remember_location: rememberLocation });
            locationsChangeHandler(defaultAddress);
        }

        if (selectedAddressId) {
            locationsChangeHandler(selectedAddressId);
        } else {
            filterDepartmentsByAddressId();
        }
    }, [filterDepartmentsByAddressId, selectedAddressId]);

    const isOrderFormItemCss = isOrder ? { marginBottom: '0px' } : {};

    return (
        <div className="request-location-creation">
            <Row>
                <Col span={24} style={{ maxWidth: '650px' }}>
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col>
                                    <p className="subsection-subtitle">{t('Facility location')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name="address_id"
                                        rules={[{ required: true, message: t('Please select location') }]}
                                        style={isOrderFormItemCss}
                                    >
                                        <Select
                                            disabled={loadingLocations}
                                            filterOption={(input, option) =>
                                                (option!.label as unknown as string)
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                            onChange={locationsChangeHandler}
                                            optionFilterProp="label"
                                            options={getLocationOptions()}
                                            placeholder={
                                                loadingLocations ? t('Loading locations...') : t('Choose location')
                                            }
                                            showSearch
                                        />
                                    </Form.Item>
                                    {isOrder && (
                                        
                                        <Form.Item
                                            name="remember_location"
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Checkbox
                                                disabled={form.getFieldValue('address_id') === undefined}
                                                onChange={rememberLocationChangeHandler}
                                                checked={rememberLocation}
                                            >
                                                {t('Remember selection for this order')}
                                            </Checkbox>
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col xs={4}>
                                    <Tooltip placement="top" title={t('Please click here to configure your addresses')}>
                                        <Button
                                            key="2"
                                            icon={<PlusOutlined />}
                                            onClick={() => onCreateAddressHandler()}
                                            className="creation-buttons"
                                        >
                                            {t('Add location')}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ maxWidth: '650px' }}>
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col>
                                    <p className="subsection-subtitle">{t('Department directions')}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={17}>
                                    <Form.Item name="department_id">
                                        <Select
                                            disabled={loadingLocations}
                                            filterOption={(input, option) =>
                                                (option!.label as unknown as string)
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                            onChange={departmentsChangeHandler}
                                            optionFilterProp="label"
                                            options={getDepartmentOptions()}
                                            placeholder={
                                                loadingLocations ? t('Loading departments...') : t('Choose department')
                                            }
                                            allowClear
                                            showSearch
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={4}>
                                    <Tooltip title={t('Please click here to configure your departments')}>
                                        <Button
                                            key="1"
                                            icon={<PlusOutlined />}
                                            onClick={() => onCreateDepartmentHandler()}
                                            className="creation-buttons"
                                        >
                                            {t('Add department')}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ width: '96%', minWidth: '96%' }} />
                </Col>
            </Row>
        </div>
    );
};
