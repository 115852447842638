import 'shared/AntDesignUtils/CustomModal/CustomModal.scss';
import { Modal as AntModal } from 'antd';
import React from 'react';

type Props = {
    [index: string]: any;
    children: JSX.Element | string | (JSX.Element | string)[];
    className?: string;
    defaultBody?: boolean;
}

export const CustomModal = ({ children, className = '', defaultBody = false, ...otherProps }: Props) => (
    <AntModal className={`custom-modal ${defaultBody ? 'default-body' : ''} ${className}`} centered {...otherProps}>
        {children}
    </AntModal>
)
