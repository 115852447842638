import 'shared/AntDesignUtils/InlineEditTable/InlineEditTable.scss';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { GenericObject } from 'shared/Contracts';

const EditableContext = createContext({} as FormInstance);

export const EditableRow = (props: GenericObject) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr className="editable-row" {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

type EditableCellProps = {
    title: string;
    editable: boolean;
    children: ReactNode;
    dataIndex: string;
    record: { [index: string]: string };
    handleSave: <T>(record: T) => void;
};

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}: EditableCellProps) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();

            if (values[dataIndex] === record[dataIndex]) {
                return;
            }

            handleSave({ ...record, ...values });
        } catch (error) {
            console.log('Save failed:', error);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item name={dataIndex} style={{ margin: 0, padding: 0 }}>
                <Input.TextArea autoSize={{ minRows: 1 }} ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return (
        <td className="editable-cell" {...restProps}>
            {childNode}
        </td>
    );
};
