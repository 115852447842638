import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import {
    TableDatePickerFilterDropdown,
    TableDateRangePickerFilterDropDown,
} from 'shared/AntDesignUtils/TableDatePickerFilterDropdown';
import { TableFilterDropdown } from 'shared/AntDesignUtils/TableFilterDropdown/TableFilterDropdown';
import { TableOptionFilterDropdown } from 'shared/AntDesignUtils/TableOptionFilterDropDown/TableOptionFilterDropDown';
import { GenericObject } from 'shared/Contracts';
import moment from 'moment';

export const getColumnSearchProps = (dataIndex: string, backendFilter?: boolean) => {
    const props: GenericObject = {
        filterDropdown: (props: any) => (
            <TableFilterDropdown {...props} backendFilter={backendFilter} dataIndex={dataIndex} />
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined className={filtered ? 'table-filter-icon' : ''} />,
        onFilter: (value: string | number | boolean, record: any): boolean => {
            const dataIndexValue = record[dataIndex];
            if (typeof dataIndexValue === 'string') {
                if (moment(dataIndexValue, 'YYYY-MM-DD HH:mm:ss').isValid()) {
                    const recordDate = moment(dataIndexValue, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
                    return recordDate.includes(value.toString());
                } else {
                    return dataIndexValue.toLowerCase().includes(value.toString().toLowerCase());
                }
            } else if (typeof dataIndexValue === 'number') {
                return dataIndexValue.toString().includes(value.toString());
            } else if (Array.isArray(dataIndexValue)) {
                return dataIndexValue.some((profession: { name: string }) =>
                    profession.name.toLowerCase().includes(value.toString().toLowerCase())
                );
            }
            return false;
        },
    };

    if (backendFilter) {
        delete props.onFilter;
    }

    return props;
};

export const getColumnDatePickerProps = (dataIndex: string, format?: string, backendFilter?: boolean) => {
    const props: GenericObject = {
        filterDropdown: (props: any) => (
            <TableDatePickerFilterDropdown {...props} dataIndex={dataIndex} format={format} />
        ),
        filterIcon: (filtered: boolean) => <CalendarOutlined className={filtered ? 'table-filter-icon' : ''} />,
        onFilter: (value: any, record: any): boolean => {
            // override this function to use your logic to compare two or multiple dates
            if (value === null) return true;

            const dateValue = new Date(value);

            const recordDateParts = record[dataIndex].split('.');
            const dateRecord = new Date(`${recordDateParts[2]}-${recordDateParts[1]}-${recordDateParts[0]}`);

            // comparing two dates type (Date without time)
            return (
                dateValue.getMonth() === dateRecord.getMonth() &&
                dateValue.getDate() === dateRecord.getDate() &&
                dateValue.getFullYear() === dateRecord.getFullYear()
            );
        },
    };

    if (backendFilter) {
        delete props.onFilter;
    }

    return props;
};

export const getColumnDateRangePickerProps = (dataIndex: string) => ({
    filterDropdown: (props: any) => <TableDateRangePickerFilterDropDown {...props} dataIndex={dataIndex} />,
    filterIcon: (filtered: boolean) => <CalendarOutlined className={filtered ? 'table-filter-icon' : ''} />,
});

export const getColumnOptionProps = (dataIndex: string, label: string, backendFilter?: boolean) => {
    const props: GenericObject = {
        filterDropdown: (props: any) => <TableOptionFilterDropdown {...props} label={label} />,
        onFilter: (value: string | number | boolean, record: any): boolean =>
            value ? record[dataIndex] === null : true,
    };

    if (backendFilter) {
        delete props.onFilter;
    }

    return props;
};

export const sortColumn = <T extends GenericObject>(a: T | undefined, b: T | undefined, column: string) => {
    if (!a || !a[column]) {
        return -1;
    }

    if (typeof a[column] === 'string') {
        return a[column].localeCompare(b?.[column] ?? '');
    }

    if (typeof a[column] === 'number') {
        return a[column] - (b?.[column] ?? Number.NEGATIVE_INFINITY);
    }
};

export const filterRoles = (value: number, record: any) => {
    for (const role of record.roles) {
        if (role.id === value) {
            return true;
        }
    }

    return false;
};

export const getDefaultPaginationProp = (count: number) =>
    count > 10
        ? {
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
              onChange: (page: number) => {},
          }
        : false;
