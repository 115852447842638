import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import {
    ApartmentOutlined,
    FileSyncOutlined,
    GlobalOutlined,
    KeyOutlined,
    MailOutlined,
    SettingOutlined,
    ToolOutlined,
    UserOutlined,
    WindowsOutlined,
} from '@ant-design/icons';
import { useLocalization } from 'lib/Localization';
import { useRouteMatch } from 'react-router-dom';
import { useMenuKeys } from 'lib/Helpers/MenuHelper';
import { useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';

/**
 * Render the side menu of the admin sub application.
 *
 * @return {*}
 */
export const SideMenu = () => {
    const { t } = useLocalization();
    const match = useRouteMatch();
    const { selectedMenuKey, openMenuKey } = useMenuKeys();
    const [openKeys, setOpenKeys] = useState(openMenuKey ? [openMenuKey] : undefined);

    const items: MenuProps['items'] = [
        { key: 'users', icon: <UserOutlined />, label: <Link to={`${match.path}/users`}>{t('Users')}</Link> },
        {
            key: 'organizations',
            icon: <ApartmentOutlined />,
            label: <Link to={`${match.path}/organizations`}>{t('Organizations')}</Link>,
        },
        {
            key: 'localization',
            label: (
                <span>
                    <GlobalOutlined />
                    <span>{t('Localization')}</span>
                </span>
            ),
            children: [
                {
                    key: 'translations',
                    icon: <FileSyncOutlined />,
                    label: <Link to={`${match.path}/localization/translations`}>{t('Translations')}</Link>,
                },
            ],
        },
        {
            key: 'configuration',
            label: (
                <span>
                    <SettingOutlined />
                    <span>{t('Configuration')}</span>
                </span>
            ),
            children: [
                {
                    key: 'professions',
                    icon: <ToolOutlined />,
                    label: <Link to={`${match.path}/configuration/professions`}>{t('Professions')}</Link>,
                },
                {
                    key: 'categories',
                    icon: <WindowsOutlined />,
                    label: <Link to={`${match.path}/configuration/categories`}>{t('Categories')}</Link>,
                },
                {
                    key: 'communication',
                    icon: <MailOutlined />,
                    label: <Link to={`${match.path}/configuration/communication`}>{t('Communication')}</Link>,
                },
                {
                    key: 'permissions',
                    icon: <KeyOutlined />,
                    label: <Link to={`${match.path}/configuration/permissions`}>{t('Permissions')}</Link>,
                },
            ],
        },
    ];

    return (
        <Menu
            className="sidebar-menu"
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuKey]}
            openKeys={openKeys}
            onClick={function ({ keyPath }) {
                if (keyPath.length === 2) {
                    // clicked on a sub-menu -> close other open sub-menu
                    setOpenKeys([[...keyPath].pop() as string]);
                }
            }}
            onOpenChange={function (openKeys: string[]) {
                setOpenKeys(openKeys);
            }}
            items={items}
        />
    );
};
