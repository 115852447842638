import 'pages/Client/Configuration/WorkShifts/WorkShifts.scss';

import { useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { WorkShiftsTable } from './WorkShiftsTable';
import { WorkShiftForm } from './WorkShiftForm';
import { Button, Drawer, FormInstance, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'lib/ErrorHandling';
import { WorkShift } from 'types/models';
import { AxiosResponse } from 'axios';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';

export const WorkShifts = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [shift, setShift] = useState<WorkShift>();
    const [isShiftFormOpen, setIsShiftFormOpen] = useState(false);
    const [isDataDeleting, setIsDataDeleting] = useState(false);

    const shiftsURL = '/data-management/work-shifts';
    const [shifts, issetShiftsLoading, setShifts] = useLoadEntity<WorkShift>('workShifts', shiftsURL);

    const openShiftHandler = (shift?: WorkShift) => {
        setShift(shift);
        setIsShiftFormOpen(true);
    };

    const spliceShifts = (id: number, replacementShift?: WorkShift) => {
        const currentShifts = [...shifts];
        const index = currentShifts.findIndex((shift) => shift.id === id);

        if (replacementShift) {
            currentShifts.splice(index, 1, replacementShift);
        } else {
            currentShifts.splice(index, 1);
        }

        setShifts(currentShifts);
    };

    const saveShiftHandler = async (values: WorkShift, form: FormInstance) => {
        try {
            setIsDataSaving(true);

            if (values.id) {
                const { data, status }: AxiosResponse = await Backend.put(`${shiftsURL}/${values.id}`, values);

                if (status === Backend.responseStatus.HTTP_OK) {
                    spliceShifts(data.id, data);

                    notification.success({
                        message: t('Data successfully saved'),
                    });
                    setIsShiftFormOpen(false);
                }
            } else {
                const { data, status }: AxiosResponse = await Backend.post(shiftsURL, values);

                if (status === Backend.responseStatus.HTTP_CREATED) {
                    setShifts([data, ...shifts]);

                    notification.success({
                        message: t('Data successfully created'),
                    });
                    setIsShiftFormOpen(false);
                }
            }

            form.resetFields();
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const deleteShiftHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);

            const { status }: AxiosResponse = await Backend.delete(`${shiftsURL}/${id}`);

            if (status === Backend.responseStatus.HTTP_NO_CONTENT) {
                spliceShifts(id);

                notification.success({
                    message: t('Shift successfully removed'),
                });
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    const headerButtons = [
        <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => openShiftHandler()}>
            {t('Add Shift')}
        </Button>,
    ];

    return (
            <div id="work-shifts" className="work-shifts">
                <PageHeader title={t('Shifts')} backIcon={false} ghost={false} extra={headerButtons} />

                <WorkShiftsTable
                    deleteShiftHandler={deleteShiftHandler}
                    isDataLoading={issetShiftsLoading}
                    isDataDeleting={isDataDeleting}
                    openShiftHandler={openShiftHandler}
                    shifts={shifts}
                />
                <Drawer
                    title={shift?.id ? t('Edit Shift') : t('Add Shift')}
                    width={500}
                    onClose={() => setIsShiftFormOpen(false)}
                    open={isShiftFormOpen}
                >
                    <WorkShiftForm shift={shift} saveShiftHandler={saveShiftHandler} isDataSaving={isDataSaving} />
                </Drawer>
            </div>
    );
};
