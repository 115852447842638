import { useLocalization } from 'lib/Localization';
import { Input, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import type { CsvWorkerProps, CsvWorkersWithTempId, ParsedCsvWorkersWithTempId } from 'types/models/Worker';
import { ErrorKey, TemplateErrors } from './ImportExcelModal';

type Props = {
    parsedWorkers: ParsedCsvWorkersWithTempId;
    templateErrors: TemplateErrors;
    isLoading: boolean;
};

export const ValidateWorkersTable = ({ parsedWorkers, templateErrors, isLoading = false }: Props) => {
    const { t } = useLocalization();

    if (!Object.keys(parsedWorkers).length) {
        return null;
    }

    const renderCell = (fieldName: CsvWorkerProps, fieldValue: string, csvWorker: CsvWorkersWithTempId) => {
        const errors = templateErrors[`${csvWorker.tempId}.${fieldName}`];

        const formattedErrors = errors ? (
            <Tooltip title={errors.join('\n')}>
                <InfoCircleOutlined className="has-validation-error" />
            </Tooltip>
        ) : null;

        return (
            <div className={`cell-container ${fieldName} ${errors ? 'has-error' : ''}`}>
                <Input suffix={formattedErrors} value={fieldValue} bordered={false} readOnly />
            </div>
        );
    };

    const columns: ColumnsType<CsvWorkersWithTempId> = [
        {
            title: t('Row'),
            dataIndex: 'tempId',
            rowScope: 'row',
            render: (tempId: number) => {
                return tempId + 1;
            },
        },
        {
            title: t('First name'),
            dataIndex: 'first_name',
            key: 'first_name',
            render: (firstNameValue, csvWorker) => renderCell('first_name', firstNameValue, csvWorker),
        },
        {
            title: t('Last name'),
            dataIndex: 'last_name',
            key: 'last_name',
            render: (lastNameValue: string, csvWorker) => renderCell('last_name', lastNameValue, csvWorker),
        },
        {
            title: t('Email'),
            dataIndex: 'email_address',
            key: 'email_address',
            render: (email: string, csvWorker) => renderCell('email_address', email, csvWorker),
        },
        {
            title: t('Phone number'),
            dataIndex: 'phone_number',
            key: 'phone_number',
            render: (phone: string, csvWorker) => renderCell('phone_number', phone, csvWorker),
        },
        {
            title: t('External ID'),
            dataIndex: 'external_id',
            key: 'external_id',
            render: (externalId: string, csvWorker) => renderCell('external_id', externalId, csvWorker),
        },
        {
            title: t('Shirt size'),
            dataIndex: 'shirt_size',
            key: 'shirt_size',
            render: (shirtSize: string, csvWorker) => renderCell('shirt_size', shirtSize, csvWorker),
        },
        {
            title: t('Trousers size'),
            dataIndex: 'trousers_size',
            key: 'trousers_size',
            render: (trousersSize: string, csvWorker) => renderCell('trousers_size', trousersSize, csvWorker),
        },
    ];

    return (
        <Table
            className="validation-error-table"
            rowKey="id"
            columns={columns}
            dataSource={getWorkersWithErrors(parsedWorkers, templateErrors)}
            loading={isLoading}
            pagination={false}
        />
    );
};

function getWorkersWithErrors(parsedWorkers: ParsedCsvWorkersWithTempId, templateErrors: TemplateErrors) {
    const csvWorkers = Object.values(parsedWorkers);

    const errorsObject = Object.keys(templateErrors).reduce(
        (
            acc: {
                [key: number]: string[];
            },
            key
        ) => {
            const tempId = parseInt(key);

            if (!isNaN(tempId)) {
                acc[tempId] = templateErrors[key as ErrorKey];
            }
            return acc;
        },
        {}
    );

    return csvWorkers.filter((worker) => errorsObject[worker.tempId]);
}
