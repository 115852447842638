import React from 'react';

type IconProps = {
    width?: number;
    height?: number;
};

function SuitCaseIcon({ width, height }: IconProps) {
    return (
        <svg
            className="svg-inline--fa fa-suitcase ant-menu-item-icon"
            width={width ?? 17}
            height={height ?? 17}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5441 4.66667H2.70883C1.96081 4.66667 1.35442 5.26363 1.35442 6.00001V12.6667C1.35442 13.4031 1.96081 14 2.70883 14H13.5441C14.2922 14 14.8986 13.4031 14.8986 12.6667V6.00001C14.8986 5.26363 14.2922 4.66667 13.5441 4.66667Z"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8353 14V3.33333C10.8353 2.97971 10.6926 2.64057 10.4386 2.39052C10.1846 2.14048 9.84011 2 9.4809 2H6.77207C6.41286 2 6.06836 2.14048 5.81435 2.39052C5.56035 2.64057 5.41766 2.97971 5.41766 3.33333V14"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export { SuitCaseIcon };
