import { Col, Form, Input, Row } from 'antd';
import { useLocalization } from 'lib/Localization';

type Props = {
    order: any,
    updateOrder: (order: any) => void;
}

export const RequestCreationOrder = ({ order, updateOrder }: Props) => {
    const { t } = useLocalization();

    return (
        <Row>
            <Col span={24} style={{ maxWidth: '650px' }}>
                <Row>
                    <Col span={22}>
                        <Row>
                            <Col span={17}>
                                <p className="subsection-subtitle">{t('Order name')}</p>

                                <Form.Item
                                    className="no-margin-bottom"
                                    name="order_name"
                                    rules={[{
                                        required: true,
                                        message: t('Please set the order name')
                                    }]}
                                >
                                    <Input
                                        autoComplete="off"
                                        disabled={order.current > 1}
                                        maxLength={255}
                                        placeholder={t('Type order name')}
                                        onChange={({ target }) =>
                                            updateOrder({
                                                name: target.value
                                            })
                                        }
                                        value={order.name}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={4} style={{ marginLeft: '1em' }}>
                                <p className="subsection-subtitle">{t('External ID')}</p>

                                <Form.Item
                                    className="no-margin-bottom"
                                    name="external_id"
                                >
                                    <Input
                                        autoComplete="off"
                                        disabled={order.current > 1}
                                        maxLength={255}
                                        placeholder={t('Type external ID')}
                                        onChange={({ target }) =>
                                            updateOrder({
                                                external_id: target.value
                                            })
                                        }
                                        style={{ width: '160px' }}
                                        value={order.external_id}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
