import 'shared/Requests/RequestCard.scss';

import { ReactNode } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { useLocalization } from 'lib/Localization';
import { OrganizationType } from 'types';
import { Request } from 'types/models';
import { OrganizationNames } from './OrganizationNames';
import { getCurrentEntityName, getStylesOfRequest, getTabsStatesMap, StatusBadge } from './StatusBadge';
import { AvailableTabs } from './Requests';
import { useAccount } from 'lib/Account';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { useNavigate } from 'react-router-dom-v5-compat';

const { Title, Paragraph } = Typography;

type Props = {
    activeTab: AvailableTabs;
    extra: ReactNode;
    organizationType: OrganizationType;
    request: Request;
    type: string;
};

export const RequestCard = ({ activeTab, extra, organizationType, request, type }: Props) => {
    const { t, locale } = useLocalization();
    const { accountUser: user } = useAccount();
    const navigate = useNavigate();
    const TabsStatesMap = getTabsStatesMap(t);

    const dates = request.jobs.map((job) => new Date(job.date)) as Date[];
    const styles = getStylesOfRequest(request, TabsStatesMap[activeTab], getCurrentEntityName(user));

    return (
        <Card className={`request request-card ${type}-card ${styles}-state`} type="inner">
            <Row>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} className="card-state">
                    <StatusBadge activeTab={activeTab} request={request} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Title level={4} style={{ marginBottom: 0 }} className="profession">
                        {localizeProfession(request.profession, locale).name}
                    </Title>
                    <Paragraph className="department">
                        <>
                            {request.department ? `${request.department.name} - ` : null}
                            {request.address?.address}
                        </>
                    </Paragraph>
                    <Paragraph className="organization">
                        <OrganizationNames request={request} organizationType={organizationType} />
                    </Paragraph>
                </Col>
                <Col xs={24} sm={12} md={9} lg={9} xl={9} style={{ display: 'flex' }}>
                    <div className="range-shifts">
                        <Paragraph className="date-range">{request.dateRange}</Paragraph>
                        <Paragraph className="shifts-count">({dates.length + ' ' + t('Shifts')})</Paragraph>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <Row className="buttons-row">
                        <Button
                            className="card-button"
                            onClick={() => navigate(`/${organizationType}/requests/${request.id}`)}
                        >
                            {t('See details')}
                        </Button>
                    </Row>
                    <Row>
                        <Paragraph className="extra">{extra}</Paragraph>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
