import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { DepartmentsTable } from './DepartmentsTable';
import { DepartmentForm } from './DepartmentForm';
import { Button, Drawer, FormInstance, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Department, WorkShift } from 'types/models';

export const Departments = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState<Department>();
    const [isDepartmentFormOpen, setIsDepartmentFormOpen] = useState(false);
    const [shifts, setShifts] = useState<WorkShift[]>([]);
    const departmentsURL = '/data-management/departments';

    /**
     * Fetch data form backend.
     *
     * @return void
     */
    const loadDepartmentsData = useCallback(async () => {
        try {
            setIsDataLoading(true);

            const response = await Backend.get(`${departmentsURL}?relations=workShifts&collect=workShifts`);

            setDepartments(response.data.departments);
            setShifts(response.data.collections.workShifts);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadDepartmentsData();
    }, [loadDepartmentsData]);

    /**
     * Open the department form to create/update a department.
     *
     * @param department {object}
     * @return void
     */
    const openDepartmentHandler = (department?: Department) => {
        setDepartment(department);
        setIsDepartmentFormOpen(true);
    };

    /**
     * Save a department.
     *
     * @param values
     * @param form
     * @return void
     */
    const saveDepartmentHandler = async (values: Department, form: FormInstance) => {
        try {
            setIsDataSaving(true);

            let method = 'post';
            let url = departmentsURL;

            if (values.id) {
                method = 'put';
                url = `${departmentsURL}/${values.id}`;
            }

            const response = await Backend[method](url, values);
            let message = t('Data successfully created');

            if (values.id && response.status === Backend.responseStatus.HTTP_OK) {
                message = t('Data successfully saved');
            }

            notification.success({ message });
            setIsDepartmentFormOpen(false);

            loadDepartmentsData();
            setIsDataSaving(false);

            return true;
        } catch (error) {
            handleError(error, form);
            setIsDataSaving(false);

            return false;
        }
    };

    const headerButtons = [
        <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => openDepartmentHandler()}>
            {t('Add Department')}
        </Button>,
    ];

    const shiftFilterOptions = shifts.map(({ id, name }) => ({
        value: id,
        text: name,
    }));

    return (
        <>
            <PageHeader title={t('Departments')} backIcon={false} ghost={false} extra={headerButtons} />

            <DepartmentsTable
                departmentsURL={departmentsURL}
                departments={departments}
                openDepartmentHandler={openDepartmentHandler}
                isDataLoading={isDataLoading}
                loadDepartmentsData={loadDepartmentsData}
                shiftFilterOptions={shiftFilterOptions}
            />
            <Drawer
                title={department?.id ? t('Edit Department') : t('Add Department')}
                width={500}
                onClose={() => setIsDepartmentFormOpen(false)}
                open={isDepartmentFormOpen}
            >
                <DepartmentForm
                    department={department}
                    shifts={shifts}
                    saveDepartmentHandler={saveDepartmentHandler}
                    isDataSaving={isDataSaving}
                />
            </Drawer>
        </>
    );
};
