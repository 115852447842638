import 'pages/Client/Requests/Create/RequestCreation.scss';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { Backend } from 'lib/Http/Backend';
import { useCallback, useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Modal,
    notification,
    Radio,
    Row,
    Space,
    Steps,
    Typography
} from 'antd';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    FolderOutlined,
    PlusOutlined,
    SaveOutlined,
    SendOutlined,
    SyncOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined
} from '@ant-design/icons';
import { useLocalization } from 'lib/Localization';
import {
    RequestCreationDateTime,
    RequestCreationLocation,
    RequestCreationLocationFormsDrawer,
    RequestCreationNotes,
    RequestCreationProfession,
    RequestCreationProviders
} from './';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { Address, Department, Profession, Provider, Request } from 'types/models';
import { FormattedJob, FormRequest } from 'types/staffing';
import _ from 'lodash';
import { RequestCreationInfoSummary } from './RequestCreationInfoSummary';
import { useIsOrganizationalDataComplete, useRecentItems } from 'lib/hooks';
import { RequestAcceptanceType } from 'pages/Client/Requests/Create/RequestAcceptanceType';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FileIcon } from 'shared/icons';
import { GenericObject } from 'shared/Contracts';
import { SearchFunctionParam, useSearchParams } from 'lib/Helpers/SearchParams';
import { OverviewModal } from 'pages/Client/Requests/Create/OverviewModal';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Prompt } from 'react-router-dom';
import {
    isDifferentTimes,
    isJobDateValid,
    prepareRequestData,
    requestToFormRequest,
    TimesValue,
    updateJobListing
} from 'lib/Request/utils';
import { RequestCreationTimesContainer } from 'pages/Client/Requests/Create/RequestCreationTimesContainer';
import { RequestDocuments } from 'pages/Client/Requests/Create/RequestDocuments';
import { RequestCreationOrder } from 'pages/Client/Requests/Create/RequestCreationOrder';

const { Title } = Typography;

const REQUEST_ORDER = 1;
const REQUEST_SINGLE = 2;

export const REQUEST_TYPE_SIMULTANEOUS = 0;
export const REQUEST_TYPE_SEQUENTIAL = 1;

export interface OrderFormRequest extends FormRequest {
    id?: number;
}

export interface Order {
    allStored: boolean;
    current: number;
    id?: number | null;
    name: string;
    external_id?: string;
    requests: OrderFormRequest[];
}

const defaultOrder = {
    // Indicates that all formdata have been put in the request array
    allStored: false,
    // The current request. Always starts at 1.
    current: 1,
    id: null,
    name: '',
    external_id: '',
    requests: [],
};

const defaultFormData = {
    jobs: [],
    providers: [],
    providerWorkerQuotas: {},
    type: REQUEST_TYPE_SIMULTANEOUS,
};

type SearchParamResult = {
    order?: Order;
    request?: number;
};

export const RequestCreation = () => {
    const { t, locale } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const { addToRecent } = useRecentItems();
    const isOrganizationalDataComplete = useIsOrganizationalDataComplete();
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(0);
    const [loadingProfessions, setLoadingProfessions] = useState(true);
    const [professions, setProfessions] = useState<Profession[]>([]);
    const [departments, setDepartments] = useState([]);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [providers, setProviders] = useState([]);
    const [enabledProviders, setEnabledProviders] = useState<Provider[]>([]);
    const [disabledProviders, setDisabledProviders] = useState<Provider[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
    const [loadingProviders, setLoadingProviders] = useState(true);
    const [order, setOrder, deleteOrder] = useLocalStorageState('orderRequests', defaultOrder);
    const [formData, setFormData, deleteFormData] = useLocalStorageState('request', defaultFormData);
    const [requestType, setRequestType] = useLocalStorageState('lastSelectedRequestedType', REQUEST_SINGLE);
    const [recentProfessions, setRecentProfessions] = useLocalStorageState('recentProfessions', []);
    const [recentDepartments, setRecentDepartments] = useLocalStorageState('recentDepartments', []);
    const [recentAddresses, setRecentAddresses] = useLocalStorageState('recentAddresses', []);
    const [defaultAddress, setDefaultAddress, deleteDefaultAddress] = useLocalStorageState('defaultAddress', []);
    const [rememberLocation, setRememberLocation, deleteRememberLocation] = useLocalStorageState('rememberLocation',[]);
    const [differentTimes, setDifferentTimes] = useState<number>(formData?.differentTimes || 0);
    const [selectedDates, setSelectedDates] = useState<Date[]>(formData?.selectedDates || []);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
    const [isDepartmentFormOpen, setIsDepartmentFormOpen] = useState(false);
    const [profession, setProfession] = useState<Profession | null>(null);
    const [departmentShiftIds, setDepartmentShiftIds] = useState<number[]>([]);
    const [showOverview, setShowOverview] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [formDataIsDirty, setFormDataIsDirty] = useState(false);

    const isOrderRequest = () => requestType === REQUEST_ORDER;

    useSearchParams<SearchParamResult>(
        {
            order: async ({ param }: SearchFunctionParam) => {
                setLoadingOrder(true);

                deleteFormData();
                deleteOrder();
                deleteRememberLocation();
                deleteDefaultAddress();

                const { data } = await Backend.get(`/staffing/orders/${param}`);
                const savedOrder = data.order;

                const order = {
                    allStored: true,
                    current: 1,
                    name: savedOrder.name,
                    external_id: savedOrder.external_id,
                    id: savedOrder.id,
                    requests: savedOrder.requests.reverse().map((request: Request) => ({
                        ...requestToFormRequest(request),
                        order_name: savedOrder.name,
                        external_id: savedOrder.external_id,
                    })),
                };

                setRequestType(savedOrder.auto_created ? REQUEST_SINGLE : REQUEST_ORDER);

                setOrder(order);

                form.setFieldValue('order_name', savedOrder.name);
                form.setFieldValue('external_id', savedOrder.external_id);

                await activateOrderRequest(0, order.requests);

                setLoadingOrder(false);

                return order;
            },
            request: async ({ param, results }: SearchFunctionParam) => {
                const { order } = results;

                if (!order) {
                    return;
                }

                setCurrentStep(0);

                const requests = order.requests as OrderFormRequest[];
                let index = requests.length - 1;

                const requestId = Number(param);
                index = requests.findIndex(({ id }) => id === requestId);

                if (index < -1) {
                    index = 0;
                }

                return activateOrderRequest(index, requests);
            },
            addRequest: async ({ results }: SearchFunctionParam) => {
                const { order } = results;

                if (!order) {
                    return;
                }

                return addNewRequest(order);
            },
            overview: () => isOrderRequest() && setShowOverview(true),
        },
        async ({ order: loadedOrder }: SearchParamResult) => {
            if (!loadedOrder && (formData.id || order.id)) {
                await handleClearRequest(true, false, false);
            }
        }
    );

    /**
     * Filters enabled and disabled providers based on selected profession
     */
    const filterProvidersByProfession = useCallback(
        (professionId: number) => {
            const providersWithProfession = providers.filter((provider: Provider) =>
                provider.professions.some(({ id }: { id: number }) => id === professionId)
            );
            const providersWithoutProfession = providers.filter((provider: Provider) =>
                providersWithProfession.every(({ id }: { id: number }) => id !== provider.id)
            );
            const selectedProfession = professions.find((profession) => profession.id === professionId) as Profession;

            setProfession(selectedProfession);
            setEnabledProviders(providersWithProfession);
            setDisabledProviders(providersWithoutProfession);
            setSelectedProvidersByProfession(providersWithProfession);
        },
        [professions, providers]
    );

    const setSelectedProvidersByProfession = (providersWithProfession: Provider[]) => {
        if (selectedProviders.length === 0) {
            setSelectedProviders(providersWithProfession.map((p) => p.id));
        }
    };

    /**
     * Fetch departments and addresses form backend.
     */
    const loadLocations = useCallback(async () => {
        try {
            setLoadingLocations(true);

            const departmentsResponse = await Backend.get('/data-management/departments?collect=workShiftIds');

            setDepartments(
                (departmentsResponse.data.departments || [])
                    .sort((d1: Department, d2: Department) => d1.name.localeCompare(d2.name))
                    .map((dept: Department) => {
                        dept.work_shift_ids = departmentsResponse.data.collections?.workShiftIds?.[dept.id] || [];

                        return dept;
                    })
            );

            const addressesResponse = await Backend.get('/data-management/addresses?collect=departmentIds');
            const addressesData = addressesResponse.data.addresses || [];

            setAddresses(
                addressesData
                    .sort((a1: Address, a2: Address) => a1.address.localeCompare(a2.address))
                    .map((address: Address) => {
                        address.department_ids = addressesResponse.data.collections?.departmentIds?.[address.id] || [];

                        return address;
                    })
            );
        } catch (error) {
            handleError(error);
        } finally {
            setLoadingLocations(false);
        }
    }, [handleError]);

    useEffect(() => {
        const loadProfessions = async () => {
            try {
                setLoadingProfessions(true);
                const response = await Backend.get(`/data-management/professions`);
                setProfessions(
                    localizeProfessions(response.data.professions, locale).sort((p1, p2) =>
                        p1.name.localeCompare(p2.name)
                    )
                );
            } catch (error) {
                handleError(error);
            } finally {
                setLoadingProfessions(false);
            }
        };

        const loadProviders = async () => {
            try {
                setLoadingProviders(true);
                const response = await Backend.get('/data-management/providers?relations=professions');
                setProviders(
                    (response.data.providers || []).sort((p1: Provider, p2: Provider) =>
                        p1.company_name.localeCompare(p2.company_name)
                    )
                );
            } catch (error) {
                handleError(error);
            } finally {
                setLoadingProviders(false);
            }
        };

        loadProfessions();
        loadLocations();
        loadProviders();

        if (isOrderRequest() && order.requests.length > 0) {
            activateOrderRequest(order.current - 1);
        }
    }, [handleError, loadLocations, locale, t]);

    useEffect(() => {
        if (formData.profession_id && providers.length > 0) {
            filterProvidersByProfession(formData.profession_id);
        }
    }, [filterProvidersByProfession, formData.profession_id, providers.length]);

    useEffect(() => {
        form.setFieldValue('providers', selectedProviders);

        setFormData((prevState: FormRequest) => ({
            ...prevState,
            providers: selectedProviders,
        }));
    }, [selectedProviders]);

    useEffect(() => {
        const formDataDirty = JSON.stringify(formData) !== JSON.stringify(defaultFormData);
        const orderDirty = JSON.stringify(order) !== JSON.stringify(defaultOrder);

        if (!selectedProviders.length) {
            setSelectedProviders(formData.providers || []);
        }

        setFormDataIsDirty(formDataDirty);
        setFormIsDirty(formDataDirty || orderDirty);
    }, [formData, order]);

    useEffect(() => {
        form.setFieldsValue(formData);
    }, []);

    /**
     * On each form's values change, save the form values in the state.
     * It's a workaround to fix a production bug that doesn't hold the whole form state,
     * but just the state in the last visible step
     */
    const saveFormHandler = (changedFormData: FormRequest | null, currentFormData: FormRequest) => {
        setFormData((previousFormData: FormRequest) => ({
            ...previousFormData,
            ...currentFormData,
        }));

        setRecentAddresses((state: number[]) => addToRecent(formData.address_id, state, 3));
        setRecentDepartments((state: number[]) => addToRecent(formData.department_id, state, 3));
        setRecentProfessions((state: number[]) => addToRecent(formData.profession_id, state, 5));

        setRememberLocation(formData.remember_location);
        if (formData.remember_location) {
            setDefaultAddress(formData.address_id);
        }
    };

    const closeLocationForms = () => {
        setIsAddressFormOpen(false);
        setIsDepartmentFormOpen(false);
    };

    const onDepartmentFormSaved = async (department?: Department) => {
        await loadLocations();
        closeLocationForms();

        if (department) {
            const newData: GenericObject = {
                department_id: department?.id,
            };

            form.setFieldValue('department_id', department?.id);

            const currentAddressId = form.getFieldValue('address_id');
            const deleteCurrentAddress =
                currentAddressId &&
                !(addresses.find(({ id }) => id === Number(currentStep))?.department_ids || []).includes(
                    department?.id
                );

            if (deleteCurrentAddress) {
                form.setFieldValue('address_id', null);
                newData.address_id = null;
            }

            setFormData((prevState: FormRequest) => ({
                ...prevState,
                ...newData,
            }));
        }
    };

    const onAddressFormSaved = async (address?: Address) => {
        await loadLocations();
        closeLocationForms();

        if (address) {
            const sameDepartment = address.departments?.includes(formData.department_id);

            const departmentId = sameDepartment ? formData.department_id : address?.departments?.[0] ?? null;

            form.setFieldValue('department_id', departmentId ?? null);
            form.setFieldValue('address_id', address?.id);

            setFormData((prevState: FormRequest) => ({
                ...prevState,
                address_id: address?.id,
                department_id: departmentId ?? null,
            }));
        }
    };

    const onOpenAddressForm = () => {
        closeLocationForms();
        setIsAddressFormOpen(true);
    };

    const onOpenDepartmentForm = () => {
        closeLocationForms();
        setIsDepartmentFormOpen(true);
    };

    /**
     *  When selectedDates or differentTimes change, update the job list
     */
    const updateJobList = useCallback(
        (dates: Date[], sameOrDifferentTimes?: number, selectingDates = false) => {
            const formDates = updateJobListing(form, dates, sameOrDifferentTimes, selectingDates);

            setFormData((prevState: OrderFormRequest) => ({
                ...prevState,
                dates: formDates,
            }));
        },
        [form, TimesValue.SAME, TimesValue.DIFFERENT]
    );

    const updateOrder = (orderParts: GenericObject) => setOrder((prevState: Order) => ({
        ...prevState,
        ...orderParts,
    }));

    const steps = [
        {
            title: t('Staff and shift details'),
            content: (
                <Row>
                    <Col xs={24} xxl={11}>
                        <Space size="large">
                            <div className="subsection-subtitle">{t('Request type')}</div>

                            <Radio.Group
                                disabled={isOrderRequest() && (order.requests.length > 1 || order.current > 1)}
                                value={requestType}
                                onChange={(e) => setRequestType(e.target.value)}
                            >
                                <Radio.Button value={REQUEST_ORDER}>{t('Order')}</Radio.Button>
                                <Radio.Button value={REQUEST_SINGLE}>{t('Single request')}</Radio.Button>
                            </Radio.Group>
                        </Space>

                        {isOrderRequest() && <RequestCreationOrder order={order} updateOrder={updateOrder} />}

                        <RequestCreationProfession
                            professions={professions}
                            recentProfessions={recentProfessions}
                            loadingProfessions={loadingProfessions}
                            handleChange={filterProvidersByProfession}
                        />

                        <RequestCreationLocation
                            onCreateAddressHandler={onOpenAddressForm}
                            onCreateDepartmentHandler={onOpenDepartmentForm}
                            departments={departments}
                            addresses={addresses}
                            recentDepartments={recentDepartments}
                            recentAddresses={recentAddresses}
                            loadingLocations={loadingLocations}
                            selectedAddressId={formData.address_id}
                            isOrder={isOrderRequest()}
                            defaultAddress={defaultAddress}
                            setDepartmentShiftIds={setDepartmentShiftIds}
                            setDefaultAddress={setDefaultAddress}
                            form={form}
                        />

                        {(formData.profession_id || order.name) && (
                            <RequestCreationDateTime
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                                differentTimes={differentTimes}
                                form={form}
                                saveFormHandler={saveFormHandler}
                                updateJobList={updateJobList}
                            />
                        )}
                    </Col>
                    <Col xs={12} xxl={13}>
                        <RequestCreationTimesContainer
                            departmentShiftIds={departmentShiftIds}
                            differentTimes={differentTimes}
                            form={form}
                            hideDays={differentTimes === 0}
                            selectedDates={selectedDates}
                            setDifferentTimes={setDifferentTimes}
                            saveFormHandler={saveFormHandler}
                            updateJobList={updateJobList}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: t('Provider details'),
            content: (
                <Row>
                    <Col xs={24} sm={{ span: 11 }}>
                        <RequestCreationProviders
                            enabledProviders={enabledProviders}
                            disabledProviders={disabledProviders}
                            loadingProviders={loadingProviders}
                            setSelectedProviders={setSelectedProviders}
                            selectedProviders={selectedProviders}
                        />

                        <RequestAcceptanceType form={form} formData={formData} />

                        <RequestCreationNotes />

                        <RequestDocuments
                            formData={formData as OrderFormRequest}
                            saveAsDraft={(goBack: boolean, clearRequest: boolean) => saveAsDraft(goBack, clearRequest)}
                            setFormData={setFormData}
                        />
                    </Col>
                    <Col xs={24} sm={{ span: 12, offset: 1 }}>
                        <RequestCreationInfoSummary
                            formRequests={[formData as OrderFormRequest]}
                            professions={[profession]}
                            providers={enabledProviders}
                            setFormRequest={(formRequest) => setFormData((prevState: any) => ({
                                ...prevState,
                                ...formRequest,
                            }))}
                        />
                    </Col>
                </Row>
            ),
        },
    ];

    const goToOrderRequest = async (index: number) => {
        await setRequestIntoOrder(order, false);
        await clearRequest();
        await activateOrderRequest(index);

        setCurrentStep(0);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const nextStep = async () => {
        try {
            await form.validateFields();

            if (isOrderRequest() && isLastStep()) {
                if (await setRequestIntoOrder(order)) {
                    setCurrentStep((prevStep) => prevStep - 1);
                    await activateOrderRequest(order.current);
                }

                return;
            }

            setCurrentStep((prevStep) => prevStep + 1);
        } catch {
            //
        }
    };

    const clearRequest = async () => {
        form.resetFields();
        deleteFormData();

        setCurrentStep(0);
        setSelectedProviders([]);
        setDifferentTimes(0);
        setSelectedDates([]);
    };

    const handleClearRequest = async (clearOrder = false, deleteDraft = true, goBack = true) => {
        if (clearOrder && deleteDraft && order.id) {
            try {
                await Backend.delete(`/staffing/orders/${order.id}`);
            } catch {
                // do nothing
            }
        }

        await clearRequest();

        if (clearOrder) {
            deleteOrder();
            deleteRememberLocation();
            deleteDefaultAddress();
        } else {
            updateOrder({
                ...defaultOrder,
                id: order.id,
                name: order.name,
                external_id: order.external_id,
                current: order.current,
            });

            form.setFieldValue('order_name', order.name);
            form.setFieldValue('external_id', order.external_id);
        }

        if (!isOrderRequest() || clearOrder) {
            if (!goBack) {
                return;
            }

            navigate(-1);
        } else {
            const orderRequests = [...order.requests];

            orderRequests.splice(order.current - 1, 1);

            updateOrder({
                allStored: true,
                requests: orderRequests,
            });

            if (order.current === 1) {
                setCurrentStep(0);
            } else {
                await activateOrderRequest(order.current === 1 ? 0 : order.current - 2);
                setCurrentStep(steps.length - 1);
            }

            notification.success({
                message: t('Request cleared'),
            });
        }
    };

    const requestDataIsValid = (data: FormRequest) =>
        !!data.providers.length &&
        (Number(data.type) === 0 || Number(data.type) === 1) &&
        !!data.profession_id &&
        !!data.address_id &&
        !!data.acceptance_type &&
        !!data.dates?.length &&
        !data.dates.some(({ date, times }) => {
            return (
                !date ||
                !times.length ||
                times.some(({ workersNumber, shiftTimes }) => {
                    return !workersNumber || shiftTimes.times.length < 2;
                })
            );
        });

    const orderRequestsReady = (order: Order) => !order.requests.some(
        (request: OrderFormRequest) => !requestDataIsValid(request) || request.jobs.some(
            (job) => !isJobDateValid(job as FormattedJob)
        )
    );

    const processBadRequestIndices = async (indices: number[]) => {
        if (indices.length < 0) {
            return;
        }

        if (isOrderRequest()) {
            await activateOrderRequest(indices[0]);
        }

        const validateStep = (step: number) => {
            setCurrentStep(step);

            setTimeout(async () => {
                try {
                    await form.validateFields();

                    if (step !== 0) {
                        validateStep(--step);
                    }
                } catch {
                    // do nothing
                }
            }, 300);
        };

        validateStep(steps.length - 1);
    }

    const showOutdatedOrderRequest = async (order: Order) => {
        const indices: number[] = [];

        order.requests.forEach((request: OrderFormRequest, index: number) => {
            if (!request.jobs.some((job) => !isJobDateValid(job as FormattedJob))) {
                return;
            }

            indices.push(index);
        });

        await processBadRequestIndices(indices);

        return indices;
    };

    const showIncompleteOrderRequest = async (order: Order) => {
        const indices: number[] = [];

        order.requests.forEach((request: OrderFormRequest, index: number) => {
            if (requestDataIsValid(request)) {
                return;
            }

            indices.push(index);
        });

        await processBadRequestIndices(indices);

        return indices;
    };

    const handleSendRequest = async (draftObject: GenericObject | null = null, showNotification = true) => {
        const sendAsDraft = !!draftObject;
        let objectToSave: GenericObject = {};

        try {
            if (isOrderRequest()) {
                if (!draftObject) {
                    draftObject = (await setRequestIntoOrder(order)) as Order;
                }

                objectToSave = {
                    ...draftObject,
                    auto_created: false,
                };
            }
            else {
                objectToSave = {
                    auto_created: true,
                    id: order.id,
                    name: profession!.name,
                    requests: [draftObject || formData],
                };
            }

            if (!orderRequestsReady(objectToSave as Order) && !sendAsDraft) {
                const incompleteIndices = (await showIncompleteOrderRequest(objectToSave as Order)).map((number) => number + 1);

                if (incompleteIndices.length) {
                    notification.error({
                        message: t(
                            isOrderRequest() ?
                                'Request #%{number} has incomplete fields||||Requests #%{numbers} have incomplete fields' :
                                'Request has incomplete fields',
                            {
                                number: incompleteIndices[0],
                                numbers: incompleteIndices.join(', #'),
                                smart_count: incompleteIndices.length,
                            }
                        ),
                    });

                    return false;
                }

                const outdatedIndices = (await showOutdatedOrderRequest(objectToSave as Order)).map((number) => number + 1);

                notification.error({
                    message: t(
                        isOrderRequest() ?
                            'Request #%{number} has outdated shifts||||Request #%{numbers} have outdated shifts' :
                            'Request has outdated shifts',
                        {
                            number: outdatedIndices[0],
                            numbers: outdatedIndices.join(', #'),
                            smart_count: outdatedIndices.length,
                        }
                    ),
                });

                return false;
            }

            const method = objectToSave.id ? 'put' : 'post';
            const url = objectToSave.id ? `/staffing/orders/${objectToSave.id}` : '/staffing/orders';

            setSendingRequest(true);

            const response = await Backend[method](url, {
                auto_created: objectToSave.auto_created,
                is_draft: sendAsDraft,
                name: objectToSave.name,
                external_id: objectToSave.external_id,
                requests: objectToSave.requests.map((request: OrderFormRequest) => {
                    let requestDifferentTimes: number;

                    if (typeof request.differentTimes === 'undefined') {
                        requestDifferentTimes = 0;
                    } else {
                        requestDifferentTimes = request.differentTimes as unknown as number;
                    }

                    return prepareRequestData(request, requestDifferentTimes, request.selectedDates as Date[]);
                }),
            });

            setFormIsDirty(false);
            setSendingRequest(false);

            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                if (sendAsDraft) {
                    if (showNotification) {
                        notification.success({
                            message: t(isOrderRequest() ? 'Order saved as draft' : 'Request saved as draft'),
                        });
                    }

                    const orderRequests = response.data.requests.reverse().map(requestToFormRequest);
                    const newOrder = {
                        ...order,
                        ...objectToSave,
                        allStored: true,
                        id: response.data.id,
                        requests: orderRequests,
                    } as Order;

                    updateOrder(newOrder);
                    await activateOrderRequest(order.current - 1, orderRequests);

                    return newOrder;
                } else {
                    if (showNotification) {
                        notification.success({
                            message: t(
                                isOrderRequest() ? 'Order requests successfully sent' : 'Request successfully sent'
                            ),
                        });
                    }

                    await handleClearRequest(true, false);

                    return true;
                }
            }
        } catch (error) {
            if (showNotification) {
                handleError(error, form);
            }

            setSendingRequest(false);

            return false;
        }
    };

    const saveAsDraft = async (goBack = true, clearRequest = true) => {
        let data: OrderFormRequest | GenericObject | Order | null = formData as OrderFormRequest;

        if (isOrderRequest()) {
            data = (await setRequestIntoOrder(order as Order, false)) as OrderFormRequest | GenericObject | Order;

            if (!(data as Order)?.requests?.length) {
                if (goBack) {
                    notification.error({
                        message: t('Order does not have any requests yet'),
                    });
                }

                return;
            }
        } else if (!data.profession_id) {
            return;
        }

        const newOrder = await handleSendRequest(data, clearRequest);

        if (!newOrder || !clearRequest) {
            return newOrder;
        }

        await handleClearRequest(true, false, goBack);
    };

    const formDataIsEmpty = () => !formData.profession_id && !formData.address_id && !formData.department_id;

    const activateOrderRequest = async (index: number, requests: OrderFormRequest[] = []) => {
        let request = requests.length ? requests[index] : order.requests[index];

        updateOrder({ current: index + 1 });
        deleteFormData();
        form.resetFields();
        form.setFieldValue('order_name', order.name);
        form.setFieldValue('external_id', order.external_id);

        if (request) {
            request = {
                ...request,
                order_name: order.name || request.order_name,
            };

            setSelectedProviders(request.providers);
            setDifferentTimes(isDifferentTimes(request));
            setSelectedDates(request.selectedDates || []);

            form.setFieldsValue(request);
            setFormData((prevState: FormRequest) => ({
                ...prevState,
                ...request,
            }));
        }

        return index;
    };

    const goBack = async () => {
        if (currentStep > 0) {
            prevStep();

            return;
        }

        if (isOrderRequest() && order.current > 1) {
            await setRequestIntoOrder(order, false);
            await clearRequest();
            await activateOrderRequest(order.current - 2);

            setCurrentStep(steps.length - 1);

            return;
        }

        navigate(-1);
    };

    const setRequestIntoOrder = async (order: Order, validate = true) => {
        if (!isOrderRequest() || !formDataIsDirty) {
            order.allStored = true;

            setOrder(order);

            return order;
        }

        try {
            await form.validateFields();
        } catch {
            if (validate) {
                return null;
            }
        }

        const requests = [...order.requests];

        if (order.current > order.requests.length) {
            requests.push({ ...formData, type: REQUEST_TYPE_SEQUENTIAL } as OrderFormRequest);
        } else if (!_.isEqual(formData, defaultFormData)) {
            requests.splice(order.current - 1, 1, { ...formData, type: REQUEST_TYPE_SEQUENTIAL } as OrderFormRequest);
        }

        const newOrder = {
            ...order,
            allStored: true,
            requests: requests.filter(({ profession_id }) => !!profession_id),
        };

        setOrder(newOrder);

        return newOrder;
    };

    const addNewRequest = async (order: Order) => {
        const newOrder = await setRequestIntoOrder(order);

        if (!newOrder) {
            return;
        }

        updateOrder({
            allStored: false,
            current: newOrder.requests.length + 1,
        });
        await clearRequest();

        if (rememberLocation && order.requests.length > 0) {
            if (defaultAddress) {
                form.setFieldValue('address_id', defaultAddress);
            } else {
                let defaultSelectedAddressId = order.requests[0].address_id;

                for (let i = 0; i < order.requests.length; i++) {
                    if (order.requests[i].address_id) {
                        defaultSelectedAddressId = order.requests[i].address_id;
                    }
                }

                setRememberLocation(true);
                setDefaultAddress(defaultSelectedAddressId);
                form.setFieldValue('address_id', defaultSelectedAddressId);
            }
        }

        form.setFieldValue('order_name', newOrder.name);
        form.setFieldValue('external_id', newOrder.external_id);
    };

    const isLastStep = () => currentStep === steps.length - 1;

    const isLastOrderRequest = () =>
        (order.allStored && order.current === order.requests.length) ||
        (!order.allStored && order.current - 1 === order.requests.length);

    const title = (
        <Space>
            <span className="title">
                {t('Create Request')}

                {isOrderRequest() && ` #${order.current}`}
            </span>
        </Space>
    );

    const actionConfirm = (title: string, options: GenericObject = {}) =>
        Modal.confirm({
            title: t(title),
            icon: null,
            content: (
                <>
                    <p>{t('Are you sure you want to cancel this request?')}</p>
                    <p>{t('All your progress will be deleted and the action cannot be reversed once done.')}</p>
                </>
            ),
            okText: t(title),
            cancelText: t('Dismiss'),
            centered: true,
            onOk: () => handleClearRequest(),
            ...options,
        });

    const actions: GenericObject = {
        cancelSingleRequest: {
            icon: <FileIcon color="red" />,
            key: 'cancel',
            label: t('Cancel request'),
            onClick: () => actionConfirm('Cancel request'),
        },
        cancelOrder: {
            icon: <FolderOutlined style={{ color: 'red' }} />,
            key: 'cancel',
            label: t('Cancel order'),
            onClick: () =>
                actionConfirm('Cancel order', {
                    content: (
                        <>
                            <p>{t('Are you sure you want to cancel this order?')}</p>

                            <p>
                                {t(
                                    'All the contained requests would be deleted and this action cannot be reversed once done.'
                                )}
                            </p>
                        </>
                    ),
                    onOk: () => handleClearRequest(true),
                }),
        },
        clearOrderRequest: {
            icon: <FileIcon color="red" width={10} height={10} />,
            key: 'cancel-current-request',
            label: t('Cancel current request'),
            onClick: () =>
                actionConfirm('Cancel request', {
                    content: (
                        <>
                            <p>{t('Are you sure you want to cancel this request?')}</p>

                            <p>
                                {t(
                                    'The information added will be deleted and this action cannot be reversed once done.'
                                )}
                            </p>
                        </>
                    ),
                }),
        },
        clearRequest: {
            icon: <SyncOutlined />,
            key: 'clear-request',
            label: t('Clear request'),
            onClick: () =>
                actionConfirm(
                    isOrderRequest()
                        ? 'Are you sure you want to clear this request?'
                        : 'Are you sure you want to clear the request?',
                    {
                        cancelText: t('No'),
                        content: null,
                        okText: t('Yes'),
                        onOk: async () => {
                            if (isOrderRequest()) {
                                await clearRequest();
                                form.setFieldValue('order_name', order.name);
                                form.setFieldValue('external_id', order.external_id);

                                return;
                            }

                            await handleClearRequest(false, false, false);
                        },
                    }
                ),
        },
        saveDraft: {
            icon: <SaveOutlined />,
            key: 'save-draft',
            label: t('Save draft'),
            onClick: () => saveAsDraft(),
        },
    };

    const continueButtonItems: ItemType[] = [
        actions.saveDraft,
        ...(!formDataIsEmpty() ? [actions.clearRequest] : []),
        ...(!isOrderRequest() ? [actions.cancelSingleRequest] : [actions.clearOrderRequest, actions.cancelOrder]),
    ];

    return (
        <div id="request-creation">
            <Prompt
                when={formIsDirty}
                message={() => {
                    saveAsDraft(false);

                    return true;
                }}
            />

            <Row style={{ marginBottom: '32px' }}>
                <Col span={10}>
                    <Space size="middle">
                        {order.requests.length > 2 && (
                            <Button
                                disabled={order.current === 1}
                                icon={<VerticalRightOutlined />}
                                onClick={() => goToOrderRequest(0)}
                                style={{ width: 'unset', color: 'unset' }}
                                type="link"
                            />
                        )}

                        <Button
                            icon={<ArrowLeftOutlined />}
                            onClick={goBack}
                            style={{ width: 'unset', color: 'unset' }}
                            type="link"
                        />

                        {title}

                        {isOrderRequest() && (
                            <>
                                <Button
                                    disabled={isLastStep() && isLastOrderRequest()}
                                    icon={<ArrowRightOutlined />}
                                    onClick={nextStep}
                                    style={{ width: 'unset', color: isLastStep() && isLastOrderRequest() ? '' : 'unset' }}
                                    type="link"
                                />

                                { order.requests.length > 2 && (
                                    <Button
                                        disabled={isLastOrderRequest()}
                                        icon={<VerticalLeftOutlined />}
                                        onClick={() => goToOrderRequest(order.requests.length - 1)}
                                        style={{ width: 'unset', color: 'unset' }}
                                        type="link"
                                    />
                                )}
                            </>
                        )}
                    </Space>
                </Col>

                <Col span={10}>
                    <Steps current={currentStep} className="lateral-padding" items={steps} />
                </Col>

                {isOrderRequest() && order.requests.length > 0 && (
                    <Col span={4} className="text-right">
                        <Button
                            type="primary"
                            onClick={async () => {
                                if (!(await setRequestIntoOrder(order, false))) {
                                    return;
                                }

                                setShowOverview(true);
                            }}
                        >
                            <Space>
                                {t('Order overview')}

                                <Badge color="white" count={order.requests.length} style={{ color: '#1677ff' }} />
                            </Space>
                        </Button>
                    </Col>
                )}
            </Row>

            <Divider className="create-request-header-divider" />

            <Form
                form={form}
                name="control-hooks"
                onFinish={() => handleSendRequest()}
                onValuesChange={saveFormHandler}
                className="lateral-padding"
            >
                <div className="steps-content">{steps[currentStep].content}</div>

                {(formData.profession_id || order.name) && isOrganizationalDataComplete && (
                    <>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={9} style={{ maxWidth: '630px' }}>
                                <Space>
                                    {(!isLastStep() || !isLastOrderRequest()) &&
                                        (order.name || formData.profession_id) && (
                                            <Dropdown.Button
                                                type="primary"
                                                className="form-buttons"
                                                placement="top"
                                                menu={{ items: continueButtonItems }}
                                                onClick={nextStep}
                                            >
                                                {t('Continue')}
                                            </Dropdown.Button>
                                        )}

                                    {isOrderRequest() && isLastStep() && (
                                        <Button
                                            className="form-buttons"
                                            icon={<PlusOutlined />}
                                            onClick={() => addNewRequest(order)}
                                        >
                                            {t('Add new request')}
                                        </Button>
                                    )}

                                    {isLastStep() && isLastOrderRequest() && (
                                        <Dropdown.Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loadingOrder || sendingRequest}
                                            className="form-buttons no-left-padding"
                                            placement="top"
                                            menu={{ items: continueButtonItems }}
                                        >
                                            <SendOutlined /> {t(isOrderRequest() ? 'Send order' : 'Send request')}
                                        </Dropdown.Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>

            <RequestCreationLocationFormsDrawer
                departments={departments}
                onSaveAddressHandler={onAddressFormSaved}
                onSaveDepartmentHandler={onDepartmentFormSaved}
                onFormClose={closeLocationForms}
                isAddressFormOpen={isAddressFormOpen}
                isDepartmentFormOpen={isDepartmentFormOpen}
            />

            <OverviewModal
                activateRequest={(index: number) => {
                    setShowOverview(false);
                    activateOrderRequest(index);
                }}
                closeHandler={() => setShowOverview(false)}
                open={showOverview}
                order={order as Order}
                professions={professions}
            />
        </div>
    );
};
