import './styles.scss';

import { Button, Space } from 'antd';
import { ArchiveIcon } from 'shared/icons/ArchiveIcon';
import { useLocalization } from 'lib/Localization';
import { FileUploader } from 'shared/FileUploader';
import { useEffect, useState } from 'react';
import type { RcFile } from 'antd/lib/upload/interface';
import { DeleteTwoTone, LoadingOutlined } from '@ant-design/icons';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { AxiosResponse } from 'axios';
import { RequestDocument } from 'types/staffing/RequestDocument';
import { GenericObject } from 'shared/Contracts';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';

type Props = {
    formData: OrderFormRequest,
    saveAsDraft: (goBack: boolean, clearRequest: boolean) => Promise<any>,
    setFormData: (formData: OrderFormRequest) => void;
};

export const RequestDocuments = ({ formData, saveAsDraft, setFormData }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState<GenericObject>({});
    const [showUploader, setShowUploader] = useState(false);
    const [documents, setDocuments] = useState<RequestDocument[]>([]);
    const [requestDocumentMeta, setRequestDocumentMeta] = useLocalStorageState('requestDocumentMeta', {});

    type DocumentMeta = {
        accept: string,
        max_file_size: string,
    };

    const upload = async (fileList: RcFile[]) => {
        if (!fileList.length) {
            return false;
        }

        setIsLoading(true);

        let request = formData;

        if (!formData.id) {
            try {
                const newOrder = await saveAsDraft(false, false);

                request = newOrder.requests[newOrder.current - 1];
            } catch{
                setIsLoading(false);

                return false;
            }
        }

        const fd = new FormData();

        fd.set('file', fileList[0] as Blob);

        try {
            const response: AxiosResponse<RequestDocument> = await Backend.post(
                `/staffing/requests/${request.id}/document`,
                fd,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            const newDocs = [...documents, response.data];

            setDocuments(newDocs);
            setFormData({
                ...request,
                documents: newDocs,
            });

            setIsLoading(false);
            setShowUploader(false);

            return true;
        } catch (e) {
            setIsLoading(false);
            handleError(e);

            return false;
        }
    };

    const remove = async (document: RequestDocument) => {
        try {
            setIsDeleting({
                ...isDeleting,
                [document.id]: true,
            });

            await Backend.delete(
                `/staffing/requests/documents/${document.id}`,
            );

            const localDocs = [...documents];
            const index = localDocs.findIndex(({ id }) => document.id === id);

            if (index > -1) {
                localDocs.splice(index, 1);
            }

            setDocuments(localDocs);
            updateFormDataDocs(localDocs);
        } catch (e) {
            setIsLoading(false);
            handleError(e);
        }

        setIsDeleting({
            ...isDeleting,
            [document.id]: false,
        });
    };

    const updateFormDataDocs = (documents: RequestDocument[]) => setFormData({
        ...formData,
        documents,
    });

    useEffect(() => {
        if (documents.length) {
            return;
        }

        setDocuments(formData.documents || []);
    }, [formData]);

    useEffect(() => {
        const getMeta = async () => {
            const { data } = await Backend.get<DocumentMeta>('/staffing/requests/documents/meta');

            setRequestDocumentMeta({
                ...data,
                accept: data.accept.split(',').map((val: string) => `.${val}`).join(','),
            });
        };

        if (!Object.keys(requestDocumentMeta).length) {
            getMeta();
        }
    }, []);

    return (
        <>
            <FileUploader
                accept={requestDocumentMeta.accept}
                isLoading={isLoading}
                maxFileSize={requestDocumentMeta.max_file_size}
                okButtonText={t('Upload')}
                open={showUploader}
                onCancel={() => setShowUploader(false)}
                onOk={upload}
                title={t('Upload document')}
            />

            <Button onClick={() => setShowUploader(true)}>
                <Space>
                    <ArchiveIcon /> {t('Upload document')}
                </Space>
            </Button>

            <ul className="request-documents-list">
                {documents.map((document, index) => (
                    <li key={`file-${index}`}>
                        {isDeleting[document.id] ? (
                            <>
                                <s>{document.name}</s>

                                <LoadingOutlined className="pull-right" />
                            </>
                        ) : (
                            <>
                                <a href={document.url} target="_blank">{document.name}</a>

                                <DeleteTwoTone twoToneColor="#FF4235" className="pull-right" onClick={() => remove(document)} />
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};
