/**
 * Usage:
 *
 * Say you have `const [state, setState] = useState();`, you can tap into the setState function
 * by changing `setState` to anything else like `setSState` and create a new setState function
 * like `const setState = tapState(setSState)`. This will make setState work perfectly like setSState
 * but log the new and previous states in the console.
 *
 * You can receive the previous and new states in a custom function which you provide as a second
 * param like `const setState = tapState(setSState, (newState, oldState) => { // do whatever });`.
 * Note: the new and previous states will no longer be logged to the console if you provide this function.
 *
 * @param mainStateFn
 * @param tapFn
 */
export const tapState = (mainStateFn: (s: any) => any, tapFn?: (newState: any, oldState: any) => void) => (stateOrFunction: any) => mainStateFn((prevState: any) => {
    const newState = typeof stateOrFunction !== 'function' ? stateOrFunction : stateOrFunction(prevState);

    if (tapFn) {
        tapFn(newState, prevState);
    } else {
        console.log('debugger', {
            previousState: cloneState(prevState),
            newState: cloneState(newState),
        });
    }

    return newState;
});

const cloneState = (state: any) => {
    if (!state) {
        return state;
    }

    if (Array.isArray(state)) {
        return [...state];
    }

    if (typeof state === 'object') {
        return { ...state };
    }

    return state;
}
