import { useErrorHandler } from 'lib/ErrorHandling';
import { useState } from 'react';
import { Button, Form, Input, notification, Typography } from 'antd';
import { Authentication } from 'lib/Account/Authentication';
import useQueryParameters from 'lib/Http/QueryParameters';
import { useLocalization } from 'lib/Localization';
import { LoginCredentials, ResetPassword as ResetPasswordType } from 'types';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { PasswordFormItem } from 'shared/AntDesignUtils/Password/PasswordFormItem';
import { AuthLayout } from 'pages/Authentication/AuthLayout';
import { Link, useNavigate } from 'react-router-dom-v5-compat';

export const ResetPassword = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const queryParameters = useQueryParameters();
    const navigate = useNavigate();

    const email = decodeURIComponent(queryParameters.get('email') || '');
    const token = decodeURIComponent(queryParameters.get('token') || '');

    const onForgotPassword = async (credentials: LoginCredentials) => {
        try {
            setLoading(true);
            const success = await Authentication.forgot(credentials);
            if (success) {
                notification.success({
                    message: t('Password reset successful! We have sent you an email with your password reset link.'),
                });
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setLoading(false);
        }
    };

    const onResetPassword = async (resetPasswordData: ResetPasswordType) => {
        try {
            const data = {
                token: token,
                email: email,
                password: resetPasswordData.password,
                password_confirmation: resetPasswordData.password_confirmation,
            };
            setLoading(true);
            const success = await Authentication.reset(data);
            if (success) {
                notification.success({
                    message: t('New Password saved! You can login with your new password.'),
                });
                navigate('/login');
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setLoading(false);
        }
    };

    const renderForgotPasswordForm = () => (
        <>
            <Typography.Title className="title">{t('Forgot Password')}</Typography.Title>
            <Form form={form} name="login" onFinish={onForgotPassword}>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: t('Please enter a valid email'),
                        },
                        {
                            required: true,
                            message: t('Email is required!'),
                        },
                    ]}
                >
                    <FloatingLabel label={t('Email')} required>
                        <Input />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block={true} size="large" loading={loading}>
                        {t('Reset password')}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Link to="/login">
                        <Button type="link" block={true} size="large" loading={false}>
                            {t('Back to login')}
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        </>
    );

    const renderResetPasswordForm = () => (
        <>
            <Typography.Title className="title">{t('Reset Password')}</Typography.Title>
            <Form form={form} name="reset" onFinish={onResetPassword} size="large">
                <Form.Item name="email">
                    <Input placeholder={email} disabled />
                </Form.Item>

                <PasswordFormItem
                    label={t('New password')}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('Please enter a new password!'),
                        },
                    ]}
                    required
                    showConditions
                />

                <PasswordFormItem
                    dependencies={['password']}
                    label={t('Confirm password')}
                    name="password_confirmation"
                    rules={[
                        {
                            required: true,
                            message: t('Please confirm your password!'),
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(t('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                    required
                />

                <Form.Item>
                    <Button type="primary" htmlType="submit" block={true} size="large" loading={loading}>
                        {t('Reset password')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );

    return (
        <AuthLayout cardClass="login-box">
            {token ? renderResetPasswordForm() : renderForgotPasswordForm()}
        </AuthLayout>
    );
};
