import { timePattern } from 'lib/Validation/constants';
import { t } from 'lib/Localization';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

export const getMustBeValidErrorMessage = (type) => t('Value is not a valid %{type}', { type });

export const parseDateTime = (date) =>
    date.toLowerCase() === 'now' ? dayjs() : dayjs(timePattern.test(date) ? `08-03-2023 ${date}` : date);

export const throwTypeError = (type, customErrorMessage) => {
    throw new Error(customErrorMessage || getMustBeValidErrorMessage(type));
};

export const valueIsPresent = (value) => value !== undefined && value !== null && value !== '';
