import { useEffect, useState } from 'react';

type Matcher = (selections: any[], all: any[]) => boolean;
type ReturnType = {
    addToAll: (value: any) => void;
    allSelected: boolean;
    selections: any[];
    selectAll: () => void;
    selectNone: () => void;
    setAll: (all: any[]) => void;
    toggleAll: () => void;
    toggleSelection: (value: any) => void;
};

export const useSelections = (
    matcher: Matcher = (selections: any[], all: any[]) => selections.length === all.length
): ReturnType => {
    const [all, setAll] = useState<any[]>([]);
    const [selections, setSelections] = useState<any[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const addToAll = (value: any) => setAll((prevState) => {
        const state = [...prevState];

        if (!state.includes(value)) {
            state.push(value);
        }

        return state;
    });
    const selectAll = () => setSelections(all);
    const selectNone = () => setSelections([]);
    const toggleAll = () => allSelected ? selectNone() : selectAll();
    const toggleSelection = (value: any) => {
        const localSelections = [...selections];
        const index = localSelections.indexOf(value);

        if (index > -1) {
            localSelections.splice(index, 1);
        } else {
            localSelections.push(value);
        }

        setSelections(localSelections);
    };

    useEffect(() => {
        setAllSelected(matcher(selections, all));
    }, [selections, all]);

    return {
        addToAll,
        allSelected,
        selections,
        selectAll,
        selectNone,
        setAll,
        toggleAll,
        toggleSelection
    };
};
