import { Button, Col, Layout, Row } from 'antd';
import { useLocalization } from 'lib/Localization';
import { ChangeLocaleHorizontalMenu } from 'shared/Localization/ChangeLocaleHorizontalMenu';
import simplifyIcon from 'assets/img/simplify.png';
import manageRequestIcon from 'assets/img/manage-request.png';
import staffRequestIcon from 'assets/img/staff-request.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import { OrganizationType } from 'types';
import 'pages/Onboarding/onboarding.scss';
import { Authentication, useAccount } from 'lib/Account';
import { useIsProfileSet } from 'lib/hooks';
import { Logo } from 'shared/logo';
import { Link, useNavigate } from 'react-router-dom-v5-compat';

type Props = {
    userType: OrganizationType;
};

export const Welcome = ({ userType }: Props) => {
    const { t } = useLocalization();
    const { accountUser } = useAccount();
    const isProfileSet = useIsProfileSet();
    const navigate = useNavigate();

    if (isProfileSet) {
        navigate(Authentication.determineUserHomePath(accountUser));
    }

    const renderWelcomeText = (userType: OrganizationType) => {
        if (userType === OrganizationType.PROVIDER) {
            return (
                <>
                    {t('Tempcloud is the place for all your temp staffing needs. See')}
                    &nbsp;
                    <span className="highlighted-text">{t('all staff requests, keep track of offers')}</span>
                    &nbsp;
                    {t('and')}
                    &nbsp;
                    <span className="highlighted-text">{t('enhance the relationship with your clients')}</span>.
                </>
            );
        }

        return (
            <>
                {t('Tempcloud is the place for all your temp staffing needs.')}
                &nbsp;
                <span className="highlighted-text">{t('Request staff, keep track of requests and offers')}</span>
                &nbsp;
                {t('and')}
                &nbsp;
                <span className="highlighted-text">{t('enhance your relationship with providers')}</span>.
            </>
        );
    };

    const renderStaffRequestText = (userType: OrganizationType) => {
        if (userType === OrganizationType.PROVIDER) {
            return (
                <>
                    <span className="highlighted-text">{t('See staff requests')}</span>
                    &nbsp;
                    {t('from all your clients')}
                    &nbsp;
                    <span className="highlighted-text">{t('in one place')}</span>
                </>
            );
        }

        return (
            <>
                <span className="highlighted-text">{t('Request staff')}</span>
                &nbsp;
                {t('from all your providers')}
                &nbsp;
                <span className="highlighted-text">{t('at once')}</span>
            </>
        );
    };

    const renderManageRequestText = (userType: OrganizationType) => {
        if (userType === OrganizationType.PROVIDER) {
            return (
                <>
                    <span className="highlighted-text">{t('Manage requests')}</span>
                    &nbsp;
                    {t('and')}
                    &nbsp;
                    <span className="highlighted-text">{t('keep track of them')}</span>
                </>
            );
        }

        return (
            <>
                <span className="highlighted-text">{t('Manage offers')}</span>
                &nbsp;
                {t('and')}
                &nbsp;
                <span className="highlighted-text">{t('keep track of all requests')}</span>
            </>
        );
    };

    const renderSimplifyText = (userType: OrganizationType) => {
        if (userType === OrganizationType.PROVIDER) {
            return (
                <>
                    <span className="highlighted-text">{t('Simplify')}</span>
                    &nbsp;
                    {t('and')}
                    &nbsp;
                    <span className="highlighted-text">{t('streamline')}</span>
                    &nbsp;
                    {t('your collaborations')}
                </>
            );
        }

        return (
            <>
                <span className="highlighted-text">{t('Simplify')}</span>
                &nbsp;
                {t('and')}
                &nbsp;
                <span className="highlighted-text">{t('streamline')}</span>
                &nbsp;
                {t('your collaborations')}
            </>
        );
    };

    return (
        <Layout className="welcome-background">
            <div className="welcome-logo">
                <Logo />
            </div>
            <ChangeLocaleHorizontalMenu />
            <Row>
                <Col span={18} offset={3}>
                    <h1 className="welcome-title">{t('Welcome!')}</h1>
                    <div className="welcome-text">{renderWelcomeText(userType)}</div>
                </Col>
            </Row>
            <Row gutter={[40, 40]} className="margin-top-100">
                <Col
                    xs={{ span: 20, offset: 1 }}
                    sm={{ span: 8, offset: 0 }}
                    md={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                    xxl={{ span: 8, offset: 0 }}
                >
                    <div className="icon">
                        <img alt="" src={staffRequestIcon} />
                    </div>
                    <div className="welcome-text">{renderStaffRequestText(userType)}</div>
                </Col>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 8, offset: 0 }}
                    md={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                    xxl={{ span: 8, offset: 0 }}
                >
                    <div className="icon">
                        <img alt="" src={manageRequestIcon} />
                    </div>
                    <div className="welcome-text">{renderManageRequestText(userType)}</div>
                </Col>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 8, offset: 0 }}
                    md={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                    xxl={{ span: 8, offset: 0 }}
                >
                    <div className="icon">
                        <img alt="" src={simplifyIcon} />
                    </div>
                    <div className="welcome-text">{renderSimplifyText(userType)}</div>
                </Col>
            </Row>
            <Row className="margin-top-100">
                <Col>
                    <Link to={`/onboarding`}>
                        <Button type="primary" className="get-started-text">
                            {t('Get started')} {<ArrowRightOutlined />}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Layout>
    );
};
