import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { useAccount } from 'lib/Account';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Department, WorkShift } from 'types/models';
import { AccountUser } from 'types';
import { FormInstance } from 'antd/lib/form';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

const { formLayout } = SingleColumnFormLayout;

type Props = {
    department?: Department;
    shifts?: WorkShift[];
    saveDepartmentHandler: (department: Department, form: FormInstance) => Promise<boolean>;
    isDataSaving: boolean;
};

export const DepartmentForm = ({ department, shifts, saveDepartmentHandler, isDataSaving }: Props) => {
    const { t } = useLocalization();
    const [form] = Form.useForm();
    const { accountUser: user } = useAccount();

    useEffect(() => {
        const accountUser = user as AccountUser;
        form.resetFields();
        form.setFieldsValue({
            ...department,
            client_id: accountUser.profiles.contact?.client_id,
            shifts: department?.work_shifts?.map(({ id }: WorkShift) => id),
        });
    }, [form, department, user]);

    const onFinish = async (values: Department) => {
        const accountUser = user as AccountUser;

        if (!(await saveDepartmentHandler(values, form))) {
            return;
        }

        form.resetFields();
        form.setFieldsValue({
            client_id: accountUser.profiles.contact?.client_id,
        });
    };

    const shiftOptions = shifts?.map((shift: WorkShift) => ({
        value: shift.id,
        label: shift.name,
    }));

    return (
        <Form form={form} onFinish={onFinish} {...formLayout}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="client_id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter Department name')
                    },
                    {
                        max: 150,
                        message: t('The name may not be greater than 150 characters')
                    }
                ]}
            >
                <FloatingLabel label={t('Name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="description"
                rules={[
                    {
                        max: 500,
                        message: t('The description may not be greater than 500 characters'),
                    }
                ]}
            >
                <FloatingLabel label={t('Description')}>
                    <Input.TextArea rows={6} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="shifts">
                <FloatingLabel label={t('Shifts')}>
                    <Select
                        mode="multiple"
                        placeholder={t('Related shifts')}
                        options={shiftOptions}
                        data-testid="shifts"
                        optionFilterProp="label"
                        allowClear
                    />
                </FloatingLabel>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};
