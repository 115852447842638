import { ActionProgress } from 'shared/Requests/ActionProgress';
import { Col, Row, Tag, Tooltip } from 'antd';
import { sumRequestValues } from 'shared/Requests/RequestsTable/RequestsTable';
import { Order, Request } from 'types/models';
import { useLocalization } from 'lib/Localization';
import { useAccount } from 'lib/Account';
import { userIsProvider } from 'lib/Helpers/UserHelper';
import { JobState } from 'types/staffing/JobStateMachine';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ACCEPTANCE_TYPE } from 'pages/Client/Requests/Create/RequestAcceptanceType';

type Props = {
    order: Order;
    request: Request | null;
    showDescription?: boolean;
};

const getFilledPositionsCount = (request: Request) => {
    const currentProviderFilledPositionsCount = request.jobs.filter(
        ({ applicants, state }) =>
            state === JobState.OPEN &&
            (applicants || []).some(({ state }) =>
                [JobApplicantState.OPEN, JobApplicantState.PROPOSED].includes(state)
            )
    ).length;

    // @ts-ignore
    const otherProvidersFilledPositionsCount = request.jobs.reduce((total, job) => {
        if (job.state !== JobState.OPEN) {
            return total;
        }

        return total + (job.other_provider_applicants ?? 0);
    }, 0);

    return currentProviderFilledPositionsCount + otherProvidersFilledPositionsCount;
};

const getConfirmedPositionsCount = (request: Request) =>
    request.jobs.filter(({ state }) => state === JobState.FILLED).length;

export const RequestProgress = ({ order, request, showDescription }: Props) => {
    const { t } = useLocalization();
    const { accountUser: user } = useAccount();
    const isProvider = userIsProvider(user);

    const confirmedPositionsPercent = request
        ? request.confirmedPositionsPercent ?? 0
        : sumRequestValues(order, (request) => request.confirmedPositionsPercent ?? 0) / order.requests.length;

    const filledPositionsPercent = request
        ? request.filledPositionsPercent ?? 0
        : sumRequestValues(order, (request) => request.filledPositionsPercent ?? 0) / order.requests.length;

    const allJobsCount = request
        ? request.jobs.length - request.jobs.filter(({ state }) => state === JobState.CANCELED).length
        : sumRequestValues(
              order,
              (request) => request.jobs.length - request.jobs.filter(({ state }) => state === JobState.CANCELED).length
          );

    const filledJobsCount = request
        ? getFilledPositionsCount(request)
        : sumRequestValues(order, (request) => getFilledPositionsCount(request));

    const confirmedJobsCount = request
        ? getConfirmedPositionsCount(request)
        : sumRequestValues(order, (request) => getConfirmedPositionsCount(request));

    const openJobsCount = allJobsCount - filledJobsCount - confirmedJobsCount;

    const title = !showDescription && (
        <div className="request-progress-title">
            <div className="open">
                <span>{openJobsCount}</span>
                {t('Open')}
            </div>
            <div className="filled">
                <span>{filledJobsCount}</span>
                {t('Filled')}
            </div>
            <div className="confirmed">
                <span>{confirmedJobsCount}</span>
                {t('Confirmed')}
            </div>
        </div>
    );

    return (
        <Tooltip title={title}>
            <Row gutter={10} className="request-progress text-center">
                <Col flex="none">
                    <span>
                        <strong>{filledJobsCount + confirmedJobsCount}</strong>/
                        <span style={{ fontWeight: 'normal' }}>{allJobsCount}</span>
                    </span>
                </Col>

                <Col flex="auto">
                    <ActionProgress
                        confirmedPositionsPercent={confirmedPositionsPercent}
                        filledPositionsPercent={filledPositionsPercent}
                    />
                </Col>

                {!showDescription && !isProvider && (
                    <Col flex="none">
                        <InfoCircleOutlined />
                    </Col>
                )}

                {showDescription && (
                    <Col span={24}>
                        <Tag color="green">
                            <span style={{ marginRight: '5px' }}>{confirmedJobsCount}</span>

                            {t('Confirmed')}
                        </Tag>
                        {request?.acceptance_type === ACCEPTANCE_TYPE.manual && (
                            <Tag color="blue">
                                <span style={{ marginRight: '5px' }}>{filledJobsCount}</span>

                                {t('Filled')}
                            </Tag>
                        )}
                        <Tag>
                            <span style={{ marginRight: '5px' }}>{openJobsCount}</span>

                            {t('Open')}
                        </Tag>
                    </Col>
                )}
            </Row>
        </Tooltip>
    );
};
