import { Requests as RequestsList } from 'shared/Requests';
import { OrganizationType } from 'types';

type Props = {
    setSidebarRequestNumber: Function;
};

export const Requests = ({ setSidebarRequestNumber }: Props) => {
    return (
        <RequestsList organizationType={OrganizationType.PROVIDER} setSidebarRequestNumber={setSidebarRequestNumber} />
    );
};
