import { useLocalization } from 'lib/Localization';
import { Button, Collapse, List, Result, Typography } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom-v5-compat';

const { Paragraph, Text } = Typography;

type StackTraceItem = {
    class: string;
    function: string;
    file: string;
    line: string;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    const { t } = useLocalization();
    const navigate = useNavigate();
    const axiosError = error as AxiosError;

    if (axiosError.isAxiosError && axiosError.response && axiosError.response?.status >= 400) {
        let backendAction;
        switch ((error as AxiosError).config?.method) {
            case 'get':
                backendAction = 'retrieving';
                break;
            case 'post':
            case 'put':
                backendAction = 'saving';
                break;
            case 'delete':
                backendAction = 'deleting';
                break;
            default:
                backendAction = 'processing';
        }

        const renderStackTrace = (trace: StackTraceItem[]) => (
            <Collapse>
                <Collapse.Panel header="Stack Trace" key="stack-trace">
                    <List
                        dataSource={trace}
                        renderItem={(traceItem: StackTraceItem) => (
                            <List.Item>
                                <Paragraph>
                                    <Typography.Text type="secondary" copyable>
                                        {`${traceItem.class}::${traceItem.function}`}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text copyable>{`${traceItem.file}::${traceItem.line}`}</Typography.Text>
                                </Paragraph>
                            </List.Item>
                        )}
                    />
                </Collapse.Panel>
            </Collapse>
        );

        return (
            <Result
                status="warning"
                title={`${axiosError.response.status} ${axiosError.response.statusText}`}
                subTitle={t(`Sorry, something went wrong while ${backendAction} data`)}
                extra={[
                    <Button key="1" onClick={() => navigate(-1)}>
                        {t('Back')}
                    </Button>,
                    <Button key="2" type="primary" onClick={resetErrorBoundary}>
                        {t('Try again')}
                    </Button>,
                ]}
            >
                {process.env.REACT_APP_ENVIRONMENT === 'development' ? (
                    <div className="desc" role="alert">
                        <Paragraph>
                            <Typography.Title copyable level={3}>
                                <Text strong>
                                    <CloseCircleTwoTone twoToneColor={red[6]} />{' '}
                                    {
                                        // @ts-ignore
                                        axiosError.response.data?.exception
                                    }
                                </Text>
                            </Typography.Title>
                            <Typography.Title copyable level={4} style={{ marginTop: '0' }}>
                                {
                                    // @ts-ignore
                                    axiosError.response.data?.message
                                }
                            </Typography.Title>
                            <Typography.Title copyable level={5}>
                                {
                                    // @ts-ignore
                                    `${axiosError.response.data?.file}::${axiosError.response.data?.line}`
                                }
                            </Typography.Title>
                            <br />
                            <br />
                            {
                                // @ts-ignore
                                renderStackTrace(axiosError.response.data.trace)
                            }
                        </Paragraph>
                    </div>
                ) : null}
            </Result>
        );
    }

    return (
        <Result
            status="500"
            title={t('Oh no...')}
            subTitle={t('Sorry, something went wrong')}
            extra={
                <Button type="primary" onClick={() => navigate(-1)}>
                    {t('Back')}
                </Button>
            }
        >
            {process.env.REACT_APP_ENVIRONMENT === 'development' ? (
                <div className="desc" role="alert">
                    <Paragraph>
                        <CloseCircleTwoTone twoToneColor={red[6]} /> {`${error.name}: ${error.message}`}
                    </Paragraph>
                </div>
            ) : null}
        </Result>
    );
};
