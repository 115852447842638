import { Layout, Result } from 'antd';
import { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Communication } from './Configuration/Communication';
import { Professions } from './Configuration/Professions';
import { Profession } from './Configuration/Professions/Profession';
import { Translations } from './Localization/Translations';
import { Organizations } from './Organizations';
import { Users } from './Users';
import { Categories } from './Configuration/Categories';
import { Permissions } from './Configuration/Permissions';

/**
 * Render content of the admin sub application.
 *
 * @return {*}
 */
export const Admin = () => {
    const match = useRouteMatch();

    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Switch>
                    <Route exact path={[`${match.path}/users`, '/admin']}>
                        <Users />
                    </Route>
                    <Route path={`${match.path}/organizations`}>
                        <Organizations />
                    </Route>
                    <Route path={`${match.path}/localization/translations`}>
                        <Translations />
                    </Route>
                    <Route path={`${match.path}/configuration/professions`}>
                        <Professions />
                    </Route>
                    <Route path={`${match.path}/configuration/profession/:id`}>
                        <Profession />
                    </Route>
                    <Route path={`${match.path}/configuration/categories`}>
                        <Categories />
                    </Route>
                    <Route path={`${match.path}/configuration/communication`}>
                        <Communication />
                    </Route>
                    <Route path={`${match.path}/configuration/permissions`}>
                        <Permissions />
                    </Route>

                    {/*Fallback empty page - has to be last in routes list*/}
                    <Route path={`${match.path}`}>
                        <Result
                            status="404"
                            title="Nothing here yet..."
                            subTitle="We have nothing to display here yet... any ideas? 😀"
                        />
                    </Route>
                </Switch>
            </Suspense>
        </Layout.Content>
    );
};
