import 'shared/Account/UserProfile/Onboarding/Onboarding.scss';

import { Button, Checkbox, Form, Input, notification, Select, Typography } from 'antd';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { useLocalization } from 'lib/Localization';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Category, User } from 'types/models';
import { Authentication, useAccount } from 'lib/Account';
import { useIsProfileSet } from 'lib/hooks';
import { Logo } from 'shared/logo';
import { useNavigate } from 'react-router-dom-v5-compat';

const { Title } = Typography;

const Onboarding = () => {
    const { t } = useLocalization();
    const { accountUser, setAccountUser } = useAccount();
    const isProfileSet = useIsProfileSet();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const handleError = useErrorHandler();
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);

    if (isProfileSet) {
        navigate(Authentication.determineUserHomePath(accountUser));
    }

    const { collaborationMethods } = accountUser;
    const categoryOptions = categories.map(({ id, name }) => ({
        value: id,
        label: t(name),
    }));
    const collaborationMethodOptions = collaborationMethods.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const initialValues = {
        first_name: accountUser.profile.first_name,
        last_name: accountUser.profile.last_name,
        company_name: accountUser.organization.company_name,
    };

    useEffect(() => {
        const loadCategoriesData = async () => {
            try {
                const response = await Backend.get('/data-management/categories');
                setCategories(response.data);
            } catch (error) {
                handleError(error);
            }
        };
        loadCategoriesData();
    }, [handleError]);

    const sendProfileData = async (values: User) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.put(`/account/profile/onboard`, values);

            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Profile successfully saved'),
                });
                setAccountUser({ ...accountUser, ...response.data });
                navigate(Authentication.determineUserHomePath(accountUser), { replace: true });
                window.location.reload();
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    return (
        <>
            <Title className="profile-onboarding-title">
                <Logo />
            </Title>
            <div id="setup-profile-container">
                <Title level={2}>{t('Set up your profile')}</Title>
                <Form form={form} initialValues={initialValues} onFinish={sendProfileData}>
                    <Form.Item
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter first name'),
                            },
                        ]}
                    >
                        <FloatingLabel label={t('First name')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter last name'),
                            },
                        ]}
                    >
                        <FloatingLabel label={t('Last name')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="company_name"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter company name'),
                            },
                        ]}
                    >
                        <FloatingLabel label={t('Company name')} required>
                            <Input disabled={!accountUser.organization.is_fixed} />
                        </FloatingLabel>
                    </Form.Item>

                    {accountUser.organization.is_fixed && (
                        <>
                            <Title level={4}>{t('What categories of work are you interested in?')}</Title>

                            <Form.Item
                                name="categories"
                                rules={[
                                    {
                                        required: true,
                                        message: t('Please select categories'),
                                    },
                                ]}
                            >
                                <Checkbox.Group options={categoryOptions} />
                            </Form.Item>
                        </>
                    )}

                    <Title level={4}>{t('What are your usual methods of collaboration?')}</Title>

                    <Form.Item name="collaboration_methods">
                        <FloatingLabel label={t('Collaboration methods')}>
                            <Select
                                mode="multiple"
                                options={collaborationMethodOptions}
                                placeholder={t('Select options...')}
                                style={{ width: '100%' }}
                            />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isDataSaving} block>
                            {t('Continue')} <ArrowRightOutlined />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export { Onboarding };
