import { useLocalization } from 'lib/Localization';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import { Button, Table } from 'antd';
import { Organization } from 'types/models';
import { ColumnsType } from 'antd/lib/table';

type Props = {
    organizations: Organization[];
    showOrganizationDetailsHandler: (organizationId: number) => void;
    isDataLoading: boolean;
};

/**
 * Table to display organizations of provider/client.
 *
 * @param organizations
 * @param showOrganizationDetailsHandler
 * @param isDataLoading
 * @returns {JSX.Element}
 * @constructor
 */
export const OrganizationsTable = ({ organizations, showOrganizationDetailsHandler, isDataLoading }: Props) => {
    const { t } = useLocalization();

    /**
     * Render cell for organization name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: Organization) => (
        <Button type="link" onClick={() => showOrganizationDetailsHandler(record.id)}>
            {record.company_name}
        </Button>
    );

    const columns: ColumnsType<Organization> = [
        {
            title: t('Name'),
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => sortColumn(a, b, 'company_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('company_name'),
            render: (company_name, record) => renderNameCell(record),
        },
        {
            title: t('City'),
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => sortColumn(a, b, 'city'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('city'),
        },
        {
            title: t('Phone'),
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => sortColumn(a, b, 'phone_number'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('phone_number'),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={organizations}
            pagination={getDefaultPaginationProp(organizations.length)}
            loading={isDataLoading}
        />
    );
};
