import { ApplicationError, useErrorHandler } from 'lib/ErrorHandling';
import { useState } from 'react';
import { Button, Checkbox, Form, Input, Result, Row, Typography } from 'antd';
import { Authentication, useAccount } from 'lib/Account';
import { useLocalization } from 'lib/Localization';
import { LoginCredentials } from 'types';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { getQueryParam } from 'lib/Helpers/General';
import { AuthLayout } from 'pages/Authentication/AuthLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const Login = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const location = useLocation();
    const [infoMessage, setInfoMessage] = useState<string>('');
    const { setAccountUser } = useAccount();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // We try to determine what page the user was trying to reach when (if) he was redirected to the login page.
    const { referer } = location.state || { referer: { pathname: '/' } };

    if (location.search) {
        setInfoMessage(getQueryParam('message', location));
    }

    if (infoMessage) {
        return (
            <AuthLayout>
                <Result status="info" title={t(infoMessage)} />
                <Row className="justify-center">
                    <Link to={`/login`} onClick={() => setInfoMessage('')}>
                        {t('Log in')}
                    </Link>
                </Row>
            </AuthLayout>
        );
    }

    const onSubmit = async (credentials: LoginCredentials) => {
        try {
            setLoading(true);
            const loggedInUser = await Authentication.login(credentials);

            setAccountUser(loggedInUser);
            // Redirect user to intended page.
            navigate(referer, { replace: true });
        } catch (error) {
            handleError(error, form, ({ message }: ApplicationError) => {
                setInfoMessage(String(message));
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout cardClass="login-box">
            <Typography.Title className="title">{t('Login')}</Typography.Title>
            <Form form={form} name="login" initialValues={{ remember: true }} onFinish={onSubmit}>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: t('Please enter a valid email'),
                        },
                        {
                            required: true,
                            message: t('Email is required'),
                        },
                    ]}
                >
                    <FloatingLabel label={t('Email')} required>
                        <Input autoFocus />
                    </FloatingLabel>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('Password is required'),
                        },
                    ]}
                >
                    <FloatingLabel label={t('Password')} required>
                        <Input.Password />
                    </FloatingLabel>
                </Form.Item>

                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>{t('Remember me')}</Checkbox>
                    </Form.Item>

                    <Link className="login-form-forgot" to="/reset-password">
                        {t('Forgot password')}
                    </Link>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block={true} size="large" loading={loading}>
                        {t('Log in')}
                    </Button>
                </Form.Item>
            </Form>
        </AuthLayout>
    );
};
