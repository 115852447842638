import { useLocalization } from 'lib/Localization';
import { ProviderRequestState } from 'types/staffing';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { ProviderRequest, Request } from 'types/models';
import { Tag, Tooltip } from 'antd';
import { OrganizationType } from 'types';

type Props = {
    organizationType: OrganizationType;
    request: Request;
};

export const OrganizationNames = ({ organizationType, request }: Props) => {
    const { t } = useLocalization();

    const getProviderRequestStateInfo = (providerRequestState: string, providerCompanyName: string) => {
        let icon, color, tooltip;

        switch (providerRequestState) {
            case ProviderRequestState.OPEN:
                icon = <ClockCircleOutlined />;
                color = 'warning';
                tooltip = `${t('Awaiting response')} - ${t(
                    '%{provider} still has to accept or reject the request',
                    { provider: providerCompanyName }
                )}`;
                break;
            case ProviderRequestState.ACCEPTED:
                icon = <SyncOutlined />;
                color = 'processing';
                tooltip = `${t('Accepted')} - ${t('The request is being staffed by %{provider}', { provider: providerCompanyName })}`;
                break;
            case ProviderRequestState.CONCLUDED:
                icon = <CheckCircleOutlined />;
                color = 'success';
                tooltip = `${t('Concluded')} - ${t('%{provider} has finished the staffing process', { provider: providerCompanyName })}`;
                break;
            case ProviderRequestState.REJECTED:
                icon = <CloseCircleOutlined />;
                color = 'error';
                tooltip = `${t('Rejected')} - ${t('%{provider} has rejected the request', { provider: providerCompanyName })}`;
                break;
            default:
                icon = color = tooltip = '';
        }

        return { icon, color, tooltip };
    };

    const renderProviderTag = (providerRequest: ProviderRequest) => {
        const { icon, color, tooltip } = getProviderRequestStateInfo(
            providerRequest.state,
            providerRequest.provider.company_name
        );

        return (
            <Tooltip key={providerRequest.provider.id} title={tooltip} mouseEnterDelay={1}>
                <Tag icon={icon} color={color}>
                    {providerRequest.provider.company_name}
                </Tag>
            </Tooltip>
        );
    };

    const renderClientTag = () => {
        return <Tag>{request.client?.company_name}</Tag>;
    };

    /**
     * Renders organization name(s)
     */
    const renderOrganization = () => {
        if (organizationType === OrganizationType.CLIENT) {
            return request.provider_requests?.map((providerRequest) => renderProviderTag(providerRequest));
        } else {
            return renderClientTag();
        }
    };

    return <>{renderOrganization()}</>;
};
