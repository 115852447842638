import 'shared/Localization/ChangeLocaleHorizontalMenu.scss';
import { Dropdown, Menu } from 'antd';
import { locales, useLocalization } from 'lib/Localization';
import { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const ChangeLocaleHorizontalMenu = () => {
    const localizationContext = useLocalization();
    const [langMenuVisible, setLangMenuVisible] = useState(false);

    const items = locales.map((locale) => ({
        key: locale.shortCode,
        label: locale.name,
        onClick: () => localizationContext.changeLocale(locale.shortCode),
    }));

    const langMenu = (
        <Menu
            selectedKeys={[localizationContext.locale.shortCode]}
            mode="horizontal"
            className="change-locale"
            overflowedIndicator={localizationContext.locale.shortCode.toUpperCase()}
            items={items}
        />
    );

    return (
        <div className="change-lang-menu">
            <Dropdown overlay={langMenu} onOpenChange={setLangMenuVisible}>
                <div>
                    <span className="lang-title">
                        {localizationContext.locale.shortCode.toUpperCase()}&nbsp;
                        {langMenuVisible ? (
                            <UpOutlined className="lang-menu-icon" />
                        ) : (
                            <DownOutlined className="lang-menu-icon" />
                        )}
                    </span>
                </div>
            </Dropdown>
        </div>
    );
};
