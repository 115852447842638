import type { SelectProps } from 'antd';
import { Select } from 'antd';
import { Profession, Worker } from 'types/models';
import { useLocalization } from 'lib/Localization';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';

type ProfessionMultiSelectProps = {
    allProfessions: Profession[];
    handleProfessionChange: (worker: Worker, selectedProfessions: Profession[]) => void;
    defaultSelections: Profession[];
    worker: Worker;
};

const ProfessionMultiSelect = ({
    handleProfessionChange,
    allProfessions,
    defaultSelections,
    worker,
}: ProfessionMultiSelectProps) => {
    const localizationContext = useLocalization();

    let translatedProfessions = localizeProfessions(allProfessions, localizationContext.locale);

    const options: SelectProps['options'] =
        translatedProfessions?.map((profession: Profession) => ({
            key: profession.id,
            label: profession.name,
            value: profession.name,
        })) ?? [];

    defaultSelections = localizeProfessions(defaultSelections ? defaultSelections : [], localizationContext.locale);

    const existingProfessions = defaultSelections?.map((profession: Profession) => profession.name);

    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                width: '301px',
                maxWidth: '100%',
            }}
            defaultValue={existingProfessions}
            onChange={(professions: string[]) => {
                const selectedProfessions = [
                    ...translatedProfessions.filter((profession: Profession) => {
                        const translations = profession.translations?.map(({ name }) => name) || [];

                        return (
                            translations.some((name) => professions.includes(name)) ||
                            professions.includes(profession.name)
                        );
                    }),
                ];

                handleProfessionChange(worker, selectedProfessions);
            }}
            options={options}
        />
    );
};

export { ProfessionMultiSelect };
