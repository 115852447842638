import './styles.scss';

import { Button, notification, Upload } from 'antd';
import type { RcFile } from 'antd/lib/upload/interface';
import { useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { InboxOutlined } from '@ant-design/icons';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    accept?: string;
    isLoading: boolean;
    maxFileSize?: string | number;
    okButtonText: string;
    open: boolean;
    onCancel: () => void;
    onOk: (fileList: RcFile[]) => Promise<boolean>;
    renderBeforeDragBox?: () => JSX.Element | JSX.Element[] | null | undefined;
    renderContent?: () => JSX.Element | JSX.Element[] | null | undefined;
    renderFooter?: () => JSX.Element | JSX.Element[] | null | undefined;
    title: string;
};

const { Dragger } = Upload;

export const FileUploader = ({
    accept,
    isLoading = false,
    maxFileSize,
    okButtonText,
    open = false,
    onCancel,
    onOk,
    renderBeforeDragBox,
    renderContent,
    renderFooter,
    title,
    ...otherProps
}: Props) => {
    const { t } = useLocalization();
    const [fileList, setFileList] = useState<RcFile[]>([]);

    const handleOnCancel = () => {
        setFileList([]);
        onCancel();
    };

    const handleUpload = async () => (await onOk(fileList)) && setFileList([]);

    const renderUploadFooter= () => [
        <Button key="back" onClick={handleOnCancel}>
            {t('Cancel')}
        </Button>,
        <Button className="import-button" disabled={!fileList.length} key="submit" type="primary" onClick={handleUpload} loading={isLoading}>
            {okButtonText}
        </Button>,
    ];

    const renderUploadContent = () => (
        <>
            {renderBeforeDragBox?.()}

            <Dragger
                accept={accept}
                beforeUpload={(file: RcFile) => {
                    if (validateFile(file)) {
                        setFileList([file]);
                    }

                    return false;
                }}
                directory={false}
                multiple={false}
                name="file"
                showUploadList={false}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>

                <p className={`ant-upload-text ${fileList.length ? 'has-file-name' : 'drag-text'}`}>
                    {fileList.length
                        ? trimFileName(fileList[0].name, 30)
                        : t('Click or drag file to this area to upload')}
                </p>
                {maxFileSize && !fileList.length && (
                    <small className="text-center">
                        ({t('%{maxSize} max size', { maxSize: maxFileSize })})
                    </small>
                )}

                {fileList.length > 0 && (
                    <p>
                        <a
                            className="delete-download-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                setFileList([]);
                            }}
                        >
                            {t('delete file')}
                        </a>
                    </p>
                )}
            </Dragger>
        </>
    );

    const validateFile = (file: RcFile) => {
        if (!maxFileSize) {
            return true;
        }

        const maxFileSizeByte = typeof(maxFileSize) !== 'number' ?
            // Assumes maxFileSize is in MB
            parseInt(maxFileSize) * 1000 * 1000 :
            maxFileSize;

        if (maxFileSizeByte >= file.size) {
            return true;
        }

        notification.error({
            message: t(`File cannot be more than ${maxFileSize}`),
        });

        return false;
    };

    return (
        <>
            <CustomModal
                className="file-uploader-modal"
                title={title}
                open={open}
                closable={false}
                footer={renderFooter?.() || renderUploadFooter()}
                centered
                {...otherProps}
            >
                {renderContent?.() || renderUploadContent()}
            </CustomModal>
        </>
    );
};

function trimFileName(fileName: string, maxLength: number) {
    if (fileName.length > maxLength) {
        const firstPart = fileName.substring(0, 20);
        const lastPart = fileName.substring(fileName.length - 5);

        fileName = `${firstPart}...${lastPart}`;
    }

    return fileName;
}
