import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Divider, notification, Space, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { EditableCell, EditableRow } from 'shared/AntDesignUtils/InlineEditTable';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import { Backend } from 'lib/Http/Backend';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { Translation } from 'types/models';
import { downloadExcel, extractExcelFileData } from 'lib/Helpers/ExcelHelper';
import { GenericObject } from 'shared/Contracts';
import { useCollections } from 'lib/Contexts/Collections';

export const Translations = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const [isDataDeleting, setIsDataDeleting] = useState(false);
    const [downloadableTranslations, setDownloadableTranslations] = useState<GenericObject[]>([]);
    const [rawTranslations, setRawTranslations] = useState<GenericObject[]>([]);
    const [translations, setTranslations] = useState<Translation[]>([]);
    const [languageFilter, setLanguageFilter] = useState<string>('');
    const [collections] = useCollections();
    const [fileInputValue, setFileInputValue] = useState('');

    /**
     * After the component renders
     */
    useEffect(() => {
        const loadTranslations = async () => {
            try {
                setIsDataLoading(true);
                const response = await Backend.get(`localization/translations`);
                const translations = response.data.map((translation: Translation) =>
                    formatBackendTranslation(translation)
                );
                setTranslations(translations);
                setRawTranslations(response.data);
            } catch (error) {
                handleError(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        loadTranslations();
    }, [handleError]);

    useEffect(() => {
        if (!rawTranslations.length || !collections.languages?.length) {
            return;
        }

        setDownloadableTranslations(
            rawTranslations.map(({ group, key, text }: Translation) => {
                const row: GenericObject = {
                    group,
                    key
                };

                collections.languages.forEach(({ name, code }) => {
                    row[name] = text?.[code] ?? '';
                });

                return row;
            })
        );
    }, [collections.languages, rawTranslations]);

    /**
     * Update a translation.
     *
     * @param translation
     * @return {Promise<void>}
     */
    const saveTranslation = async (translation: Translation) => {
        try {
            setIsDataSaving(true);
            const translationLine = { ...translation };
            // Prepare for backend
            delete translationLine.token;
            delete translationLine.key;
            delete translationLine.id;
            const response = await Backend.put(`localization/translation/${translation.id}`, translationLine);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Translation successfully saved.'),
                });
            }
            return response.data;
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataSaving(false);
        }
    };

    /**
     * Delete a translation.
     *
     * @param {int} id
     * @returns {Promise<any>}
     */
    const deleteTranslation = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`localization/translation/${id}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Translation successfully deleted.'),
                });
            }
            return response.data;
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Handle delete action.
     *
     * @param {int} id
     * @returns {Promise<void>}
     */
    const handleDelete = async (id: number) => {
        await deleteTranslation(id);
        setTranslations(translations.filter((item: Translation) => item.id !== id));
    };

    /**
     * Handle save action.
     *
     * @param translationRow
     * @returns {Promise<void>}
     */
    const handleSave = async (translationRow: Translation) => {
        const originalTranslations = [...translations];
        const updatedTranslations: Translation[] = [...translations];
        const index = updatedTranslations.findIndex((item: Translation) => translationRow.id === item.id);
        // Temporarily set the updated translation to the state so it can be displayed
        // while the actual translation is written to the backend
        translationRow.key += '-temp';
        updatedTranslations[index] = translationRow;
        setTranslations(updatedTranslations);

        try {
            const newTranslationLine = await saveTranslation(translationRow);
            updatedTranslations[index] = formatBackendTranslation(newTranslationLine);
            // After the data has been written we update the state with the actual data from the backend.
            setTranslations(updatedTranslations);
        } catch (error) {
            setTranslations(originalTranslations);
            handleError(error);
        }
    };

    /**
     * Format a translation from it's backend format to the format that we use in this component.
     *
     * @param backendTranslationLine
     */
    const formatBackendTranslation = (backendTranslationLine: Translation): Translation => ({
        key: backendTranslationLine.id?.toString(),
        id: backendTranslationLine.id,
        token: backendTranslationLine.key?.toString() || '',
        ...backendTranslationLine.text,
    });

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    /**
     * Filter translations to just show missing translations for a certain language if filters are set.
     *
     * @returns {*[]}
     */
    const filterTranslations = () => {
        if (languageFilter === '') {
            return translations;
        }

        return translations.filter((translation) => _.isEmpty(translation[languageFilter]));
    };

    const columns: ColumnsType<Translation> = [
        {
            title: 'Original text token',
            dataIndex: 'token',
            width: '19.5%',
            sorter: (a, b) => sortColumn(a, b, 'token'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('token'),
        },
    ];

    collections.languages.forEach(({ name, code }) => columns.push({
        title: name,
        dataIndex: code,
        onCell: (record: Translation) => ({
            record,
            editable: true,
            dataIndex: code,
            title: name,
            handleSave: handleSave
        }),
        sorter: (a, b) => sortColumn(a, b, code),
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps(code)
    } as ColumnType<Translation>));

    columns.push({
        dataIndex: 'actions',
        render: (text, record) => translations.length >= 1 && (
            <DeletePopconfirm
                title={t('Sure?')}
                id={record.id as number}
                isDataDeleting={isDataDeleting}
                deleteHandler={handleDelete}
            />
        )
    });

    const fileInput = useRef<HTMLInputElement>(null);

    const exportAll = () => {
        if (!collections.languages.length) {
            return;
        }
        const columnWidths = [
            { wch: 5 },
            { wch: 30 }
        ];

        collections.languages.forEach(() => columnWidths.push({ wch: 30 }));

        const date = new Date();
        const filename = `translations_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}.xlsx`;

        downloadExcel(filename, downloadableTranslations, columnWidths);

        notification.success({
            message: t('Translations exported.'),
        });
    };

    useEffect(() => {
        const handleChange = async (event: any) => {
            setFileInputValue(event.target.value);

            const file = event.target.files[0];
            const resetFile = () => setFileInputValue('');

            if (!file) {
                resetFile();

                return;
            }

            const rows = await extractExcelFileData(file);
            const langCode: GenericObject = {};

            collections.languages.forEach(({ name, code }: any) => {
                langCode[name] = code;
            });

            const data = rows.map(({ group, key, ...langs }: any) => {
                const text: GenericObject = {};

                for (const lang in langs) {
                    if (!langCode[lang] || !langs[lang]) {
                        continue;
                    }

                    text[langCode[lang]] = langs[lang];
                }

                return { group, key, text };
            });

            try {
                await Backend.post('localization/translations/import', { data });

                notification.success({
                    message: t('Translations are being imported.'),
                });
            } catch (e) {
                handleError(e);
            }

            resetFile();
        };

        fileInput.current?.addEventListener('change', handleChange);

        return () => {
            fileInput.current?.removeEventListener('change', handleChange);
        };
    }, [collections]);

    return (
        <PageHeader
            title={t('Translations')}
            backIcon={false}
            ghost={false}
            extra={
                <Space>
                    <Button type="primary" onClick={exportAll}>{t('Export')}</Button>
                    <input
                        ref={fileInput}
                        className="hidden"
                        type="file"
                        value={fileInputValue}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <Button
                        disabled={!!fileInputValue}
                        loading={!!fileInputValue}
                        type="primary"
                        onClick={() => fileInput.current?.click()}
                    >
                        {t('Import')}
                    </Button>
                </Space>
            }
        >
            <Space style={{ marginBottom: 16 }}>
                {t('Only show missing translations for:')}
                {collections.languages.filter(({ code }) => code !== 'en')
                    .map(({ code, name }) => (
                        <Button onClick={() => setLanguageFilter(code)}>{t(name)}</Button>
                    ))}
                <Divider type="vertical" />
                <Button onClick={() => setLanguageFilter('')}>{t('Reset filters')}</Button>
            </Space>
            <Table
                columns={columns}
                components={components}
                dataSource={filterTranslations()}
                loading={isDataLoading || isDataSaving || isDataDeleting}
                pagination={getDefaultPaginationProp(filterTranslations().length)}
                rowClassName={() => 'editable-row'}
                rowKey="id"
                size="small"
                bordered
            />
        </PageHeader>
    );
};
