import { GenericObject } from 'shared/Contracts';
import { useState } from 'react';
import {
    getColumnDatePickerProps as _getColumnDatePickerProps,
    getColumnDateRangePickerProps as _getColumnDateRangePickerProps,
    getColumnOptionProps as _getColumnOptionProps,
    getColumnSearchProps as _getColumnSearchProps,
} from 'lib/Helpers/TableHelper';

export const useTableFilterHelper = () => {
    const [filters, setFilters] = useState<GenericObject>({});

    const wrapForFilters =
        <T extends Array<any>>(propFn: Function) =>
        (dataIndex: string, ...otherParams: T) => {
            const props = propFn(dataIndex, ...otherParams);

            props.filteredValue = filters[dataIndex];

            return props;
        };

    const getColumnSearchProps = wrapForFilters<[backendFilter?: boolean]>(_getColumnSearchProps);

    const getColumnDatePickerProps =
        wrapForFilters<[format?: string, backendFilter?: boolean]>(_getColumnDatePickerProps);

    const getColumnDateRangePickerProps = wrapForFilters<[]>(_getColumnDateRangePickerProps);

    const getColumnOptionProps = wrapForFilters<[label: string, backendFilter?: boolean]>(_getColumnOptionProps);

    return {
        getColumnDatePickerProps,
        getColumnDateRangePickerProps,
        getColumnOptionProps,
        getColumnSearchProps,
        setFilters,
    };
};
