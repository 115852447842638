import type { MenuProps } from 'antd';
import { Menu, Badge } from 'antd';
import {
    ApartmentOutlined,
    EnvironmentOutlined,
    PlusOutlined,
    SettingOutlined,
    TeamOutlined,
    ToolOutlined
} from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { useLocalization } from 'lib/Localization';
import { useMenuKeys } from 'lib/Helpers/MenuHelper';
import { useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';

import 'shared/style/TopMenuItem.scss';
import { CalendarIcon, FileIcon, SuitCaseIcon } from 'shared/icons';

type Props = {
    isCollapsed: boolean;
    invitationNumber: number | null;
    showInvitationBadge: boolean;
};

export const SideMenu = ({ isCollapsed, showInvitationBadge, invitationNumber }: Props) => {
    const { t } = useLocalization();
    const match = useRouteMatch();
    const { selectedMenuKey, openMenuKey } = useMenuKeys();
    const [openKeys, setOpenKeys] = useState(openMenuKey ? [openMenuKey] : undefined);

    const canShowInvitationBadge = showInvitationBadge && invitationNumber !== null;
    const invitationBadgeClass = 'white-badge-text' + (invitationNumber ? '' : ' opacity-50');

    const providersLabel =
        !isCollapsed && canShowInvitationBadge ? (
            <Badge
                count={invitationNumber}
                overflowCount={99}
                offset={[45, 7]}
                color="#ffffff"
                className={invitationBadgeClass}
                showZero
            >
                {t('Providers')}
            </Badge>
        ) : (
            t('Providers')
        );

    const providersIcon =
        isCollapsed && canShowInvitationBadge ? (
            <Badge
                className={invitationBadgeClass}
                color="#ffffff"
                count={invitationNumber}
                offset={[20, 17]}
                overflowCount={99}
                size="small"
                showZero
            >
                <TeamOutlined />
            </Badge>
        ) : (
            <TeamOutlined />
        );

    const items: MenuProps['items'] = [
        {
            key: 'create_request',
            icon: <PlusOutlined />,
            label: <Link to={`${match.path}/requests/create`}>{t('Create request')}</Link>,
            className: 'top-menu-item-action top-menu-create-request',
        },
        {
            key: 'requests',
            icon: <FileIcon />,
            label: <Link to={`${match.path}/requests`}>{t('Requests')}</Link>,
        },
        {
            key: 'providers',
            className: isCollapsed && canShowInvitationBadge ? 'with-count' : '',
            icon: providersIcon,
            label: <Link to={`${match.path}/providers`}>{providersLabel}</Link>,
        },
        {
            key: 'configuration',
            label: (
                <span>
                    <SettingOutlined />
                    <span>{t('Configuration')}</span>
                </span>
            ),
            children: [
                {
                    key: 'organization',
                    icon: <ApartmentOutlined />,
                    label: <Link to={`${match.path}/configuration/organization`}>{t('Organization')}</Link>,
                },
                {
                    key: 'professions',
                    icon: <ToolOutlined />,
                    label: <Link to={`${match.path}/configuration/professions`}>{t('Professions')}</Link>,
                },
                {
                    key: 'work-shifts',
                    icon: <CalendarIcon />,
                    label: <Link to={`${match.path}/configuration/work-shifts`}>{t('Shifts')}</Link>,
                },
                {
                    key: 'departments',
                    icon: <SuitCaseIcon />,
                    label: <Link to={`${match.path}/configuration/departments`}>{t('Departments')}</Link>,
                },
                {
                    key: 'locations',
                    icon: <EnvironmentOutlined />,
                    label: <Link to={`${match.path}/configuration/locations`}>{t('Locations')}</Link>,
                },
            ],
        },
    ];

    return (
        <Menu
            className="sidebar-menu"
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuKey]}
            openKeys={openKeys}
            onClick={function ({ keyPath }) {
                if (keyPath.length === 2) {
                    // clicked on a sub-menu -> close other open sub-menu
                    setOpenKeys([[...keyPath].pop() as string]);
                }
            }}
            onOpenChange={function (openKeys: string[]) {
                setOpenKeys(openKeys);
            }}
            items={items}
        />
    );
};
