import { useLocation } from 'react-router-dom-v5-compat';

/**
 * Utility function to parse URL query parameters.
 *
 * Example usage:
 * // URL: https://dev.tempcloud.local/users?age=42
 * const queryParameters = useQueryParameters();
 * const age = queryParameter.get("age"); // 42
 *
 * @return {URLSearchParams}
 */
const useQueryParameters = () => {
    return new URLSearchParams(useLocation().search);
};

export default useQueryParameters;
