import { Redirect, Route } from 'react-router-dom';
import { notification } from 'antd';
import { Authentication, useAccount } from '.';
import { useLocalization } from 'lib/Localization';
import { ReactNode } from 'react';
import { useIsProfileSet } from 'lib/hooks';
import { useLocation } from 'react-router-dom-v5-compat';

type Props = {
    children: ReactNode;
    requirePermissions: string[];
    path: string;
}

function PrivateRoute({ children, requirePermissions: requiredPermissions, path, ...rest }: Props) {
    const { accountUser: user } = useAccount();
    const isProfileSet = useIsProfileSet();
    const { t } = useLocalization();
    const location = useLocation();

    // Check Authentication
    if (!user.isAuthenticated) {
        notification.warning({
            key: String(user.id),
            message: t('Please log in to access the requested page.'),
        });

        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { referer: location.pathname },
                }}
            />
        );
    }

    const onboardingPath = '/onboarding';
    const welcomePath = Authentication.determineUserWelcomePath(user);

    if (!isProfileSet && ![onboardingPath, welcomePath].includes(location.pathname)) {
        return <Redirect to={welcomePath} />;
    }

    // Check Authorization
    if (requiredPermissions) {
        // check if route is restricted by role
        const validPermissions = user.security.permissions.filter((accountPermission) =>
            requiredPermissions.includes(accountPermission)
        );

        if (validPermissions.length === 0) {
            // user not authorized
            notification.warning({
                message: t('You do not have the necessary permissions to access this section.'),
            });

            return <Redirect to={Authentication.determineUserHomePath(user)} />;
        }
    }

    return <Route {...rest} render={() => children} />;
}

export default PrivateRoute;
