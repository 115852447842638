import 'shared/Requests/Requests.scss';

import { Badge, Button, Col, Progress, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { RequestCard } from './RequestCard';
import { PlusOutlined } from '@ant-design/icons';
import { Request } from 'types/models';
import { OrganizationType } from 'types';
import { RequestTable } from './RequestsTable';
import { useAccount } from 'lib/Account';
import { useTabLocation } from 'lib/hooks';
import { userIsProvider } from 'lib/Helpers/UserHelper';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';

export enum AvailableTabs {
    ACTIVE = 'active',
    CANCELED = 'canceled',
    DRAFT = 'draft',
    HISTORY = 'history',
    PENDING = 'pending',
}

type Props = {
    organizationType: OrganizationType;
    setSidebarRequestNumber: Function;
};

export const Requests = ({ organizationType, setSidebarRequestNumber }: Props) => {
    const navigate = useNavigate();
    const { accountUser: user } = useAccount();
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const isProvider = userIsProvider(user);

    const defaultTab = 'list1';

    const [activeTab, setActiveTab] = useTabLocation(defaultTab);
    const [isGridView, setIsGridView] = useState(activeTab.startsWith('grid'));
    const [clientOptions, setClientOptions] = useState<any[]>([]);
    const [creatorOptions, setCreatorOptions] = useState<any[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);
    const [pendingRequestsCount, setPendingRequestsCount] = useState<number>(0);
    const [draftRequestsCount, setDraftRequestsCount] = useState<number>(0);

    useEffect(() => {
        const getOrdersMeta = async () => {
            try {
                const { data } = await Backend.get('/staffing/orders-meta');

                setClientOptions(data.clients.map((client: any) => ({
                    value: client.id,
                    text: client.company_name,
                })));

                setCreatorOptions(data.creators.map((creator: any) => ({
                    value: creator.id,
                    text: `${creator.first_name} ${creator.last_name}`,
                })));

                setDepartmentOptions(data.departments.map((department: any) => ({
                    value: department!.id,
                    text: department!.name,
                })));

                setDraftRequestsCount(data.draftRequestsCount);
                setPendingRequestsCount(data.pendingRequestsCount);

                setSidebarRequestNumber(data.pendingRequestsCount);
            } catch (e) {
                handleError(e);
            }
        };

        getOrdersMeta();
    }, []);

    const renderActiveCardExtra = (request: Request) => (
        <div className="progress-bar-container">
            <div className="progress-bar-info">
                {' '}
                {t('%{percentage}% confirmed', { percentage: request.filledPositionsPercent })}{' '}
            </div>
            <Progress percent={request.filledPositionsPercent!} size="small" showInfo={false} />
        </div>
    );

    const getHeaderButtons = () => {
        if (organizationType === OrganizationType.PROVIDER) {
            return [];
        }

        return [
            <Button
                key="1"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('/client/requests/create')}
            >
                {t('Create request')}
            </Button>,
        ];
    };

    const draftTabLabel = (
        <Badge count={draftRequestsCount} overflowCount={99} offset={[20, 7]} color="#1677ff" showZero={true}>
            {t('Drafts')}
        </Badge>
    );

    const pendingTabLabel = (
        <Badge count={pendingRequestsCount} overflowCount={99} offset={[20, 7]} color="#1677ff" showZero={true}>
            {t('Pending')}
        </Badge>
    );

    const switchToActiveTab = () => setActiveTab(activeTab.substring(0, activeTab.length - 1) + '2');

    const renderRequestCard = (request: Request, activeTab: AvailableTabs) => (
        <Col className="gutter-row" span={24} key={request.id}>
            <RequestCard
                activeTab={activeTab}
                extra={activeTab === AvailableTabs.ACTIVE ? renderActiveCardExtra(request) : null}
                organizationType={organizationType}
                request={request}
                type={'pending'}
            />
        </Col>
    )

    const renderRequestTab = (activeTab: AvailableTabs, additionalTableProps = {}) => (
        <RequestTable
            activeTab={activeTab}
            collection={{
                clientOptions: clientOptions,
                creatorOptions: creatorOptions,
                departmentOptions: departmentOptions,
            }}
            onAccept={() => switchToActiveTab()}
            organizationType={organizationType}
            {...additionalTableProps}
        />
    );

    const tabItems = [
        {
            label: t('Active'),
            key: isGridView ? 'grid1' : defaultTab,
            children: renderRequestTab(AvailableTabs.ACTIVE, {
                    actionColumnWidth: '50px',
                    showProgress: true,
                }),
        },
        {
            label: pendingRequestsCount ? pendingTabLabel : t('Pending'),
            key: isGridView ? 'grid2' : 'list2',
            children: renderRequestTab(AvailableTabs.PENDING, {
                    showProgress: true,
                }),
        },
        {
            label: draftRequestsCount ? draftTabLabel : t('Drafts'),
            key: isGridView ? 'grid3' : 'list3',
            children: renderRequestTab(AvailableTabs.DRAFT),
        },
        {
            label: t('History'),
            key: isGridView ? 'grid4' : 'list4',
            children: renderRequestTab(AvailableTabs.HISTORY),
        },
    ];

    if (isProvider) {
        tabItems.splice(2, 1);
    }

    const activateGridView = () => {
        setIsGridView(true);
        setActiveTab(activeTab.replace('list', 'grid'));
    };

    const deactivateGridView = () => {
        setIsGridView(false);
        setActiveTab(activeTab.replace('grid', 'list'));
    };

    return (
        <div className="requests">
            <PageHeader title={t('Requests')} backIcon={false} ghost={false} extra={getHeaderButtons()}>
                <Tabs
                    activeKey={activeTab}
                    items={tabItems}
                    onTabClick={setActiveTab}
                    // tabBarExtraContent={
                    //     <>
                    //         <Button
                    //             type="text"
                    //             className={isGridView ? 'button-selected' : ''}
                    //             icon={<AppstoreOutlined />}
                    //             onClick={activateGridView}
                    //         />
                    //         <Button
                    //             type="text"
                    //             className={isGridView ? '' : 'button-selected'}
                    //             icon={<UnorderedListOutlined />}
                    //             onClick={deactivateGridView}
                    //         />
                    //     </>
                    // }
                />
            </PageHeader>
        </div>
    );
};
