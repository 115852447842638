import { useErrorHandler } from 'lib/ErrorHandling';
import {
    getColumnOptionProps,
    getColumnSearchProps,
    getDefaultPaginationProp,
    sortColumn,
} from 'lib/Helpers/TableHelper';
import { Backend } from 'lib/Http/Backend';
import { getOppositeOrganizationType } from 'lib/Organization';
import { useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, notification, Select, Table } from 'antd';
import { OrganizationType } from 'types';
import { Organization } from 'types/models';
import { ColumnsType } from 'antd/lib/table';
import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';

type Props = {
    organizationType: OrganizationType;
    organizations: Organization[];
    oppositeOrganizations: Organization[];
    openOrganizationHandler: (organization: Organization) => void;
    loadData: () => Promise<void>;
    isDataLoading: boolean;
};

export const OrganizationsTable = ({
    organizationType,
    organizations,
    oppositeOrganizations,
    openOrganizationHandler,
    loadData,
    isDataLoading,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataSaving, setIsDataSaving] = useState<{ [index: number]: boolean }>({});
    const [isDataDeleting, setIsDataDeleting] = useState(false);
    const oppositeOrganizationType = getOppositeOrganizationType(organizationType);
    const oppositeOrganizationOptions = oppositeOrganizations.map((organization) => ({
        value: organization.id,
        label: organization.company_name,
    }));

    /**
     * Save connected organizations.
     *
     * @param organizationId
     * @param connectedOrganizationIds
     */
    const saveHandler = async ({
        organizationId,
        connectedOrganizationIds,
    }: {
        organizationId: number;
        connectedOrganizationIds: number[];
    }) => {
        try {
            setIsDataSaving({ [organizationId]: true });
            const response = await Backend.put(
                `data-management/${organizationType}s/${organizationId}/${oppositeOrganizationType}s`,
                connectedOrganizationIds
            );
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t(`Connected ${oppositeOrganizationType}s successfully saved`),
                });
            }
            loadData();
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataSaving({ [organizationId]: false });
        }
    };

    /**
     * Render cell for organization name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: Organization) =>
        record.deleted_at === null ? (
            <Button type="link" onClick={() => openOrganizationHandler(record)}>
                {record.company_name}
            </Button>
        ) : (
            <Button type="link"> {record.company_name} </Button>
        );

    /**
     * Render an in-line view for connected organizations.
     *
     * @return {*}
     * @param connectedOrganizations
     * @param organizationId
     */
    const renderConnectedOrganizationsCell = (connectedOrganizations: Organization[], organization: Organization) => {
        const organizationId = organization.id;
        const connectedOrganizationIds = connectedOrganizations.map(
            (connectedOrganization) => connectedOrganization.id
        );

        return (
            <Select
                mode="multiple"
                placeholder={t(`Connect ${oppositeOrganizationType}s`)}
                className="multi-select"
                onChange={(connectedOrganizationIds) =>
                    saveHandler({
                        organizationId,
                        connectedOrganizationIds,
                    })
                }
                disabled={organization.deleted_at !== null}
                value={connectedOrganizationIds}
                options={oppositeOrganizationOptions}
                loading={isDataSaving[organizationId]}
                optionFilterProp="label"
            />
        );
    };

    const softDeleteOrganizationHandler = async (organizationId: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`data-management/${organizationType}s/${organizationId}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                setIsDataDeleting(false);
                notification.success({
                    message: t('Organization removed successfully'),
                });
                loadData();
            }
        } catch (error) {
            setIsDataDeleting(false);
            handleError(error);
        }
    };

    const renderStatusOrganization = (record: Organization) =>
        record.deleted_at ? (
            <p className="red-color"> {t('Deleted')} </p>
        ) : (
            <p className="black-color"> {t('Active')} </p>
        );

    const renderActionColumn = (record: Organization) =>
        !record.deleted_at && (
            <DeletePopconfirm
                title={t('Are you sure you want to delete this organization?')}
                id={record.id}
                isDataDeleting={isDataDeleting}
                deleteHandler={softDeleteOrganizationHandler}
            />
        );

    /**
     * Filter table by connected organizations.
     *
     * @param value
     * @param record
     *
     * @returns {boolean}
     */
    const filterByConnectedOrganizations = (value: string | number | boolean, record: Organization) => {
        for (const connectedOrganization of record[`${oppositeOrganizationType}s`]) {
            if (connectedOrganization.id === value) {
                return true;
            }
        }
        return false;
    };

    const citiesWithNoDuplicates = [...new Set(organizations.map((organization) => organization.city).filter(Boolean))];

    const columns: ColumnsType<Organization> = [
        {
            title: t('Name'),
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => sortColumn(a, b, 'company_name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('company_name'),
            render: (company_name, record) => renderNameCell(record),
        },
        {
            title: t('City'),
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => sortColumn(a, b, 'city'),
            sortDirections: ['ascend', 'descend'],
            filters: (citiesWithNoDuplicates as []).map((city: string) => ({
                value: city,
                text: city,
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => value === record.city,
            render: (city, record) => (!record.deleted_at ? city : ''),
        },
        {
            title: t('Zip'),
            dataIndex: 'zip_code',
            key: 'zip_code',
            sorter: (a, b) => sortColumn(a, b, 'zip_code'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('zip_code'),
            render: (zip_code, record) => (!record.deleted_at ? zip_code : ''),
        },
        {
            title: t(`Connected ${oppositeOrganizationType}s`),
            dataIndex: `${oppositeOrganizationType}s`,
            key: `${oppositeOrganizationType}s`,
            render: (connectedOrganizations, record) =>
                renderConnectedOrganizationsCell(connectedOrganizations, record),
            filters: oppositeOrganizations.map((oppositeOrganization) => ({
                value: oppositeOrganization.id,
                text: oppositeOrganization.company_name,
            })),
            filterMultiple: true,
            filterSearch: true,
            onFilter: filterByConnectedOrganizations,
        },
        {
            title: t('Status'),
            dataIndex: `deleted_at`,
            key: `status`,
            render: (connectedOrganizations, record) => renderStatusOrganization(record),
            defaultFilteredValue: [true],
            ...getColumnOptionProps('deleted_at', t('Hide the deleted ones')),
        },
        {
            title: '',
            key: `action`,
            dataIndex: `action`,
            render: (connectedOrganizations, record) => renderActionColumn(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={organizations}
            loading={isDataLoading}
            pagination={getDefaultPaginationProp(organizations.length)}
        />
    );
};
