import { RequestState } from 'types/staffing/RequestStateMachine';
import { useLocalization } from 'lib/Localization';
import { TooltipTag } from 'shared/AntDesignUtils/TooltipTag';

type Props = {
    requestState: string;
};

export const RequestHeaderTag = ({ requestState }: Props) => {
    const { t } = useLocalization();

    const requestStateHeaderTag: Record<string, JSX.Element> = {
        [RequestState.DRAFT]: (
            <TooltipTag
                color="yellow"
                text={ t('Draft') }
                title={ t('The request has not been sent to any provider yet') }
            />
        ),
        [RequestState.POSTED]: (
            <TooltipTag
                color="orange"
                text={ t('Awaiting response')
            } title={ t('The request is waiting on responses from providers') }
            />
        ),
        [RequestState.PENDING]: (
            <TooltipTag
                color="blue"
                text={ t('Active') }
                title={ t('The request is being staffed by the providers') }
            />
        ),
        [RequestState.FULFILLED]: (
            <TooltipTag
                color="green"
                text={ t('Fulfilled') }
                title={ t('The providers have finished the staffing process') }
            />
        ),
        [RequestState.CANCELED]: (
            <TooltipTag
                color="red"
                text={ t('Canceled') }
                title={ t('You canceled this request') }
            />
        ),
        [RequestState.REJECTED]: (
            <TooltipTag
                color="red"
                text={ t('Rejected') }
                title={ t('The request was rejected by all providers') }
            />
        ),
        [RequestState.EXPIRED]: (
            <TooltipTag
                color="red"
                text={ t('Expired') }
                title={ t('The request has expired') }
            />
        ),
    };

    return requestStateHeaderTag[requestState];
};
