import { Progress, Space, Typography } from 'antd';
import { useLocalization } from 'lib/Localization';

const { Text } = Typography;

type Props = {
    breakAfterText?: boolean;
    confirmedPositionsPercent?: number;
    filledPositionsPercent: number;
    showText?: boolean;
}

export const ActionProgress = ({
    breakAfterText = false,
    confirmedPositionsPercent = 0,
    filledPositionsPercent,
    showText = false,
}: Props) => {
    const { t } = useLocalization();

    const progress = <Progress
        className="progress"
        percent={ filledPositionsPercent }
        showInfo={ false }
        success={{ percent: confirmedPositionsPercent }}
    />;

    if (!showText) {
        return progress;
    }

    return (
        <Space key="filledPositions" className="space-margin-left" style={{ width: '100%' }}>
            { showText && (
                <>
                    <Text strong className="color-primary">
                        { t('Filled positions') } { filledPositionsPercent }%
                    </Text>

                    { breakAfterText && (<br />) }
                </>
            ) }

            {progress}
        </Space>
    );
};
