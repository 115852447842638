import { Button, Col, Form, Input, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { DoubleColumnFormLayout } from 'lib/antFormLayouts';
import { FormInstance } from 'antd/lib/form';
import { Category, Organization } from 'types/models';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { DefaultOptionType } from 'rc-select/lib/Select';

const { formLayout, saveButtonLayout } = DoubleColumnFormLayout;

type Props = {
    form: FormInstance;
    organization: Organization;
    categoryOptions: { label: string; value: number }[];
    countryOptions: DefaultOptionType[];
    saveHandler: (values: Organization) => void;
    isOrganizationSaving: boolean;
};

export const OrganizationForm = ({
    form,
    organization,
    categoryOptions,
    countryOptions,
    saveHandler,
    isOrganizationSaving,
}: Props) => {
    const { t } = useLocalization();
    const [isAddress2Hidden, setAddress2Hidden] = useState(!organization.address_line_2);

    /**
     * When component receives new organization props.
     */
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...organization,
            categories: (organization.categories || []).map(({ id }: Category) => id),
        });

        if (organization.is_fixed) {
            form.setFieldValue('country_id', null);
        }
    }, [form, organization]);

    const renderSecondAddress = () => {
        if (isAddress2Hidden) {
            return (
                <Form.Item>
                    <Button
                        type="link"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => setAddress2Hidden(!isAddress2Hidden)}
                    >
                        {t('Add location')}
                    </Button>
                </Form.Item>
            );
        }

        return (
            <Form.Item name="address_line_2">
                <FloatingLabel label={t('Additional address')}>
                    <Input
                        placeholder={t('Additional address information')}
                        suffix={
                            <MinusCircleOutlined
                                onClick={() => {
                                    form.setFieldsValue({ address_line_2: '' });
                                    setAddress2Hidden(!isAddress2Hidden);
                                }}
                            />
                        }
                    />
                </FloatingLabel>
            </Form.Item>
        );
    };

    return (
        <Form form={form} onFinish={saveHandler} {...formLayout}>
            <Row>
                <Col span={10}>
                    <Form.Item
                        name="company_name"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter an organization name'),
                            },
                        ]}
                    >
                        <FloatingLabel label={t('Company name')} required>
                            <Input placeholder={t('Organization Name')} />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="zip_code"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter a zip code')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('Zip code')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter a city')
                            },
                            {
                                max: 100,
                                message: t('The city may not be greater than 100 characters')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('City')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="phone_number"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter a phone number')
                            },
                            {
                                max: 30,
                                message: t('The phone number may not be greater than 30 characters')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('Phone number')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item name="categories">
                        <FloatingLabel label={t('Category of activity')}>
                            <Select mode="multiple" options={categoryOptions} size="small" allowClear />
                        </FloatingLabel>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        name="address_line_1"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter a street name and no.')
                            },
                            {
                                max: 100,
                                message: t('The street name and no may not be greater than 100 characters')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('Street name and no.')} required>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>

                    {renderSecondAddress()}

                    <Form.Item
                        name="country_id"
                        rules={[
                            {
                                required: true,
                                message: t('Please enter a country')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('Country')} required>
                            <Select options={countryOptions} />
                        </FloatingLabel>
                    </Form.Item>

                    <Form.Item
                        name="website"
                        rules={[
                            {
                                max: 100,
                                message: t('The website may not be greater than 100 characters')
                            }
                        ]}
                    >
                        <FloatingLabel label={t('Website')}>
                            <Input />
                        </FloatingLabel>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <Form.Item {...saveButtonLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isOrganizationSaving}
                            disabled={organization.is_fixed}
                        >
                            {t('Save')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
