import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';

function useLoadEntity<Entity>(
    entityName: string,
    entityUrl?: string
): [Entity[], boolean, Dispatch<SetStateAction<Entity[]>>, (url?: string) => Promise<void>, Record<string, any>] {
    const [entity, setEntity] = useState<Entity[]>([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState<boolean>(true);
    const handleError = useErrorHandler();

    const loadEntity = useCallback(
        async (url?: string) => {
            if (!url && !entityUrl) {
                return;
            }

            setLoading(true);

            try {
                const response = await Backend.get(String(url || entityUrl));
                const data = response?.data;

                if (data && data[entityName]) {
                    setEntity(data[entityName]);
                }

                if (data.meta) {
                    setMeta(data.meta);
                }
            } catch (error) {
                handleError(error);
            } finally {
                setTimeout(() => setLoading(false));
            }
        },
        [entityName, entityUrl]
    );

    useEffect(() => {
        loadEntity();
    }, [entityUrl]);

    return [entity, loading, setEntity, loadEntity, meta];
}

export { useLoadEntity };
