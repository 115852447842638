import 'shared/AntDesignUtils/TableOptionFilterDropDown/TableOptionFilterDropdown.scss';
import { Checkbox } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';

type Props = {
    setSelectedKeys: (selectedKeys: boolean[]) => void;
    selectedKeys: boolean[];
    confirm: (param?: FilterConfirmProps) => void;
    clearFilters: () => void;
    visible: boolean;
    label: string;
};

export const TableOptionFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    visible,
    label,
}: Props) => (
    <div className="table-option-filter-dropdown">
        <Checkbox
            onChange={(e) => {
                setSelectedKeys([!selectedKeys[0]]);
                confirm({ closeDropdown: false });
            }}
            checked={selectedKeys[0]}
        >
            {label}
        </Checkbox>
    </div>
);
