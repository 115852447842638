import { Button, Table, Tag, Tooltip } from 'antd';
import { useLocalization } from 'lib/Localization';
import { getColumnSearchProps } from 'lib/Helpers/TableHelper';
import moment from 'moment';
import { User } from 'types/models';
import { ColumnsType } from 'antd/lib/table';
import { useCollections } from 'lib/Contexts/Collections';
import { Role } from 'types/Role';
import { Backend } from 'lib/Http/Backend';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useLoadPaginatedEntity } from 'lib/hooks/useLoadPaginatedEntity';
import { useTable } from 'lib/hooks/useTable';
import { getUserOrganizationType } from 'lib/Organization';

export const UsersTable = () => {
    const { t } = useLocalization();
    const navigate = useNavigate();

    const [collections] = useCollections();
    const [users, tableParams, isLoadingUsers, _, setTableParams] = useLoadPaginatedEntity<User>(
        'users',
        '/account/users'
    );
    const [impersonating, setImpersonating] = useState(0);

    const { handleTableChange } = useTable(setTableParams);

    const roleOptions = collections.roles.map((role) => ({
        value: role.id,
        text: role.name,
    }));

    /**
     * Render cell for user's name.
     *
     * @param user
     */
    const formatName = (user: User) => {
        return `${user.first_name} ${user.last_name}`;
    };

    /**
     * Render cell for email of contact.
     *
     * @param email {string}
     * @return {*}
     */
    const renderEmailCell = (email: string) => (
        <a href={`mailto:${email}`} target="blank">
            {email}
        </a>
    );

    /**
     * Render cell for user's organization.
     *
     * @param user
     */
    const formatOrganizationName = (user: User) => {
        const userOrganization = user.agent_profile?.provider || user.contact_profile?.client;
        return userOrganization?.company_name ?? '';
    };

    /**
     * Render an in-line view for user's roles.
     *
     * @param user { object }
     * @return {*}
     */
    const renderRolesCell = (user: User) => user.roles?.map((role) => <Tag key={role.id}>{role.name}</Tag>);

    /**
     * Render cell for user's last login.
     *
     * @param user { object }
     * @return {*}
     */
    const renderLastLoginCell = (user: User) => (
        <Tooltip
            title={
                <span>Created at: {user.created_at ? moment(user.created_at).format('DD.MM.YYYY HH:mm:ss') : ''}</span>
            }
        >
            {user.last_login_at ? moment(user.last_login_at).format('DD.MM.YYYY HH:mm:ss') : ''}
        </Tooltip>
    );

    /**
     * Filter table by role.
     *
     * @param value
     * @param user
     *
     * @returns {boolean}
     */
    const filterRoles = (value: string | number | boolean, user: User) => {
        for (const role of user.roles || []) {
            if (role.id === value) {
                return true;
            }
        }
        return false;
    };

    const impersonate = async (user: User) => {
        try {
            setImpersonating(user.id);
            await Backend.get(`/account/impersonate/take/${user.id}`);
        } catch {
            // do nothing
        } finally {
            setTimeout(() => {
                const userType = getUserOrganizationType(user);

                navigate(`/${userType}/configuration/organization`);
                window.location.reload();
            }, 1000);
        }
    };

    const columns: ColumnsType<User> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name', true),
            render: (first_name, user) => formatName(user),
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('email', true),
            render: (email) => renderEmailCell(email),
        },
        {
            title: t('Organization'),
            dataIndex: 'organization',
            key: 'organization',
            ...getColumnSearchProps('organization', true),
            render: (organization, user) => formatOrganizationName(user),
        },
        {
            title: t('Roles'),
            dataIndex: 'roles',
            key: 'roles',
            filters: roleOptions,
            filterMultiple: true,
            filterSearch: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (roles, user) => renderRolesCell(user),
        },
        {
            title: t('Last login'),
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (last_login_at, user) => renderLastLoginCell(user),
        },
        {
            dataIndex: '_',
            key: 'action',
            render: (_, user) =>
                !user.roles.some(({ name }) => name === Role.ADMIN) && (
                    <Button
                        disabled={!!impersonating}
                        loading={impersonating === user.id}
                        type="primary"
                        onClick={() => impersonate(user)}
                    >
                        {t('Impersonate')}
                    </Button>
                ),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={users}
            pagination={tableParams.pagination}
            loading={isLoadingUsers}
            onChange={handleTableChange}
        />
    );
};
