import { useLocalization } from 'lib/Localization';
import { Button, Modal, notification } from 'antd';
import type { ParsedCsvWorkersWithTempId } from 'types/models/Worker';
import { Worker } from 'types/models/Worker';
import { TemplateErrors } from './ImportExcelModal';
import { Backend } from 'lib/Http/Backend';
import { ValidateWorkersTable } from './ValidateWorkersTable';
import { useCallback } from 'react';

type ActionNeededModalProps = {
    className?: string;
    open: boolean;
    isLoading: boolean;
    uploadUrl: string;
    onUploadAgain: () => void;
    onClose: () => void;
    onSuccess: () => void;
    parsedWorkers?: ParsedCsvWorkersWithTempId;
    templateErrors?: TemplateErrors;
};

export const ActionNeededModal = ({
    className,
    open = false,
    onUploadAgain,
    onClose,
    onSuccess,
    uploadUrl,
    parsedWorkers,
    isLoading = false,
    templateErrors,
}: ActionNeededModalProps) => {
    const { t } = useLocalization();

    const hasNonErrorRows = useCallback(() => {
        if (!parsedWorkers) {
            return false;
        }

        const errorWorkerIndices: number[] = [];

        for (const key in templateErrors) {
            const index = Number(key.split('.')[0]);

            if (errorWorkerIndices.includes(index)) {
                continue;
            }

            errorWorkerIndices.push(index);
        }

        return errorWorkerIndices.length < Object.keys(parsedWorkers).length;
    }, [templateErrors, parsedWorkers]);

    if (!parsedWorkers) {
        return null;
    }

    const importWithoutErrors = async () => {
        try {
            if (templateErrors) {
                const workerTempIdsWithError = Object.keys(templateErrors).map((key) => Number(key.split('.')[0]));

                for (const key in parsedWorkers) {
                    if (workerTempIdsWithError.includes(Number(key))) {
                        delete parsedWorkers[key];
                    }
                }
            }

            await Backend.post<Worker>(uploadUrl, parsedWorkers);
            onSuccess();

            return notification.success({
                message: t('Workers without errors are imported successfully.'),
            });
        } catch (error) {
            return notification.error({
                message: t('Something went wrong. Please review your sheet.'),
            });
        } finally {
            onClose();
        }
    };

    const modalFooter = [
        <Button key="back" type={hasNonErrorRows() ? 'default' : 'primary'} onClick={onUploadAgain}>
            {t('Upload new file')}
        </Button>,
    ];

    if (hasNonErrorRows()) {
        modalFooter.push(
            <Button key="submit" type="primary" onClick={importWithoutErrors} loading={isLoading}>
                {t('Import without these rows')}
            </Button>
        );
    }

    return (
        <Modal
            className="action-needed-modal"
            title={t('Action needed')}
            open={open}
            onCancel={onClose}
            footer={modalFooter}
            width="unset"
            rootClassName={className}
            centered
        >
            <div className="text-center">
                <p className="errors-description">
                    {t(
                        'We have found some errors in your Excel file. Please assess the errors and choose the way you want to proceed'
                    )}
                </p>

                <span className="text-errors-header">{t('Input errors')}</span>

                {templateErrors && (
                    <ValidateWorkersTable
                        isLoading={isLoading}
                        parsedWorkers={parsedWorkers}
                        templateErrors={templateErrors}
                    />
                )}
            </div>
        </Modal>
    );
};
