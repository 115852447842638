import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { useAccount } from 'lib/Account';
import { WorkShift } from 'types/models';
import { FormInstance } from 'antd/lib/form';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { TimePicker } from 'shared/AntDesignUtils/TimePicker';
import { Duration } from 'shared/AntDesignUtils/Duration';
import { max, number, required, time } from 'lib/Validation/validators';
import { parseDateTime } from 'lib/Validation/helpers';

type Props = {
    shift?: WorkShift;
    saveShiftHandler: (shift: WorkShift, form: FormInstance) => void;
    isDataSaving: boolean;
};

export const WorkShiftForm = ({ shift, saveShiftHandler, isDataSaving }: Props) => {
    const { t } = useLocalization();
    const [form] = Form.useForm();
    const { accountUser: user } = useAccount();
    const [startTime, setStartTime] = useState(shift?.start_time);
    const [endTime, setEndTime] = useState(shift?.end_time);
    const [breakDuration, setBreakDuration] = useState(shift?.break_duration);
    const [maxBreakDuration, setMaxBreakDuration] = useState(60);
    const [breakMaxTime, setBreakMaxTime] = useState('');

    useEffect(() => {
        setStartTime(shift?.start_time);
        setEndTime(shift?.end_time);

        form.resetFields();
        form.setFieldsValue({ ...shift });
    }, [form, shift, user]);

    useEffect(() => {
        if (!startTime || !endTime) {
            return;
        }

        const $startTime = parseDateTime(startTime);
        let $endTime = parseDateTime(endTime);

        if ($endTime.isSameOrBefore($startTime)) {
            $endTime = $endTime.add(1, 'day');
        }

        const $maxBreakDuration = $endTime.diff($startTime, 'minutes');

        setMaxBreakDuration($maxBreakDuration - 1);
    }, [startTime, endTime]);

    useEffect(() => {
        if (!endTime) {
            return;
        }

        const $endTime = parseDateTime(endTime);
        const $breakMaxTime = $endTime.subtract(Number(breakDuration), 'minutes');

        setBreakMaxTime(`${$breakMaxTime.hour()}:${$breakMaxTime.minute()}`);
    }, [breakDuration]);

    const breakDurationHandler = (value: number) => {
        form.setFieldValue('break_duration', value);
        setBreakDuration(value);
    };

    const onFinish = (values: WorkShift) => {
        if (shift?.id) {
            values.id = shift.id;
        }

        saveShiftHandler(values, form);

        setStartTime(undefined);
        setEndTime(undefined);
    };

    return (
        <div className="work-shifts">
            <Form id="work-shift-form" form={form} onFinish={onFinish} validateTrigger="onBlur">
                <Row>
                    <Col span={16}>
                        <Form.Item
                            name="name"
                            messageVariables={{ name: 'Shift name' }}
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter shift name')
                                },
                                {
                                    max: 150,
                                    message: t('The shift name may not be greater than 150 characters')
                                }
                            ]}
                        >
                            <FloatingLabel label={t('Name')} required>
                                <Input />
                            </FloatingLabel>
                        </Form.Item>

                        <Form.Item
                            name="start_time"
                            messageVariables={{ name: 'Shift start time' }}
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter shift start time')
                                },
                                time()
                            ]}
                        >
                            <FloatingLabel label={t('Shift start time')} required>
                                <TimePicker
                                    endTime={endTime}
                                    onSearch={setStartTime}
                                    onSelect={setStartTime}
                                    showDuration={false}
                                    showIcon={false}
                                    required
                                />
                            </FloatingLabel>
                        </Form.Item>

                        <Form.Item
                            name="end_time"
                            messageVariables={{ name: 'Shift end time' }}
                            rules={[
                                {
                                    required: true,
                                    message: t('Please enter shift end time')
                                },
                                time()
                            ]}
                        >
                            <FloatingLabel label={t('Shift end time')} required>
                                <TimePicker
                                    onSearch={setEndTime}
                                    onSelect={setEndTime}
                                    showDuration={false}
                                    showIcon={false}
                                    startTime={startTime}
                                    required
                                    showNextDay
                                />
                            </FloatingLabel>
                        </Form.Item>

                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name="break_duration"
                                    messageVariables={{ name: 'Break time' }}
                                    rules={[number(), max(maxBreakDuration)]}
                                >
                                    <FloatingLabel label={t('Break time')}>
                                        <Duration
                                            disabled={!startTime || !endTime}
                                            className="break-duration"
                                            handler={breakDurationHandler}
                                            max={maxBreakDuration}
                                        />
                                    </FloatingLabel>
                                </Form.Item>
                            </Col>

                            <Col span={11} offset={2}>
                                <Form.Item
                                    name="break_start_time"
                                    messageVariables={{ name: 'Break start time' }}
                                    rules={[time()]}
                                >
                                    <FloatingLabel label={t('Break start time')}>
                                        <TimePicker
                                            disabled={!breakDuration}
                                            endTime={breakMaxTime || endTime}
                                            showDuration={false}
                                            showIcon={false}
                                            startTime={startTime}
                                            required
                                        />
                                    </FloatingLabel>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isDataSaving}>
                                {t('Save')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div >
    );
};
