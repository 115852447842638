import { useCallback } from 'react';
import { useErrorHandler as useErrorBoundaryHandler } from 'react-error-boundary';
import { FormInstance, notification } from 'antd';
import axios, { AxiosError } from 'axios';
import { map } from 'lodash';
import { NamePath } from 'antd/lib/form/interface';
import { Backend } from 'lib/Http/Backend';
import { useLocalization } from 'lib/Localization';

export interface ApplicationError {
    status: number | undefined;
    message: string | undefined;
}

export const useErrorHandler = () => {
    const handleError = useErrorBoundaryHandler();
    const { t } = useLocalization();

    return useCallback(
        (error: AxiosError | unknown, form?: FormInstance, callback?: (error: ApplicationError) => void) => {
            if (!axios.isAxiosError(error)) {
                return notification.error({
                    message: t('Something went wrong'),
                });
            }

            if (isFormValidationError(error, form)) {
                return formValidationErrorsHandler(error, form as FormInstance);
            }

            if (callback && isApplicationError(error)) {
                return callback({
                    message: error.response?.data,
                    status: error.response?.status,
                });
            }

            if (isSimpleMessageError(error)) {
                const errorsCount = Object.keys(error.response?.data.errors || []).length - 1;

                return notification.error({
                    message: (error.response?.data.error || error.response?.data.message).replace(
                        ':count',
                        errorsCount
                    ),
                });
            }

            return handleError(error);
        },
        [handleError, t]
    );
};

const isSimpleMessageError = (error: AxiosError) => {
    // @ts-ignore
    return Boolean(error.response?.data?.error || error.response?.data?.message);
};

const isApplicationError = (error: AxiosError) => {
    return error.response?.status === Backend.responseStatus.HTTP_UNPROCESSABLE_ENTITY;
};

const isFormValidationError = (error: AxiosError, form?: FormInstance) => {
    // @ts-ignore
    return form && error.response?.data?.errors;
};

const formValidationErrorsHandler = (error: AxiosError, form: FormInstance) => {
    interface ValidationError {
        name: NamePath;
        errors: string[];
    }
    // @ts-ignore
    const validationErrors = error.response?.data?.errors;

    if (form && validationErrors) {
        const getFieldName = (form: FormInstance, fieldPath: string) => {
            // If no field exists at the given path, we deal with a select option and the last array item represents the
            // select option key. We drop the option key such that AntD renders the error message for the correct field.
            if (fieldPath.includes('.')) {
                const name = fieldPath.split('.');

                return form.getFieldInstance(name) ? name : name.slice(0, -1);
            }

            return fieldPath;
        };
        const fieldErrors: ValidationError[] = map(validationErrors, (errors: string[], fieldPath: string) => ({
            name: getFieldName(form, fieldPath),
            errors: errors,
        }));

        form.setFields(fieldErrors);
    }
};
