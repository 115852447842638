import { GenericObject } from 'shared/Contracts';

export const OpenNotifIcon = ({ className, ...otherProps }: GenericObject) => (
    <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...otherProps}
    >
        <path d="M6 10L13 3" stroke="#A6A9AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 3H13V7" stroke="#A6A9AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M11 10.6L11 13L3 13L3 5L5.4 5"
            stroke="#A6A9AD"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
