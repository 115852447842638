import { useLocalization } from 'lib/Localization';
import { Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import { NotificationTemplate } from 'types/models';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';

type Props = {
    notificationTemplates: NotificationTemplate[];
    openTemplateHandler: (template: NotificationTemplate) => void;
    languageFilterOptions: ColumnFilterItem[];
    isDataLoading: boolean;
};

/**
 * Table to display notification templates
 *
 * @param notificationTemplates
 * @param openTemplateHandler
 * @param languageFilterOptions
 * @param isDataLoading
 * @returns {JSX.Element}
 * @constructor
 */
export const NotificationTemplatesTable = ({
    notificationTemplates,
    openTemplateHandler,
    languageFilterOptions,
    isDataLoading,
}: Props) => {
    const { t } = useLocalization();

    /**
     * Render a cell with action button.
     *
     * @param id {string}
     * @return {*}
     */
    const renderActionCell = (record: NotificationTemplate) => (
        <Space>
            <EditOutlined onClick={() => openTemplateHandler(record)} title={`edit_${record.id}`} />
        </Space>
    );

    const columns: ColumnsType<NotificationTemplate> = [
        {
            title: t('Type'),
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => sortColumn(a, b, 'type'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('type'),
        },
        {
            title: t('Language'),
            dataIndex: 'language_id',
            key: 'language_id',
            filters: languageFilterOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, record) => record.language_id === value,
            render: (language_id, record) => record.language?.name,
        },
        {
            title: t('Action'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={notificationTemplates}
            columns={columns}
            pagination={getDefaultPaginationProp(notificationTemplates.length)}
            loading={isDataLoading}
        />
    );
};
