import { AutoComplete } from 'antd';
import { GenericObject } from 'shared/Contracts';
import './style.scss';

type Props = GenericObject & {
    className?: string | undefined;
    handler: (value: number) => void;
    max?: number;
    placeholder?: string;
    value?: string;
};

const allOptions = [10, 15, 30, 45, 60];

export const Duration = ({ className, handler, max = 60, value, ...otherProps }: Props) => {
    const changeHandler = (value: string) => {
        const stripNonDigits = value.replace(/\D/g, '');
        handler(+stripNonDigits);
    };

    const options = allOptions.filter((value) => value < max).map((value) => ({ value: `${value}` }));

    return (
        <AutoComplete
            className={`duration-autocomplete ${className}`}
            options={options}
            filterOption={(inputValue, option) => option!.value.includes(inputValue)}
            onSearch={(value: string) => changeHandler(value)}
            onSelect={(value: string) => changeHandler(value)}
            value={value}
            {...otherProps}
        />
    );
};
