import { useErrorHandler } from 'lib/ErrorHandling';
import { getCurrentLocale, I18n, loadLocale } from 'lib/Localization';
import { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { CompatRoute, CompatRouter } from 'react-router-dom-v5-compat';
import 'lib/antNotificationConfig';
import { AccountContext, Authentication, PrivateRoute } from 'lib/Account';
import { Login, Register, ResetPassword } from 'pages/Authentication';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Layout } from 'shared/Layout';
import { AdminSection } from 'pages/Admin';
import { ClientSection } from 'pages/Client';
import { ProviderSection } from 'pages/Provider';
import { Welcome } from 'pages/Onboarding/Welcome';
import { Onboarding } from 'shared/Account/UserProfile/Onboarding';
import { VerifyEmail } from 'pages/Authentication/VerifyEmail';
import { Result } from 'antd';
import { AccountUser, Locale, OrganizationType } from 'types';

import 'assets/style/index.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { SetupGuidePopup } from 'shared/SetupGuidePopup/SetupGuidePopup';
import { Backend } from 'lib/Http/Backend';
import { User } from 'types/models/User';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const App = () => {
    const handleError = useErrorHandler();
    const [cookies] = useCookies<string, { locale: string }>([]);
    const [user, setUser] = useState<AccountUser>(Authentication.guestUser as AccountUser);
    const [loadingInitialData, setLoadingInitialData] = useState(true);
    const [showSetupGuide, setShowSetupGuide] = useState(true);
    const [userData, setUserData] = useState<User | null>(null);
    // We get an error while building in the bitbucket CI/CD pipeline that
    // we can not reproduce locally
    // "Property 'locale' does not exist on type '{}'.  TS2339"
    // TODO: remove the ignore statement below when we find the problem.
    // @ts-ignore
    const currentLocale: Locale = getCurrentLocale(cookies.locale);

    useEffect(() => {
        const loadUserAndOrganization = async () => {
            // On first render fetch and set the initial user
            // This will be either the authenticated user or the generic guest (unauthenticated) user
            try {
                const initialUser = await Authentication.getInitialUser();
                setUser(initialUser);

                if (initialUser.isAuthenticated) {
                    setUserData({ ...initialUser.profile, email: initialUser.username })
                    setShowSetupGuide(!initialUser.tutorial_completed && initialUser.isAuthenticated);
                }
            } catch (error: any) {
                if (error.response?.status !== 404) {
                    // If we cannot get the initial user.
                    handleError(error);
                }
            }
        };
        Promise.all([loadUserAndOrganization(), loadLocale(currentLocale.shortCode)])
            .catch((error) => handleError(error))
            .finally(() => setLoadingInitialData(false));
    }, [handleError, currentLocale.shortCode]);

    if (loadingInitialData) {
        return <LoaderSpin />;
    }

    return (
        <AccountContext.Provider value={{ accountUser: user, setAccountUser: setUser }}>
            <I18n>
                <BrowserRouter>
                    <CompatRouter>
                        <Suspense fallback={<LoaderSpin />}>
                            <Switch>
                                <CompatRoute path="/login">
                                    <Login />
                                </CompatRoute>

                                <CompatRoute path="/register/client/welcome">
                                    <Welcome userType={OrganizationType.CLIENT} />
                                </CompatRoute>

                                <CompatRoute path="/register/provider/welcome">
                                    <Welcome userType={OrganizationType.PROVIDER} />
                                </CompatRoute>

                                <CompatRoute path="/register/:token" component={Register} />

                                <CompatRoute path="/reset-password">
                                    <ResetPassword />
                                </CompatRoute>

                                <CompatRoute path="/verify-email">
                                    <VerifyEmail />
                                </CompatRoute>

                                <PrivateRoute
                                    path="/onboarding"
                                    requirePermissions={['access-client', 'access-provider']}
                                >
                                    <Onboarding />
                                </PrivateRoute>

                                <PrivateRoute path="/admin" requirePermissions={['access-admin']}>
                                    <Layout section={AdminSection} />
                                </PrivateRoute>

                                <PrivateRoute path="/client" requirePermissions={['access-client']}>
                                    <Layout section={ClientSection} />
                                </PrivateRoute>

                                <PrivateRoute path="/provider" requirePermissions={['access-provider']}>
                                    <Layout section={ProviderSection} />
                                </PrivateRoute>

                                {/*If we cannot match any route try to redirect to users home path*/}
                                <CompatRoute path="/">
                                    <Redirect to={Authentication.determineUserHomePath(user)} />
                                </CompatRoute>

                                {/*Fallback empty page - has to be last in routes list*/}
                                <CompatRoute>
                                    <Result status="404" title="Are you lost?" subTitle="This page does not exist." />
                                </CompatRoute>
                            </Switch>
                        </Suspense>
                        {showSetupGuide && userData && (
                            <SetupGuidePopup open={true} onClose={() => setShowSetupGuide(false)} profile={userData} />
                        )}
                    </CompatRouter>
                </BrowserRouter>
            </I18n>
        </AccountContext.Provider>
    );
};
