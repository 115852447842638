import { NotificationsList } from './NotificationsList';
import { Popover } from 'antd';
import { GenericObject } from '../Contracts';
import { useState } from 'react';

type Props = GenericObject & {
    children: JSX.Element;
}

export const NotificationsPopover = ({ children, ...otherProps }: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover
            content={ <NotificationsList closePopover={ () => setOpen(false) } /> }
            onOpenChange={ setOpen }
            open={ open }
            overlayClassName="notifications-popup"
            { ...otherProps }>
            { children }
        </Popover>
    );
};
