import 'shared/AntDesignUtils/CheckboxSelection/CheckboxSelection.scss';
import { Checkbox } from 'antd';

type Props = {
    fullSelected?: boolean;
    partialSelected?: boolean;
    noneSelected?: boolean;
    title?: string;
    text?: string;
    onChange?: (checked: boolean) => void;
};

export const CheckboxSelection = ({
    fullSelected = false,
    partialSelected = false,
    noneSelected = true,
    title = '',
    text = '',
    onChange = (checked) => {},
}: Props) => {
    const toggleChecked = (e: any) => {
        onChange(!((fullSelected || partialSelected) && !noneSelected));
        e.stopPropagation();
    };

    const checkedInput = () => fullSelected || partialSelected;

    return (
        <span className="checkbox-container">
            {text}
            <span title={title}>
                <Checkbox
                    onChange={(e) => toggleChecked(e)}
                    className="checkbox-input"
                    checked={checkedInput()}
                    style={!noneSelected && !fullSelected ? { display: 'none' } : undefined}
                />
                <Checkbox
                    indeterminate={true}
                    onChange={(e) => toggleChecked(e)}
                    className="checkbox-input partial-selection"
                    style={!partialSelected ? { display: 'none' } : undefined}
                />
            </span>
        </span>
    );
};
