import { Backend } from 'lib/Http/Backend';
import { AdminSection } from 'pages/Admin';
import { ClientSection } from 'pages/Client';
import { ProviderSection } from 'pages/Provider';
import { AccountUser, GuestUser, LoginCredentials, MemberType, ResetPassword } from 'types';
import { User } from 'types/models';

const guestUser: GuestUser = {
    isAuthenticated: false,
    username: 'Guest',
    type: 'guest',
};

/**
 * @returns {Promise<{name: string, isAuthenticated: boolean, type: string, username: string}>}
 */
const login = async (credentials: LoginCredentials) => {
    await Backend.get('/sanctum/csrf-cookie');

    const loginResponse = await Backend.post('/account/login', credentials);

    if (loginResponse.status === Backend.responseStatus.HTTP_OK) {
        return {
            isAuthenticated: true,
            ...loginResponse.data,
        };
    }
};

const logout = async () => {
    const logoutResponse = await Backend.post('/account/logout');

    return logoutResponse.status < 300;
};

/**
 * Request a reset password link.
 */
const forgot = async (credentials: LoginCredentials) => {
    const response = await Backend.post('/account/forgot', credentials);

    return response.status < 300;
};

/**
 * Reset the password for a user.
 */
const reset = async (resetPasswordData: ResetPassword) => {
    const response = await Backend.post('/account/reset', resetPasswordData);

    return response.status < 300;
};

const register = async (userData: User, token: string) => {
    const response = await Backend.post(`/account/register/${token}`, userData);

    if (response.status === Backend.responseStatus.HTTP_CREATED) {
        return {
            isAuthenticated: true,
            ...response.data,
        };
    } else {
        throw response;
    }
};

/**
 * Get the logged in user. Returns `null` if user not logged in.
 * Can be used to check if user is logged in.
 *
 * @returns {Promise<null|{}>}
 */
const getLoggedInUser = async () => {
    const accountUserResponse = await Backend.get('/account/user');

    // If we get 404 back it means the user is not logged in.
    if (accountUserResponse.status === Backend.responseStatus.HTTP_BAD_REQUEST) {
        return null;
    }

    // We got an authenticated user so we set the authenticated flag and return the data.
    return {
        isAuthenticated: true,
        ...accountUserResponse.data,
    };
};

/**
 * Get the initial user of the application.
 *
 * Checks with the backend for a valid session and if it exists it returns the logged in user.
 * If not it returns the default unauthenticated user aka 'guest'
 *
 * @returns {Promise<{name: string, isAuthenticated: boolean, type: string, username: string}>}
 */
const getInitialUser = async () => {
    const loggedInUser = await getLoggedInUser();

    return loggedInUser || guestUser;
};

const organizationOrDefaultRoute = (accountUser: AccountUser | GuestUser, defaultRoute: string) => {
    // checks that the organization required fields have been filled
    if ('organization' in accountUser && accountUser.organization.zip_code) {
        return defaultRoute;
    }

    return 'configuration/organization';
};

/**
 * Determine the home path of the user depending on the roles of the user.
 *
 * @param {Object} accountUser
 *
 * @returns {string} the home path
 */
const determineUserHomePath = (accountUser: AccountUser | GuestUser) => {
    switch (accountUser.type) {
        case AdminSection.userType:
            return `/${ AdminSection.identifier }`;
        case ClientSection.userType:
            return `/${ ClientSection.identifier }/${ organizationOrDefaultRoute(accountUser, 'requests') }`;
        case ProviderSection.userType:
            return `/${ ProviderSection.identifier }/${ organizationOrDefaultRoute(accountUser, 'requests') }`;
        case 'guest':
            return '/login';
        default:
            throw new Error(`Invalid user type '${accountUser.type}' encountered while determining home path`);
    }
};

/**
 * Determine the type [agent/contact/admin] of the current logged user.
 *
 * @param {Object} accountUser
 *
 * @returns {string} the user type
 */
const getCurrentUserMemberType = (accountUser: AccountUser): MemberType => {
    // if (accountUser.types.length === 1) {
    return accountUser.types[0] as MemberType;
    // }
    // TODO: manage multiple user types
};

const determineUserWelcomePath = (accountUser: AccountUser | GuestUser) => {
    switch (accountUser.type) {
        case AdminSection.userType:
            return `/${AdminSection.identifier}`;
        case ClientSection.userType:
            return `/register/${ClientSection.identifier}/welcome`;
        case ProviderSection.userType:
            return `/register/${ProviderSection.identifier}/welcome`;
        case 'guest':
            return '/login';
        default:
            throw new Error(`Invalid user type '${accountUser.type}' encountered while determining welcome path`);
    }
};

export const Authentication = {
    guestUser,
    login,
    logout,
    forgot,
    reset,
    register,
    getInitialUser,
    determineUserHomePath,
    getCurrentUserMemberType,
    determineUserWelcomePath,
};
