import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { NotificationTemplatesTable } from './NotificationTemplatesTable';
import { NotificationTemplateForm } from './NotificationTemplateForm';
import { useErrorHandler } from 'lib/ErrorHandling';
import { NotificationTemplate } from 'types/models';
import { useCollections } from 'lib/Contexts/Collections';

/**
 * Stateful component responsible for communication data management.
 *
 */
export const Communication = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [notificationTemplates, setNotificationTemplates] = useState([]);
    const [notificationTemplate, setNotificationTemplate] = useState<NotificationTemplate>();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [collections] = useCollections();

    /**
     * Fetch notification templates from backend.
     *
     * @returns {Promise<void>}
     */
    const loadNotificationTemplatesData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const response = await Backend.get('/data-management/notification-templates?relations=language');
            setNotificationTemplates(response.data.notificationTemplates);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    /**
     * After the component renders
     */
    useEffect(() => {
        loadNotificationTemplatesData();
    }, [loadNotificationTemplatesData]);

    /**
     * Open the notification template editing form
     *
     * @param id
     */
    const openTemplateHandler = (record: NotificationTemplate) => {
        setNotificationTemplate(record);
        setIsFormOpen(true);
    };

    const languageFilterOptions = collections.languages.map((language) => ({
        value: language.id,
        text: language.name,
    }));

    return (
        <PageHeader title={t('Notification Templates')} backIcon={false} ghost={false}>
            <NotificationTemplatesTable
                notificationTemplates={notificationTemplates}
                openTemplateHandler={openTemplateHandler}
                languageFilterOptions={languageFilterOptions}
                isDataLoading={isDataLoading}
            />
            <Drawer title={t('Edit translation')} width={700} onClose={() => setIsFormOpen(false)} open={isFormOpen}>
                {notificationTemplate ? (
                    <NotificationTemplateForm
                        notificationTemplate={notificationTemplate}
                        loadNotificationTemplatesData={loadNotificationTemplatesData}
                        setIsFormOpen={setIsFormOpen}
                    />
                ) : null}
            </Drawer>
        </PageHeader>
    );
};
