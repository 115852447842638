import { Drawer, Tabs, Badge } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Authentication, useAccount } from 'lib/Account';
import { useLocalization } from 'lib/Localization';
import {
    InvitationButton,
    InvitationForm,
    InvitationsTable,
    MyInvitationsTable
} from 'shared/Organizations/Organization/Invitation';
import { OrganizationDetails } from 'shared/Organizations/Organization/OrganizationDetails';
import { OrganizationsTable } from 'shared/Organizations/OrganizationsTable';
import { Backend } from 'lib/Http/Backend';
import { getOrganizationTypeFromMemberType } from 'lib/Organization';
import { getOppositeMemberType } from 'lib/Organization/Member';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'lib/ErrorHandling';
import { ConnectedOrganizationsMembersTable } from './ConnectedOrganizationsMembersTable';
import { MemberType, OrganizationType } from 'types';
import { Contact, Organization, Provider } from 'types/models';
import useQueryParameters from 'lib/Http/QueryParameters';
import { useNavigate } from 'react-router-dom-v5-compat';

/**
 * Container component responsible for data management of organizations connected to a given client/provider.
 */
export const ConnectedOrganizations = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const queryParameters = useQueryParameters();
    const navigate = useNavigate();
    const { accountUser: user } = useAccount();
    const memberType = Authentication.getCurrentUserMemberType(user);
    const oppositeMemberType = getOppositeMemberType(memberType) as MemberType;
    const organizationType = getOrganizationTypeFromMemberType(memberType) as OrganizationType;
    const oppositeOrganizationType = getOrganizationTypeFromMemberType(oppositeMemberType);
    const oppositeOrganizationTypeTrans =
        oppositeOrganizationType === OrganizationType.PROVIDER ? t('Providers') : t('Clients');
    let member = user.profiles[memberType] as Contact | Provider;
    let organizationId: number;
    if (memberType === MemberType.CONTACT) {
        organizationId = (member as Contact).client_id;
    } else {
        organizationId = (member as Provider).provider_id;
    }
    const urlRelations = [
        `${oppositeOrganizationType}s`,
        `${oppositeOrganizationType}s.${oppositeMemberType}s`,
        `${oppositeOrganizationType}s.${oppositeMemberType}s.user`,
        `${oppositeOrganizationType}s.country`,
        `${oppositeOrganizationType}s.professions`,
        `${oppositeOrganizationType}s.professions.category`,
    ];
    const url = `/data-management/${organizationType}s/${organizationId}?relations=${urlRelations.join()}`;
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [connectedOrganizations, setConnectedOrganizations] = useState([]);
    const [isDetailedOpen, setIsDetailedOpen] = useState(false);
    const [isInvitationDrawerOpen, setIsInvitationDrawerOpen] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const [userInvitations, setUserInvitations] = useState([]);
    const [isInvitationsLoading, setIsInvitationsLoading] = useState(false);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>();

    const loadInvitationsData = useCallback(async () => {
        try {
            setIsInvitationsLoading(true);
            const invitationsResponse = await Backend.get('/account/invitations');
            setInvitations(invitationsResponse.data.invitations);

            const userInvitationsResponse = await Backend.get('/account/userInvitations');
            setUserInvitations(userInvitationsResponse.data.invitations);
        } catch (error) {
            handleError(error);
        } finally {
            setIsInvitationsLoading(false);
        }
    }, [handleError]);

    const closeInvitationDrawer = () => {
        setIsInvitationDrawerOpen(false);

        if (queryParameters.has('invitation')) {
            navigate(window.location.pathname, { replace: true });
        }
    };

    useEffect(() => {
        const loadConnectedOrganizationsData = async () => {
            try {
                setIsDataLoading(true);
                const response = await Backend.get(url);
                setConnectedOrganizations(response.data[`${organizationType}`][`${oppositeOrganizationType}s`] || []);
            } catch (error) {
                handleError(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        loadConnectedOrganizationsData();
        loadInvitationsData();
    }, [url, loadInvitationsData, organizationType, oppositeOrganizationType, handleError]);

    useEffect(() => {
        setIsInvitationDrawerOpen(
            isInvitationDrawerOpen || decodeURIComponent(queryParameters.get('invitation') || '') === 'open'
        );
    }, [isInvitationDrawerOpen, queryParameters, setIsInvitationDrawerOpen]);

    /**
     * After invitation is sent, close the drawer and reload invitations.
     */
    const invitationSentHandler = () => {
        closeInvitationDrawer();

        loadInvitationsData();
    };

    const showOrganizationDetailsHandler = (organizationId: number) => {
        setSelectedOrganizationId(organizationId);
        setIsDetailedOpen(true);
    };

    const connectedOrganization = (_.find(connectedOrganizations, { id: selectedOrganizationId }) || {
        company_name: '',
    }) as Organization;

    const headerButtons = [
        <InvitationButton
            key="1"
            openInvitationForm={() => setIsInvitationDrawerOpen(true)}
            organizationType={oppositeOrganizationType}
        />,
    ];

    const myInvitationsTabLabel = (
        <Badge count={userInvitations.length} overflowCount={99} offset={[20, 7]} color="#1677ff" showZero>
            {t('My invitations')}
        </Badge>
    );

    const connectedOrganizationsTabItems = [
        {
            label: oppositeOrganizationTypeTrans,
            key: '1',
            children: (
                <OrganizationsTable
                    organizations={connectedOrganizations}
                    showOrganizationDetailsHandler={showOrganizationDetailsHandler}
                    isDataLoading={isDataLoading}
                />
            ),
        },
        {
            label: myInvitationsTabLabel,
            key: '2',
            children: (
                <MyInvitationsTable
                    invitations={userInvitations}
                    isInvitationsLoading={isInvitationsLoading}
                    organizationType={organizationType}
                    loadInvitations={loadInvitationsData}
                />
            ),
        },
        {
            label: t(`Invitations sent`),
            key: '3',
            children: (
                <InvitationsTable
                    invitations={invitations}
                    isInvitationsLoading={isInvitationsLoading}
                    loadInvitations={loadInvitationsData}
                />
            ),
        },
    ];

    const organizationDetailsTabItems = [
        {
            label: t('General'),
            key: '1',
            children: <OrganizationDetails organization={connectedOrganization} />,
        },
        {
            label: t(`${_.capitalize(oppositeMemberType)}s`),
            key: '2',
            children: <ConnectedOrganizationsMembersTable profiles={connectedOrganization[`${oppositeMemberType}s`]} />,
        },
    ];

    return (
        <>
            <PageHeader title={oppositeOrganizationTypeTrans} backIcon={false} ghost={false} extra={headerButtons}>
                <Tabs items={connectedOrganizationsTabItems} />
            </PageHeader>

            <Drawer
                title={t(`${_.capitalize(oppositeOrganizationType)} details`)}
                width={500}
                onClose={() => setIsDetailedOpen(false)}
                open={isDetailedOpen}
            >
                {isDetailedOpen ? <Tabs items={organizationDetailsTabItems} /> : null}
            </Drawer>

            <Drawer
                title={t(`Invite ${oppositeOrganizationType}`)}
                width={500}
                onClose={() => closeInvitationDrawer()}
                open={isInvitationDrawerOpen}
            >
                <InvitationForm
                    memberType={oppositeMemberType}
                    currentMemberType={memberType}
                    invitationSentHandler={() => invitationSentHandler()}
                />
            </Drawer>
        </>
    );
};