import 'react-day-picker/style.css';
import 'shared/EnhancedDayPicker/EnhancedDayPicker.scss';
import { MouseEvent, useEffect, useState } from 'react';
import { DayPicker, ModifierStatus, WeekNumberClickEventHandler } from 'react-day-picker';
import {
    datesIncludeDate,
    diffDates,
    getDatesBetween,
    sortDates,
    uniqueDates as getUniqueDates
} from 'lib/Helpers/DateTimeHelper';
import { addDays, isBefore, subDays } from 'date-fns';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useLocalization } from 'lib/Localization';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { Caption } from './customComponents';
import { InfoCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title } = Typography;

const dynamicLocaleImport = async (localeShortCode: string) => {
    try {
        const locale = await import(`date-fns/locale/${localeShortCode}`);

        return locale.default;
    } catch (error) {}
};

type Props = {
    initialSelectedDates: Date[];
    multiple?: boolean;
    onChange: (dates: Date[]) => void;
    setFooterError?: Function;
};

export const EnhancedDayPicker = ({ initialSelectedDates = [], multiple = true, onChange, setFooterError }: Props) => {
    const fromDate = initialSelectedDates[0] && initialSelectedDates[0].getTime() < Date.now() ?
        initialSelectedDates[0] :
        new Date();
    fromDate.setHours(0, 0, 0, 0); // initialize to the nearest midnight in the past
    const {
        t,
        locale: { shortCode: localeShortCode },
    } = useLocalization();
    const [shiftPressed, setShiftPressed] = useState(false);
    const [locale, setLocale] = useState();
    const [days, setDays] = useState<Date[]>(initialSelectedDates);
    const [month, setMonth] = useState<Date>(fromDate);
    const [mouseHoverDay, setMouseHoverDay] = useState<Date | null>(null);
    const [range, setRange] = useState<{
        from: Date | null;
        to: Date | null;
    }>({
        from: null,
        to: null,
    });
    const { from, to } = range;
    const modifiers = {
        start: from,
        end: mouseHoverDay,
        inRange: from && mouseHoverDay ? { from: addDays(from, 1), to: subDays(mouseHoverDay, 1) } : undefined,
    };

    const updateFooterError = (footerMessage: string | undefined) => {
        setFooterError?.(footerMessage);
    };

    useEffect(() => {
        dynamicLocaleImport(localeShortCode).then((locale) => setLocale(locale));
    }, [localeShortCode]);

    useEffect(() => {
        setDays(initialSelectedDates);
    }, [initialSelectedDates]);

    /**
     * Handle the dates selection/deselection
     * @param dates
     */
    const selectDatesHandler = (dates: Date[]) => {
        // remove dates prior to fromDate
        let selectedDates = dates.filter((d) => d.getTime() >= fromDate.getTime());
        let allSelectedDates;

        if (!multiple) {
            allSelectedDates = dates;
        } else if (selectedDates.some((date) => !datesIncludeDate(days, date))) {
            allSelectedDates = sortDates(getUniqueDates([...days, ...selectedDates]));
        } else {
            allSelectedDates = diffDates(days, selectedDates);
        }

        setDays(allSelectedDates);
        onChange(allSelectedDates);
    };

    /**
     * Handle the week number click
     *
     * @param weekNumber
     * @param dates
     * @param e
     */
    const weekNumberClickHandler: WeekNumberClickEventHandler = (weekNumber, dates, e: MouseEvent) => {
        e.preventDefault();
        selectDatesHandler(dates);
    };

    /**
     * Check whether the selected day is the first of the range
     *
     * @param from
     * @param to
     * @param day
     */
    const isSelectingFirstDay = (from: Date | null, to: Date | null, day: Date) => {
        const isBeforeFirstDay = from && isBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    };

    /**
     * Handle both the single day selection mode and range days selection mode
     *
     * @param day
     * @param modifiers
     * @param e
     */
    const handleDayClick = (day: Date, modifiers: ModifierStatus, e: MouseEvent) => {
        e.preventDefault();

        if (shiftPressed) {
            // range days selection mode
            if (from && to && day >= from && day <= to) {
                return;
            }
            if (isSelectingFirstDay(from, to, day)) {
                setRange({
                    from: day,
                    to: null,
                });

                updateFooterError(t('Select the last day'));
            } else {
                setRange({
                    ...range,
                    to: day,
                });
                setMouseHoverDay(day);

                updateFooterError(t('Release the Shift key'));
            }
        } else {
            // single day selection mode
            selectDatesHandler([day]);
        }
    };

    const handleDayMouseEnter = (day: Date) => {
        if (!isSelectingFirstDay(from, to, day) && shiftPressed) {
            setMouseHoverDay(day);
        }
    };

    /**
     *  Handle the Shift key up event
     */
    const shiftUpHandler = () => {
        if (from && to) {
            selectDatesHandler(getDatesBetween(from, to));
        }
        setRange({
            from: null,
            to: null,
        });
        setMouseHoverDay(null);
        setShiftPressed(false);

        updateFooterError(t('Hold the Shift key to select a range of days'));
    };

    const shiftDownHandler = () => {
        setShiftPressed(true);

        updateFooterError(rangeFooterMessage());
    };

    const rangeFooterMessage = () => {
        if (!range.from) return t('Select the first day');
        else if (!range.to) return t('Select the last day');

        return t('Release the Shift key');
    };

    const selectedDatesMessage =
        days.length === 0
            ? t('Select assignment dates')
            : t('1 day selected |||| %{smart_count} days selected', { smart_count: days.length });

    const clearDatesHandler = () => {
        selectDatesHandler(!multiple ? [] : days);
    };

    return (
        <>
            {multiple && (
                <>
                    <KeyboardEventHandler
                        handleKeys={['shift']}
                        handleEventType="keydown"
                        onKeyEvent={shiftDownHandler}
                        handleFocusableElements
                    />
                    <KeyboardEventHandler
                        handleKeys={['shift']}
                        handleEventType="keyup"
                        onKeyEvent={shiftUpHandler}
                        handleFocusableElements
                    />
                </>
            )}
            <Row className="enhanced-day-picker">
                <Col md={12}>
                    <Title className="subsection-title" level={5}>
                        <Space>
                            {t('Date & Time')}

                            {multiple && (
                                <Tooltip
                                    color="black"
                                    placement="bottomLeft"
                                    title={t('Hold the Shift key to select a range of days')}
                                >
                                    <InfoCircleFilled/>
                                </Tooltip>
                            )}
                        </Space>
                    </Title>
                </Col>
                <Col md={12} className="text-right">
                    {days.length === 0 && (
                        <p className="subsection-subtitle">
                            <span className="selected-dates">{selectedDatesMessage}</span>
                        </p>
                    )}
                    {days.length > 0 && (
                        <Space className="subsection-subtitle">
                            {multiple && <span className="selected-dates selected-days">{selectedDatesMessage}</span>}
                            <span className="span-link" onClick={clearDatesHandler}>
                                {t('Clear date & Time')}
                            </span>
                        </Space>
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DayPicker
                        mode="uncontrolled"
                        disabled={(date) => {
                            return dayjs(date).format('DD-MM-YYYY') !== dayjs(initialSelectedDates[0]).format('DD-MM-YYYY')
                                && date.getTime() < Date.now();
                        }}
                        selected={days}
                        // @ts-ignore
                        modifiers={shiftPressed ? modifiers : undefined}
                        onDayClick={handleDayClick}
                        showWeekNumber={false}
                        onWeekNumberClick={weekNumberClickHandler}
                        fromDate={fromDate}
                        onDayMouseEnter={handleDayMouseEnter}
                        locale={locale}
                        month={month}
                        onMonthChange={setMonth}
                        components={{ Caption }}
                        numberOfMonths={2}
                        fixedWeeks
                        showOutsideDays
                    />
                </Col>
            </Row>
        </>
    );
};
