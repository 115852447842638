import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, Descriptions, Drawer, Form, notification, Tabs, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CompassOutlined, PhoneOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { useLocalization } from 'lib/Localization';
import { OrganizationForm } from './OrganizationForm';
import { MemberForm, MembersTable } from 'shared/Organizations/Organization/Member';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { Backend } from 'lib/Http/Backend';
import { useAccount } from 'lib/Account';
import { getMemberTypeFromOrganizationType } from 'lib/Organization/Member';
import { useErrorHandler } from 'lib/ErrorHandling';
import { MemberType, OrganizationType } from 'types';
import { Contact, Member, Organization as OrganizationModel, Provider, Role } from 'types/models';
import { FormInstance } from 'antd/lib/form';
import { useCollections } from 'lib/Contexts/Collections';

type Props = {
    organizationType: OrganizationType;
};

export const Organization = ({ organizationType }: Props) => {
    const { t } = useLocalization();
    const { accountUser: user, setAccountUser } = useAccount();
    const [categories, setCategories] = useState([]);
    const organizationsUrl = `/data-management/${organizationType}s`;
    const memberType = getMemberTypeFromOrganizationType(organizationType);
    const membersUrl = `/data-management/${memberType}s`;
    let memberProfile = user.profiles[memberType] as Contact | Provider;

    let organizationId: number;
    if (memberType === MemberType.CONTACT) {
        organizationId = (memberProfile as Contact).client_id;
    } else {
        organizationId = (memberProfile as Provider).provider_id;
    }

    const [organizationForm] = Form.useForm();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isOrganizationSaving, setIsOrganizationSaving] = useState(false);
    const [isMemberSaving, setIsMemberSaving] = useState(false);
    const [organization, setOrganization] = useState<OrganizationModel>({} as OrganizationModel);
    const [member, setMember] = useState<Member>();
    const [members, setMembers] = useState([]);
    const [isMemberFormOpen, setIsMemberFormOpen] = useState(false);
    const handleError = useErrorHandler();
    const [collections] = useCollections();

    const loadOrganizationData = useCallback(async () => {
        try {
            setIsDataLoading(true);

            const { data } = await Backend.get(
                `${organizationsUrl}/${organizationId}?relations=${memberType}s,${memberType}s.user.roles,categories`
            );
            const organization = data[organizationType];

            setCategories(data.categories);

            setAccountUser({ ...user, organization });

            setOrganization(organization);
            setMembers(organization[`${memberType}s`] || []);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [organizationsUrl, organizationId, memberType, organizationType, handleError]);

    useEffect(() => {
        loadOrganizationData();
    }, [loadOrganizationData]);

    const saveOrganizationHandler = async (values: OrganizationModel) => {
        try {
            setIsOrganizationSaving(true);

            const response = await Backend.put(`${organizationsUrl}/${organizationId}`, {
                ...values,
                address_line_2: values.address_line_2 ?? null,
                user_id: user.id,
            });

            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Data successfully saved'),
                });

                loadOrganizationData();
            }
        } catch (error) {
            handleError(error, organizationForm);
        } finally {
            setIsOrganizationSaving(false);
        }
    };

    /**
     * Open the member form to create/update a member.
     */
    const openMemberFormHandler = (member?: Member) => {
        setMember(member);
        setIsMemberFormOpen(true);
    };

    const saveMemberHandler = async (member: Member, form?: FormInstance): Promise<boolean> => {
        let saved = true;

        // Update, else create new.
        try {
            setIsMemberSaving(true);

            if (member.id) {
                const response = await Backend.put(`${membersUrl}/${member.id}`, member);

                if (response.status === Backend.responseStatus.HTTP_OK) {
                    notification.success({
                        message: t('Member successfully saved'),
                    });
                    setIsMemberFormOpen(false);
                }
            } else {
                const response = await Backend.post(membersUrl, member);

                if (response.status === Backend.responseStatus.HTTP_CREATED) {
                    notification.success({
                        message: t('Member successfully created'),
                    });
                    setIsMemberFormOpen(false);
                }
            }

            loadOrganizationData();
        } catch (error) {
            handleError(error, form);
            saved = false;
        } finally {
            setIsMemberSaving(false);
        }

        return saved;
    };

    const formatMembers = (members: Member[]) =>
        members.map((member) => ({
            ...member,
            key: member.id,
            email: member.user.email || t('N/A'),
            roles: member.user.roles,
        }));

    const countryString = _.get(_.find(collections.countries, { id: organization.country_id }), 'name', t('N/A'));
    const addressString = `${organization.address_line_1},${organization.city},${countryString}`;
    const googleMapsQuery = `https://google.com/maps/dir/?api=1&destination=${addressString}`;
    const tags = [
        <Tag key="1" color="green" icon={<TeamOutlined />}>
            {`${organization[`${memberType}s`]?.length || 0} ${t('Members')}`}
        </Tag>,
    ];

    const openDirections = () => {
        const { city, zip_code, address_line_1, address_line_2 } = organization;
        if (city && zip_code && (address_line_1 || address_line_2)) {
            window.open(encodeURI(googleMapsQuery), '_blank');
            return false;
        } else {
            organizationForm.submit();
        }
    };

    const headerButtons = [
        <Button key="1" icon={<PhoneOutlined />} href={`tel:${organization.phone_number}`}>
            {organization.phone_number}
        </Button>,
        <Button key="2" icon={<CompassOutlined />} onClick={() => openDirections()}>
            {t('Directions')}
        </Button>,
        <Button key="3" type="primary" icon={<PlusOutlined />} onClick={() => openMemberFormHandler()}>
            {t('Add Member')}
        </Button>,
    ];

    const descriptions = (
        <Descriptions size="small" column={1}>
            <Descriptions.Item label={t('Created on')}>
                {organization.created_at === null
                    ? t('N/A')
                    : moment(organization.created_at).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label={t('Updated on')}>
                {organization.updated_at === null
                    ? t('N/A')
                    : moment(organization.updated_at).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
        </Descriptions>
    );

    const countryOptions =
        collections.countries.map((country) => ({
            value: country.id,
            label: t(country.name),
        })) || [];

    const roleOptions =
        collections.roles.map((role) => ({
            value: role.id,
            label: t(role.name),
        })) || [];

    const categoryOptions = categories.map(({ id, name }) => ({
        value: id,
        label: t(name),
    }));

    const getRolesFilterOptions = () => {
        let roles: Role[] = [];

        members.forEach((member: Member) => {
            member.user.roles.map((role: Role) => {
                if (!roles.map((role) => role.id).includes(role.id)) {
                    roles.push(role);
                }
            });
        });
        return roles || [];
    };

    const allRoles = getRolesFilterOptions();

    const roleFilterOptions = allRoles.map((role) => {
        return {
            value: role.id,
            text: t(role.name),
        };
    });

    const tabItems = [
        {
            label: t('Organization'),
            key: '1',
            children: isDataLoading ? (
                <LoaderSkeleton size={2} />
            ) : (
                <OrganizationForm
                    form={organizationForm}
                    organization={organization}
                    categoryOptions={categoryOptions}
                    countryOptions={countryOptions}
                    saveHandler={saveOrganizationHandler}
                    isOrganizationSaving={isOrganizationSaving}
                />
            ),
        },
        {
            label: t('Members'),
            key: '2',
            children: (
                <MembersTable
                    membersUrl={membersUrl}
                    members={formatMembers(members)}
                    roleOptions={roleOptions}
                    roleFilterOptions={roleFilterOptions}
                    saveHandler={saveMemberHandler}
                    openMemberFormHandler={openMemberFormHandler}
                    loadOrganizationData={loadOrganizationData}
                    isDataLoading={isDataLoading}
                    organizationType={organizationType}
                />
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title={organization.company_name || t('Organization')}
                backIcon={false}
                ghost={false}
                tags={tags}
                extra={headerButtons}
            >
                {descriptions}
                <Tabs items={tabItems} />
            </PageHeader>

            <Drawer
                title={member?.id ? t('Edit member') : t('Add Member')}
                width={500}
                onClose={() => setIsMemberFormOpen(false)}
                open={isMemberFormOpen}
            >
                <MemberForm
                    member={member}
                    organizationId={organizationId}
                    organizationType={organizationType}
                    roleOptions={roleOptions}
                    saveHandler={saveMemberHandler}
                    isMemberSaving={isMemberSaving}
                />
            </Drawer>
        </>
    );
};
