export enum JobApplicantState {
    OPEN = 'open',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    PROPOSED = 'proposed',
    REPLACED = 'replaced',
    WITHDRAWN = 'withdrawn',
}

export enum JobApplicantTransition {
    ACCEPT = 'accept',
    REJECT = 'reject',
    PROPOSE = 'propose',
    REPLACE = 'replace',
    WITHDRAW = 'withdraw',
}
