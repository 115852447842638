import axios, { AxiosInstance } from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    withCredentials: true,
    withXSRFToken: true,
    timeout: +(process.env.REACT_APP_REQUEST_TIMEOUT || 10000),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Language':
            new URLSearchParams(document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')).get('locale') || 'en',
    },
    data: {},
});

// Set XDEBUG flag on the URL when in development.
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    axiosInstance.interceptors.request.use((config) => {
        let urlSeparator = '?';
        if (config && config.url?.includes('?')) {
            urlSeparator = '&';
        }
        config.url = `${config.url}${urlSeparator}XDEBUG_SESSION_START=PHPSTORM`;

        return config;
    });
}

const responseStatus = {
    HTTP_OK: 200,
    HTTP_CREATED: 201,
    HTTP_ACCEPTED: 202,
    HTTP_NON_AUTHORITATIVE: 203,
    HTTP_NO_CONTENT: 204,
    HTTP_BAD_REQUEST: 400,
    HTTP_UNAUTHORIZED: 401,
    HTTP_FORBIDDEN: 403,
    HTTP_NOT_FOUND: 404,
    HTTP_METHOD_NOT_ALLOWED: 405,
    HTTP_UNPROCESSABLE_ENTITY: 422,
};

interface Client extends AxiosInstance {
    [index: string]: any;
    responseStatus: {
        HTTP_OK: number;
        HTTP_CREATED: number;
        HTTP_ACCEPTED: number;
        HTTP_NON_AUTHORITATIVE: number;
        HTTP_NO_CONTENT: number;
        HTTP_BAD_REQUEST: number;
        HTTP_UNAUTHORIZED: number;
        HTTP_FORBIDDEN: number;
        HTTP_NOT_FOUND: number;
        HTTP_METHOD_NOT_ALLOWED: number;
        HTTP_UNPROCESSABLE_ENTITY: number;
    };
    setLocaleForQueries: (locale: string) => void;
}

const setLocaleForQueries = (locale: string) => {
    axiosInstance.defaults.headers['Accept-Language'] = locale;
    axiosInstance.defaults.headers.common['Accept-Language'] = locale;
};

// @ts-ignore
const backendHttpClient: Client = {
    responseStatus,
    setLocaleForQueries,
    ...axiosInstance,
};

export { backendHttpClient as Backend };
