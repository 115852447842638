import { Skeleton, Spin } from 'antd';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';
import { GenericObject } from '../Contracts';

type LoaderSpinProps = GenericObject & {
    children?: JSX.Element;
    fontSize?: number;
    label?: string;
    inline?: boolean;
}

/**
 * Render a spinning circular loading animation.
 *
 * @return {*}
 */
export const LoaderSpin = ({ children, fontSize, label, inline, ...otherProps }: LoaderSpinProps) => {
    const inlineStyle: CSSProperties = { fontSize: fontSize || inline ? 18 : 24 };
    const fixedStyle: CSSProperties = {
        fontSize: fontSize || 48,
        position: 'fixed',
        top: '50%',
        left: '50%'
    };

    return (
        <>
            <Spin indicator={<LoadingOutlined style={inline ? inlineStyle : fixedStyle}/>} spinning {...otherProps} />
            {label}
            {children}
        </>
    );
};

/**
 * Render a skeleton of animated lines. Can be used to hint tables and texts.
 *
 * @param size {int} number of times the skeleton is rendered
 *
 * @return {*}
 */
export const LoaderSkeleton = ({ size = 1 }) => (
    <div data-testid="skeleton-loader">
        {_.times(size, (index) => (
            <Skeleton active key={index} />
        ))}
    </div>
);
