import 'pages/Client/Requests/Create/RequestCreationProviders.scss';
import { useLocalization } from 'lib/Localization';
import { Button, Checkbox, Collapse, Form, Radio, Skeleton, Typography } from 'antd';
import { Provider } from 'types/models';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { REQUEST_TYPE_SEQUENTIAL, REQUEST_TYPE_SIMULTANEOUS } from 'pages/Client/Requests/Create/RequestCreation';

const { Title } = Typography;

type Props = {
    enabledProviders: Provider[];
    disabledProviders: Provider[];
    loadingProviders: boolean;
    setSelectedProviders: (selectedProviders: number[]) => void;
    selectedProviders: number[];
};

export const RequestCreationProviders = ({
    enabledProviders,
    disabledProviders,
    loadingProviders,
    setSelectedProviders,
    selectedProviders,
}: Props) => {
    const { t } = useLocalization();
    const { Panel } = Collapse;
    const [disabledProvidersDisplayed, displayDisabledProviders] = useState(false);
    let [allSelected, setAllSelected] = useState(false);

    allSelected = enabledProviders.length === selectedProviders.length;

    const enabledProvidersOptions = enabledProviders.map((provider) => {
        return {
            label: provider.company_name,
            value: provider.id,
        };
    });

    const disabledProvidersOptions = disabledProviders.map((provider) => {
        return {
            label: provider.company_name,
            value: provider.id,
        };
    });

    const onProvidersChange = (providers: CheckboxValueType[]) => {
        setSelectedProviders(providers as number[]);
    };

    return (
        <div className="request-creation-providers">
            <Title className="subsection-title select-providers" level={5}>
                {t('Send request to Providers')}

                <Button
                    type="link"
                    onClick={() => {
                        setSelectedProviders(
                            !allSelected ? enabledProvidersOptions.map(({ value }) => Number(value)) : []
                        );

                        setAllSelected(!allSelected);
                    }}
                >
                    {!allSelected ? t('Select all') : t('Clear selection')}
                </Button>
            </Title>
            {loadingProviders ? (
                <Skeleton />
            ) : (
                <>
                    <Form.Item
                        valuePropName="checked"
                        name="providers"
                        rules={[{ required: true, message: t('Please select Providers') }]}
                    >
                        <Checkbox.Group
                            options={enabledProvidersOptions}
                            onChange={onProvidersChange}
                            value={selectedProviders}
                            className="providers-group"
                        />
                    </Form.Item>
                    {disabledProvidersOptions.length > 0 && (
                        <Collapse
                            ghost
                            onChange={(activePanelsKeys) => displayDisabledProviders(activePanelsKeys.length > 0)}
                        >
                            <Panel
                                showArrow={false}
                                header={
                                    <span className="disabled-providers-message">
                                        {t('These providers do not cover the profession you selected')}
                                        {disabledProvidersDisplayed ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                    </span>
                                }
                                className="disabled-providers-panel"
                                key="disabled_providers_panel"
                            >
                                <Checkbox.Group
                                    options={disabledProvidersOptions}
                                    disabled
                                    className="providers-group"
                                />
                            </Panel>
                        </Collapse>
                    )}
                    {selectedProviders.length > 0 && (
                        <Form.Item name="type" className="request-type-container" hidden={true}>
                            <Radio.Group disabled={selectedProviders.length === 1}>
                                <Radio value={REQUEST_TYPE_SIMULTANEOUS}>{t('Simultaneous request')}</Radio>
                                <Radio value={REQUEST_TYPE_SEQUENTIAL}>{t('Sequential request')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                </>
            )}
        </div>
    );
};
