import { notification } from 'antd';
import { polyglot } from './Polyglot';
import { find } from 'lodash';
import { Backend } from 'lib/Http/Backend';
import { locales } from './Locales';

export function t(key: string, options: object = {}): string {
    return polyglot.t(key, options);
}

export async function addTranslation(translation: string) {
    try {
        const defaultLocale = find(locales, { isDefault: true });
        await Backend.post('/localization/add-translation', {
            key: translation,
            translation,
            locale: defaultLocale?.shortCode ?? 'en',
        });
    } catch (error) {
        notification.error({
            message: t('An error occurred while saving translations'),
        });
    }
}
