import { Suspense } from 'react';
import { Layout, Result } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Request, Requests } from 'pages/Provider/Requests';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Organization } from 'shared/Organizations/Organization';
import { Professions } from 'shared/Configuration/Professions';
import { Clients } from './Clients';
import { OrganizationType } from 'types';
import { Workers } from 'pages/Provider/Workers';
import { Settings } from 'shared/Settings';

type Props = {
    setSidebarRequestNumber: Function;
};

/**
 * Render content of the provider sub application.
 *
 * @return {*}
 */
export const Provider = ({ setSidebarRequestNumber }: Props) => {
    const match = useRouteMatch();

    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Switch>
                    <Route exact path={[`${match.path}/requests`, '/provider']}>
                        <Requests setSidebarRequestNumber={setSidebarRequestNumber} />
                    </Route>
                    <Route path={`${match.path}/requests/:id`}>
                        <Request />
                    </Route>
                    <Route path={`${match.path}/clients`}>
                        <Clients />
                    </Route>
                    <Route path={`${match.path}/workers`}>
                        <Workers/>
                    </Route>
                    <Route path={`${match.path}/configuration/organization`}>
                        <Organization organizationType={OrganizationType.PROVIDER}/>
                    </Route>
                    <Route path={`${match.path}/configuration/professions`}>
                        <Professions/>
                    </Route>
                    <Route path={`${match.path}/settings`}>
                        <Settings/>
                    </Route>

                    {/*Fallback empty page - has to be last in routes list*/}
                    <Route path={`${match.path}`}>
                        <Result
                            status="404"
                            title="Nothing here yet..."
                            subTitle="We have nothing to display here yet... any ideas? 😀"
                        />
                    </Route>
                </Switch>
            </Suspense>
        </Layout.Content>
    );
};
