import { useAccount } from 'lib/Account';
import { MemberType } from 'types';
import { Agent, Contact } from 'types/models';

const useIsProfileSet = () => {
    const { accountUser: user } = useAccount();

    const isAgentOrContact = user.type === MemberType.AGENT || user.type === MemberType.CONTACT;

    return !isAgentOrContact || (user.profile as Agent | Contact).profile_set;
};

export { useIsProfileSet };
