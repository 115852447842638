import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Form, Input, notification, Select, Space } from 'antd';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Profession } from 'types/models';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

const { formLayout, saveButtonLayout } = SingleColumnFormLayout;

type Props = {
    profession?: Profession;
    countryOptions: DefaultOptionType[];
    categoryOptions: DefaultOptionType[];
    loadProfessionData: () => Promise<void>;
    setIsFormOpen: (isFormOpen: boolean) => void;
};

export const ProfessionForm = ({
    profession,
    countryOptions = [],
    categoryOptions = [],
    loadProfessionData,
    setIsFormOpen,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const [isDataSaving, setIsDataSaving] = useState(false);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(profession);
    }, [form, profession]);

    const saveProfessionHandler = async (values: Profession) => {
        try {
            const url = 'data-management/professions';
            setIsDataSaving(true);
            if (values.id) {
                const response = await Backend.put(`${url}/${values.id}`, values);
                if (response.status === Backend.responseStatus.HTTP_OK) {
                    notification.success({
                        message: t('Data successfully saved'),
                    });
                }
            } else {
                const response = await Backend.post(url, values);
                if (response.status === Backend.responseStatus.HTTP_CREATED) {
                    notification.success({
                        message: t('Data successfully created'),
                    });
                }
                form.resetFields();
            }

            await loadProfessionData();
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const handleSave = async (keepFormOpen: boolean) => {
        await form.validateFields();
        await saveProfessionHandler(form.getFieldsValue());
        !keepFormOpen && setIsFormOpen(false);
    };

    return (
        <Form form={form} onFinish={saveProfessionHandler} {...formLayout}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a name'),
                    },
                    {
                        max: 150,
                        message: t('The profession name may not be greater than 150 characters')
                    }
                ]}
            >
                <FloatingLabel label={t('Name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="description"
                rules={[
                    {
                        max: 500,
                        message: t('The profession description may not be greater than 500 characters')
                    }
                ]}
            >
                <FloatingLabel label={t('Description')}>
                    <Input.TextArea rows={3} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="category_id"
                rules={[
                    {
                        required: true,
                        message: t('Please choose a category'),
                    },
                ]}
            >
                <FloatingLabel label={t('Category')} required>
                    <Select placeholder={t('Choose category')} className="multi-select" options={categoryOptions} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="country_id"
                rules={[
                    {
                        required: true,
                        message: t('Please choose a country'),
                    },
                ]}
            >
                <FloatingLabel label={t('Country')} required>
                    <Select placeholder={t('Choose country')} className="multi-select" options={countryOptions} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item {...saveButtonLayout}>
                <Space>
                    <Button
                        type="primary"
                        htmlType="button"
                        loading={isDataSaving}
                        onClick={() => handleSave(false)}
                    >
                        {t('Save')}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="button"
                        loading={isDataSaving}
                        onClick={() => handleSave(true)}
                    >
                        {t('Save and Add')}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
