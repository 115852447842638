import { useAccount } from 'lib/Account';
import { MemberType } from 'types';

const useIsOrganizationalDataComplete = () => {
    const { accountUser: user } = useAccount();

    return user.type === MemberType.ADMIN || user.organization.is_organizational_data_complete;
};

export { useIsOrganizationalDataComplete };
