import { memberTypesMapping } from 'lib/Organization/OrganizationTypesUtils';
import { MemberType, OrganizationType } from 'types';

export const getMemberTypeFromOrganizationType = (organizationType: OrganizationType) => {
    return memberTypesMapping[organizationType];
};

export const getOppositeMemberType = (memberType: MemberType) => {
    if (memberType === MemberType.CONTACT) {
        return MemberType.AGENT;
    }
    if (memberType === MemberType.AGENT) {
        return MemberType.CONTACT;
    }
};
