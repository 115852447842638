import { useErrorHandler } from 'lib/ErrorHandling';
import { MemberType, OrganizationType } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Drawer, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Backend } from 'lib/Http/Backend';
import { OrganizationsTable } from './OrganizationsTable';
import { OrganizationDetails } from 'shared/Organizations/Organization/OrganizationDetails';
import { ConnectedOrganizationsMembersTable } from 'shared/Organizations/ConnectedOrganizations/ConnectedOrganizationsMembersTable';
import _ from 'lodash';
import { Client, Organization, Provider } from 'types/models';
import { InvitationForm } from 'shared/Organizations/Organization';

export const Organizations = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [providers, setProviders] = useState([]);
    const [isDetailedOpen, setDetailedOpen] = useState(false);
    const [organizationType, setOrganizationType] = useState('');
    const [memberType, setMemberType] = useState('');
    const [organization, setOrganization] = useState<Organization>();
    const [isAgentInvitationDrawerOpen, setIsAgentInvitationDrawerOpen] = useState(false);
    const [isContactInvitationDrawerOpen, setIsContactInvitationDrawerOpen] = useState(false);

    /**
     * Fetch clients and providers from backend.
     *
     * @returns {Promise<void>}
     */
    const loadData = useCallback(async () => {
        try {
            setIsDataLoading(true);
            let response = await Backend.get(
                '/data-management/clients?relations=providers,providers.country,country,contacts,contacts.user,professions&withDeleted=true'
            );
            setClients(response.data.clients);
            response = await Backend.get(
                '/data-management/providers?relations=clients,clients.country,country,agents,agents.user,professions&withDeleted=true'
            );
            setProviders(response.data.providers);
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataLoading(false);
        }
    }, [handleError]);

    const openProviderDetail = (provider: Provider) => {
        setMemberType(MemberType.AGENT);
        setOrganizationType(OrganizationType.PROVIDER);
        setOrganization(provider);
        setDetailedOpen(true);
    };

    const openClientDetail = (client: Client) => {
        setMemberType(MemberType.CONTACT);
        setOrganizationType(OrganizationType.CLIENT);
        setOrganization(client);
        setDetailedOpen(true);
    };

    const agentInvitationSentHandler = () => {
        setIsAgentInvitationDrawerOpen(false);
        loadData();
    };

    const contactInvitationSentHandler = () => {
        setIsContactInvitationDrawerOpen(false);
        loadData();
    };

    /**
     * After the component renders
     */
    useEffect(() => {
        loadData();
    }, [loadData]);

    const organizationsTabItems = [
        {
            label: t('Providers'),
            key: '1',
            children: (
                <OrganizationsTable
                    organizationType={OrganizationType.PROVIDER}
                    organizations={providers}
                    oppositeOrganizations={clients}
                    openOrganizationHandler={openProviderDetail}
                    loadData={loadData}
                    isDataLoading={isDataLoading}
                />
            ),
        },
        {
            label: t('Clients'),
            key: '2',
            children: (
                <OrganizationsTable
                    organizationType={OrganizationType.CLIENT}
                    organizations={clients}
                    oppositeOrganizations={providers}
                    openOrganizationHandler={openClientDetail}
                    loadData={loadData}
                    isDataLoading={isDataLoading}
                />
            ),
        },
    ];

    const organizationDetailsTabItems = [
        {
            label: t('General'),
            key: '1',
            children: organization ? <OrganizationDetails organization={organization} /> : null,
        },
        {
            label: t(_.capitalize(`${memberType}s`)),
            key: '2',
            children: organization ? (
                <ConnectedOrganizationsMembersTable profiles={organization[`${memberType}s`]} />
            ) : null,
        },
    ];

    return (
        <>
            <PageHeader
                title={t('Organizations')}
                backIcon={false}
                ghost={false}
                extra={
                    <>
                        <Button type="primary" onClick={() => setIsContactInvitationDrawerOpen(true)}>
                            {t('Invite Client')}
                        </Button>
                        <Button type="primary" onClick={() => setIsAgentInvitationDrawerOpen(true)}>
                            {t('Invite Provider')}
                        </Button>
                    </>
                }
            >
                <Tabs items={organizationsTabItems} />
            </PageHeader>

            <Drawer
                title={t(`${_.capitalize(organizationType)} details`)}
                width={500}
                onClose={() => setDetailedOpen(false)}
                open={isDetailedOpen}
            >
                <Tabs items={organizationDetailsTabItems} />
            </Drawer>

            <Drawer
                title={t(`Invite Provider`)}
                width={500}
                onClose={() => setIsAgentInvitationDrawerOpen(false)}
                open={isAgentInvitationDrawerOpen}
            >
                <InvitationForm
                    memberType={MemberType.AGENT}
                    currentMemberType={MemberType.ADMIN}
                    invitationSentHandler={() => agentInvitationSentHandler()}
                />
            </Drawer>

            <Drawer
                title={t(`Invite Client`)}
                width={500}
                onClose={() => setIsContactInvitationDrawerOpen(false)}
                open={isContactInvitationDrawerOpen}
            >
                <InvitationForm
                    memberType={MemberType.CONTACT}
                    currentMemberType={MemberType.ADMIN}
                    invitationSentHandler={() => contactInvitationSentHandler()}
                />
            </Drawer>
        </>
    );
};
