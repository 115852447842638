import 'shared/AntDesignUtils/TableFilterDropdown/TableFilterDropdown.scss';
import { Input } from 'antd';
import { t } from 'lib/Localization';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useEffect } from 'react';

type Props = {
    backendFilter?: boolean;
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: (param?: FilterConfirmProps) => void;
    clearFilters: () => void;
    dataIndex: string;
    visible: boolean;
};

let confirmTimeout: any;

export const TableFilterDropdown = ({
    backendFilter,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex,
    visible,
}: Props) => {
    useEffect(() => {
        const input = document.querySelector(
            `input.ant-input.search-input.table-filter-search-input.active`
        ) as HTMLElement | null;
        if (input !== null) setTimeout(() => input.focus(), 200);
    }, [visible]);

    return (
        <div className="table-search-filter-dropdown" style={{ padding: 8 }}>
            <Input
                placeholder={`${t('Search')} ${dataIndex.replace('_', ' ')}`}
                value={selectedKeys[0]}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);

                    if (backendFilter) {
                        clearTimeout(confirmTimeout);
                        confirmTimeout = setTimeout(() => confirm({ closeDropdown: false }), 500);

                        return;
                    }

                    confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                    clearTimeout(confirmTimeout);
                    confirm({ closeDropdown: true });
                }}
                className={'search-input table-filter-search-input' + (visible ? ' active' : '')}
            />
        </div>
    );
};
