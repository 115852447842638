import 'shared/Requests/Request/RequestLocation.scss';
import { useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Col, Form, Row, Select as AntSelect, Tooltip } from 'antd';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { Address } from 'types/models';
import { PlusOutlined } from '@ant-design/icons';
import { useRecentItems } from 'lib/hooks';
import { RecentItem } from 'types/RecentItem';

const { Option } = AntSelect;

type Props = {
    addresses: Address[];
    recentAddresses: number[];
    loadingLocations: boolean;
    requestAdress?: number;
    setRequestAdress: (addressId: number) => void;
    onCreateAddressHandler: () => void;
};

export const RequestLocation = ({
    addresses,
    recentAddresses,
    loadingLocations,
    requestAdress,
    setRequestAdress,
    onCreateAddressHandler,
}: Props) => {
    const { t } = useLocalization();
    const { getSelectOptionsFor } = useRecentItems();
    const [form] = Form.useForm();

    const locationsChangeHandler = (addressId: number) => {
        setRequestAdress(addressId);
        form.setFieldsValue({ address_id: addressId });
    };

    const getLocationOptions = () =>
        getSelectOptionsFor('locations', recentAddresses, addresses, ({ id, name, address, city }: RecentItem) => ({
            label: `${name}, ${address}, ${city}`,
            value: id,
        }));

    useEffect(() => {
        if (requestAdress) {
            locationsChangeHandler(requestAdress);
        }
    }, [requestAdress]);

    return (
        <div className="request-location-creation request-location">
            <Row>
                <Col span={24} style={{ maxWidth: '650px' }}>
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col>
                                    <p className="subsection-subtitle">{t('Facility location')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Form form={form}>
                                        <Form.Item
                                            name="address_id"
                                            rules={[{ required: true, message: t('Please select location') }]}
                                        >
                                            <Select
                                                value={requestAdress}
                                                disabled={loadingLocations}
                                                filterOption={(input, option) =>
                                                    (option!.label as unknown as string)
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                onChange={locationsChangeHandler}
                                                optionFilterProp="label"
                                                options={getLocationOptions()}
                                                placeholder={
                                                    loadingLocations ? t('Loading locations...') : t('Choose location')
                                                }
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col xs={4}>
                                    <Tooltip placement="top" title={t('Please click here to configure your addresses')}>
                                        <Button
                                            key="2"
                                            icon={<PlusOutlined />}
                                            onClick={() => onCreateAddressHandler()}
                                            className="creation-buttons"
                                        >
                                            {t('Add location')}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
