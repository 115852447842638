import { createContext, useContext } from 'react';
import { AccountUser } from 'types';

const accountContextData: {
    accountUser: AccountUser;
    setAccountUser: Function;
} = {
    accountUser: {} as AccountUser,
    setAccountUser: () => {},
};

export const AccountContext = createContext(accountContextData);

export function useAccount() {
    return useContext(AccountContext);
}
