import 'pages/Client/Requests/Create/RequestCreationTime.scss';

import { TimePicker } from 'shared/AntDesignUtils/TimePicker';
import { useLocalization } from 'lib/Localization';
import { TimeRangePicker, TimeRangePickerInputObject } from 'shared/AntDesignUtils/TimeRangePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { MinusCircleOutlined, UserOutlined } from '@ant-design/icons';

import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import {
    getStartEndTimesInTheMiddleOfInterval,
    getTimeOptionsForTimePicker,
    timeFormat
} from 'lib/Helpers/DateTimeHelper';
import { Duration } from 'shared/AntDesignUtils/Duration';
import { ShiftsDropdown } from './ShiftsDropdown';
import { WorkShift } from 'types/models';
import { FormRequest } from 'types/staffing';
import { max, required } from 'lib/Validation/validators';
import { AddCommentIcon } from 'shared/icons/AddCommentIcon';

type ShiftInterval = [string | undefined, string | undefined];

type Props = {
    dateField: FormListFieldData;
    form: FormInstance;
    remove?: (index: number | number[]) => void;
    shifts?: WorkShift[];
    saveFormHandler: (changedFormData: FormRequest | null, currentFormData: FormRequest) => void;
    timeField: FormListFieldData;
    timesOnly?: boolean;
};

const MAX_POSSIBLE_SHIFTS = 50;

export const RequestCreationTime = ({
    dateField,
    form,
    remove,
    shifts = [],
    saveFormHandler,
    timeField,
    timesOnly,
}: Props) => {
    const { t } = useLocalization();

    const [breakStartTime, setBreakStartTime] = useState<string | undefined>();
    const [breakDuration, setBreakDuration] = useState<number>();
    const [timeRangeValue, setTimeRangeValue] = useState<TimeRangePickerInputObject>({
        times: [],
        touched: false,
    });
    const [comment, setComment] = useState<string | null>(null);
    const [shiftDuration, setShiftDuration] = useState<string>('');

    useEffect(() => {
        const dates = form.getFieldValue('dates');
        const times = dates?.[dateField.name]?.times[timeField.name];
        const [formShiftStartTime, formShiftEndTime] = times?.shiftTimes?.times ?? [undefined, undefined];

        setTimeRangeValue({
            times: [formShiftStartTime, formShiftEndTime],
            touched: true,
        });

        if (times) {
            if (!isNaN(+times.breakDuration)) {
                setBreakDuration(+times.breakDuration);
            }

            setBreakStartTime(times.breakStartTime);
            setComment(times?.comment);
        }
    }, [form]);

    const [shiftStartTime, shiftEndTime] = timeRangeValue.times;
    const momentBreakStartTime = moment(breakStartTime, timeFormat);
    const momentShiftStartTime = moment(shiftStartTime, timeFormat);
    const momentShiftEndTime = moment(shiftEndTime, timeFormat);

    if (momentShiftEndTime <= momentShiftStartTime) {
        momentShiftEndTime.add(1, 'days');
    }

    const shiftDurationInMinutes = momentShiftEndTime.diff(momentShiftStartTime) / 60_000;

    const breakStartOptions = getTimeOptionsForTimePicker(
        15,
        shiftStartTime,
        moment(shiftEndTime, timeFormat).subtract(breakDuration, 'minutes').format(timeFormat)
    );

    /**
     * Strip non digits chars from Autocomplete Input and updates form state
     */
    const breakDurationChangeHandler = (value: number) => {
        setBreakDuration(value);
        const dates = form.getFieldValue('dates');
        dates[dateField.name].times[timeField.name].breakDuration = value;
        form.setFieldsValue({ dates });
    };

    const breakStartTimeChangeHandler = (value: string) => {
        setBreakStartTime(value);
        const dates = form.getFieldValue('dates');
        dates[dateField.name].times[timeField.name].breakStartTime = value;
        form.setFieldsValue({ dates });
    };

    const cancelComment = () => {
        setComment(null);

        const dates = form.getFieldValue('dates');
        dates[dateField.name].times[timeField.name].comment = null;
        form.setFieldsValue({ dates });

        saveFormHandler(null, { ...form.getFieldsValue(), dates });
    };

    const dateHasMultipleTimes = () => {
        const dates = form.getFieldValue('dates') || [];

        return dates[dateField.name]?.times?.length > 1;
    };

    /*
     * Set the default break start time in the middle of the shift
     */
    const breakStartTimeAdvise = () => {
        if (!breakStartTime && shiftStartTime && shiftEndTime && breakDuration) {
            const [defaultBreakStartTime] = getStartEndTimesInTheMiddleOfInterval(
                shiftStartTime,
                shiftEndTime,
                breakDuration
            );
            breakStartTimeChangeHandler(defaultBreakStartTime);
        }
    };

    const validateShiftTimes = (
        rule: {},
        timeRangePickerInput: TimeRangePickerInputObject = { times: [undefined, undefined], touched: false }
    ) => {
        const { times, touched } = timeRangePickerInput;
        const [startTime, endTime] = times;
        const regexTime = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');

        if (touched) {
            if (!startTime && !endTime) {
                return Promise.reject(t('Start/end times are required'));
            }
            if (!startTime) {
                return Promise.reject(t('Start time is required'));
            }
            if (!endTime) {
                return Promise.reject(t('End time is required'));
            }
            if (!regexTime.test(startTime) && !regexTime.test(endTime)) {
                return Promise.reject(t('Wrong time format for start/end times'));
            }
            if (!regexTime.test(startTime)) {
                return Promise.reject(t('Wrong time format for start time'));
            }
            if (!regexTime.test(endTime)) {
                return Promise.reject(t('Wrong time format for end time'));
            }
        }

        return Promise.resolve();
    };

    const validateBreakDuration = (rule: {}, value: string) => {
        if (shiftEndTime && shiftStartTime && value) {
            const regexDuration = new RegExp('^[1-9]([0-9]|)([0-9]|)([0-9]|)$');

            if (shiftDurationInMinutes < +value) {
                return Promise.reject(t('The break is too long'));
            }
            if (breakStartTime) {
                const momentBreakEndTime = moment(momentBreakStartTime).clone().add(value, 'm');
                if (momentBreakEndTime > momentShiftEndTime) {
                    return Promise.reject(t('The break exceeds working hours'));
                }
            }
            if (+value < 1) {
                return Promise.reject(t('The break is too short'));
            }
            if (!regexDuration.test(value)) {
                return Promise.reject(t('Wrong break duration format'));
            }
        }

        return Promise.resolve();
    };

    const validateBreakStartTime = () => {
        if (breakDuration && !breakStartTime) {
            return Promise.reject(t('Break start time is required'));
        }

        if (momentBreakStartTime < momentShiftStartTime) {
            momentBreakStartTime.date(momentShiftEndTime.date());
        }

        if (momentShiftStartTime.isValid() && momentShiftEndTime.isValid() && momentBreakStartTime.isValid()) {
            if (!momentBreakStartTime.isBetween(momentShiftStartTime, momentShiftEndTime)) {
                return Promise.reject(t('The break must start between start and end date'));
            }
        }
        return Promise.resolve();
    };

    const saveShiftTimes = (shiftInterval: ShiftInterval) => {
        const shiftRanges = {
            times: shiftInterval,
            touched: true,
        };
        const dates = form.getFieldValue('dates');

        dates[dateField.name].times[timeField.name].shiftTimes = shiftRanges;
        form.setFieldsValue({ dates });

        saveFormHandler(null, { ...form.getFieldsValue(), dates });
        setTimeRangeValue(shiftRanges);
    };

    const handleTimeRangeChange = (value: TimeRangePickerInputObject) => {
        saveShiftTimes(value.times as ShiftInterval);

        if (!breakStartOptions.map((option) => option.value).includes(breakStartTime)) {
            breakStartTimeChangeHandler('');
        }
    };

    const shiftChangedHandler = (shift: WorkShift) => {
        breakDurationChangeHandler(shift.break_duration);
        breakStartTimeChangeHandler(shift.break_start_time);

        saveShiftTimes([shift.start_time, shift.end_time]);
    };

    const useAlternateSize = timesOnly && !dateHasMultipleTimes();

    const getShiftDurationElement = () => !timesOnly && !!shiftDuration && (<em style={{ opacity: .5 }}>({shiftDuration})</em>);

    const commentColumnWidth = timesOnly ? 3 : 4;

    return (
        <div className="left-processing-border">
            <tr>
                {!timesOnly && (
                    <td className="shift-number-column">
                        <Form.Item
                            label={t('Staff no.')}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            {...timeField}
                            name={[timeField.name, 'workersNumber']}
                            rules={[required(), max(MAX_POSSIBLE_SHIFTS)]}
                        >
                            <Input
                                className="workers-number"
                                max={MAX_POSSIBLE_SHIFTS}
                                min={1}
                                prefix={<UserOutlined />}
                                type="number"
                            />
                        </Form.Item>
                    </td>
                )}
                <td className="shift-interval-column">
                    <Form.Item
                        label={<>{t('Shift interval')} &nbsp; {getShiftDurationElement()}</>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        {...timeField}
                        name={[timeField.name, 'shiftTimes']}
                        className="shift-dropdown-wrapper-input"
                        rules={[
                            { required: true, message: t('Start/end times are required') },
                            {
                                validator: validateShiftTimes
                            }
                        ]}
                        validateFirst={true}
                    >
                        <>
                            {!timesOnly && (
                                <ShiftsDropdown shifts={shifts} handleShiftSelected={shiftChangedHandler} />
                            )}

                            <TimeRangePicker
                                allowClear={false}
                                className="shift-duration"
                                handleShiftDurationChange={setShiftDuration}
                                onChange={handleTimeRangeChange}
                                width={80}
                                value={timeRangeValue}
                                tooltip
                            />
                        </>
                    </Form.Item>
                </td>
                <td className="break-column">
                    <Form.Item
                        label={t('Break')}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name={[timeField.name, 'breakDuration']}
                        className="break-time"
                        dependencies={[timeField.name, 'breakStartTime']}
                        rules={[{ validator: validateBreakDuration }]}
                    >
                        <Tooltip placement="topLeft" title={t('Duration')}>
                            <Duration
                                className="break-duration"
                                placeholder={t('Duration')}
                                handler={breakDurationChangeHandler}
                                defaultValue={`${breakDuration ?? ''}`}
                                value={`${breakDuration ?? ''}`}
                            />
                        </Tooltip>
                    </Form.Item>
                </td>
                <td className="start-time-column">
                    <Tooltip placement="topLeft" title={t('Set break start time')}>
                        <Form.Item
                            label={t('Start time')}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            name={[timeField.name, 'breakStartTime']}
                            className="break-time"
                            dependencies={[timeField.name, 'breakDuration']}
                            rules={[{ validator: validateBreakStartTime }]}
                        >
                            <TimePicker
                                className="break-start-time"
                                disabled={!breakDuration}
                                dropdownOptions={breakStartOptions}
                                onChange={breakStartTimeChangeHandler}
                                onClear={() => breakStartTimeChangeHandler('')}
                                onIconClick={breakStartTimeAdvise}
                                placeholder="hh:mm"
                                defaultValue={breakStartTime}
                                showIcon={false}
                                allowClear
                            />
                        </Form.Item>
                    </Tooltip>
                </td>
                {!useAlternateSize && dateHasMultipleTimes() && (
                    <td className="icon-column">
                        <MinusCircleOutlined className="remove-time" onClick={() => remove?.(timeField.name)} />
                    </td>
                )}
                <td className="icon-column">
                    <AddCommentIcon
                        className="toggle-comment"
                        color={comment !== null ? '#bfbfbf' : '#1677ff'}
                        onClick={() => setComment('')}
                    />
                </td>
            </tr>

            {comment !== null && (
                <tr>
                    <td colSpan={commentColumnWidth}>
                        <Form.Item
                            label={t('Comment')}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            {...timeField}
                            name={[timeField.name, 'comment']}
                        >
                            <Input className="comment" value={comment} />
                        </Form.Item>
                    </td>

                    <td className="icon-column" colSpan={2} style={{ width: 'unset' }}>
                        <MinusCircleOutlined className="remove-time" onClick={cancelComment} />
                    </td>
                </tr>
            )}
        </div>
    );
};
