import { useLocalization } from 'lib/Localization';
import { Typography, Input, Form } from 'antd';

const { Title } = Typography;
const { TextArea } = Input;

export const RequestCreationNotes = () => {
    const { t } = useLocalization();

    return (
        <>
            <Title className="subsection-title" level={5}>
                {t('Notes')}
            </Title>
            <Form.Item name="notes" rules={[{ max: 2000, message: t('Max 2000 characters allowed') }]}>
                <TextArea rows={7} placeholder={t('Leave a note here')} />
            </Form.Item>
        </>
    );
};
