import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Form, Input, notification, Select } from 'antd';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { ProfessionTranslation } from 'types/models';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

const { formLayout } = SingleColumnFormLayout;

type Props = {
    professionTranslation?: ProfessionTranslation;
    languageOptions: DefaultOptionType[];
    loadProfessionData: () => Promise<void>;
    setIsFormOpen: (isFormOpen: boolean) => void;
};

/**
 * Form to manage a profession's translations
 *
 * @param professionTranslation
 * @param languageOptions
 * @param saveHandler
 * @returns {JSX.Element}
 * @constructor
 */
export const ProfessionTranslationForm = ({
    professionTranslation,
    languageOptions,
    loadProfessionData,
    setIsFormOpen,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const [isDataSaving, setIsDataSaving] = useState(false);
    // if editing a translation, add the current language to the select options
    let translationLanguageOption = professionTranslation?.language
        ? {
              value: professionTranslation.language.id,
              label: t(professionTranslation.language.name),
          }
        : null;

    /**
     * When component receives new ProfessionTranslation props.
     */
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(professionTranslation);
    }, [form, professionTranslation]);

    /**
     * Save a profession translation
     *
     * @param values
     * @returns {Promise<void>}
     */
    const saveProfessionTranslationHandler = async (values: ProfessionTranslation) => {
        try {
            const url = 'data-management/profession-translations';
            setIsDataSaving(true);
            if (values.id) {
                const response = await Backend.put(`${url}/${values.id}`, values);
                if (response.status === Backend.responseStatus.HTTP_OK) {
                    notification.success({
                        message: t('Translation successfully saved'),
                    });
                    setIsFormOpen(false);
                }
            } else {
                const response = await Backend.post(url, values);
                if (response.status === Backend.responseStatus.HTTP_CREATED) {
                    notification.success({
                        message: t('Translation successfully created'),
                    });
                    setIsFormOpen(false);
                }
            }
            loadProfessionData();
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    return (
        <Form form={form} onFinish={saveProfessionTranslationHandler} {...formLayout}>
            <Form.Item
                name="id"
                style={{
                    display: 'none',
                }}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="profession_id"
                style={{
                    display: 'none',
                }}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="language_id"
                rules={[
                    {
                        required: true,
                        message: t('Please select a language'),
                    },
                ]}
            >
                <FloatingLabel label={t('Language')} required>
                    <Select
                        placeholder={t('Choose language')}
                        className="multi-select"
                        options={
                            translationLanguageOption
                                ? [translationLanguageOption, ...languageOptions]
                                : languageOptions
                        }
                    />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a name'),
                    },
                ]}
            >
                <FloatingLabel label={t('Name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="description">
                <FloatingLabel label={t('Description')}>
                    <Input.TextArea rows={3} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};
