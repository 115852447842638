import { useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { useErrorHandler } from 'lib/ErrorHandling';
import { ChangePassword } from 'types/auth/ChangePassword';
import { PasswordFormItem } from 'shared/AntDesignUtils/Password/PasswordFormItem';

const { formLayout, saveButtonLayout } = SingleColumnFormLayout;

type Props = {
    setIsEditUserProfileOpen: (isEditUserProfileOpen: boolean) => void;
};

/**
 * Form to change the auth user's password
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ChangePasswordForm = ({ setIsEditUserProfileOpen }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const [isDataSaving, setIsDataSaving] = useState(false);

    /**
     * Change user's password.
     *
     * @param values
     *
     * @returns {Promise<void>}
     */
    const changePasswordHandler = async (values: ChangePassword) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.put(`/account/profile/password`, values);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Password successfully changed'),
                });
                form.resetFields();
                setIsEditUserProfileOpen(false);
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    const formPasswordLayout = { ...formLayout, labelCol: { span: 7 }, wrapperCol: { span: 20 } };

    return (
        <Form form={form} onFinish={changePasswordHandler} {...formPasswordLayout} layout={'vertical'}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <PasswordFormItem
                label={ t('Old password') }
                name="old_password"
                rules={ [{ required: true, message: t('Please enter the old password') }] }
                required
            />

            <PasswordFormItem
                label={t('New password')}
                name="password"
                rules={[{ required: true, message: t('Please enter the new password') }]}
                required
                showConditions
            />

            <PasswordFormItem
                dependencies={['password']}
                label={t('Confirm password')}
                name="password_confirmation"
                rules={[
                    { required: true, message: t('Please confirm the new password') },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
                required
            />

            <Form.Item {...saveButtonLayout}>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Change')}
                </Button>
            </Form.Item>
        </Form>
    );
};
