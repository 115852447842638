import { useLocation } from 'react-router-dom-v5-compat';

/**
 * Custom hook to return the left menu open (sub-menu) key and selected key based on the current location
 */
const useMenuKeys = () => {
    const location = useLocation();
    const locationParts = location.pathname.split('/').filter(Boolean) ?? [];
    /*
        eg. location.pathname: "/client/configuration/locations"
            locationParts: [ "client", "configuration", "locations" ]
     */
    locationParts.shift(); // removes the first element, "client". locationParts: [ "configuration", "addresses" ]
    const selectedMenuKey = locationParts.pop() ?? ''; // get and removes the last element, "addresses". locationParts: [ "configuration" ]
    const openMenuKey = locationParts.pop() ?? ''; // get and removes the last element, "configuration". locationParts: [ ]

    return { selectedMenuKey, openMenuKey };
};

export { useMenuKeys };
