import { useLocalization } from 'lib/Localization';
import './styles.scss';
import { FormattedJob } from 'types/staffing';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { useEffect, useState } from 'react';
import { Form, notification, Tooltip } from 'antd';
import { TimeRangePicker } from 'shared/AntDesignUtils/TimeRangePicker';
import { Duration } from 'shared/AntDesignUtils/Duration';
import { TimePicker } from 'shared/AntDesignUtils/TimePicker';
import {
    getStartEndTimesInTheMiddleOfInterval,
    getTimeOptionsForTimePicker,
    removeTimeSeconds,
    timeFormat
} from 'lib/Helpers/DateTimeHelper';
import moment from 'moment/moment';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { formatJobs } from 'shared/Requests/helpers';

type Props = {
    job?: FormattedJob;
    onClose: () => void;
    setJob: (job: FormattedJob) => void;
};

export const WorkedTimeModal = ({ job, onClose, setJob }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const startTime = removeTimeSeconds(job?.start_time || '');
    const endTime = removeTimeSeconds(job?.end_time || '');

    const [timeRangeValue, setTimeRangeValue] = useState({
        times: [startTime, endTime],
        touched: false,
    });
    const [shiftStartTime, shiftEndTime] = timeRangeValue.times;
    const [breakDuration, setBreakDuration] = useState(parseInt(job?.breakDuration || '0') || undefined);
    const [breakStartTime, setBreakStartTime] = useState(job?.breakStartTime);

    const breakStartOptions = getTimeOptionsForTimePicker(
        15,
        shiftStartTime,
        moment(shiftEndTime, timeFormat).subtract(breakDuration, 'minutes').format(timeFormat)
    );

    useEffect(() => {
        if (!job) {
            return;
        }

        const startTime = removeTimeSeconds(job.start_time);
        const endTime = removeTimeSeconds(job.end_time);

        setTimeRangeValue({
            times: [startTime, endTime],
            touched: false,
        });
        setBreakDuration(parseInt(job.breakDuration || '0') || undefined);
        setBreakStartTime(job.breakStartTime);
    }, [job]);

    /*
     * Set the default break start time in the middle of the shift
     */
    const breakStartTimeAdvise = () => {
        if (!breakStartTime && shiftStartTime && shiftEndTime && breakDuration) {
            const [defaultBreakStartTime] = getStartEndTimesInTheMiddleOfInterval(
                shiftStartTime,
                shiftEndTime,
                breakDuration
            );
            setBreakStartTime(defaultBreakStartTime);
        }
    };

    const save = async () => {
        let breakEndTime = '';

        if (breakStartTime && breakStartTime != '') {
            breakEndTime = moment(breakStartTime, timeFormat).add(breakDuration, 'minutes').format(timeFormat);
        }

        try {
            const { data } = await Backend.put(`/staffing/jobs/${job?.id}/confirm-times`, {
                confirmed_start_time: shiftStartTime,
                confirmed_end_time: shiftEndTime,
                confirmed_start_break: breakStartTime,
                confirmed_end_break: breakEndTime,
            });

            setJob(formatJobs([data], true)[0]);

            notification.success({
                message: t('Work time added successfully'),
            });

            onClose();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <CustomModal
            className="worked-time-modal"
            okText={t('Confirm')}
            onCancel={onClose}
            onOk={save}
            open={!!job}
            title={t('Confirm worked time')}
        >
            <table cellPadding={5} cellSpacing={0}>
                <tbody>
                    <tr>
                        <th className="text-left" colSpan={4}>
                            {t('Shift time')}:
                        </th>
                    </tr>
                    <tr>
                        <td className="text-indented">{t('Shift interval')}</td>
                        <td></td>
                        <td>{t('Break')}</td>
                        <td>{t('Start time')}</td>
                    </tr>
                    <tr>
                        <td className="text-indented">
                            {startTime} - {endTime}
                        </td>
                        <td style={{ paddingLeft: '15px' }}>{job?.shiftDuration}</td>
                        <td>{job?.breakDuration}</td>
                        <td>{job?.breakStartTime}</td>
                    </tr>
                    <tr>
                        <th className="text-left" colSpan={4} style={{ paddingTop: '20px' }}>
                            {t('Actual time worked')}:
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Form.Item
                                label={t('Shift interval')}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className="inline-block shift-dropdown-wrapper-input"
                                rules={[{ required: true, message: t('Start/end times are required') }]}
                                validateFirst={true}
                            >
                                <TimeRangePicker
                                    allowClear={false}
                                    className="shift-duration"
                                    onChange={({ times }) =>
                                        setTimeRangeValue({
                                            times: times as string[],
                                            touched: true,
                                        })
                                    }
                                    width={80}
                                    value={timeRangeValue}
                                />
                            </Form.Item>
                        </td>
                        <td style={{ paddingTop: '18px' }}>
                            <Form.Item
                                label={t('Break')}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 16 }}
                                className="inline-block break-time"
                            >
                                <Duration
                                    className="break-duration"
                                    placeholder={t('Duration')}
                                    handler={(duration) => setBreakDuration(duration || undefined)}
                                    value={`${breakDuration ?? ''}`}
                                />
                            </Form.Item>
                        </td>
                        <td>
                            {!!breakDuration && (
                                <Tooltip placement="topLeft" title={t('Set break start time')}>
                                    <Form.Item
                                        label={t('Start time')}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className="break-time"
                                    >
                                        <TimePicker
                                            className="break-start-time"
                                            dropdownOptions={breakStartOptions}
                                            onChange={(time: string) => setBreakStartTime(time)}
                                            onClear={() => setBreakStartTime('')}
                                            onIconClick={breakStartTimeAdvise}
                                            placeholder="hh:mm"
                                            showIcon={false}
                                            style={{ width: 80 }}
                                            value={breakStartTime}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Tooltip>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </CustomModal>
    );
};
