import 'pages/Client/Requests/Create/RequestCreationInfoSummary.scss';
import { useLocalization } from 'lib/Localization';
import { Button, Card, Checkbox, Menu, MenuProps, Popover, Space, Tag } from 'antd';
import { Profession, Provider } from 'types/models';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { OpenNotifIcon } from 'shared/icons';
import { useEffect, useMemo, useState } from 'react';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { getTime, getTimeDuration } from 'lib/Helpers/DateTimeHelper';
import { UsersIcon } from 'shared/icons/UsersIcon';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { useSelections } from 'lib/hooks/useSelections';
import {
    addGroupSerialNumber,
    countGroupItems,
    getDurationMinutes,
    getJobUniqueDates,
    prepareRequestJobs,
} from 'lib/Request/utils';
import dayjs from 'dayjs';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { GenericObject } from 'shared/Contracts';
import { FormattedJob } from 'types/staffing';
import { JobState } from 'types/staffing/JobStateMachine';

type Props = {
    activateRequest?: (index: number) => void;
    formRequests: OrderFormRequest[];
    hideHeader?: boolean;
    job?: FormattedJob;
    newQuotaOnly?: boolean;
    professions?: (Profession | null)[];
    providers?: Provider[];
    setFormRequest?: (formRequest: OrderFormRequest, index: number) => void;
    setNewRequestProviders?: (providers: number[]) => void;
    showProviderQuota?: boolean;
    simple?: boolean;
};

const STOPPER_CLASS = 'ignore-menu';

const createProfessionKey = (id: number, index: number) => `k${id}_${index}`;

export const RequestCreationInfoSummary = ({
    activateRequest,
    formRequests,
    hideHeader = false,
    job,
    newQuotaOnly,
    professions,
    providers = [],
    setFormRequest,
    setNewRequestProviders,
    showProviderQuota,
    simple,
}: Props) => {
    const { t } = useLocalization();
    const { allSelected, selections, selectNone, setAll, toggleAll, toggleSelection } = useSelections();
    const { getDateTimes, getProviderWorkerQuota, getTotalDateTimesWorkers } = useRequestActions();

    const [selectedKeys, setSelectedKeys] = useState<string[]>([
        createProfessionKey(formRequests[0]?.profession_id || 0, 0),
    ]);
    const [addingProviderQuota, setAddingProviderQuota] = useState(
        showProviderQuota || (formRequests[0].jobs || []).some((job) => !!job.providers?.length)
    );
    const [settingMultiple, setSettingMultiple] = useState<boolean>(false);
    const [requestJobs, setRequestJobs] = useState<any[]>(formRequests[0].jobs || []);
    const [jobTotalWorkers, setJobTotalWorkers] = useState<GenericObject>({});
    const [savedJobProviders, setSavedJobProviders] = useState<number[][]>([]);
    const [requestProviders, setRequestProviders] = useState<number[]>([]);

    const getProfessionById = (id: number) =>
        ((professions || []).find((profession) => profession?.id === id) || {
            name: t('Unknown profession'),
        }) as Profession;

    const getHeader = (request: OrderFormRequest) => {
        const profession = getProfessionById(request.profession_id);

        if (formRequests.length > 1) {
            return profession.name;
        }

        return (
            <div className="header-card">
                {!simple && (
                    <>
                        <div>
                            <strong>{t('Request summary')}</strong>
                        </div>

                        <Button
                            className="pull-right"
                            style={{ marginTop: 30, marginRight: 5 }}
                            disabled={(request.providers || []).length <= 1}
                            onClick={() => setAddingProviderQuota(!addingProviderQuota)}
                        >
                            {t(addingProviderQuota ? 'Remove Provider Quota' : 'Add Provider Quota')}
                        </Button>
                    </>
                )}

                <table className="heading-table">
                    <tbody>
                        {!simple && (
                            <tr>
                                <td width={100}>
                                    <strong>{t('Profession')}:</strong>
                                </td>
                                <td>{t(profession.name)}</td>
                            </tr>
                        )}

                        <tr>
                            <td>
                                <strong>{t('Provider')}:</strong>
                            </td>
                            <td>
                                {(providers ?? [])
                                    .filter(({ id }) => requestProviders.includes(id))
                                    .map((provider) => (
                                        <Tag style={{ fontWeight: 'bold' }}>
                                            <Space>
                                                {provider.company_name}
                                                <UsersIcon />
                                                {getProviderWorkerQuota(
                                                    {
                                                        ...request,
                                                        jobs: requestJobs,
                                                    },
                                                    provider.id
                                                )}
                                            </Space>
                                        </Tag>
                                    ))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const showProfessions = () => formRequests.length > 1;

    const getProfessionMenuItems = (): MenuProps['items'] =>
        formRequests.map(({ profession_id }, index) => ({
            key: createProfessionKey(profession_id, index),
            label: (
                <>
                    {getProfessionById(profession_id).name}

                    <OpenNotifIcon
                        className="open-notif"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            activateRequest?.(index);
                        }}
                    />
                </>
            ),
        }));

    const scrollSummaryIntoView = (key: string) => {
        if (selectedKeys.includes(key)) {
            return;
        }

        const cardBody = document.querySelector('.step1-summary-card.with-list > .ant-card-body');

        cardBody?.classList.add(STOPPER_CLASS);

        const elem = cardBody?.querySelector(`#${key}`);

        elem?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const isInViewport = (element: Element) => {
        const rect = element.getBoundingClientRect();
        const html = document.documentElement;

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
        );
    };

    const defaultRequestJobs = useMemo(
        () =>
            formRequests.map((formRequest) =>
                prepareRequestJobs(formRequest, formRequest.selectedDates as Date[], true)
            ),
        [formRequests]
    );

    // @tc-ignore
    const getProfessionMenuTdColSpan = () =>
        formRequests.reduce((total: number, formRequest, key) => {
            // @ts-ignore
            const requestRows = (formRequest.datePicker ?? []).reduce((subtotal: number, date, index) => {
                const dateRows = (getDateTimes(formRequest, index) ?? []).length;

                return subtotal + dateRows + 1 /* Date title row */;
            }, 0);

            return total + requestRows + 1 /* Profession header rows */;
        }, 0);

    const calculateTotalWorkersPerProfession = (request: OrderFormRequest) => {
        let totalWorkers = 0;

        (request.datePicker ?? []).map((datePicker: Date, index: number) => {
            const dateTimes = getDateTimes(request, index) || [];

            dateTimes.forEach(({ workersNumber }: any) => {
                totalWorkers += parseInt(workersNumber);
            });
        });

        return (
            <span>
                <span style={{ padding: '0px 2px' }}>|</span> {totalWorkers}{' '}
                {totalWorkers > 1 ? t('workers') : t('worker')}
            </span>
        );
    };

    const getSummaryRow = (
        index: number,
        date: string,
        { breakDuration, breakStartTime, shiftTimes, workersNumber }: any,
        key: number,
        dataKey: string
    ) => (
        <tr key={`row-summary-${date}-${index}-${key}`} data-key={dataKey}>
            <td>
                <Space>
                    <span>{key + 1}.</span>
                    <UserOutlined />
                    <span className="workers-number">{workersNumber}</span>
                </Space>
            </td>
            <td>
                {!!(shiftTimes?.times || []).length && (
                    <>
                        <span className="shift-start">{shiftTimes.times[0]}</span>-
                        <span className="shift-end">{shiftTimes.times[1]}</span>
                        <span className="interval">({getTimeDuration(shiftTimes.times[0], shiftTimes.times[1])})</span>
                    </>
                )}
            </td>
            <td>{breakDuration}</td>
            <td>{breakStartTime}</td>
        </tr>
    );

    const addProviderToSelections = (providerId: number) =>
        setRequestJobs((prevState) => {
            const jobs = [...prevState];

            selections.forEach((index) => {
                if (jobs[index].providers.includes(providerId)) {
                    return;
                }

                jobs.splice(index, 1, {
                    ...jobs[index],
                    providers: [...jobs[index].providers, providerId],
                });
            });

            return jobs;
        });

    const getProviderSelect = (index: number, state?: JobState) =>
        !!providers?.length &&
        !!requestJobs.length &&
        !!savedJobProviders.length && (
            <Select
                autoClearSearchValue={false}
                closeOptionOnSelect={false}
                defaultValue={requestJobs[index].providers.map((id: number) => ({
                    key: id,
                    label: providers.find((provider) => provider.id === id)?.company_name,
                }))}
                disabled={state === JobState.CANCELED}
                mode="multiple"
                optionFilterProp="label"
                options={providers.map(({ id, company_name }) => ({
                    disabled: newQuotaOnly && savedJobProviders[index].includes(id),
                    label: company_name,
                    value: id,
                }))}
                onDeselect={(value: number) =>
                    setRequestJobs((prevState) => {
                        const jobs = [...prevState];
                        const providers = [...(jobs[index].providers ?? [])];
                        const providerIndex = providers.indexOf(value);

                        if (providerIndex > -1) {
                            providers.splice(providerIndex, 1);
                        }

                        jobs.splice(index, 1, {
                            ...jobs[index],
                            providers,
                        });

                        return jobs;
                    })
                }
                onSelect={(value: number) =>
                    setRequestJobs((prevState) => {
                        const jobs = [...prevState];
                        const providers = [...(jobs[index].providers ?? [])];

                        if (!providers.includes(value)) {
                            providers.push(value);
                        }

                        jobs.splice(index, 1, {
                            ...jobs[index],
                            providers,
                        });

                        return jobs;
                    })
                }
                placeholder={t('All')}
                style={{ width: '100%' }}
                value={requestJobs[index].providers.map((id: number) => ({
                    key: id,
                    label: providers.find((provider) => provider.id === id)?.company_name,
                }))}
                filterOption
                showSearch
            />
        );

    const getExpandedSummaryRows = (
        index: number,
        { date, end_break, end_time, start_break, start_time, worker_number }: any
    ) => (
        <tr
            key={`row-extended-summary-${index}`}
            className={requestJobs[index].state === JobState.CANCELED ? 'state-cancelled' : ''}
        >
            <td>
                <Space size="large">
                    {!simple && (
                        <Checkbox checked={selections.includes(index)} onClick={() => toggleSelection(index)} />
                    )}
                    <span>{worker_number}.</span>
                </Space>
            </td>
            <td>
                <span className="shift-start">{getTime(start_time)}</span>-
                <span className="shift-end">{getTime(end_time)}</span>
                <span className="interval">({getTimeDuration(start_time, end_time)})</span>
            </td>
            <td>{start_break ? getDurationMinutes(date, start_break, end_break) : ''}</td>
            <td>{getTime(start_break)}</td>
            <td>{getProviderSelect(index, requestJobs[index].state)}</td>
        </tr>
    );

    const updateRequestProviders = () => {
        const allJobProviders = requestJobs.reduce((prev: number[], cur: any) => {
            return prev.concat(cur.providers);
        }, []);

        const newRequestProviders = [...new Set((formRequests[0].providers || []).concat(allJobProviders))];

        setRequestProviders(newRequestProviders);
        setNewRequestProviders?.(newRequestProviders);
    };

    useEffect(() => {
        const cardBody = document.querySelector('.step1-summary-card.with-list > .ant-card-body');
        const activateMenu = () =>
            !cardBody?.classList.contains(STOPPER_CLASS) &&
            cardBody?.querySelectorAll('tr').forEach((elem) => {
                if (!isInViewport(elem) || !(elem as HTMLElement).dataset.key) {
                    return;
                }

                setSelectedKeys([(elem as HTMLElement).dataset.key as string]);
            });
        const removeStopperCss = () => cardBody?.classList.remove(STOPPER_CLASS);

        cardBody?.addEventListener('scroll', activateMenu);
        cardBody?.addEventListener('scrollend', removeStopperCss);

        return () => {
            cardBody?.removeEventListener('scroll', activateMenu);
            cardBody?.removeEventListener('scrollend', removeStopperCss);
        };
    }, []);

    useEffect(() => {
        let jobs = requestJobs;

        // keep request jobs if already saved.
        if (!jobs.length && formRequests[0].id) {
            jobs = (formRequests[0].jobs || []).map((job) => {
                job.providers = [];

                return job;
            });
        }

        updateRequestProviders();

        setFormRequest?.(
            {
                ...formRequests[0],
                jobs,
            },
            0
        );
    }, [requestJobs]);

    useEffect(() => {
        if (addingProviderQuota) {
            const jobs = addGroupSerialNumber(
                formRequests[0].jobs?.length ? formRequests[0].jobs : defaultRequestJobs[0],
                'date',
                'worker_number'
            );

            setSavedJobProviders(jobs.map(({ providers }) => [...providers]));
            setJobTotalWorkers(countGroupItems(jobs, 'date'));
            setRequestJobs(jobs);
            setAll(Object.keys(jobs).map(Number));
        } else {
            setSavedJobProviders([]);
            setJobTotalWorkers({});
            setRequestJobs([]);
            setAll([]);
        }

        selectNone();
    }, [addingProviderQuota, formRequests[0].datePicker]);

    useEffect(() => {
        updateRequestProviders();

        if (showProviderQuota || formRequests[0].providers?.length >= 2) {
            return;
        }

        setAddingProviderQuota(false);
    }, [formRequests[0].providers]);

    useEffect(() => {
        if (!requestJobs.length || !defaultRequestJobs[0].length) {
            return;
        }

        setRequestJobs((prevState) => {
            const localJobs = [...prevState];

            defaultRequestJobs[0].forEach((job, index) => {
                if (localJobs.length === index) {
                    localJobs.push(job);
                } else if (localJobs[index].date !== job.date) {
                    localJobs.splice(index, 0, job);
                }
            });

            if (localJobs.length !== defaultRequestJobs[0].length) {
                localJobs.splice(defaultRequestJobs[0].length);
            }

            return localJobs;
        });
    }, [defaultRequestJobs[0]]);

    return (
        <Card bordered={false} className={'step1-summary-card' + (showProfessions() ? ' with-list' : '')}>
            {!showProfessions() && !hideHeader && <h4 className="summary-header">{getHeader(formRequests[0])}</h4>}

            <div id="table-wrapper" className={hideHeader ? 'no-header' : ''}>
                <table id="main" className={addingProviderQuota ? 'expanded' : ''}>
                    <thead className={hideHeader ? 'no-top-space' : ''}>
                        <tr>
                            {showProfessions() && <th id="profession">{t('Profession')}</th>}
                            <th id="staff-needed" className={showProfessions() ? '' : 'no-profession'}>
                                {!simple && addingProviderQuota && (
                                    <Checkbox
                                        checked={allSelected}
                                        indeterminate={!allSelected && !!selections.length}
                                        onClick={toggleAll}
                                    />
                                )}
                                {!addingProviderQuota && t('Staff needed')}
                            </th>
                            <th id="shift-interval" className={showProfessions() ? '' : 'no-profession'}>
                                {t('Shift interval')}
                            </th>
                            <th id="break" className={showProfessions() ? '' : 'no-profession'}>
                                {t('Break')}
                            </th>
                            <th id="start-time" className={showProfessions() ? '' : 'no-profession'}>
                                {t('Start time')}
                            </th>
                            {addingProviderQuota && (
                                <th id="providers">
                                    {!simple && (
                                        <Popover
                                            arrow={false}
                                            onOpenChange={setSettingMultiple}
                                            overlayClassName="providers-popover"
                                            content={
                                                <Menu
                                                    items={(providers ?? []).map(({ id, company_name }) => ({
                                                        label: company_name,
                                                        key: id,
                                                    }))}
                                                    onClick={({ key }) => addProviderToSelections(Number(key))}
                                                    selectable={false}
                                                />
                                            }
                                            placement="bottom"
                                            trigger="click"
                                        >
                                            <Button
                                                disabled={selections.length < 2}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                {t(settingMultiple ? 'Choose multiple' : 'Assign multiple')}
                                            </Button>
                                        </Popover>
                                    )}

                                    {t('Provider')}
                                </th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {formRequests.map((request, rIndex) => (
                            <>
                                {showProfessions() && (
                                    <tr key={`summary-${rIndex}`}>
                                        {!rIndex && (
                                            <td
                                                id="profession-menu"
                                                valign="top"
                                                rowSpan={getProfessionMenuTdColSpan()}
                                            >
                                                <Menu
                                                    items={getProfessionMenuItems()}
                                                    mode="inline"
                                                    onClick={({ key }) => scrollSummaryIntoView(key)}
                                                    onSelect={({ key }) => setSelectedKeys([key])}
                                                    selectedKeys={selectedKeys}
                                                />
                                            </td>
                                        )}

                                        <td className="summary-header" colSpan={4}>
                                            {getHeader(request)} {calculateTotalWorkersPerProfession(request)}
                                        </td>
                                    </tr>
                                )}

                                {(
                                    request.datePicker ??
                                    getJobUniqueDates(requestJobs).map((date) => dayjs(date).toDate())
                                ).map((datePicker: Date, dIndex: number) => {
                                    const $date = dayjs(datePicker);
                                    const date = $date.format('YYYY-MM-DD');

                                    const day = datePicker.getDay();

                                    const dateTimes = getDateTimes(request, rIndex) || [];
                                    const totalWorkersPerDay =
                                        addingProviderQuota && jobTotalWorkers[date]
                                            ? jobTotalWorkers[date]
                                            : getTotalDateTimesWorkers(dateTimes);
                                    const dataKey = createProfessionKey(request.profession_id, rIndex);

                                    return (
                                        <>
                                            {(!addingProviderQuota || !job || dayjs(job.date).isSame(date)) && (
                                                <tr key={`${date}-day-summary`} data-key={dataKey}>
                                                    <td
                                                        id={
                                                            !dIndex
                                                                ? createProfessionKey(request.profession_id, rIndex)
                                                                : ''
                                                        }
                                                        className={
                                                            'date-title' +
                                                            ([0, 6].includes(day) ? ' weekend' : '') +
                                                            (!rIndex && !dIndex ? ' first-title' : '')
                                                        }
                                                        colSpan={5}
                                                    >
                                                        <div>
                                                            {$date.format('dddd, MMM DD, YYYY')}
                                                            <span style={{ padding: '0px 6px' }}>|</span>
                                                            {t('1 worker||||%{smart_count} workers', {
                                                                smart_count: totalWorkersPerDay,
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                            {!addingProviderQuota &&
                                                dateTimes.map((dateTime) =>
                                                    getSummaryRow(dIndex, date, dateTime, rIndex, dataKey)
                                                )}

                                            {addingProviderQuota &&
                                                requestJobs.map(
                                                    (_job, index) =>
                                                        (!job || job.id === _job.id) &&
                                                        dayjs(_job.date).isSame(date) &&
                                                        getExpandedSummaryRows(index, _job)
                                                )}
                                        </>
                                    );
                                })}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};
