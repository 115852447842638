import { DefaultOptionType } from 'rc-select/lib/Select';
import { useLocalization } from '../Localization';
import { RecentItem } from 'types/RecentItem';


const getDefaultOptionObject = ({ id, name }: RecentItem) => ({
    label: name,
    value: id
});

const useRecentItems = () => {
    const { t } = useLocalization();

    const addToRecent = (value: number, recentData: number[], maxRecentItems: number): number[] => {
        if (value === null || value === undefined) {
            return recentData;
        }

        const index = recentData.indexOf(value);
        const localData = [...recentData];

        if (index !== -1) {
            localData.splice(index, 1);
        }

        if (localData.length >= maxRecentItems) {
            localData.splice(0, localData.length - maxRecentItems + 1);
        }

        localData.push(value);

        return localData;
    };

    const getSelectOptionsFor = (
        type: string,
        recentItems: number[],
        allItems: RecentItem[],
        getOptionObject: (item: RecentItem) => DefaultOptionType = getDefaultOptionObject
    ) => {
        const options: Array<DefaultOptionType> = [];

        if (!allItems.length) {
            return options;
        }

        const enabledRecentItems = recentItems.length > 0 ? allItems.filter((item) => recentItems.includes(item.id)) : [];

        const allOptions = allItems.map((item) => ({
            key: `all_option_${ item.id }`,
            ...getOptionObject(item)
        }));

        if (enabledRecentItems.length) {
            options.push(
                {
                    key: 'recent_group_1',
                    label: t('Recent'),
                    options: enabledRecentItems.map((item) => {
                        return {
                            key: `filter_option_${ item.id }`,
                            ...getOptionObject(item)
                        };
                    })
                },
                {
                    key: 'recent_group_2',
                    label: t(`All ${ type }`),
                    options: allOptions
                }
            );

            return options;
        }

        return allOptions;
    };

    return { addToRecent, getSelectOptionsFor };
};

export { useRecentItems };
