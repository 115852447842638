import { GenericObject } from 'shared/Contracts';

export const isValidEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

interface Location {
    search: string;
}

export const getQueryParam = (param: string, location: Location): any => {
    // no IE compatibility
    const search = new URLSearchParams(location.search).get(param);

    return search ? search.toString() : null;
};

export const toSnakeCase = (str: string) => {
    return str.replace(/\s+/g, '_').toLowerCase();
};

export const convertPropsToSnakeCase = (obj: any) => {
    const newData: GenericObject = {};

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const snakeCaseKey = toSnakeCase(key);
            newData[snakeCaseKey] = obj[key];
        }
    }

    return newData;
};

export const debounce = (func: (param: any) => unknown, duration = 500) => {
    let timeout: NodeJS.Timeout;

    return (param: any) => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            func(param);
        }, duration);
    };
};

export const mergeArrays = <T extends { id: number }>(array1: T[], array2: T[]): T[] => {
    const mergedArray = [...array2];

    array1.forEach((arr) => {
        const matchingIndex = mergedArray.findIndex((job) => job.id === arr.id);

        if (matchingIndex !== -1) {
            mergedArray[matchingIndex] = { ...mergedArray[matchingIndex], ...arr };
        } else {
            mergedArray.push(arr);
        }
    });

    return mergedArray;
};

export const getEnumValue = (theEnum: any, key?: string): any => {
    if (!key) {
        return;
    }

    const enumPair = Object.entries(theEnum).filter((value) => value[0] === key);

    return enumPair[0]?.[1];
};
