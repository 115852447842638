import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { UsersTable } from './UsersTable';

/**
 * Stateful component responsible for users management.
 *
 * @return {*}
 */
export const Users = () => {
    const { t } = useLocalization();

    return (
        <PageHeader title={t('Users')} backIcon={false} ghost={false}>
            <UsersTable />
        </PageHeader>
    );
};
