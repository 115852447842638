import { Tag, Tooltip } from 'antd';

type Props = {
    color: string;
    text: string;
    title: string;
}

export const TooltipTag = ({ color, text, title }: Props) => (
    <Tooltip title={ title } mouseEnterDelay={ 1 }>
        <Tag color={color}>{ text }</Tag>
    </Tooltip>
);
