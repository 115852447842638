import React from 'react';
import { GenericObject } from 'shared/Contracts';

export const ArchiveIcon = (props: GenericObject) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
        <path
            d="M11.6667 1.75H2.33335C2.02393 1.75 1.72719 1.87292 1.5084 2.09171C1.2896 2.3105 1.16669 2.60725 1.16669 2.91667V4.08333C1.16792 4.28693 1.22241 4.48667 1.32474 4.66268C1.42708 4.8387 1.57369 4.98487 1.75002 5.08667V11.0833C1.75002 11.3928 1.87294 11.6895 2.09173 11.9083C2.31052 12.1271 2.60727 12.25 2.91669 12.25H11.0834C11.3928 12.25 11.6895 12.1271 11.9083 11.9083C12.1271 11.6895 12.25 11.3928 12.25 11.0833V5.08667C12.4263 4.98487 12.573 4.8387 12.6753 4.66268C12.7776 4.48667 12.8321 4.28693 12.8334 4.08333V2.91667C12.8334 2.60725 12.7104 2.3105 12.4916 2.09171C12.2729 1.87292 11.9761 1.75 11.6667 1.75ZM2.33335 2.91667H11.6667V4.08333H2.33335V2.91667ZM2.91669 11.0833V5.25H11.0834V11.0833H2.91669Z"
            fill="black" />
        <path d="M4.66669 6.41669H9.33335V7.58335H4.66669V6.41669Z" fill="black" />
    </svg>
)
