import moment from 'moment/moment';
import { Job } from 'types/models';
import { getTime, getTimeDuration, getTimeRange, removeTimeSeconds } from 'lib/Helpers/DateTimeHelper';
import { FormattedJob } from 'types/staffing';
import { JobState } from 'types/staffing/JobStateMachine';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';

/**
 * Format jobs for table display
 */
export const formatJobs = (jobs: Job[], isClient: boolean): FormattedJob[] => jobs.map((job) => {
    const [
        startTime,
        endTime,
        startBreak,
        endBreak,
        confirmedStartTime,
        confirmedEndTime,
        confirmedStartBreak,
        confirmedEndBreak,
    ] = [
        job.start_time,
        job.end_time,
        job.start_break,
        job.end_break,
        job.confirmed_start_time,
        job.confirmed_end_time,
        job.confirmed_start_break,
        job.confirmed_end_break,
    ].map((time) => removeTimeSeconds(time || ''));
    const applicant = (job.applicants || []).find(
        ({ state }) => isClient ?
            state === JobApplicantState.ACCEPTED :
            [JobApplicantState.ACCEPTED, JobApplicantState.PROPOSED, JobApplicantState.OPEN].includes(state)
    );

    return {
        ...job,
        breakDuration: getTimeDuration(startBreak, endBreak),
        breakStartTime: getTime(job.start_break as string),
        canAssignWorker: (job.state === JobState.FILLED && !!applicant) || (job.state !== JobState.CANCELED && job.state !== JobState.FILLED),
        confirmedBreakDuration: getTimeDuration(confirmedStartBreak, confirmedEndBreak),
        confirmedBreakStartTime: getTime(job.confirmed_start_break as string),
        confirmedShiftDuration: getTimeDuration(confirmedStartTime, confirmedEndTime),
        confirmedTime: getTimeRange(confirmedStartTime, confirmedEndTime),
        confirmedIsDifferent: !!confirmedStartTime && (
            endTime !== confirmedEndTime ||
            startTime !== confirmedStartTime ||
            startBreak !== confirmedStartBreak ||
            endBreak !== confirmedEndBreak
        ),
        formattedDate: moment(job.date).format('ddd, D MMM YYYY'),
        key: job.id,
        applicantId: applicant?.id,
        applicantState: applicant?.state,
        providerName: applicant?.worker?.provider?.company_name,
        selected: false,
        shiftDuration: getTimeDuration(startTime, endTime),
        time: getTimeRange(startTime, endTime),
        workerId: applicant?.worker_id,
        workerName: applicant?.worker && `${applicant.worker.first_name} ${applicant.worker.last_name}`,
        data: job.data
    };
}).sort((a, b) => a.date.localeCompare(b.date));
