import { Button } from 'antd';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    [index: string]: any;
}

export const DashedButton = ({ children, ...otherProps }: Props) => (
    <Button style={ { borderStyle: 'dashed' } } ghost {...otherProps}>
        { children }
    </Button>
);
