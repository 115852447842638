import { BorderOutlined, CheckSquareTwoTone, CloseSquareOutlined, FilterFilled } from '@ant-design/icons';
import { Button, Dropdown, Space, Checkbox, MenuProps, Popover } from 'antd';
import { t } from 'lib/Localization';
import { useEffect, useState } from 'react';

type Props = {
    filterRequestJobs: (requestJobTableFilters: string[]) => void;
    isClient: boolean;
};

export const RequestJobTableFilters = ({ filterRequestJobs, isClient }: Props) => {
    const [open, setOpen] = useState(false);
    let [requestJobTableFilters, setRequestJobTableFilters] = useState<string[]>([]);

    const toggleFilters = (filter: string) => {
        if (requestJobTableFilters.includes(filter)) {
            requestJobTableFilters = requestJobTableFilters.filter((e) => e !== filter);
        } else {
            requestJobTableFilters.push(filter);
        }
        setRequestJobTableFilters(requestJobTableFilters);
        filterRequestJobs(requestJobTableFilters);
    };

    const handleOpenChange = (flag: boolean) => {
        setOpen(flag);
    };

    const renderCheckbox = (filterType: string, title: string, style: any) => {
        return (
            <>
                <Checkbox
                    onClick={() => toggleFilters(filterType)}
                    checked={requestJobTableFilters.includes(filterType)}
                    style={style}
                >
                    {t(title)}
                </Checkbox>
                <br />
            </>
        );
    };

    const items = [
        renderCheckbox('openShifts', 'Open shifts', {
            margin: '6px 0px',
        }),
        renderCheckbox('workedTimeConfirmed', 'Worked time confirmed', {
            margin: '6px 0px',
        }),
        renderCheckbox('workedTimeNotConfirmed', 'Worked time not confirmed', {
            marginTop: '6px',
        }),
    ];

    if (isClient) {
        items.unshift(
            renderCheckbox('filled', 'Filled', {
                marginBottom: '6px',
            })
        );
    } else {
        items.unshift(
            renderCheckbox('assignedByMe', 'Assigned by me', {
                marginBottom: '6px',
            })
        );
    }

    return (
        <>
            <Popover
                placement="bottomLeft"
                content={items}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                showArrow={false}
            >
                <Button
                    style={{
                        float: 'right',
                        marginBottom: '10px',
                        marginTop: '-32px',
                        borderRadius: '2px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    <FilterFilled />
                </Button>
            </Popover>
        </>
    );
};
