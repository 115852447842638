import { useEffect, useState } from 'react';
import { Layout as AntLayout, Menu, MenuProps } from 'antd';
import { Header } from 'shared/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'shared/ErrorFallback';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import { AppSection, OrganizationType } from 'types';
import { CollectionsProvider } from 'lib/Contexts/Collections';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useLocalization } from '../lib/Localization';
import { CommentOutlined } from '@ant-design/icons';
import { ClientProviderInviteIcon } from 'shared/icons/ClientProviderInviteIcon';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';
import { useAccount } from 'lib/Account';
import { useRouteMatch } from 'react-router-dom';

const { Sider, Footer } = AntLayout;

type Props = {
    section: AppSection;
};

export const Layout = ({ section }: Props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [requestNumber, setRequestNumber] = useState(null);
    const [userInvitations, setUserInvitations] = useState([]);
    const handleError = useErrorHandler();
    const location = useLocation();
    const SideMenu = section.sideMenu;
    const Content = section.content;
    const acceptedOrganizationTypes = [OrganizationType.CLIENT, OrganizationType.PROVIDER];
    const { accountUser: user } = useAccount();
    const match = useRouteMatch();

    const { locale, t } = useLocalization();

    useEffect(() => {
        const loadPendingRequests = async () => {
            try {
                const response = await Backend.get(`staffing/requests/pendingRequests`);
                const pendingRequests = response.data.count;
                setRequestNumber(pendingRequests);
            } catch (error) {
                handleError(error);
            }
        };

        loadPendingRequests();
    }, [handleError]);

    useEffect(() => {
        const getInvitationsCount = async () => {
            try {
                const userInvitationsResponse = await Backend.get('/account/user-invitations-count');
                const invitationNumber = userInvitationsResponse.data.invitationsCount;
                setUserInvitations(invitationNumber);
            } catch (error) {
                handleError(error);
            }
        };

        getInvitationsCount();
    }, [handleError]);

    const showFreshworksWidget = async () => {
        const freshWidget = (window as any).FreshworksWidget;
        if (typeof freshWidget === 'function') {
        
            freshWidget('identify', 'ticketForm', {
                name: user.profile.first_name + ' ' + user.profile.last_name,
                email: user.username
            });
    
            freshWidget('open');
        } 
    };

    const bottomMenuItems: MenuProps['items'] = [
        {
            className: 'sidebar-support-button',
            key: 'report_issue',
            icon: <CommentOutlined />,
            label: <a href="#" onClick={showFreshworksWidget}>
                {t('Support')}
            </a>,
        },
    ];

    if (userIsClient(user)) {
        bottomMenuItems.unshift({
            key: 'invite_provider',
            icon: <ClientProviderInviteIcon />,
            label: <Link to={`${match.path}/providers?invitation=open`}>{t('Invite new provider')}</Link>,
            className: 'sidebar-invite-button',
        })
    }

    if (userIsProvider(user)) {
        bottomMenuItems.unshift({
            key: 'invite_client',
            icon: <ClientProviderInviteIcon />,
            label: <Link to={`${match.path}/clients?invitation=open`}>{t('Invite new client')}</Link>,
            className: 'sidebar-invite-button',
        })
    }

    return (
        <CollectionsProvider>
            <AntLayout>
                <Header section={section} />
                <AntLayout>
                    <Sider
                        breakpoint="xxl"
                        collapsedWidth={80}
                        className="main-sidebar mdc-elevation--z24"
                        collapsed={collapsed}
                        onCollapse={() => setCollapsed(!collapsed)}
                        collapsible
                    >
                        <SideMenu
                            isCollapsed={collapsed}
                            requestNumber={
                                acceptedOrganizationTypes.includes(section.identifier as OrganizationType)
                                    ? requestNumber
                                    : 0
                            }
                            showRequestBadge={acceptedOrganizationTypes.includes(
                                section.identifier as OrganizationType
                            )}
                            invitationNumber={userInvitations}
                            showInvitationBadge={userInvitations}
                        />

                        {section.bottomMenu && (
                            <div className="bottom-menu text-center">
                                <Menu items={bottomMenuItems} mode="inline" selectable={false} theme="dark" />
                            </div>
                        )}
                    </Sider>
                    <AntLayout className="main-content">
                        <ErrorBoundary FallbackComponent={ErrorFallback} key={location.pathname}>
                            <Content setSidebarRequestNumber={setRequestNumber} />
                        </ErrorBoundary>
                        <Footer className="content-footer">© {new Date().getFullYear()} Smartbridge AG</Footer>
                    </AntLayout>
                </AntLayout>
            </AntLayout>
        </CollectionsProvider>
    );
};