import React from 'react';

type IconProps = {
    width?: number;
    height?: number;
};

function CalendarIcon({ width, height }: IconProps) {
    return (
        <svg
            className="svg-inline--fa fa-suitcase ant-menu-item-icon"
            width={width ?? 17}
            height={height ?? 17}
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5118 2.12857C3.5118 1.47486 4.05222 0.942856 4.71626 0.942856H5.73207C6.39612 0.942856 6.93653 1.47486 6.93653 2.12857V2.94286H9.60666V2.12857C9.60666 1.47486 10.1471 0.942856 10.8111 0.942856H11.8269C12.491 0.942856 13.0314 1.47486 13.0314 2.12857V2.94286H13.8586C15.0845 2.94286 16.0788 3.92174 16.0788 5.12857V7.31428H0.46437V5.12857C0.46437 3.92174 1.45873 2.94286 2.68464 2.94286H3.5118V2.12857ZM4.90491 2.31428V4.31428H2.68464C2.22812 4.31428 1.85748 4.67915 1.85748 5.12857V5.94286H14.6857V5.12857C14.6857 4.67915 14.3151 4.31428 13.8586 4.31428H11.6383V2.31428H10.9998V4.31428H5.54342V2.31428H4.90491ZM0.464149 8.31451H16.0786L16.0788 14.8426C16.0788 16.0495 15.0845 17.0284 13.8586 17.0284H2.68464C1.45873 17.0284 0.464383 16.0495 0.46437 14.8427C0.46437 14.8427 0.46437 14.8427 0.46437 14.8427L0.464149 8.31451ZM1.85731 9.68594L1.85748 14.8426C1.85748 15.2921 2.22812 15.657 2.68464 15.657H13.8586C14.3151 15.657 14.6857 15.2921 14.6857 14.8427C14.6857 14.8427 14.6857 14.8427 14.6857 14.8427L14.6855 9.68594H1.85731ZM2.78602 11.2859C2.78602 10.9072 3.09788 10.6002 3.48258 10.6002H12.77C13.1547 10.6002 13.4665 10.9072 13.4665 11.2859C13.4665 11.6646 13.1547 11.9717 12.77 11.9717H3.48258C3.09788 11.9717 2.78602 11.6646 2.78602 11.2859Z"
                fill="currentColor"
            />
        </svg>
    );
}

export { CalendarIcon };
