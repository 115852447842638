import { Form } from 'antd';

type LayoutType = Parameters<typeof Form>[0]['layout'];
type LabelAlignType = Parameters<typeof Form>[0]['labelAlign'];

/**
 * To be used with single column ant forms.
 *
 * @type {object}
 */
export const SingleColumnFormLayout = {
    formLayout: {
        layout: 'horizontal' as LayoutType,
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 16,
        },
        labelAlign: 'left' as LabelAlignType,
    },
    saveButtonLayout: {
        style: {
            paddingTop: '20px',
        },
    },
};

/**
 * To be used with double column ant forms.
 *
 * @type {object}
 */
export const DoubleColumnFormLayout = {
    formLayout: {
        layout: 'horizontal' as LayoutType,
        wrapperCol: {
            span: 22,
        },
        labelAlign: 'left' as LabelAlignType,
    },
    saveButtonLayout: {
        wrapperCol: {
            span: 22,
        },
        style: {
            paddingTop: '20px',
        },
    },
};
