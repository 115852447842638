import 'pages/Provider/Workers/Workers.scss';

import { useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Drawer, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useAccount } from 'lib/Account';
import { WorkersTable } from './WorkersTable';
import { WorkerForm } from './WorkerForm';
import { Backend } from 'lib/Http/Backend';
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Profession, Worker } from 'types/models';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';
import { ImportExcelModal, ValidationErrorCallbackProps } from './Modals/ImportExcelModal';
import { downloadExcel } from 'lib/Helpers/ExcelHelper';
import { ActionNeededModal } from './Modals/ActionNeededModal';
import { useNavigate } from 'react-router-dom-v5-compat';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';

const uploadExcelTemplateUrl = '/data-management/workers/uploadExcelTemplate';
const workersUrl = '/data-management/workers';
const workersWithProfessionsUrl = `${workersUrl}?relations=professions`;
const workersUpdateUrl = (id: number) => `/data-management/workers/${id}`;
const professionsUrl = '/data-management/professions?all';

export const Workers = () => {
    const { t } = useLocalization();
    const localizationContext = useLocalization();
    const { accountUser: user } = useAccount();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [importingInProgress, setImportingInProgress] = useState(false);
    const [openActionModal, setOpenActionModal] = useState(true);
    const [actionModalProps, setActionModalProps] = useState<ValidationErrorCallbackProps | {}>({});
    const providerId = user.profiles.agent?.provider_id as number;
    const [isWorkerFormOpen, setIsWorkerFormOpen] = useState(false);
    const [worker, setWorker] = useState<Worker>({} as Worker);
    let [allProfessions, isProfessionsLoading] = useLoadEntity<Profession>('professions', professionsUrl);
    let [workers, isWorkersLoading, setWorkers, reloadWorkers] = useLoadEntity<Worker>(
        'workers',
        workersWithProfessionsUrl
    );

    let translatedProfessions = localizeProfessions(allProfessions, localizationContext.locale);

    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    workers = workers.map((worker) => {
        worker.translatedProfessions = localizeProfessions(worker.professions, localizationContext.locale);
        return worker;
    });

    const openWorkerHandler = (worker?: Worker) => {
        setWorker(worker ?? ({ provider_id: providerId } as Worker));
        setIsWorkerFormOpen(true);
    };

    const verifyDataButton = (
        <a
            className="underline has-validation-error"
            href="#"
            onClick={(e) => {
                e.preventDefault();
                setOpen(false);
                setOpenActionModal(true);
            }}
        >
            {t('Verify data')}
        </a>
    );

    const importingIndicator = (importingInProgress || hasValidationError) && (
        <p className={`excel-spinner ${hasValidationError ? 'has-validation-error' : ''}`}>
            {hasValidationError ? (
                <CloseCircleOutlined
                    onClick={() => {
                        setActionModalProps({});
                        setHasValidationError(false);
                    }}
                />
            ) : (
                <Spin indicator={<LoadingOutlined spin />} />
            )}
            {hasValidationError ? t('Some data imported unsuccessfully.') : t('Excel importing in progress')}
            {hasValidationError && verifyDataButton}
        </p>
    );

    const headerButtons = [
        importingIndicator,
        <Button key="1" onClick={() => setOpen(true)}>
            {t('Import Excel')}
        </Button>,
        <Button key="2" type="primary" icon={<PlusOutlined />} onClick={() => openWorkerHandler()}>
            {t('Add Worker')}
        </Button>,
    ];

    const workerSavedCallback = () => {
        reloadWorkers();
    };

    const handleProfessionChange = async (
        worker: Worker,
        selectedProfessions: Profession[],
        fromEditWorkerForm = false
    ) => {
        if (worker?.id && !fromEditWorkerForm) {
            await Backend.put(workersUpdateUrl(worker.id), {
                ...worker,
                professions: selectedProfessions.map((profession) => profession.id),
            });
        }

        if (!fromEditWorkerForm) {
            reloadWorkers();
        } else {
            reloadWorkers().then(() => {
                setCurrentPage(currentPage);
            });
        }
    };

    const onTemplateValidationError = ({ parsedWorkers, templateErrors }: ValidationErrorCallbackProps) => {
        setOpenActionModal(true);
        setHasValidationError(true);
        setActionModalProps({
            parsedWorkers,
            templateErrors,
        });
        setOpen(false);
    };

    const handleDownloadExcelTemplate = () =>
        downloadExcel(
            `${t('Workers')}_Tempcloud.xlsx`,
            [
                {
                    [t('First name')]: '',
                    [t('Last name')]: '',
                    [t('Email address')]: '',
                    [t('Phone number')]: '',
                    [t('External ID')]: '',
                    [t('Shirt size')]: '',
                    [t('Trousers size')]: '',
                },
            ],
            [
                { wch: 20 }, // first column width
                { wch: 20 }, // so on...
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ]
        );

    const onImportSuccess = () => {
        setActionModalProps({});
        setHasValidationError(false);
        setOpen(false);

        reloadWorkers();
    };

    return (
        <>
            <PageHeader
                backIcon={false}
                className="provider-workers"
                extra={headerButtons}
                ghost={false}
                onBack={() => navigate(-1)}
                title={t('Workers')}
            >
                <WorkersTable
                    handleProfessionChange={handleProfessionChange}
                    allProfessions={allProfessions}
                    workersUrl={workersUrl}
                    workers={workers}
                    openHandler={openWorkerHandler}
                    loadWorkersData={reloadWorkers}
                    pagination={{
                        current: currentPage,
                        pageSize: 10,
                        total: 0,
                    }}
                    isDataLoading={isWorkersLoading || isProfessionsLoading}
                    onPaginationChange={handlePaginationChange}
                />
                <Drawer
                    title={worker.id ? t('Edit worker') : t('Add Worker')}
                    width={500}
                    onClose={() => setIsWorkerFormOpen(false)}
                    open={isWorkerFormOpen}
                >
                    <WorkerForm
                        allProfessions={translatedProfessions}
                        handleProfessionChange={handleProfessionChange}
                        worker={worker}
                        workerSavedCallback={workerSavedCallback}
                        setIsFormOpen={setIsWorkerFormOpen}
                    />
                </Drawer>

                <ActionNeededModal
                    className="provider-workers"
                    uploadUrl={uploadExcelTemplateUrl}
                    open={openActionModal}
                    isLoading={false}
                    onClose={() => {
                        setOpen(false);
                        setOpenActionModal(false);
                    }}
                    onSuccess={onImportSuccess}
                    onUploadAgain={() => {
                        setOpenActionModal(false);
                        setHasValidationError(false);
                        setOpen(true);
                    }}
                    {...actionModalProps}
                />
                <ImportExcelModal
                    open={open}
                    isLoading={importingInProgress}
                    okButtonText={t('Import')}
                    onStart={() => {
                        setActionModalProps({});
                        setImportingInProgress(true);
                    }}
                    onCancel={() => {
                        setOpen(false);
                        setOpenActionModal(false);
                    }}
                    onSuccess={onImportSuccess}
                    onEnd={() => setImportingInProgress(false)}
                    downloadExcelTemplate={handleDownloadExcelTemplate}
                    onValidationError={onTemplateValidationError}
                    uploadUrl={uploadExcelTemplateUrl}
                />
            </PageHeader>
        </>
    );
};
