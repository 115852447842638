import { Job, Request } from 'types/models';
import { GenericObject } from 'shared/Contracts';
import { differenceInMinutes } from 'date-fns';
import { FormattedJob, FormRequest } from 'types/staffing';
import _ from 'lodash';
import moment from 'moment/moment';
import { getStartEndTimesInTheMiddleOfInterval, removeTimeSeconds, timeFormat } from 'lib/Helpers/DateTimeHelper';
import { FormInstance } from 'antd';
import { formatJobs } from 'shared/Requests/helpers';

export const TimesValue = {
    SAME: 0,
    DIFFERENT: 1,
};

export const compileDateTimes = (jobs: Job[]) => {
    const dateTimes: GenericObject = {};
    let jobsIterated: number[] = [];

    for (let i = 0; i < jobs.length; i++) {
        const job = jobs[i];

        if (jobsIterated.includes(job.id)) {
            continue;
        }

        let workersNumber = 0;
        for (let j = 0; j < jobs.length; j++) {
            if (
                job.date === jobs[j].date &&
                job.start_time === jobs[j].start_time &&
                job.end_time === jobs[j].end_time &&
                job.start_break === jobs[j].start_break &&
                job.end_break === jobs[j].end_break
            ) {
                workersNumber++;
                jobsIterated.push(jobs[j].id);
            }
        }

        const key = job.start_time + job.end_time + job.start_break + job.end_break;

        const breakDuration =
            job.start_break && job.end_break
                ? getDurationMinutes(job.date, job.start_break, job.end_break)
                : undefined;

        const startTime = removeTimeSeconds(job.start_time);
        const endTime = removeTimeSeconds(job.end_time);

        const newTimeObj = {
            workersNumber: workersNumber,
            breakStartTime: removeTimeSeconds(job.start_break || ''),
            breakDuration,
            comment: job.comment,
            shiftTimes: {
                times: [startTime, endTime],
                touched: false,
            },
        };

        const dateObj = Object.values(dateTimes)
            .flat()
            .find(({ date }: GenericObject) => date === job.date);

        if (dateObj) {
            dateObj.times.push(newTimeObj);
        } else {
            if (!dateTimes[key]) {
                dateTimes[key] = [];
            }

            dateTimes[key].push({
                date: job.date,
                times: [newTimeObj],
            });
        }
    }

    return dateTimes;
};

export const isWeekendDay = (date?: Date) => {
    if (!date) {
        return false;
    }

    const day = date.getDay();
    return day === 6 || day === 0; // 6 = Saturday, 0 = Sunday
};

export const addGroupSerialNumber = (data: GenericObject[], groupKey: string, serialNumberKey: string) => {
    let serialNumber: number = 0;
    let group: any = null;

    return data.map((_data) => {
        if (group !== _data[groupKey]) {
            group = _data[groupKey];
            serialNumber = 0;
        }

        _data[serialNumberKey] = ++serialNumber;

        return _data;
    });
};

export const countGroupItems = (data: GenericObject[], groupKey: string) => {
    const groupCount: GenericObject = {};

    data.forEach((_data) => {
        if (!groupCount[_data[groupKey]]) {
            groupCount[_data[groupKey]] = 0;
        }

        groupCount[_data[groupKey]]++;
    });

    return groupCount;
};

export const getDurationMinutes = (date: string, start: string, end: string) => differenceInMinutes(
    new Date(date + ' ' + end),
    new Date(date + ' ' + start)
);

export const getJobUniqueDates = (jobs: Job[]) => [...new Set(jobs.map(({ date }) => date))];

export const pluckToObject = (key: string, data: GenericObject[], objectKey: string) => {
    const items: GenericObject = {};

    data.forEach((_data) => {
        items[_data[objectKey]] = _data[key];
    });

    return items;
};

export const prepareRequestJobs = (formData: FormRequest, selectedDates: Date[], expand: boolean = false) => {
    const jobs = [];
    const preparedRequest = _.cloneDeep(formData);

    for (const date of preparedRequest.dates || []) {
        for (const time of date.times || []) {
            const { breakDuration, breakStartTime, shiftTimes, workersNumber, comment } = time;
            const momentBreakStartTime = moment(breakStartTime, timeFormat);

            if (shiftTimes?.times) {
                const [startTime, endTime] = shiftTimes.times;
                const breakDurationInt = +breakDuration;
                let startBreak;
                let endBreak;

                if (breakDurationInt > 0) {
                    if (momentBreakStartTime.isValid()) {
                        startBreak = momentBreakStartTime.format(timeFormat);
                        endBreak = moment(momentBreakStartTime).add(breakDurationInt, 'minutes').format(timeFormat);
                    } else {
                        [startBreak, endBreak] = getStartEndTimesInTheMiddleOfInterval(
                            startTime,
                            endTime,
                            breakDurationInt
                        );
                    }
                }

                if (!startTime || !endTime) {
                    continue;
                }

                for (let i = 0; i < workersNumber; i++) {
                    jobs.push({
                        date: date.date,
                        end_break: endBreak,
                        end_time: endTime,
                        providers: [],
                        start_break: startBreak,
                        start_time: startTime,
                        comment,
                    });
                }
            }
        }
    }

    const uniqueDates = getJobUniqueDates(jobs as unknown as Job[]);

    if (expand && uniqueDates.length === 1) {
        const datesToReplicate = [...selectedDates];
        datesToReplicate.shift();
        const jobsClone = [...jobs];

        for (const dateToReplicate of datesToReplicate) {
            let workerNumber = 1;

            for (const job of jobsClone) {
                jobs.push({
                    ...job,
                    date: moment(dateToReplicate).format('YYYY-MM-DD'),
                });
            }
        }
    }

    return jobs;
};

export const prepareRequestData = (formData: FormRequest, differentTimes: number, selectedDates: Date[]) => {
    const preparedRequest = _.cloneDeep(formData);

    if (!preparedRequest.jobs?.length) {
        preparedRequest.jobs = prepareRequestJobs(formData, selectedDates, !differentTimes);
    }

    delete preparedRequest.dates;
    delete preparedRequest.datePicker;
    delete preparedRequest.differentTimes;
    delete preparedRequest.selectedDates;
    delete preparedRequest.order_name;
    delete (preparedRequest as any).providerWorkerQuotas;

    return preparedRequest;
};

export const requestToFormRequest = (request: Request) => {
    const dateTimes = compileDateTimes(request.jobs);
    const dates = Object.values(dateTimes).flat();
    const actualDates = dates.map(({ date }) => new Date(date));

    return {
        id: request.id,
        address_id: request.address_id,
        acceptance_type: request.acceptance_type,
        datePicker: actualDates,
        dates,
        department_id: request.department_id,
        differentTimes: isDifferentTimes(request),
        documents: request.documents,
        jobs: request.jobs,
        notes: request.notes,
        profession_id: request.profession_id,
        providers: request.provider_requests.map(({ provider_id }) => provider_id),
        selectedDates: actualDates,
        type: request.type,
    } as unknown as FormRequest;
};

export const updateJobListing = (
    form: FormInstance,
    dates: Date[],
    sameOrDifferentTimes?: number,
    selectingDates = false
) => {
    const formDatesField = form.getFieldValue('dates');

    if (dates.length > 0) {
        const emptyJob = [{ workersNumber: 1, comment: null }];
        let formDates: any[];

        if (sameOrDifferentTimes === TimesValue.DIFFERENT) {
            formDates = dates.map((date: Date) => {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                const formDateAlreadyPresent = formDatesField?.find(
                    ({ date }: { date: string }) => date === formattedDate
                );

                return (
                    formDateAlreadyPresent ?? {
                        date: formattedDate,
                        times: (selectingDates || !formDatesField) ? [...emptyJob] : formDatesField[0].times,
                    }
                );
            });
        } else {
            formDates = [
                {
                    date: moment(dates[0]).format('YYYY-MM-DD'),
                    times: formDatesField?.[0] ? formDatesField[0].times : [...emptyJob],
                },
            ];
        }

        form.setFieldsValue({
            dates: formDates,
        });

        return formDates;
    }
};

export const isDifferentTimes = (request: Request) => {
    let isDifferentTimes = TimesValue.SAME;

    let jobDays: any = [];
    let jobDaysTemp: any = {};

    (request.jobs || []).forEach(function (v: any) {
        if (typeof jobDaysTemp[v.date] === 'undefined') {
            jobDaysTemp[v.date] = [];
        }
        jobDaysTemp[v.date].push({
            start_time: v.start_time,
            end_time: v.end_time,
            start_break: v.start_break,
            end_break: v.end_break,
        });
    });

    for (const [key, value] of Object.entries(jobDaysTemp)) {
        jobDays.push(value);
    }

    for (let i = 0; i < jobDays.length; i++) {
        for (let j = 0; j < jobDays.length; j++) {
            if (i != j) {
                if (!_.isEqual(jobDays[i], jobDays[j])) {
                    isDifferentTimes = TimesValue.DIFFERENT;
                }
            }
        }
    }

    return isDifferentTimes;
};

export const isJobDateValid = (job: FormattedJob) => {
    const currentDateTime = new Date();
    const jobDateTime = new Date(`${job.date}T${job.start_time}`);

    return jobDateTime >= currentDateTime;
};

export const requestHasFutureJob = (request: Request, isClient: boolean) =>
    formatJobs(request.jobs, isClient).some(isJobDateValid);
