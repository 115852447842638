import { createContext, useContext } from 'react';
import { Locale } from 'types';

const t = (key: string, options: object = {}): string => '';

export type LocalizationFunction = typeof t;

export type LocalizationContext = {
    changeLocale: (localShortCode: string) => void,
    locale: Locale,
    t: LocalizationFunction,
};

export const LocalizationContext = createContext<LocalizationContext>({
    locale: {} as Locale,
    changeLocale: (localeShortCode: string) => {},
    t,
});

export function useLocalization() {
    return useContext(LocalizationContext);
}
