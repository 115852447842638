import { AppSection, MemberType, OrganizationType } from 'types';
import { SideMenu } from './SideMenu';
import { Client } from './Client';

/**
 * Client sub application.
 */
export const ClientSection: AppSection = {
    bottomMenu: true,
    identifier: OrganizationType.CLIENT,
    userType: MemberType.CONTACT,
    sideMenu: SideMenu,
    content: Client,
};
