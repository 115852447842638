import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const getDateRangePresets = (
    translator: (key: string, options?: {}) => string
): {
    label: string;
    value: [Dayjs, Dayjs];
}[] => {
    const nextWeek = dayjs().add(1, 'weeks');
    const nextMonth = dayjs().add(1, 'months');

    return [
        { label: translator('This Week'), value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
        { label: translator('Next Week'), value: [nextWeek.startOf('isoWeek'), nextWeek.clone().endOf('isoWeek')] },
        { label: translator('This Month'), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: translator('Next Month'), value: [nextMonth.startOf('month'), nextMonth.clone().endOf('month')] },
    ];
};

export { getDateRangePresets };
