import React from 'react';

type IconProps = {
    width?: number;
    height?: number;
};

function ChatInfoIcon({ width, height }: IconProps) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 4.80775C10.232 4.80775 10.4246 4.73108 10.5778 4.57775C10.7311 4.42458 10.8077 4.232 10.8077 4C10.8077 3.768 10.7311 3.57542 10.5778 3.42225C10.4246 3.26892 10.232 3.19225 10 3.19225C9.768 3.19225 9.57542 3.26892 9.42225 3.42225C9.26892 3.57542 9.19225 3.768 9.19225 4C9.19225 4.232 9.26892 4.42458 9.42225 4.57775C9.57542 4.73108 9.768 4.80775 10 4.80775ZM9.25 12.7115H10.75V6.673H9.25V12.7115ZM0.5 19.0385V2.30775C0.5 1.80258 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80258 19.5 2.30775V13.6923C19.5 14.1974 19.325 14.625 18.975 14.975C18.625 15.325 18.1974 15.5 17.6923 15.5H4.0385L0.5 19.0385ZM3.4 14H17.6923C17.7693 14 17.8398 13.9679 17.9038 13.9038C17.9679 13.8398 18 13.7692 18 13.6923V2.30775C18 2.23075 17.9679 2.16025 17.9038 2.09625C17.8398 2.03208 17.7693 2 17.6923 2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V15.3848L3.4 14Z"
                fill="white"
            />
        </svg>
    );
}

export { ChatInfoIcon };
