import './RequestAcceptanceType.scss';

import { Checkbox, Col, Form, FormInstance, Popover, Row, Select, Space, Typography } from 'antd';
import { useLocalization } from 'lib/Localization';
import { useEffect, useState } from 'react';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Authentication, useAccount } from 'lib/Account';
import { Backend } from 'lib/Http/Backend';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { Category, Contact } from 'types/models';

const { Title } = Typography;

type Props = {
    form: FormInstance;
    formData: any;
};

export enum ACCEPTANCE_TYPE {
    automatic = 1,
    manual = 2,
}

export const RequestAcceptanceType = ({ form, formData }: Props) => {
    const { t } = useLocalization();
    const { accountUser: user } = useAccount();
    const isClient = userIsClient(user);

    const [saveDefault, setSaveDefault] = useState(false);

    const storeDefaultType = () => {
        if (saveDefault) {
            const acceptance_type = form.getFieldValue('acceptance_type');

            if (isClient) {
                (user.profile as Contact).acceptance_type = acceptance_type;

                Backend.put(`/data-management/contacts/${user.profile.id}`, {
                    acceptance_type: acceptance_type,
                });
            }
        }
    };

    useEffect(() => {
        if (form && !form.getFieldValue('acceptance_type')) {
            let acceptance_type = (user.profile as Contact).acceptance_type;

            form.setFieldValue('acceptance_type', acceptance_type);
            formData.acceptance_type = acceptance_type;
        }
    }, [form]);

    useEffect(() => {
        storeDefaultType();
    }, [saveDefault]);

    const popupContent = t(
        'Automatically approve the first candidate proposed to your shift or review each candidate and manually approve them for each shift.'
    );

    return (
        <>
            <Title className="subsection-title" level={5}>
                <Space>
                    {t('Employee acceptance type')}
                    <Popover
                        color="#000"
                        content={popupContent}
                        overlayClassName="request-acceptance-info-popup"
                        placement="topLeft"
                        style={{ color: '#fff' }}
                        trigger="click"
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </Space>
            </Title>

            <Row id="request-acceptance">
                <Col span={16}>
                    <Form.Item
                        name="acceptance_type"
                        rules={[
                            {
                                required: true,
                                message: t('Please select an acceptance type'),
                            },
                        ]}
                    >
                        <Select
                            onChange={storeDefaultType}
                            options={[
                                { label: t('Automatic acceptance'), value: ACCEPTANCE_TYPE.automatic },
                                { label: t('Manual acceptance'), value: ACCEPTANCE_TYPE.manual },
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col className="default-checkbox">
                    <Form.Item>
                        <Checkbox checked={saveDefault} onChange={() => setSaveDefault(!saveDefault)}>
                            {t('Set as default')}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
