import { notification } from 'antd';
import { polyglot } from 'lib/Localization/Polyglot';
import { find, findIndex } from 'lodash';
import { Backend } from 'lib/Http/Backend';
import { t } from 'lib/Localization/Translation';
import { locale as setMomentLocale } from 'moment';
import { Locale } from 'types';
import { locale as setDayjsLocale } from 'dayjs';

export const locales: Locale[] = [
    {
        name: 'Deutsch',
        shortCode: 'de',
        isDefault: false,
        isLoaded: false,
        libraries: {
            antD: {
                shortCode: 'de_DE',
            },
            dayjs: {
                shortCode: 'de',
            },
            moment: {
                shortCode: 'de',
            },
            polyglot: {
                shortCode: 'de',
                phrases: undefined,
            },
        },
    },
    {
        name: 'English',
        shortCode: 'en',
        isDefault: true,
        isLoaded: false,
        libraries: {
            antD: {
                shortCode: 'en_US',
            },
            dayjs: {
                shortCode: 'en',
            },
            moment: {
                shortCode: 'en-in',
            },
            polyglot: {
                shortCode: 'en',
                phrases: undefined,
            },
        },
    },
];

export async function loadLocale(localeShortCode: string) {
    const localeIndex = findIndex(locales, { shortCode: localeShortCode });
    const locale: Locale = locales[localeIndex];

    if (!locale.isLoaded) {
        for (let library of ['moment', 'dayjs', 'antD', 'polyglot']) {
            const libraryLocale = locale.libraries[library];

            switch (library) {
                case 'polyglot':
                    try {
                        const response = await Backend.get('/localization/translations/' + locale.shortCode);
                        locale.libraries[library].phrases = response.data;
                    } catch (error) {
                        notification.error({
                            message: t('An error occurred while fetching translations'),
                        });
                    }
                    break;

                case 'moment':
                    await import('moment/locale/' + libraryLocale.shortCode);
                    break;

                case 'dayjs':
                    await import('dayjs/locale/' + libraryLocale.shortCode + '.js');
                    break;

                case 'antD':
                    const loadedLocale = await import('antd/es/locale/' + libraryLocale.shortCode + '.js');
                    locale.libraries[library].locale = loadedLocale.default;
                    break;

                default:
                    break;
            }
        }

        locale.isLoaded = true;
    }

    setDayjsLocale(locale.libraries.dayjs.shortCode);
    setMomentLocale(locale.libraries.moment.shortCode);
    polyglot.locale(locale.libraries.polyglot.shortCode);
    polyglot.replace(locale.libraries.polyglot.phrases);

    return locale;
}

export const getCurrentLocale = (localeShortCode: string) => {
    const localeFromBrowser = [
        ...(window.navigator.languages || []),
        window.navigator.language,
        window.navigator.browserLanguage,
        window.navigator.userLanguage,
        window.navigator.systemLanguage,
    ]
        .filter(Boolean)
        .map((language) => language.substr(0, 2))
        .find((language) => find(locales, { shortCode: language }));
    const localeFilter = localeShortCode
        ? { shortCode: localeShortCode }
        : localeFromBrowser
        ? { shortCode: localeFromBrowser }
        : { isDefault: true };

    return find(locales, localeFilter) as Locale;
};
