import { JobApplicant, Order, Request } from 'types/models';
import { LocalizationContext } from 'lib/Localization';
import * as XLSX from 'xlsx-js-style';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { LocalizationFunction } from 'lib/Localization/LocalizationContext';
import { Locale } from 'types';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';
import { getTimeDuration, removeTimeSeconds } from 'lib/Helpers/DateTimeHelper';
import { GenericObject } from 'shared/Contracts';
import { JobState } from 'types/staffing/JobStateMachine';
import { ShirtSizes, TrousersSize } from 'pages/Provider/Workers/WorkerForm';
import { getEnumValue } from 'lib/Helpers/General';

export const downloadExcel = async (order: Order, { locale, t }: LocalizationContext, request?: Request) => {
    const requestRows = compileRequestRows(request ? [request] : order.requests, locale);
    const requestHeaders = getRequestHeaders(t);
    const mainHeaders = addBackgroundColor(
        fillEmptyColumns({
            profession: t('Request details'),
            shift_date: t('Shift time'),
            assignee_provider: t('Assignee'),
            confirmed_start_time: t('Worked time')
        }),
        {
            profession: '#C9DAF8',
            shift_date: '#D9EAD3',
            assignee_provider: '#F9CB9C',
            confirmed_start_time: '#FCE5CD',
        }
    );

    const allRows = [
        rowObjToArr(
            fillEmptyColumns({ profession: t('Order name'), department: order.name }),
            {
                profession: addBoldOptions(),
            },
        ),
        rowObjToArr(
            fillEmptyColumns({
                profession: t('Contact person'),
                department: order.created_by.first_name + ' ' + order.created_by.last_name
            }),
            {
                profession: addBoldOptions(),
            },
        ),
        {},
        mainHeaders.map((col) => addBoldOptions(col)),
        rowObjToArr(requestHeaders).map(addBorderOptions).map(addBoldOptions),
        ...requestRows.flat().map((row) => rowObjToArr(row)),
    ];

    let rowToMerge = 4;

    // remove order name
    if (request && order.auto_created) {
        allRows.shift();

        rowToMerge = 3;
    }

    const worksheet = XLSX.utils.json_to_sheet(allRows, { skipHeader: true });

    worksheet['!cols'] = getColWidths(allRows);

    if (!worksheet['!merges']) {
        worksheet['!merges'] = [];
    }

    worksheet['!merges']!.push(
        XLSX.utils.decode_range(`A${rowToMerge}:C${rowToMerge}`),
        XLSX.utils.decode_range(`D${rowToMerge}:H${rowToMerge}`),
        XLSX.utils.decode_range(`I${rowToMerge}:M${rowToMerge}`),
        XLSX.utils.decode_range(`N${rowToMerge}:Q${rowToMerge}`),
    );

    const workbook = XLSX.utils.book_new();
    const filename = !request ?
        `Tempcloud_${order.name.replaceAll(' ', '_')}` :
        `Tempcloud_${localizeProfession(request.profession, locale).name.replaceAll(' ', '_')}`;

    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
};

const addBorderOptions = (options: GenericObject = {}) => {
    if (!options.s) {
        options.s = {};
    }

    options.s.border = {
        top: 'medium',
        bottom: 'medium',
        left: 'medium',
        right: 'medium',
    };

    return options;
};

const addBoldOptions = (options: GenericObject = {}) => {
    if (!options.s) {
        options.s = {};
    }

    if (!options.s.font) {
        options.s.font = {};
    }

    options.s.font.bold = true;

    return options;
};

const getRequestHeaders = (t: LocalizationFunction) => ({
    profession: t('Profession'),
    department: t('Department'),
    location: t('Location'),
    shift_date: t('Date'),
    shift_start_time: t('Start time'),
    shift_end_time: t('End time'),
    shift_break: t('Break'),
    shift_break_start: t('Break start'),
    assignee_provider: t('Provider'),
    assignee_name: t('Name'),
    assignee_shirt_size: t('Shirt size'),
    assignee_trousers_size: t('Trousers size'),
    assignee_info: t('Additional info'),
    confirmed_start_time: t('Start time'),
    confirmed_end_time: t('End time'),
    confirmed_break: t('Break'),
    confirmed_break_start: t('Break start')
});

const compileRequestRows = (requests: Request[], locale: Locale) => requests.map((request) =>
    request.jobs.filter((job) => job.state !== JobState.CANCELED)
        .sort((jobA, jobB) => {
            const a = `${jobA.date}_${jobA.start_time}_${jobA.end_time}`;
            const b = `${jobB.date}_${jobB.start_time}_${jobB.end_time}`;

            return a.localeCompare(b);
        })
        .map((job, index) => {
            const jobApplicant: JobApplicant | undefined = job.applicants?.find(
                ({ state }) => state === JobApplicantState.ACCEPTED
            );

            return {
                profession: index ? '' : localizeProfession(request.profession, locale).name,
                department: index ? '' : request.department?.name,
                location: index ? '' : `${request.address?.name || ''}, ${request.address?.address || ''}, ${request.address?.zip_code || ''} ${request.address?.city || ''}, ${request.address?.country?.name || ''}`,
                shift_date: job.date,
                shift_start_time: removeTimeSeconds(job.start_time),
                shift_end_time: removeTimeSeconds(job.end_time),
                shift_break: getTimeDuration(job.start_break, job.end_break),
                shift_break_start: removeTimeSeconds((job.start_break || '')),
                assignee_provider: jobApplicant?.worker.provider.company_name || '',
                assignee_name: (jobApplicant?.worker.first_name || '') + ' ' + (jobApplicant?.worker.last_name || ''),
                assignee_shirt_size: getEnumValue(ShirtSizes, jobApplicant?.worker.shirt_size) || '',
                assignee_trousers_size: getEnumValue(TrousersSize, jobApplicant?.worker.trousers_size) || '',
                assignee_info: jobApplicant?.worker.additional_information || '',
                confirmed_start_time: removeTimeSeconds((job.confirmed_start_time || '')),
                confirmed_end_time: job.confirmed_end_time || '',
                confirmed_break: job.confirmed_start_time ? getTimeDuration(job.confirmed_start_break, job.confirmed_end_break) : '',
                confirmed_break_start: removeTimeSeconds((job.confirmed_start_break || '')),
            };
        }));

const rowObjToArr = (row: GenericObject, colOptions: GenericObject = {}) => Object.keys(row).map((key) => ({
    v: row[key],
    t: 's',
    ...(colOptions[key] || {}),
}));

const fillEmptyColumns = (row: GenericObject) => ({
    profession: '',
    department: '',
    location: '',
    shift_date: '',
    shift_start_time: '',
    shift_end_time: '',
    shift_break: '',
    shift_break_start: '',
    assignee_provider: '',
    assignee_name: '',
    assignee_shirt_size: '',
    assignee_trousers_size: '',
    assignee_info: '',
    confirmed_start_time: '',
    confirmed_end_time: '',
    confirmed_break: '',
    confirmed_break_start: '',
    ...row
});

const getColWidths = (rows: GenericObject[]) => {
    const columns = Object.keys(fillEmptyColumns({}));

    return columns.map((col, index) => {
        const maxWidth = rows.reduce((w, r) => Math.max(w, r[index]?.v?.length || 0), 10);

        return { wch: Math.min(maxWidth, 40) };
    });
};

const addBackgroundColor = (row: GenericObject, colors: GenericObject) => {
    const rowArr = [];

    for (const key in row) {
        const colObj: GenericObject = {
            v: row[key],
            t: 's',
        };

        if (colors[key]) {
            colObj.s = {
                fill: {
                    patternType: 'solid',
                    fgColor: {
                        rgb: colors[key].replace('#', ''),
                    },
                },
            };
        }

        rowArr.push(colObj);
    }

    return rowArr;
}
