import { DeletePopconfirm } from 'shared/AntDesignUtils/DeletePopconfirm';
import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, notification, Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getDefaultPaginationProp } from 'lib/Helpers/TableHelper';
import { ProfessionTranslation } from 'types/models';
import { ColumnsType } from 'antd/lib/table';

type Props = {
    professionTranslations: ProfessionTranslation[];
    loadProfessionData: () => Promise<void>;
    openProfessionTranslationFormHandler: (professionTranslation: ProfessionTranslation) => void;
};

/**
 * Table to display profession translations
 *
 * @param professionTranslations
 * @param openProfessionTranslationFormHandler
 * @param loadProfessionData
 * @returns {JSX.Element}
 * @constructor
 */
export const ProfessionTranslationsTable = ({
    professionTranslations,
    loadProfessionData,
    openProfessionTranslationFormHandler,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataDeleting, setIsDataDeleting] = useState(false);

    /**
     * Delete a profession translation by id.
     *
     * @param id {int}
     * @return {Promise<void>}
     */
    const deleteProfessionTranslationHandler = async (id: number) => {
        try {
            setIsDataDeleting(true);
            const response = await Backend.delete(`/data-management/profession-translations/${id}`);
            if (response.status === Backend.responseStatus.HTTP_NO_CONTENT) {
                notification.success({
                    message: t('Profession translation successfully removed'),
                });
                loadProfessionData();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setIsDataDeleting(false);
        }
    };

    /**
     * Render cell for profession name.
     *
     * @param record { object }
     * @return {*}
     */
    const renderNameCell = (record: ProfessionTranslation) => {
        return (
            <Button type="link" onClick={() => openProfessionTranslationFormHandler(record)}>
                {record.name}
            </Button>
        );
    };
    /**
     * Render cell for profession's language name.
     *
     * @param record { object }
     * @returns {*}
     */
    const renderLanguageCell = (record: ProfessionTranslation) => record.language?.name;

    /**
     * Render a cell with action buttons.
     *
     * @param key {string}
     * @return {*}
     */
    const renderActionCell = (record: ProfessionTranslation) => (
        <Space>
            <EditOutlined onClick={() => openProfessionTranslationFormHandler(record)} />
            <DeletePopconfirm
                title={t('Are you sure you want to delete this profession translation?')}
                id={record.id}
                isDataDeleting={isDataDeleting}
                deleteHandler={deleteProfessionTranslationHandler}
            />
        </Space>
    );

    const columns: ColumnsType<ProfessionTranslation> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => renderNameCell(record),
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Language'),
            dataIndex: 'language_id',
            key: 'language_id',
            render: (value, record) => renderLanguageCell(record),
        },
        {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => renderActionCell(record),
        },
    ];

    return (
        <>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={professionTranslations}
                pagination={getDefaultPaginationProp(professionTranslations.length)}
            />
        </>
    );
};
