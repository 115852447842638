import 'shared/Organizations/Organization/OrganizationDetails.scss';
import { useLocalization } from 'lib/Localization';
import { Col, Row } from 'antd';
import { Organization } from 'types/models';
import { OrganizationDetailsProfessionsTable } from 'shared/Organizations/Organization/OrganizationDetailsProfessionsTable';

type Props = {
    organization: Organization;
};

const DescriptionItem = ({ title, content }: { title: string; content?: string | null }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

/**
 * Component to display organization details.
 *
 * @param organization
 * @returns {JSX.Element}
 * @constructor
 */
export const OrganizationDetails = ({ organization }: Props) => {
    const { t } = useLocalization();

    return (
        <div className="organization-details">
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('Name')} content={organization.company_name} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('Phone')} content={organization.phone_number} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('Address 1')} content={organization.address_line_1} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('Address 2')} content={organization.address_line_2} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('Zip')} content={organization.zip_code} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('City')} content={organization.city} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('Country')} content={organization.country.name} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('Description')} content={organization.description} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <p className="site-description-item-profile-p-label">{t('Professions')}:</p>
                    <OrganizationDetailsProfessionsTable professions={organization.professions} />
                </Col>
            </Row>
        </div>
    );
};
