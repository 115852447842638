import React from 'react';

type IconProps = {
    width?: number;
    height?: number;
};

function ClientProviderInviteIcon({ width, height }: IconProps) {
    return (
        <svg
            className="svg-inline--fa fa-suitcase ant-menu-item-icon"
            width={width ?? 16}
            height={height ?? 16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 1.5C6.74 1.5 6.47 1.59 6.28 1.78C6.09 1.97 6 2.24 6 2.5V3H1.5V13H7.1C7.035 12.68 7 12.345 7 12H2.5V8.72C2.795 8.89 3.135 9 3.5 9H8.005C8.285 8.62 8.625 8.28 9.005 8H3.5C2.905 8 2.5 7.595 2.5 7V4H13.5V7C13.5 7.08 13.495 7.155 13.48 7.225C13.84 7.335 14.18 7.485 14.5 7.67V3H10V2.5C10 2.24 9.91 1.97 9.72 1.78C9.53 1.59 9.26 1.5 9 1.5H7ZM7 2.5H9V3H7V2.5ZM4.5 6V7.5H5.5V6H4.5ZM10.5 6V7.23C10.8256 7.12824 11.1606 7.05956 11.5 7.025V6H10.5ZM12 8C9.795 8 8 9.795 8 12C8 14.205 9.795 16 12 16C14.205 16 16 14.205 16 12C16 9.795 14.205 8 12 8ZM12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9ZM11.5 10V11.5H10V12.5H11.5V14H12.5V12.5H14V11.5H12.5V10H11.5Z"
                fill="white"
            />
        </svg>
    );
}

export { ClientProviderInviteIcon };