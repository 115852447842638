import 'pages/Client/Requests/Create/RequestCreationProfession.scss';
import { ReactElement, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Col, Form, Modal, Row, Tooltip, Typography } from 'antd';
import { Profession } from 'types/models';
import { Select } from 'shared/AntDesignUtils/Select/Select';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { useAccount } from 'lib/Account';
import { useIsOrganizationalDataComplete, useRecentItems } from 'lib/hooks';
import { useNavigate } from 'react-router-dom-v5-compat';

const { Title } = Typography;

type Props = {
    handleChange: (professionId: number) => void;
    loadingProfessions: boolean;
    professions: Profession[];
    recentProfessions: number[];
};

export const RequestCreationProfession = ({
    handleChange,
    loadingProfessions,
    professions,
    recentProfessions,
}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useLocalization();
    const navigate = useNavigate();
    const { accountUser: user } = useAccount();
    const isOrganizationalDataComplete = useIsOrganizationalDataComplete();
    const { getSelectOptionsFor } = useRecentItems();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const checkIfOrganizationalDataIsComplete = () => {
        if (!isOrganizationalDataComplete) {
            showModal();
        }
    };

    return (
        <div className="request-creation-profession">
            <Row>
                <Col span={24} style={{ maxWidth: '650px' }}>
                    <p className="subsection-subtitle">{t('Profession')}</p>

                    <Row>
                        <Col span={22}>
                            <Form.Item
                                name="profession_id"
                                rules={[{ required: true, message: t('Please select profession') }]}
                                className="no-margin-bottom"
                            >
                                <Select
                                    showSearch
                                    disabled={loadingProfessions}
                                    placeholder={
                                        loadingProfessions ? t('Loading professions...') : t('Choose staff profession')
                                    }
                                    optionFilterProp="label"
                                    options={getSelectOptionsFor('professions', recentProfessions, professions)}
                                    filterOption={(input, option) =>
                                        (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    onClick={checkIfOrganizationalDataIsComplete}
                                    popupClassName={isOrganizationalDataComplete ? undefined : 'hidden'}
                                    onChange={handleChange}
                                    dropdownRender={(menu) =>
                                        isOrganizationalDataComplete ? menu : (null as unknown as ReactElement)
                                    }
                                    suffixIcon={
                                        <Tooltip
                                            placement="top"
                                            title={t('Please click here to configure your professions')}
                                        >
                                            <SettingOutlined
                                                onClick={() =>
                                                    navigate(`/${user.organization.type}/configuration/professions`)
                                                }
                                            />
                                        </Tooltip>
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal
                className="warning-modal"
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={false}
                width={350}
            >
                <p>
                    <InfoCircleOutlined />
                </p>
                <p>
                    {t('In order to complete your request,')}
                    <br />
                    {t('the information in')} <strong>{t('My Organization')}</strong>
                    <br />
                    {t('must be filled.')}
                </p>
                <p>
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate('/client/configuration/organization');
                        }}
                    >
                        {t('Fill now')}
                    </Button>
                </p>
            </Modal>
        </div>
    );
};
