import './Settings.scss';

import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { useLocalization } from 'lib/Localization';
import { NotificationsTab } from 'shared/Settings/NotificationsTab';
import { useTabLocation } from 'lib/hooks';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { useEffect, useState } from 'react';
import { Setting } from 'types/models';

const url = '/account/settings';

export const Settings = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const [settings, setSettings] = useState<Setting[]>([]);
    const [isSaving, setIsSaving] = useState({});

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data } = await Backend.get(url);

                setSettings(data);
            } catch (e) {
                handleError(e);
            }
        }

        loadData();
    }, [url]);

    const updateSetting = async (setting: Setting) => {
        try {
            setIsSaving((prevState) => ({
                ...prevState,
                [setting.id]: true,
            }));

            await Backend.put(`${url}/${setting.id}`, { value: setting.value });

            setSettings((prevState) => {
                const localState = [...prevState];
                const index = localState.findIndex(({ id }) => id === setting.id);

                if (index < 0) {
                    return prevState;
                }

                localState.splice(index, 1, setting);

                return localState;
            })
        } catch (e) {
            handleError(e);
        } finally {
            setIsSaving((prevState) => ({
                ...prevState,
                [setting.id]: false,
            }));
        }
    }

    const defaultTab = 'notifications';
    const [activeTab, setActiveTab] = useTabLocation(defaultTab);

    const tabItems = [
        {
            label: t('Notifications'),
            key: defaultTab,
            children: (
                <NotificationsTab
                    isSaving={isSaving}
                    settings={settings}
                    updateSetting={updateSetting}
                />
            ),
        },
    ];

    return (
        <>
            <PageHeader
                className="settings-page"
                title={t('Settings')}
                backIcon={false}
                ghost={false}
            >
                <Tabs
                    activeKey={activeTab}
                    items={tabItems}
                    onTabClick={setActiveTab}
                />
            </PageHeader>

        </>
    );
};
