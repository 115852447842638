import { AppSection, MemberType, OrganizationType } from 'types';
import { SideMenu } from './SideMenu';
import { Provider } from './Provider';

/**
 * Provider sub application.
 */
export const ProviderSection: AppSection = {
    bottomMenu: true,
    identifier: OrganizationType.PROVIDER,
    userType: MemberType.AGENT,
    sideMenu: SideMenu,
    content: Provider,
};
