import { ReactNode, useCallback, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useCookies } from 'react-cookie';
import { LocalizationContext } from 'lib/Localization/LocalizationContext';
import { getCurrentLocale, loadLocale } from 'lib/Localization/index';
import { t } from 'lib/Localization/Translation';
import { Backend } from 'lib/Http/Backend';

type Props = {
    children: ReactNode;
}

const I18n = ({ children }: Props) => {
    const [cookies, setCookie] = useCookies<string, { locale: string }>([]);
    const currentLocale = getCurrentLocale(cookies.locale);
    const [locale, setLocale] = useState(currentLocale);

    const changeLocale = useCallback(
        async (localeShortCode: string) => {
            const loadedLocale = await loadLocale(localeShortCode);
            // set the locale into a cookie in order to remember the current language on the user's next visit
            setCookie('locale', loadedLocale.shortCode, { path: '/', sameSite: 'lax' });
            // it is important to call the setLocale at the end because it updates the state and the component is re-rendered.
            setLocale({ ...loadedLocale });
            Backend.setLocaleForQueries(loadedLocale.shortCode);
            window.location.reload();
        },
        [setCookie]
    );

    return (
        <ConfigProvider locale={locale.libraries.antD.locale}>
            <LocalizationContext.Provider value={{ locale, changeLocale, t }}>{children}</LocalizationContext.Provider>
        </ConfigProvider>
    );
};

export default I18n;
