import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    color?: string;
    height?: number;
    width?: number;
};

export const UsersIcon = ({ color = '#2e97e5', width = 17, height = 17, ...otherProps }: Props) => (
    <svg width={width} height={height} viewBox="0 0 12 8" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...otherProps}>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.42858 2.99999C2.7185 2.99999 2.14286 3.57563 2.14286 4.2857C2.14286 4.93484 2.62393 5.47162 3.24901 5.55897C3.22616 5.70262 3.21429 5.84991 3.21429 5.99998C3.21429 6.14306 3.22508 6.28361 3.24588 6.42088C2.14793 6.32817 1.28572 5.40763 1.28572 4.2857C1.28572 3.10224 2.24511 2.14285 3.42858 2.14285C4.25483 2.14285 4.97186 2.61048 5.32943 3.29552C5.04483 3.36585 4.77746 3.47994 4.53489 3.63023C4.31084 3.2529 3.89925 2.99999 3.42858 2.99999ZM8.75413 6.42088C9.85208 6.32818 10.7143 5.40763 10.7143 4.2857C10.7143 3.10224 9.7549 2.14285 8.57143 2.14285C7.74519 2.14285 7.02816 2.61048 6.67058 3.29552C6.95519 3.36585 7.22255 3.47994 7.46512 3.63023C7.68917 3.2529 8.10077 2.99999 8.57143 2.99999C9.28152 2.99999 9.85715 3.57563 9.85715 4.2857C9.85715 4.93484 9.37608 5.47162 8.75101 5.55897C8.77385 5.70262 8.78572 5.84991 8.78572 5.99998C8.78572 6.14306 8.77493 6.28361 8.75413 6.42088Z"
              fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6 4.71429C5.28992 4.71429 4.71429 5.28992 4.71429 6C4.71429 6.71008 5.28992 7.28572 6 7.28572C6.71008 7.28572 7.28572 6.71008 7.28572 6C7.28572 5.28992 6.71008 4.71429 6 4.71429ZM3.85715 6C3.85715 4.81654 4.81654 3.85715 6 3.85715C7.18347 3.85715 8.14286 4.81654 8.14286 6C8.14286 7.18347 7.18347 8.14286 6 8.14286C4.81654 8.14286 3.85715 7.18347 3.85715 6Z"
              fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6 8.14286C4.81653 8.14286 3.85714 9.10225 3.85714 10.2857H3C3 8.62887 4.34315 7.28572 6 7.28572C7.65685 7.28572 9 8.62887 9 10.2857H8.14286C8.14286 9.10225 7.18347 8.14286 6 8.14286Z"
              fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.42857 6.42857C2.24511 6.42857 1.28572 7.38796 1.28572 8.57143H0.428574C0.428574 6.91457 1.77172 5.57143 3.42857 5.57143V6.42857Z"
              fill={color} />
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.57143 6.42857C9.75489 6.42857 10.7143 7.38796 10.7143 8.57143H11.5714C11.5714 6.91457 10.2283 5.57143 8.57143 5.57143V6.42857Z"
              fill={color} />
    </svg>
);
