import 'shared/Organizations/Organization/Invitation/InvitationForm.scss';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { Button, DatePicker, Form, Input, notification, Tooltip, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { useLocalization } from 'lib/Localization';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { MemberType } from 'types';
import { Invitation } from 'types/models';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { useAccount } from 'lib/Account';
import { getOrganizationTypeFromMemberType } from 'lib/Organization';

const { formLayout } = SingleColumnFormLayout;
const { TextArea } = Input;

type HasSelectValue = {
    company?: {
        label: string;
        value: ReactNode;
    };
};
type Props = {
    memberType: MemberType;
    currentMemberType: MemberType;
    invitationSentHandler: () => void;
};

export const InvitationForm = ({ memberType, currentMemberType, invitationSentHandler }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const { accountUser } = useAccount();
    const [form] = Form.useForm();
    const email = Form.useWatch('email', form);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const isAdmin = currentMemberType === MemberType.ADMIN;
    const expirationDate = dayjs().add(30, 'day');
    const inviterType = getOrganizationTypeFromMemberType(memberType);

    const sendInvitationHandler = async (formData: Invitation & HasSelectValue) => {
        try {
            setSendingInvitation(true);

            const data = !isAdmin
                ? {
                    ...formData,
                    country_id: accountUser.country?.id,
                    inviterType,
                }
                : {
                    ...formData,
                    inviterType,
                };

            const response = await Backend.post('/account/invitation', { ...data });

            if (response.status === Backend.responseStatus.HTTP_CREATED) {
                notification.success({
                    message: t('Invitation successfully sent'),
                });
                invitationSentHandler();
                form.resetFields();
            }
        } catch (error) {
            handleError(error, form);
        } finally {
            setSendingInvitation(false);
        }
    };

    const renderEmailErrors = () => {
        const error = form.getFieldError('email')[0];

        return (
            error && (
                <>
                    {error === t('Domain already in use!') ? (
                        <div>
                            {error}
                            <Tooltip
                                title={t(
                                    'You are already collaborating with a member of this company. You can’t invite more than one member with this domain : %{domain}',
                                    {
                                        domain: `@${email.split('@')[1]}`,
                                    }
                                )}
                            >
                                <Typography.Text type="danger" className="pull-right">
                                    <InfoCircleFilled />
                                </Typography.Text>
                            </Tooltip>
                        </div>
                    ) : (
                        <div>{error}</div>
                    )}
                </>
            )
        );
    };

    return (
        <>
            <Form
                form={form}
                onFinish={sendInvitationHandler}
                {...formLayout}
                initialValues={{
                    message: t(
                        'Hello! We are using Tempcloud for more efficient collaboration with our partners. Let’s connect and continue working on the platform!'
                    ),
                }}
            >
                <Form.Item name="invitation_type" initialValue={memberType} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: t('Please enter a valid email'),
                        },
                        {
                            required: true,
                            message: t('Please enter an email address'),
                        },
                        {
                            max: 150,
                            message: t(`Input must be no longer than 150 characters.`),
                        },
                    ]}
                    help={renderEmailErrors()}
                >
                    <FloatingLabel label={t('Email')} required>
                        <Input allowClear />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: t('Please enter first name'),
                        },
                        {
                            max: 150,
                            message: t(`First name must be no longer than 150 characters.`),
                        },
                    ]}
                >
                    <FloatingLabel label={t('First name')}>
                        <Input />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: t('Please enter last name'),
                        },
                        {
                            max: 150,
                            message: t(`Last name must be no longer than 150 characters.`),
                        },
                    ]}
                >
                    <FloatingLabel label={t('Last name')}>
                        <Input />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item
                    name="message"
                    rules={[
                        {
                            max: 500,
                            message: t(`The message may not be greater than 500 characters.`)
                        }
                    ]}>
                    <FloatingLabel label={t('Message')}>
                        <TextArea rows={7} placeholder={t('Write your message here')} />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item name="expiration_date" hidden={!isAdmin} initialValue={expirationDate}>
                    <FloatingLabel label={t('Expiration date')}>
                        <DatePicker format="DD.MM.YYYY" />
                    </FloatingLabel>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={sendingInvitation}>
                        {t('Send invitation')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
