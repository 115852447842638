import { TableParams } from 'types/Table';
import { GenericObject } from 'shared/Contracts';

export const useTable = (setTableParams: (tableParams: TableParams) => void) => {
    const getCleanFilters = (filters: GenericObject) => {
        const customFilter: GenericObject = {};

        for (const key in filters) {
            if (!filters[key]) {
                continue;
            }

            if (key === 'startEnd') {
                customFilter['startDate'] = filters[key][0][0];
                customFilter['endDate'] = filters[key][0][1];

                continue;
            }

            customFilter[key] = filters[key].join(',');
        }

        return customFilter;
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setTableParams({
            pagination,
            filters: getCleanFilters(filters),
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        } as any);
    };

    return {
        getCleanFilters,
        handleTableChange,
    };
};
