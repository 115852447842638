import 'shared/Requests/ProgressModal.scss';

import { useLocalization } from 'lib/Localization';
import { Button, Card, Col, Row, Space, Tag } from 'antd';
import { Order, Request } from 'types/models';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ProviderRequestState } from 'types/staffing';
import { useEffect, useState } from 'react';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { RequestProgress } from 'shared/Requests/RequestsTable/RequestProgress';

type Props = {
    [index: string]: any;
    close: () => void;
    order: Order;
    requestId?: number;
}

type RequestStateProviders = {
    [index: number]: {
        [index: string]: string[];
    };
};

export const ProgressModal = ({ close, order, requestId, ...otherProps }: Props) => {
    const { t, locale } = useLocalization();

    const getStateProviders = (request: Request, targetState: ProviderRequestState) => request.provider_requests
        .filter(({ state }) => state === targetState)
        .map(({ provider }) => provider.company_name);

    const [requestStateProviders, setRequestStateProviders] = useState<RequestStateProviders>({});

    useEffect(() => {
        const stateProviders: RequestStateProviders = {};

        order.requests.filter(({ id }) => requestId ? requestId === id : true)
            .forEach((request) => {
                stateProviders[request.id] = {
                    assigned: getStateProviders(request, ProviderRequestState.CONCLUDED),
                    accepted: getStateProviders(request, ProviderRequestState.ACCEPTED),
                    pending: getStateProviders(request, ProviderRequestState.OPEN),
                    rejected: getStateProviders(request, ProviderRequestState.REJECTED),
                }
            });

        setRequestStateProviders(stateProviders);
    }, [order]);

    return (
        <CustomModal
            className="request-progress-modal"
            closable={false}
            footer={[
                <Button key="close" onClick={close}>
                    {t('Close')}
                </Button>
            ]}
            onCancel={close}
            open={true}
            title={t('Progress')}
            {...otherProps}
        >
            {order.requests.filter(({ id }) => requestId ? requestId === id : true)
                .map((request) => (
                    <Card className="progress-card" size="small" title={(
                        <Row>
                            <Col span={14}>
                                {localizeProfession(request.profession, locale).name}
                            </Col>

                            <Col span={8}>
                                <RequestProgress order={order} request={request} showDescription />
                            </Col>
                        </Row>
                    )}>
                        <table>
                            {!!requestStateProviders[request.id]?.assigned?.length && (
                                <tr>
                                    <td>{t('Assigned')}</td>
                                    <td>
                                        {requestStateProviders[request.id].assigned.map((name) => (
                                            <Tag>
                                                <Space>
                                                    <CheckCircleOutlined/>
                                                    {name}
                                                </Space>
                                            </Tag>
                                        ))}
                                    </td>
                                </tr>
                            )}
                            {!!requestStateProviders[request.id]?.accepted?.length && (
                                <tr>
                                    <td>{t('Request accepted')}</td>
                                    <td>
                                        {requestStateProviders[request.id].accepted.map((name) => (
                                            <Tag>
                                                <Space>
                                                    <CheckCircleOutlined/>
                                                    {name}
                                                </Space>
                                            </Tag>
                                        ))}
                                    </td>
                                </tr>
                            )}
                            {!!requestStateProviders[request.id]?.pending?.length && (
                                <tr>
                                    <td>{t('Awaiting response')}</td>
                                    <td>
                                        {requestStateProviders[request.id].pending.map((name) => (
                                            <Tag>
                                                <Space>
                                                    <CheckCircleOutlined/>
                                                    {name}
                                                </Space>
                                            </Tag>
                                        ))}
                                    </td>
                                </tr>
                            )}
                            {!!requestStateProviders[request.id]?.rejected?.length && (
                                <tr>
                                    <td>{t('Rejected')}</td>
                                    <td>
                                        {requestStateProviders[request.id].rejected.map((name) => (
                                            <Tag>
                                                <Space>
                                                    <CheckCircleOutlined/>
                                                    {name}
                                                </Space>
                                            </Tag>
                                        ))}
                                    </td>
                                </tr>
                            )}
                        </table>
                    </Card>
                ))}
        </CustomModal>
    );
};
