import { useErrorHandler } from 'lib/ErrorHandling';
import { Backend } from 'lib/Http/Backend';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Button, Form, Input, notification } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotificationTemplate } from 'types/models';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

type Props = {
    notificationTemplate?: NotificationTemplate;
    loadNotificationTemplatesData: () => Promise<void>;
    setIsFormOpen: (isFormOpen: boolean) => void;
};

/**
 * Form to manage a notification template
 *
 * @param notificationTemplate
 * @param saveHandler
 * @returns {JSX.Element}
 */
export const NotificationTemplateForm = ({
    notificationTemplate,
    loadNotificationTemplatesData,
    setIsFormOpen,
}: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [form] = Form.useForm();
    const [isDataSaving, setIsDataSaving] = useState(false);

    /**
     * When component receives new notification props.
     */
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(notificationTemplate);
    }, [form, notificationTemplate]);

    /**
     * Save a notificationTemplate
     *
     * @param values
     * @returns {Promise<void>}
     */
    const saveNotificationTemplateHandler = async (values: NotificationTemplate) => {
        try {
            setIsDataSaving(true);
            const response = await Backend.put(`data-management/notification-templates/${values.id}`, values);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Data successfully updated'),
                });
            }
            setIsFormOpen(false);
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
        loadNotificationTemplatesData();
    };

    return (
        <Form form={form} onFinish={saveNotificationTemplateHandler}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="type" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="language_id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="subject" rules={[{ required: true, message: t('Please enter a subject') }]}>
                <FloatingLabel label={t('Subject')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="content"
                label={t('Content')}
                valuePropName="content"
                getValueFromEvent={(event, editor) => {
                    return editor.getData();
                }}
            >
                <CKEditor editor={ClassicEditor} data={notificationTemplate?.content} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};
