import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { RolesPermissionsTable } from './RolesPermissionsTable';

/**
 * Stateful container component responsible for permissions data management.
 *
 */
export const Permissions = () => {
    const { t } = useLocalization();

    return (
        <PageHeader title={t('Permissions')} backIcon={false} ghost={false}>
            <RolesPermissionsTable />
        </PageHeader>
    );
};
