// @ts-ignore
import * as XLSX from 'xlsx-js-style';
import { GenericObject } from 'shared/Contracts';

export const downloadExcel = (filename: string, data: GenericObject[], columnWidths?: any[]) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    if (columnWidths?.length) {
        worksheet['!cols'] = columnWidths;
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, filename, { compression: true });
};

export const extractExcelFileData = <T extends GenericObject>(file: Blob | File): Promise<T[]> => {
    return new Promise<T[]>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read((event.target?.result as string) ?? '', { type: 'binary' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(worksheet) as T[];

            resolve(data);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsBinaryString(file);
    });
};
