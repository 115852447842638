import 'pages/Client/Requests/Create/RequestCreationDateTime.scss';
import { useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { Col, Divider, Form, FormInstance, Row } from 'antd';
import { FormRequest } from 'types/staffing';
import { EnhancedDayPicker } from 'shared/EnhancedDayPicker';

type Props = {
    differentTimes?: number;
    form: FormInstance;
    multiple?: boolean;
    saveFormHandler?: (changedFormData: FormRequest | null, currentFormData: FormRequest) => void;
    selectedDates: Date[];
    setFooterError?: Function;
    setSelectedDates: (selectedDates: Date[]) => void;
    updateJobList: (dates: Date[], sameOrDifferentTimes?: number, selectingDates?: boolean) => void;
};

export const RequestCreationDateTime = ({
    selectedDates,
    setSelectedDates,
    differentTimes,
    multiple = true,
    form,
    saveFormHandler,
    setFooterError,
    updateJobList,
}: Props) => {
    const { t } = useLocalization();

    useEffect(() => {
        updateJobList(selectedDates, differentTimes);
    }, [selectedDates]);

    /**
     * Handles the datepicker dates selection
     *
     * @param dates
     */
    const datesSelectedHandler = (dates: Date[]) => {
        setSelectedDates(dates);
        saveFormHandler?.(null, { ...form.getFieldsValue(), selectedDates: dates });
        updateJobList(dates, differentTimes, true);
    };

    return (
        <Row className="request-creation-date-time">
            <Col xs={24} xxl={24}>
                <div className="date-picker-form-item-container">
                    <Form.Item name="datePicker" rules={[{ required: true, message: t('Please select dates') }]}>
                        <EnhancedDayPicker
                            initialSelectedDates={selectedDates}
                            multiple={multiple}
                            onChange={datesSelectedHandler}
                            setFooterError={setFooterError}
                        />
                    </Form.Item>

                    {multiple && <Divider />}
                </div>
            </Col>
        </Row>
    );
};
