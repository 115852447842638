import type { MenuProps } from 'antd';
import { Badge, Menu } from 'antd';
import { ApartmentOutlined, PlusOutlined, SettingOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { useLocalization } from 'lib/Localization';
import { useMenuKeys } from 'lib/Helpers/MenuHelper';
import { useState } from 'react';
import { FileIcon, WorkersIcon } from 'shared/icons';
import { Link } from 'react-router-dom-v5-compat';

import 'shared/style/TopMenuItem.scss';

type Props = {
    isCollapsed: boolean;
    requestNumber: number | null;
    showRequestBadge: boolean;
    invitationNumber: number | null;
    showInvitationBadge: boolean;
};

/**
 * Render the side menu of the provider sub application.
 *
 * @return {*}
 */
export const SideMenu = ({ isCollapsed, requestNumber, showRequestBadge, showInvitationBadge, invitationNumber }: Props) => {
    const { t } = useLocalization();
    const match = useRouteMatch();
    const { selectedMenuKey, openMenuKey } = useMenuKeys();

    const [openKeys, setOpenKeys] = useState(openMenuKey ? [openMenuKey] : undefined);
    const canShowRequestBadge = () => showRequestBadge && requestNumber !== null;
    const canShowInvitationBadge = showInvitationBadge && invitationNumber !== null;

    const requestBadgeClass = 'white-badge-text' + (requestNumber ? '' : ' opacity-50');
    const invitationBadgeClass = 'white-badge-text' + (invitationNumber ? '' : ' opacity-50');

    const requestLabel =
        !isCollapsed && canShowRequestBadge() ? (
            <Badge
                count={requestNumber}
                overflowCount={99}
                offset={[45, 7]}
                color="#ffffff"
                className={requestBadgeClass}
                showZero
            >
                {t('Requests')}
            </Badge>
        ) : (
            t('Requests')
        );

    const requestIcon =
        isCollapsed && canShowRequestBadge() ? (
            <Badge
                className={requestBadgeClass}
                color="#ffffff"
                count={requestNumber}
                offset={[20, 10]}
                overflowCount={99}
                size="small"
                showZero
            >
                <FileIcon />
            </Badge>
        ) : (
            <FileIcon />
        );

    const clientsLabel =
        !isCollapsed && canShowInvitationBadge ? (
            <Badge
                count={invitationNumber}
                overflowCount={99}
                offset={[65, 7]}
                color="#ffffff"
                className={invitationBadgeClass}
                showZero
            >
                {t('Clients')}
            </Badge>
        ) : (
            t('Clients')
        );

    const clientsIcon =
        isCollapsed && canShowInvitationBadge ? (
            <Badge
                className={invitationBadgeClass}
                color="#ffffff"
                count={invitationNumber}
                offset={[20, 17]}
                overflowCount={99}
                size="small"
                showZero
            >
                <TeamOutlined />
            </Badge>
        ) : (
            <TeamOutlined />
        );

    const items: MenuProps['items'] = [
        {
            key: 'requests',
            className: isCollapsed && canShowRequestBadge() ? 'with-count' : '',
            icon: requestIcon,
            label: <Link to={`${match.path}/requests`}>{requestLabel}</Link>,
        },
        {
            key: 'clients',
            className: isCollapsed && canShowInvitationBadge ? 'with-count' : '',
            icon: clientsIcon,
            label: <Link to={`${match.path}/clients`}>{clientsLabel}</Link>,
        },
        {
            key: 'workers',
            icon: <WorkersIcon />,
            label: <Link to={`${match.path}/workers`}>{t('Workers')}</Link>,
        },
        {
            key: 'configuration',
            label: (
                <span>
                    <SettingOutlined />
                    <span>{t('Configuration')}</span>
                </span>
            ),
            children: [
                {
                    key: 'organization',
                    icon: <ApartmentOutlined />,
                    label: <Link to={`${match.path}/configuration/organization`}>{t('Organization')}</Link>,
                },
                {
                    key: 'professions',
                    icon: <ToolOutlined />,
                    label: <Link to={`${match.path}/configuration/professions`}>{t('Professions')}</Link>,
                },
            ],
        },
    ];

    return (
        <Menu
            className="sidebar-menu"
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuKey]}
            openKeys={openKeys}
            onClick={function ({ keyPath }) {
                if (keyPath.length === 2) {
                    // clicked on a sub-menu -> close other open sub-menu
                    setOpenKeys([[...keyPath].pop() as string]);
                }
            }}
            onOpenChange={function (openKeys: string[]) {
                setOpenKeys(openKeys);
            }}
            items={items}
        />
    );
};