import './ShiftFormModal.scss';
import { FormattedJob, FormRequest } from 'types/staffing';
import { CustomModal } from 'shared/AntDesignUtils/CustomModal/CustomModal';
import { Button, Form, notification, Steps } from 'antd';
import { useLocalization } from 'lib/Localization';
import { GenericObject } from 'shared/Contracts';
import { RequestCreationDateTime } from 'pages/Client/Requests/Create';
import { useCallback, useEffect, useState } from 'react';
import { compileDateTimes, TimesValue, updateJobListing } from 'lib/Request/utils';
import { RequestCreationTimesContainer } from 'pages/Client/Requests/Create/RequestCreationTimesContainer';
import { Job, Provider, Request } from 'types/models';
import moment from 'moment';
import { timeFormat } from 'lib/Helpers/DateTimeHelper';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { userIsClient } from 'lib/Helpers/UserHelper';
import { useAccount } from 'lib/Account';
import { formatJobs } from 'shared/Requests/helpers';
import { RequestCreationInfoSummary } from 'pages/Client/Requests/Create/RequestCreationInfoSummary';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';

type Props = GenericObject & {
    departmentId?: number;
    job?: FormattedJob;
    jobsToUpdate: FormattedJob[];
    onCancel: () => void;
    open: boolean;
    providers: Provider[];
    request: Request;
    setJobsToUpdate: (jobs: FormattedJob[]) => void;
    setOpenModal: (open: boolean) => void;
    setRequest: (request: Request) => void;
};

export const ShiftFormModal = ({
    departmentId,
    job,
    jobsToUpdate,
    onCancel,
    open,
    providers,
    request,
    setJobsToUpdate,
    setOpenModal,
    setRequest,
    ...otherProps
}: Props) => {
    const { t } = useLocalization();
    const { accountUser: user } = useAccount();
    const isClient = userIsClient(user);
    const handleError = useErrorHandler();

    const [form] = Form.useForm();

    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [differentTimes, setDifferentTimes] = useState<number>(TimesValue.SAME);
    const [jobsToCreate, setJobsToCreate] = useState<any[]>([]);
    const [initialRequest, setInitialRequest] = useState<Request>(request);
    const [currentStep, setCurrentStep] = useState(0);
    const [showAddButton, setShowAddButton] = useState(false);

    const addJobToUpdate = (job: FormattedJob) => {
        setJobsToUpdate([...jobsToUpdate.filter(({ id }) => id !== job.id), job]);
    };

    const updateFromJobsToUpdate = (job?: FormattedJob) => jobsToUpdate.find(({ id }) => id === job?.id) || job;

    const updateJobList = useCallback(
        (dates: Date[], sameOrDifferentTimes?: number, selectingDates = false) =>
            updateJobListing(form, dates, sameOrDifferentTimes, selectingDates),
        [form, TimesValue.SAME, TimesValue.DIFFERENT]
    );

    const handleCreateJobs = async (jobsToCreate: Job[]) => {
        try {
            const response = await Backend.post(`staffing/requests/${request.id}/jobs`, {
                jobs: jobsToCreate,
            });

            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t(`Jobs successfully added`),
                });

                const requestJobs = [...request.jobs];

                response.data.forEach((job: Job) => {
                    const index = requestJobs.findIndex(({ id }) => id === job.id);

                    requestJobs.splice(index, 0, job);
                });

                setRequest({
                    ...request,
                    jobs: requestJobs,
                });
                setJobsToUpdate([]);
                setJobsToCreate([]);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const createJobs = (newDateAndTimes: any) => {
        let jobs: any[] = [];
        let count = 0;

        (newDateAndTimes.datePicker ?? []).forEach((pickerDate: string) => {
            const date = moment(pickerDate).format('YYYY-MM-DD');
            const dateEntry = newDateAndTimes.dates?.[0];

            if (dateEntry) {
                dateEntry.times.forEach((timeEntry: any) => {
                    for (let i = 0; i < parseInt(timeEntry.workersNumber); i++) {
                        const start_time = timeEntry.shiftTimes?.times?.[0];
                        const end_time = timeEntry.shiftTimes?.times?.[1];
                        const comment = timeEntry.comment;

                        if (!start_time || !end_time) {
                            return;
                        }

                        let start_break = timeEntry.breakStartTime as unknown as string;
                        let end_break = moment(start_break, 'HH:mm')
                            .add(timeEntry.breakDuration, 'minutes')
                            .format(timeFormat);

                        if (!start_break) {
                            start_break = '';
                            end_break = '';
                        }

                        const jobData = {
                            id: `new-${count}`,
                            date,
                            formattedDate: moment(date).format('ddd, DD MMM YYYY'),
                            start_time,
                            end_time,
                            start_break,
                            end_break,
                            comment,
                            providers: [],
                        };
                        jobs.push(jobData);

                        count++;
                    }
                });
            }
        });

        return jobs;
    };

    const saveFormHandler = (changedFormData: FormRequest | null, newDateAndTimes: FormRequest) => {
        // updating existing shift
        if (newDateAndTimes.dates?.length && job?.id) {
            const requestJobs = [...request.jobs];
            const requestJobIndex = requestJobs.findIndex(({ id }) => id === job.id);

            if (requestJobIndex === -1) {
                return;
            }

            const oldFormatedJob = formatJobs([requestJobs[requestJobIndex]], isClient)[0];

            const start_break = newDateAndTimes.dates[0].times[0].breakStartTime as unknown as string;
            let end_break = '';

            if (start_break) {
                end_break = moment(start_break, 'HH:mm')
                    .add(newDateAndTimes.dates[0].times[0].breakDuration, 'minutes')
                    .format(timeFormat);
            }

            const localJob = {
                ...job,
                comment: newDateAndTimes.dates[0].times[0].comment as unknown as string,
                date: moment(newDateAndTimes.datePicker?.[0] ?? newDateAndTimes.dates[0].date).format('YYYY-MM-DD'),
                breakDuration: newDateAndTimes.dates[0].times[0]?.breakDuration?.toString() || '',
                start_time: newDateAndTimes.dates[0].times[0].shiftTimes.times[0],
                end_time: newDateAndTimes.dates[0].times[0].shiftTimes.times[1],
                start_break,
                end_break,
                data: {
                    isUpdated: true,
                    prevTime: oldFormatedJob.time,
                    prevBreakDuration: oldFormatedJob.breakDuration,
                    prevBreakStartTime: oldFormatedJob.breakStartTime,
                    prevDate: oldFormatedJob.date,
                    prevShiftDuration: oldFormatedJob.shiftDuration,
                },
            };

            const formattedJob = formatJobs([localJob as unknown as Job], isClient)[0];

            addJobToUpdate(formattedJob);
        } else {
            // creating new shift
            const jobs = createJobs(newDateAndTimes);

            setJobsToCreate(jobs);
        }
    };

    const onCancelHandler = (force: boolean = false) => {
        if (!force && currentStep > 0) {
            setCurrentStep(currentStep - 1);

            return;
        }

        setJobsToCreate([]);
        setJobsToUpdate([]);
        setSelectedDates([]);
        setRequest(initialRequest);
        setCurrentStep(0);
        form.resetFields();
        onCancel();
    };

    useEffect(() => {
        form.resetFields();

        if (!job) {
            return;
        }

        setInitialRequest(request);

        const dates = Object.values(compileDateTimes([job])).flat();

        form.setFieldsValue({ dates });
        setSelectedDates(dates.map(({ date }: GenericObject) => new Date(date)));
    }, [job]);

    useEffect(() => {
        const formValues = form.getFieldsValue();
        const hasDates = formValues?.datePicker?.length || formValues?.dates?.length;
        const shiftTimes = formValues?.dates?.[0]?.times?.[0]?.shiftTimes?.times;
        const startTime = shiftTimes?.[0];
        const endTime = shiftTimes?.[1];

        setShowAddButton(currentStep > 0 || !!hasDates && !!startTime && !!endTime);
    }, [jobsToCreate, jobsToUpdate]);

    if (!isClient) {
        return null;
    }

    const steps = [
        {
            key: 'date-time',
            title: t('Date & time'),
            content: (
                <>
                    <Form.Item name="date" style={{ marginBottom: 0 }}>
                        <RequestCreationDateTime
                            differentTimes={differentTimes}
                            form={form}
                            multiple={!job}
                            saveFormHandler={saveFormHandler}
                            selectedDates={selectedDates}
                            setSelectedDates={setSelectedDates}
                            updateJobList={updateJobList}
                        />
                    </Form.Item>

                    <RequestCreationTimesContainer
                        departmentShiftIds={departmentId ? [departmentId] : []}
                        differentTimes={differentTimes}
                        form={form}
                        hideDays={differentTimes === 0}
                        selectedDates={selectedDates}
                        setDifferentTimes={setDifferentTimes}
                        saveFormHandler={saveFormHandler}
                        timesOnly={!!job}
                        updateJobList={updateJobList}
                        noDivider
                    />
                </>
            )
        },
        {
            key: 'providers',
            title: t('Provider quota'),
            content: (
                <RequestCreationInfoSummary
                    formRequests={[{
                        ...request,
                        jobs: jobsToCreate.length ? jobsToCreate : request.jobs.map((job: Job) => updateFromJobsToUpdate(formatJobs([job], isClient)[0])),
                        providers: request.provider_requests.map(({ provider_id }) => provider_id),
                    } as unknown as OrderFormRequest]}
                    job={updateFromJobsToUpdate(job)}
                    providers={providers}
                    setFormRequest={(orderFormRequest) => {
                        if (job) {
                            const updatedJob = orderFormRequest.jobs.find(({ id }) => id === job.id) as FormattedJob;

                            addJobToUpdate(updatedJob);

                            setRequest(orderFormRequest as unknown as Request);
                        } else {
                            setJobsToCreate(orderFormRequest.jobs);
                        }
                    }}
                    newQuotaOnly={!!job}
                    hideHeader
                    showProviderQuota
                    simple={!!job}
                />
            ),
        },
    ];

    return (
        <CustomModal
            className={`shift-form-modal ${!job ? 'adding' : ''}`}
            defaultBody={!!job}
            footer={[
                <Button key="close" onClick={() => onCancelHandler()}>
                    {t(currentStep > 0 ? 'Previous' : 'Cancel')}
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    disabled={!job && !showAddButton && currentStep !== (steps.length - 1)}
                    onClick={async () => {
                        if (currentStep < (steps.length - 1)) {
                            setCurrentStep(currentStep + 1);

                            return;
                        }

                        setOpenModal(false);

                        if (job) {
                            setCurrentStep(0);

                            return;
                        }

                        await handleCreateJobs(jobsToCreate);
                    }}
                >
                    {currentStep === (steps.length - 1) ?
                        t(job ? 'Confirm changes' : 'Add shifts') :
                        t('Continue')
                    }
                </Button>,
            ]}
            onCancel={() => onCancelHandler(true)}
            open={open}
            title={t(job ? 'Edit shift' : 'Add shifts')}
            width={job || currentStep === 0 ? 700 : 800}
            {...otherProps}
        >
            <Steps current={currentStep} items={steps} style={{ marginBottom: 40 }} />

            <Form form={form} onValuesChange={saveFormHandler}>
                <div className="steps-content">{steps[currentStep].content}</div>
            </Form>
        </CustomModal>
    );
};
