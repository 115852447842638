import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { useAccount } from 'lib/Account';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Address, Country, Department } from 'types/models';
import { FormInstance } from 'antd/lib/form';
import { useCollections } from 'lib/Contexts/Collections';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';

const { formLayout } = SingleColumnFormLayout;

type Props = {
    address?: Address;
    departments: Department[];
    saveAddressHandler: (address: Address, form: FormInstance) => void;
    isDataSaving: boolean;
};

/**
 * Form to manage client's address.
 *
 * @param address
 * @param departments
 * @param saveAddressHandler
 * @param isDataSaving
 * @constructor
 */
export const AddressForm = ({ address, departments, saveAddressHandler, isDataSaving }: Props) => {
    const { t } = useLocalization();
    const [form] = Form.useForm();
    const { accountUser: user } = useAccount();
    const [collections] = useCollections();

    const organization = user?.organization;

    /**
     * When component receives new address prop.
     */
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...address,
            client_id: user.profiles.contact?.client_id,
            departments: address?.departments?.map((department: Department) => department.id),
        });
    }, [form, address, user]);

    const countryOptions = collections.countries.map((country: Country) => ({
        value: country.id,
        label: t(country.name),
    }));

    const departmentsOptions = departments.map((department: Department) => ({
        value: department.id,
        label: department.name,
    }));

    const onFinish = (values: Address) => {
        saveAddressHandler(values, form);
        form.resetFields();
        form.setFieldsValue({
            client_id: user.profiles.contact?.client_id,
        });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            {...formLayout}
            initialValues={{
                country_id: organization?.country_id,
            }}
        >
            <Form.Item
                name="id"
                style={{
                    display: 'none',
                }}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="client_id"
                style={{
                    display: 'none',
                }}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter the name of the location'),
                    },
                ]}
            >
                <FloatingLabel label={t('Name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="address"
                rules={[
                    {
                        required: true,
                        message: t('Please enter Address/Street'),
                    },
                ]}
            >
                <FloatingLabel label={t('Address/street')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="zip_code"
                rules={[
                    {
                        required: true,
                        message: t('Please enter Zip Code'),
                    },
                ]}
            >
                <FloatingLabel label={t('Zip Code')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="city"
                rules={[
                    {
                        required: true,
                        message: t('Please enter City'),
                    },
                ]}
            >
                <FloatingLabel label={t('City')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="country_id"
                rules={[
                    {
                        required: true,
                        message: t('Please select Country'),
                    },
                ]}
            >
                <FloatingLabel label={t('Country')} required>
                    <Select options={countryOptions} data-testid="country" />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="departments">
                <FloatingLabel label={t('Departments')}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={t('Related Departments')}
                        options={departmentsOptions}
                        data-testid="departments"
                        optionFilterProp="label"
                    />
                </FloatingLabel>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isDataSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};
