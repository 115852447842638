import './Select.scss';
import { useRef } from 'react';
import { Select as AntSelect } from 'antd';
import { DefaultOptionType, LabeledValue, RefSelectProps, SelectProps } from 'antd/lib/select';

type Props = SelectProps & {
    closeOptionOnSelect?: boolean;
}

export const Select = ({ children, closeOptionOnSelect = true, onSelect, ...props }: Props) => {
    const selectRef = useRef<RefSelectProps>(null);

    const handleSelect = (value: string | number | LabeledValue, option: DefaultOptionType) => {
        if (onSelect) {
            onSelect(value, option);
        }

        if (closeOptionOnSelect) {
            selectRef?.current?.blur();
        }
    };

    return (
        <AntSelect ref={selectRef} onSelect={handleSelect} {...props}>
            {children}
        </AntSelect>
    );
};
