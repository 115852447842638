import { useErrorHandler } from 'lib/ErrorHandling';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { CustomMenuItemType, GroupedItems, SelectPopup } from 'shared/AntDesignUtils/SelectPopup/SelectPopup';
import { Profession, Request, Worker } from 'types/models';
import { SelectEventHandler } from 'rc-menu/es/interface';
import { FormattedJob } from 'types/staffing';
import { useLocalization } from 'lib/Localization';
import { mergeArrays } from 'lib/Helpers/General';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';
import { useAccount } from 'lib/Account';
import { Drawer } from 'antd';
import { WorkerForm } from 'pages/Provider/Workers/WorkerForm';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';
import { localizeProfessions } from 'lib/Helpers/ProfessionHelper';
import { Backend } from 'lib/Http/Backend';

const professionsUrl = '/data-management/professions?all';

type Props = {
    children: ReactNode;
    jobs: FormattedJob[];
    onReset: () => void;
    request: Request;
    selectedJobs: FormattedJob[];
    setJobs: (jobs: FormattedJob[]) => void;
    setSelectedWorker?: (worker: Worker) => void;
    workerJobs: FormattedJob[];
};

export const WorkerSelectPopup = ({
    children,
    onReset,
    jobs,
    setJobs,
    selectedJobs,
    setSelectedWorker,
    workerJobs,
    request,
}: Props) => {
    const { t } = useLocalization();
    const localizationContext = useLocalization();
    const handleError = useErrorHandler();
    const [rejectedWorkerIds, setRejectedWorkerIds] = useState<number[]>([]);
    let [allProfessions] = useLoadEntity<Profession>('professions', professionsUrl);
    let translatedProfessions = localizeProfessions(allProfessions, localizationContext.locale);

    const { accountUser: user } = useAccount();
    const [isWorkerFormOpen, setIsWorkerFormOpen] = useState(false);
    const [worker, setWorker] = useState<Worker>({} as Worker);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [loadingWorkers, setLoadingWorkers] = useState(false);

    const providerId = user.profiles.agent?.provider_id as number;

    const loadWorkersData = useCallback(async () => {
        setLoadingWorkers(true);

        try {
            const response = await Backend.get('/data-management/workers?relations=professions');
            setWorkers(response.data.workers);
        } catch (error) {
            handleError(error);
        }

        setLoadingWorkers(false);
    }, [handleError]);

    useEffect(() => {
        loadWorkersData();
    }, []);

    const workerSavedCallback = () => {
        loadWorkersData();
    };

    const openWorkerHandler = (worker?: Worker) => {
        setWorker({ provider_id: providerId } as Worker);
        setIsWorkerFormOpen(true);
    };

    const createMenuItem = (worker: Worker) => ({
        className: rejectedWorkerIds.includes(worker.id) ? 'rejected' : '',
        disabled: rejectedWorkerIds.includes(worker.id),
        key: worker.id,
        label: `${worker.first_name} ${worker.last_name}`,
    });

    const menuItems: CustomMenuItemType[] = workers
        .filter(({ id }) => !rejectedWorkerIds.includes(id))
        .map(createMenuItem);
    const workersLinkedToProfession = workers
        .filter((worker) => {
            return worker.professions.some((profession) => profession.id === request.profession?.id);
        })
        .map(createMenuItem);
    const rejectedMenuItems: CustomMenuItemType[] = workers
        .filter(({ id }) => rejectedWorkerIds.includes(id))
        .map(createMenuItem);

    const professionName = request.profession?.name;

    const groupedMenuItems: GroupedItems = {
        [t(professionName)]: workersLinkedToProfession,
        [t('All workers')]: menuItems,
        [t('Rejected workers')]: rejectedMenuItems,
    };

    const onRemoveHandler = async () => {
        const resetJob = selectedJobs.map((selectedJob) => {
            return {
                ...selectedJob,
                workerId: undefined,
                workerName: undefined,
            };
        });

        const mergedJobs = mergeArrays(resetJob, jobs);
        setJobs(mergedJobs);

        onReset();
    };

    const onSelectHandler: SelectEventHandler = async ({ key }) => {
        const selectedWorker = workers.find(({ id }) => id === Number(key)) as Worker;

        setSelectedWorker?.(selectedWorker);

        const assignedJobs = selectedJobs.map((job) => ({
            ...job,
            workerId: selectedWorker.id,
            workerName: `${selectedWorker.first_name} ${selectedWorker.last_name}`,
        }));

        const mergedJobs = mergeArrays(assignedJobs, jobs);
        setJobs(mergedJobs);

        onReset();
    };

    const highlightsKeys = workerJobs.length === 1 && workerJobs[0].workerId ? [workerJobs[0].workerId] : [];

    useEffect(() => {
        const idGroups = workerJobs.map((job) =>
            (job.applicants || [])
                .filter(({ state }) => state === JobApplicantState.REJECTED)
                .map(({ worker_id }) => worker_id)
        );

        setRejectedWorkerIds(Array.from(new Set(idGroups.flatMap((a) => a))));
    }, [workerJobs]);

    return (
        <>
            <SelectPopup
                highlightKeys={highlightsKeys}
                items={groupedMenuItems}
                loadingItems={loadingWorkers}
                onRemove={onRemoveHandler}
                onSelect={onSelectHandler}
                overlayClassName="worker-select-popup-overlay"
                isWorkerSelect={true}
                openWorkerHandler={openWorkerHandler}
            >
                {children}
            </SelectPopup>
            <Drawer
                title={worker.id ? t('Edit worker') : t('Add Worker')}
                width={500}
                onClose={() => setIsWorkerFormOpen(false)}
                open={isWorkerFormOpen}
            >
                <WorkerForm
                    allProfessions={translatedProfessions}
                    worker={worker}
                    workerSavedCallback={workerSavedCallback}
                    setIsFormOpen={setIsWorkerFormOpen}
                />
            </Drawer>
        </>
    );
};
