import { Button, notification, Popconfirm, Space, Table, Tag } from 'antd';
import { useLocalization } from 'lib/Localization';
import { getColumnSearchProps, getDefaultPaginationProp, sortColumn } from 'lib/Helpers/TableHelper';
import moment from 'moment';
import { Invitation, RemappedInvitation } from 'types/models';
import { ColumnsType } from 'antd/lib/table';
import {
    ClockCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    StopOutlined,
} from '@ant-design/icons';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { InvitationActions, InvitationStatus } from 'types/models/Invitation';

type Props = {
    invitations: Invitation[];
    isInvitationsLoading: boolean;
    loadInvitations: Function;
};

/**
 * Table to display invitations sent to other organizations.
 *
 * @param invitations
 * @param isInvitationsLoading
 * @param loadInvitations
 * @returns {JSX.Element}
 * @constructor
 */
export const InvitationsTable = ({ invitations, isInvitationsLoading, loadInvitations }: Props) => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();

    const remappedInvitations = invitations
        .map((invitationItem: Invitation) => {
            return {
                id: invitationItem.id,
                invitationToken: invitationItem.invitation_token,
                invitationType: invitationItem.invitation_type,
                name: invitationItem.data?.first_name.concat(' ', invitationItem.data?.last_name),
                firstName: invitationItem.data?.first_name,
                lastName: invitationItem.data?.last_name,
                email: invitationItem.email,
                company: invitationItem.data?.company_name,
                status: invitationItem.status,
                status_message: processInvitationStatusMessage(invitationItem.status),
                send_date: invitationItem.created_at,
                sent_by: invitationItem.user?.first_name.concat(' ', invitationItem.user?.last_name),
                accountType: invitationItem.account_type,
                message: '',
            };
        })
        .filter(function (item: RemappedInvitation) {
            return item.status !== InvitationStatus.REGISTERED && item.status !== InvitationStatus.ACCEPTED;
        });

    const handleInvitationStatusRequest = async (token: string, action: InvitationActions) => {
        try {
            const invitationResponse = await Backend.get('/account/invitation/' + action + '/' + token);

            if (invitationResponse.data === false) {
                throw new Error('Could not set invitation status');
            }

            notification.success({
                message: t('Invitation status successfully set to ' + action),
            });
        } catch (error) {
            handleError(error);
        } finally {
            loadInvitations();
        }
    };

    function processInvitationStatusMessage(status: String) {
        switch (status) {
            case InvitationStatus.DECLINED:
                return t('Invitation declined');

            case InvitationStatus.ACCEPTED:
            case InvitationStatus.REGISTERED:
                return t('Invitation accepted');

            case InvitationStatus.CANCELED:
                return t('Invitation canceled');

            case InvitationStatus.EXPIRED:
                return t('Invitation expired');

            case InvitationStatus.PENDING:
            case InvitationStatus.ACCESSED:
                return t('Pending response');

            default:
                return t('No status');
        }
    }

    const renderStatusCell = (status: String) => {
        let color, icon, message;

        switch (status) {
            case InvitationStatus.DECLINED:
                color = 'error';
                icon = <CloseCircleOutlined />;
                message = t('Invitation declined');
                break;
            case InvitationStatus.CANCELED:
                color = 'default';
                icon = <CloseCircleOutlined />;
                message = t('Invitation canceled');
                break;
            case InvitationStatus.EXPIRED:
                color = 'default';
                icon = <ExclamationCircleOutlined />;
                message = t('Invitation expired');
                break;
            case InvitationStatus.PENDING:
            case InvitationStatus.ACCESSED:
                color = 'warning';
                icon = <ClockCircleOutlined />;
                message = t('Pending response');
                break;

            default:
                color = 'default';
                icon = null;
                message = t('No status');
        }

        return (
            <Tag icon={icon} color={color}>
                {message}
            </Tag>
        );
    };

    const columns: ColumnsType<RemappedInvitation> = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => sortColumn(a, b, 'name'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
        },
        {
            title: t('Send date'),
            dataIndex: 'send_date',
            key: 'send_date',
            sorter: (a, b) => sortColumn(a, b, 'send_date'),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('send_date'),
            render: (value) => moment(value).format('DD.MM.YYYY'),
        },
        {
            title: t('Sent by'),
            dataIndex: 'sent_by',
            key: 'sent_by',
            sorter: (a, b) => sortColumn(a, b, 'sent_by'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('sent_by'),
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => sortColumn(a, b, 'status'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('status_message'),
            render: (value) => renderStatusCell(value),
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    {_.status !== InvitationStatus.EXPIRED && _.status !== InvitationStatus.CANCELED && (
                        <Popconfirm
                            title={t('Are you sure you want to cancel the invitation?')}
                            onConfirm={() =>
                                handleInvitationStatusRequest(_.invitationToken, InvitationActions.CANCELED)
                            }
                            onCancel={() => {}}
                            okText={t('Yes')}
                            cancelText={t('No')}
                        >
                            <Button type="primary" shape="circle" icon={<StopOutlined />} />
                        </Popconfirm>
                    )}
                    <Popconfirm
                        title={t('Are you sure you want to delete the invitation?')}
                        onConfirm={() => handleInvitationStatusRequest(_.invitationToken, InvitationActions.DELETED)}
                        onCancel={() => {}}
                        okText={t('Yes')}
                        cancelText={t('No')}
                    >
                        <Button shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={remappedInvitations}
            pagination={getDefaultPaginationProp(invitations.length)}
            loading={isInvitationsLoading}
        />
    );
};
