import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { useLocalization } from 'lib/Localization';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Member, Role } from 'types/models';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { FormInstance } from 'antd/lib/form';
import { OrganizationType } from 'types';
import { useAccount } from 'lib/Account';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
const { formLayout, saveButtonLayout } = SingleColumnFormLayout;

type Props = {
    member?: Member;
    organizationId: number;
    organizationType: OrganizationType;
    roleOptions: DefaultOptionType[];
    saveHandler: (values: Member, form: FormInstance) => Promise<boolean>;
    isMemberSaving: boolean;
};

export const MemberForm = ({
    member,
    organizationId,
    organizationType,
    roleOptions,
    saveHandler,
    isMemberSaving,
}: Props) => {
    const { t } = useLocalization();
    const [form] = Form.useForm();
    const { accountUser: user } = useAccount();

    /**
     * When component receives new member props.
     */
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...member,
            [`${organizationType}_id`]: organizationId,
            roles: member?.user.roles?.map((role: Role) => role.id),
        });
    }, [form, member, organizationId, organizationType]);

    const onFinish = async (values: Member) => {
        // TC-1089
        // Commented out because its hidden temporarily
        // values.roles = form.getFieldValue('roles');

        // @ts-ignore
        values.roles = roleOptions.map((roleOption) => {
            return roleOption.value;
        });

        if (await saveHandler(values, form)) {
            form.resetFields();
            form.setFieldsValue({
                [`${organizationType}_id`]: organizationId,
            });
        }
    };

    const canManageMember = (): boolean => {
        return true;

        // TC-1089
        // Commented out because its hidden temporarily
        // if (member === undefined || user.security.permissions.includes('manage-owner-roles')) {
        //     return true;
        // }
        // // @ts-ignore
        // return (
        //     user.security.permissions.includes(`manage-${organizationType}-roles`) &&
        //     !member.roles.map((role) => role.name).includes(`${organizationType}.owner`)
        // );
    };

    return (
        <Form form={form} onFinish={onFinish} {...formLayout}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name={`${organizationType}_id`} style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: t('Please enter a valid email'),
                    },
                    {
                        required: true,
                        message: t('Please enter an email address'),
                    },
                ]}
            >
                <FloatingLabel label={t('Email')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>
            <Form.Item
                name="first_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a first name')
                    },
                    {
                        max: 150,
                        message: t(`The first name may not be greater than 150 characters.`)
                    }
                ]}
            >
                <FloatingLabel label={t('First name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>
            <Form.Item
                name="last_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter a last name'),
                    },
                    {
                        max: 150,
                        message: t(`The last name may not be greater than 150 characters.`),
                    }
                ]}
            >
                <FloatingLabel label={t('Last name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>
            <Form.Item
                name="phone_number"
                rules={[{
                    max: 20,
                    message: t(`The phone number may not be greater than 20 characters`),
                },
                {
                    min: 9,
                    message: t(`The phone number may not be less than 9 characters`),
                }
                ]}
            >
                <FloatingLabel label={t('Phone')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>
            <Form.Item
                name="job_description"
                rules={[{
                    max: 150,
                    message: t(`The job description may not be greater than 150 characters`),
                }]}
            >
                <FloatingLabel label={t('Job')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>
            {/* TC-1089  */}
            {/* Commented out because its hidden temporarily */}
            {/* <Form.Item
                name="roles"
                rules={[
                    {
                        required: true,
                        message: t('Please enter member role(s)'),
                    },
                ]}
            >
                <FloatingLabel label={t('Roles')} required>
                    <Select
                        mode="multiple"
                        placeholder={t('Assign roles')}
                        className="multi-select"
                        options={roleOptions}
                        disabled={!canManageMember()}
                    />
                </FloatingLabel>
            </Form.Item> */}
            <Form.Item {...saveButtonLayout}>
                <Button type="primary" htmlType="submit" loading={isMemberSaving}>
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form >
    );
};
