import { ProviderRequestState } from 'types/staffing/ProviderRequestStateMachine';
import { useLocalization } from 'lib/Localization';
import { RequestState } from 'types/staffing';
import { TooltipTag } from 'shared/AntDesignUtils/TooltipTag';

type Props = {
    providerRequestState: string;
    requestState: string;
};

export const ProviderRequestHeaderTag = ({ providerRequestState, requestState }: Props) => {
    const { t } = useLocalization();

    const providerRequestStateHeaderTags: Record<string, JSX.Element> = {
        [ProviderRequestState.OPEN]: (
            <TooltipTag color="orange"
                        text={ t('Awaiting response') }
                        title={ t('Your organization has still to accept or reject the request') }
            />
        ),
        [ProviderRequestState.ACCEPTED]: (
            <TooltipTag color="blue"
                        text={ t('Active') }
                        title={ t('The request is being staffed by your organization') }
            />
        ),
        [ProviderRequestState.CONCLUDED]: (
            <TooltipTag color="green"
                        text={ t('Concluded') }
                        title={ t('Your organization has finished the staffing process') }
            />
        ),
        [ProviderRequestState.REJECTED]: (
            <TooltipTag color="red"
                        text={ t('Rejected') }
                        title={ t('Your organization has rejected the request') }
            />
        ),
    };

    const requestStateHeaderTags: Record<string, JSX.Element> = {
        [RequestState.CANCELED]: (
            <TooltipTag color="red"
                        text={ t('Canceled') }
                        title={ t('Client canceled the request') }
            />
        ),
        [RequestState.EXPIRED]: (
            <TooltipTag color="red"
                        text={ t('Expired') }
                        title={ t('The request has expired') }
            />
        ),
    };

    return requestStateHeaderTags[requestState] || providerRequestStateHeaderTags[providerRequestState];
};
