import { useErrorHandler } from 'lib/ErrorHandling';
import { useEffect } from 'react';
import { Backend } from 'lib/Http/Backend';
import useQueryParameters from 'lib/Http/QueryParameters';
import { notification } from 'antd';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { useLocalization } from 'lib/Localization';
import { useNavigate } from 'react-router-dom-v5-compat';

export const VerifyEmail = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const queryParameters = useQueryParameters();
    const email = decodeURIComponent(queryParameters.get('hash') || '');
    const id = decodeURIComponent(queryParameters.get('id') || '');
    const navigate = useNavigate();
    /**
     * After the component renders
     */
    useEffect(() => {
        verifyEmail();
    });

    /**
     * Verify email on backend side.
     *
     * @return {Promise<void>}
     */
    const verifyEmail = async () => {
        try {
            const response = await Backend.get(`account/verify-email/${id}/${email}`);
            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t('Email verified successfully!'),
                });
            }
        } catch (error) {
            handleError(error);
        }

        navigate('/login');
    };

    return <LoaderSkeleton size={2} />;
};
