import { Suspense } from 'react';
import { Layout, Result } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';
import { Organization } from 'shared/Organizations/Organization';
import { Request } from 'pages/Provider/Requests/Request';
import { Departments } from './Configuration/Departments';
import { Addresses } from './Configuration/Addresses';
import { Professions } from 'shared/Configuration/Professions';
import { Providers } from './Providers';
import { RequestCreation, Requests } from './Requests';
import { OrganizationType } from 'types';
import { WorkShifts } from './Configuration/WorkShifts';
import { Settings } from 'shared/Settings';

type Props = {
    setSidebarRequestNumber: Function;
};

/**
 * Render content of the client sub application.
 */
export const Client = ({ setSidebarRequestNumber }: Props) => {
    const match = useRouteMatch();

    return (
        <Layout.Content className="content-body mdc-elevation--z2">
            <Suspense fallback={<LoaderSpin />}>
                <Switch>
                    <Route exact path={[`${match.path}/requests`, '/client']}>
                        <Requests setSidebarRequestNumber={setSidebarRequestNumber} />
                    </Route>
                    <Route path={`${match.path}/requests/create`}>
                        <RequestCreation />
                    </Route>
                    <Route path={`${match.path}/requests/:id`}>
                        <Request />
                    </Route>
                    <Route path={`${match.path}/providers`}>
                        <Providers />
                    </Route>
                    <Route path={`${match.path}/configuration/organization`}>
                        <Organization organizationType={OrganizationType.CLIENT} />
                    </Route>
                    <Route path={`${match.path}/configuration/departments`}>
                        <Departments />
                    </Route>
                    <Route path={ `${ match.path }/configuration/work-shifts` }>
                        <WorkShifts/>
                    </Route>
                    <Route path={ `${ match.path }/configuration/professions` }>
                        <Professions/>
                    </Route>
                    <Route path={ `${ match.path }/configuration/locations` }>
                        <Addresses/>
                    </Route>
                    <Route path={ `${ match.path }/settings` }>
                        <Settings/>
                    </Route>

                    {/*Fallback empty page - has to be last in routes list*/ }
                    <Route path={ `${ match.path }` }>
                        <Result
                            status="404"
                            title="Nothing here yet..."
                            subTitle="We have nothing to display here yet... any ideas? 😀"
                        />
                    </Route>
                </Switch>
            </Suspense>
        </Layout.Content>
    );
};
