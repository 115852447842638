import { DatePicker, Space } from 'antd';

type Props = {
    confirm: () => void;
    format?: string;
    setSelectedKeys: (selectedKeys: string[]) => void;
};

export const TableDatePickerFilterDropdown = ({ confirm, format, setSelectedKeys }: Props) => (
    <div style={{ padding: 8 }}>
        <Space>
            <DatePicker
                format={format}
                onChange={(date: any) => {
                    setSelectedKeys(date ? [date.format('DD-MM-YYYY')] : []);
                    confirm();
                }}
                allowClear={true}
            />
        </Space>
    </div>
);
