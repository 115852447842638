import { cloneElement, useState } from 'react';

import 'shared/AntDesignUtils/FloatingLabel/FloatingLabel.scss';
import { GenericObject } from 'shared/Contracts';

type Props = GenericObject & {
    children: JSX.Element;
    label: string;
    placeholder?: string;
    required?: boolean;
};

export const FloatingLabel = ({ label, placeholder, children, required = false, ...otherProps }: Props) => {
    const [focus, setFocus] = useState(false);

    const { value } = otherProps as { value: string };
    const isOccupied = focus || (value && value?.length !== 0);
    let labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';
    labelClass += focus ? ' focused' : '';
    labelClass += children.props.prefix ? ' with-prefix' : '';
    const requiredMark = required ? <span className="text-danger">*</span> : null;

    if (!placeholder) placeholder = children.props.placeholder || label;

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {cloneElement(children, { ...otherProps, placeholder: '' })}
            <label className={labelClass}>
                {isOccupied ? label : placeholder} {requiredMark}
            </label>
        </div>
    );
};
