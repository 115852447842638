import { Setting } from 'types/models';
import { useEffect, useState } from 'react';
import { useLocalization } from 'lib/Localization';
import { Checkbox, List } from 'antd';
import { GenericObject } from 'shared/Contracts';
import { LoaderSpin } from 'shared/AntDesignUtils/Loaders';

type Props = {
    isSaving: GenericObject;
    settings: Setting[];
    updateSetting: (setting: Setting) => Promise<void>;
}

export const NotificationsTab = ({ isSaving, settings, updateSetting }: Props) => {
    const { t } = useLocalization();

    const [inAppNotifications, setInAppNotifications] = useState<Setting[]>([]);
    const [emailNotifications, setEmailNotifications] = useState<Setting[]>([]);

    useEffect(() => {
        setInAppNotifications(settings.filter((setting) => setting.group.includes('notification') && setting.group.includes('in-app')));
        setEmailNotifications(settings.filter((setting) => setting.group.includes('notification') && setting.group.includes('email')));
    }, [settings]);

    const renderItem = (notification: Setting) => (
        <List.Item className="notification-item">
            {
                isSaving[notification.id] ?
                    <LoaderSpin label={notification.description} inline />:

                    <Checkbox
                        checked={notification.value === 1}
                        onChange={
                            () => updateSetting({
                                ...notification,
                                value: notification.value === 1 ? 0 : 1
                            })
                        }
                    >
                        {notification.description}
                    </Checkbox>
            }
        </List.Item>
    )

    const renderList = (header: string, notifications: Setting[]) => (
        <List
            className="notifications-group"
            header={<h2 className="tab-content-title">{header}</h2>}
            dataSource={notifications}
            renderItem={renderItem}
            size="small"
        />
    );

    return (
        <div>
            {renderList(t('In-app notifications'), inAppNotifications)}

            {renderList(t('Email notifications'), emailNotifications)}
        </div>
    );
}
